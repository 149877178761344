import immutable from 'seamless-immutable';
import { Type as ImplementType } from '../../actions/resources/ImplementsActions';


export const INITIAL_STATE = immutable({
    //dropdowns
    implementGroupDropdown: [],
    groupFetching: false,
    groupFetchingFailed: false,
    implementSubgroupDropdown: [],
    subgroupFetching: false,
    subgroupFetchingFailed: false,
    implementDropdown: [],
    implementDropdownFetching: false,
    implementDropdownFetchingFailed: false,


    //prikljucne
    implementPosting: false,
    implementPostingSuccess: false,
    implementPostingFailed: false,
    implement: [],
    implementObj: {},
    implementFetching: false,
    implementFetchingFailed: false,
    implementPages: 1,

    //prikljucne grupe
    implement_group: [],
    implementGroupObj: {},
    implementGroupsFetching: false,
    implementGroupsFetchingFailed: false,
    implementGroupsPages: 1,
    implementGroupsPosting: false,
    implementGroupsPostingSuccess: false,
    implementGroupsPostingFailed: false,

    //prikljucne podgrupe
    implement_subgroup: [],
    implementSubgroupObj: {},
    implementSubgroupsFetching: false,
    implementSubgroupsFetchingFailed: false,
    implementSubgroupsPages: 1,
    implementSubgroupsPosting: false,
    implementSubgroupsPostingSuccess: false,
    implementSubgroupsPostingFailed: false,

    deleted: false,
    deleteImplementFailed: false,
});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case ImplementType.SET_INITIAL_STATE:
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;



        case ImplementType.GET_IMPLEMENT_GROUP_DROPDOWN_CALL: {
            const groupFetching = true;
            return state.merge({ groupFetching });
            break;
        }

        case ImplementType.GET_IMPLEMENT_GROUP_DROPDOWN_SUCCESS: {
            const implementGroupDropdown = action.data.data;
            const groupFetching = false;
            return state.merge({ implementGroupDropdown, groupFetching });
            break;
        }

        case ImplementType.GET_IMPLEMENT_GROUP_DROPDOWN_FAILED: {
            const groupFetchingFailed = true;
            const groupFetching = false;
            return state.merge({ groupFetchingFailed, groupFetching });
            break;
        }

        case ImplementType.GET_IMPLEMENT_SUBGROUP_DROPDOWN_CALL: {
            const subgroupFetching = true;
            return state.merge({ subgroupFetching });
            break;
        }

        case ImplementType.GET_IMPLEMENT_SUBGROUP_DROPDOWN_SUCCESS: {
            const implementSubgroupDropdown = action.data.data;
            const subgroupFetching = false;
            return state.merge({ implementSubgroupDropdown, subgroupFetching });
            break;
        }

        case ImplementType.GET_IMPLEMENT_SUBGROUP_DROPDOWN_FAILED: {
            const subgroupFetchingFailed = true;
            const subgroupFetching = false;
            return state.merge({ subgroupFetchingFailed, subgroupFetching });
            break;
        }

        case ImplementType.GET_IMPLEMENT_CALL: {
            const implementDropdownFetching = true;
            return state.merge({ implementDropdownFetching });
            break;
        }

        case ImplementType.GET_IMPLEMENT_SUCCESS: {
            const implementDropdown = action.data.data;
            const implementDropdownFetching = false;
            return state.merge({ implementDropdown, implementDropdownFetching });
            break;
        }

        case ImplementType.GET_IMPLEMENT_FAILED: {
            const implementDropdownFetchingFailed = true;
            const implementDropdownFetching = false;
            return state.merge({ implementDropdownFetchingFailed, implementDropdownFetching });
            break;
        }

        case ImplementType.ADD_IMPLEMENT_CALL: {
            const implementPosting = true;
            return state.merge({ implementPosting });
            break;
        }

        case ImplementType.ADD_IMPLEMENT_SUCCESS: {
            const implementPosting = false;
            const implementPostingSuccess = true;
            return state.merge({ implementPostingSuccess, implementPosting, implementObj: INITIAL_STATE.implementObj })
            break;
        }

        case ImplementType.ADD_IMPLEMENT_FAILED: {
            const implementPosting = false;
            const implementPostingFailed = true;
            return state.merge({ implementPosting, implementPostingFailed });
            break;
        }

        case ImplementType.GET_VIEW_IMPLEMENT_CALL: {
            const implementFetching = true;
            return state.merge({ implementFetching });
            break;
        }

        case ImplementType.GET_VIEW_IMPLEMENT_SUCCESS: {
            const implement = action.data.data;
            const implementFetching = false;
            const implementPages = action.data.total_pages
            return state.merge({ implement, implementFetching, implementPages });
            break;
        }

        case ImplementType.GET_VIEW_IMPLEMENT_FAILED: {
            const implementFetchingFailed = true;
            const implementFetching = false;
            return state.merge({ implementFetching, implementFetchingFailed });
            break;
        }

        case ImplementType.GET_IMPLEMENT_GROUP_CALL: {
            const implementGroupsFetching = true;
            return state.merge({ implementGroupsFetching });
            break;
        }

        case ImplementType.GET_IMPLEMENT_GROUP_SUCCESS: {
            const implement_group = action.data.data;
            const implementGroupsFetching = false;
            const implementGroupsPages = action.data.total_pages
            return state.merge({ implement_group, implementGroupsFetching, implementGroupsPages });
            break;
        }

        case ImplementType.GET_IMPLEMENT_GROUP_FAILED: {
            const implementGroupsFetchingFailed = true;
            const implementGroupsFetching = false;
            return state.merge({ implementGroupsFetching, implementGroupsFetchingFailed });
            break;
        }

        case ImplementType.ADD_IMPLEMENT_GROUP_CALL: {
            const implementGroupsPosting = true;
            return state.merge({ implementGroupsPosting });
            break;
        }

        case ImplementType.ADD_IMPLEMENT_GROUP_SUCCESS: {
            const implementGroupsPosting = false;
            const implementGroupsPostingSuccess = true;
            return state.merge({ implementGroupsPostingSuccess, implementGroupsPosting, implementGroupObj: INITIAL_STATE.implementGroupObj })
            break;
        }

        case ImplementType.ADD_IMPLEMENT_GROUP_FAILED: {
            const implementGroupsPosting = false;
            const implementGroupsPostingFailed = true;
            return state.merge({ implementGroupsPosting, implementGroupsPostingFailed });
            break;
        }

        case ImplementType.GET_IMPLEMENT_SUBGROUP_CALL: {
            const implementSubgroupsFetching = true;
            return state.merge({ implementSubgroupsFetching });
            break;
        }

        case ImplementType.GET_IMPLEMENT_SUBGROUP_SUCCESS: {
            const implement_subgroup = action.data.data;
            const implementSubgroupsFetching = false;
            const implementSubgroupsPages = action.data.total_pages
            return state.merge({ implement_subgroup, implementSubgroupsFetching, implementSubgroupsPages });
            break;
        }

        case ImplementType.GET_IMPLEMENT_SUBGROUP_FAILED: {
            const implementSubgroupsFetchingFailed = true;
            const implementSubgroupsFetching = false;
            return state.merge({ implementSubgroupsFetching, implementSubgroupsFetchingFailed });
            break;
        }

        case ImplementType.ADD_IMPLEMENT_SUBGROUP_CALL: {
            const implementSubgroupsPosting = true;
            return state.merge({ implementSubgroupsPosting });
            break;
        }

        case ImplementType.ADD_IMPLEMENT_SUBGROUP_SUCCESS: {
            const implementSubgroupsPosting = false;
            const implementSubgroupsPostingSuccess = true;
            return state.merge({ implementSubgroupsPostingSuccess, implementSubgroupsPosting, implementSubgroupObj: INITIAL_STATE.implementSubgroupObj })
            break;
        }

        case ImplementType.ADD_IMPLEMENT_SUBGROUP_FAILED: {
            const implementSubgroupsPosting = false;
            const implementSubgroupsPostingFailed = true;
            return state.merge({ implementSubgroupsPosting, implementSubgroupsPostingFailed });
            break;
        }

        case ImplementType.GET_VIEW_IMPLEMENT_CALL: {
            const implementFetching = true;
            return state.merge({ implementFetching });
            break;
        }

        case ImplementType.GET_VIEW_IMPLEMENT_SUCCESS: {
            const implement = action.data.data;
            const implementFetching = false;
            const implementPages = action.data.total_pages
            return state.merge({ implement, implementFetching, implementPages });
            break;
        }

        case ImplementType.GET_VIEW_IMPLEMENT_FAILED: {
            const implementFetchingFailed = true;
            const implementFetching = false;
            return state.merge({ implementFetching, implementFetchingFailed });
            break;
        }

        case ImplementType.SET_IMPLEMENT_ROW_IN_REDUCER: {
            const data = action.rowInfo.asMutable();
            return state.merge({ [action.name]: data })
            break;
        }

        case ImplementType.DELETE_IMPLEMENT_SUCCESS: {
            const array = state[action.name].asMutable();
            const forDelete = action.forDelete;
            for (let i = 0; i < array.length; i++) {
                for (let j = 0; j <= forDelete.length; j++) {
                    if (array[i] && array[i].id === forDelete[j]) {
                        array.splice(i, 1);
                    }
                }
            }
            const deleted = true;
            return state.merge({ [action.name]: array, deleted: deleted })
            break;
        }

        case ImplementType.DELETE_IMPLEMENT_FAILED: {
            const deleteImplementFailed = true;
            return state.merge({ deleteImplementFailed })
        }

        default:
            return state;
    }
}