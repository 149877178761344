import React from 'react';
import i18n from '../../../i18n/i18n';
import { Bar } from 'react-chartjs-2';
import { Link, Typography } from '@material-ui/core';
import { formatWorkPlanningData } from '../DataFormatters';
import { withStyles } from '@material-ui/core/styles';
import { MoonLoader } from 'react-spinners';
import { Loader } from './../Loader';
import { mode } from 'crypto-js';

const styles = {
    value: { width: '8px', height: '8px', margin: '3px', borderRadius: '3px', padding: '8px', textAlign: 'center', fontWeight: 'bold', font: '12px', color: 'white' }
};

export class SowingPotato extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            load: true
        };
    };

    componentDidMount = () => {
        Loader(this.props, ['agromodelsowing-1h'], ['sowingCalled'], ['sowing'])
    };

    render() {
        const { classes } = this.props;
        let data = {}, colors = [], graph1 = {};
        if (Object.keys(this.props.data).length > 0) data = formatWorkPlanningData(this.props.data);

        if (Object.keys(data).length > 0) {
            let model = [];
            model = data['sowing_potato'];

            let work_data = [];
            work_data = model.map((item, i) => {
                let x = null;
                if (item === 0) x = 3;
                else if (item === 1) x = 2;
                else if (item === 2) x = 1;
                return x;
            });

            work_data.forEach((item, i) => {
                if (item === 3) colors.push('rgb(108, 199, 86)');
                else if (item === 2) colors.push('rgb(235, 230, 94)');
                else if (item === 1) colors.push('rgb(227, 73, 73)');
                else colors.push('rgb(54, 54, 54');
            });

            if (data.dates !== undefined) {
                graph1 = {
                    labels: data.dates,
                    datasets: [
                        {
                            data: work_data,
                            label: i18n.t('Value'),
                            backgroundColor: colors,
                            type: 'bar',
                            borderWidth: 2,
                            yAxisID: 'y-axis-1',
                        },
                    ],
                };
            }
        }

        const options1 = {
            maintainAspectRatio: false,
            legend: { display: false },
            tooltips: {
                displayColors: false,
                callbacks: {
                    label: (tooltipItem, chart) => graph1.datasets.map(ds => ds.label + ': ' + ds.data[tooltipItem.index]),
                },
            },
            scales: {
                xAxes: [
                    {
                        barPercentage: 1,
                        categoryPercentage: 1,
                        gridLines: { drawOnChartArea: false },
                    },
                ],
                yAxes: [
                    {
                        type: 'linear',
                        display: false,
                        id: 'y-axis-1',
                        gridLines: { drawOnChartArea: false },
                        ticks: {
                            min: 0,
                            max: 3,
                        },
                        categoryPercentage: 1.0,
                        barPercentage: 1.0
                    },
                ],
            },
        };

        return (
            <div style={{ paddingLeft: '35px' }}>
                {Object.keys(data).length === 0 && <div style={{ marginLeft: '39%', marginTop: '14%' }}> <MoonLoader loading={this.state.load} size={100} /></div>}
                {Object.keys(data).length > 0 &&
                    <div>
                        <div style={{ marginTop: '30px', textAlign: "center", marginBottom: '40px', marginRight: '35px' }}>
                            <span>{i18n.t('Very bad conditions - ')}</span>
                            <span className={classes.value} style={{ background: 'rgb(227, 73, 73)' }}>1</span>
                            <span className={classes.value} style={{ background: 'rgb(235, 230, 94)' }}>2</span>
                            <span className={classes.value} style={{ background: 'rgb(108, 199, 86)' }}>3</span>
                            <span>{i18n.t(' - very good conditions')}</span>
                        </div>
                        <div style={{ width: '95%', marginTop: '10px' }}>
                            <Bar data={graph1} height={360} width={600} options={options1} />
                        </div>
                    </div>
                }
            </div>
        );
    }
};

export default withStyles(styles)(SowingPotato);