import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n/i18n';
import { Button, IconButton, withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setInitialState, addUser } from '../../actions/users/UserActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactSelect from '../../utils/ReactSelect';
import axios from '../../utils/AxiosWrapper';
const styles = theme => ({
})
export class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userObj: {},
            sectorDropdown: [],
        }
    }

    componentDidMount() {
        const { userObj } = this.props;
        if (userObj) {
            this.setState({
                userObj
            })
        }

        axios.get('api/sektor/drop_down')
            .then(res => this.setState({ sectorDropdown: res.data.data }))
            .catch(err => console.log('sektor dropdown ERROR', err));
    }


    componentDidUpdate(prevProps) {
        const { userPostingFailed, setInitialState } = this.props;
        if (prevProps.userPostingFailed !== userPostingFailed) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
            setInitialState('userPostingFailed');
        }
    }

    addUser = () => {
        const { addUser } = this.props;
        const { userObj } = this.state;
        if (userObj.password === userObj.repeat_password) {
            if (userObj.ime && userObj.prezime && userObj.username && userObj.id_korisnik_grupa && (userObj.id_korisnik_grupa === 11 ? userObj.id_sektor : true)) {
                delete userObj.repeat_password;
                delete userObj.sektor_naziv;

                if (userObj.id_korisnik_grupa !== 11) delete userObj.id_sektor;

                addUser(
                    userObj
                )
            } else {
                toast.error(i18n.t('Please enter all required fields!'), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    draggablePercent: 80
                });
            }
        } else {
            toast.error(i18n.t('Passwords do not match!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    editUser = () => {
        const { addUser } = this.props;
        const { userObj } = this.state;
        if (userObj.ime && userObj.prezime && userObj.username && userObj.id_korisnik_grupa && (userObj.id_korisnik_grupa === 11 ? userObj.id_sektor : true)) {
            delete userObj.sektor_naziv;
            if (userObj.id_korisnik_grupa !== 11) delete userObj.id_sektor;

            addUser(
                userObj
            )
        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    handleChangeValue = name => event => {
        const { userObj } = this.state;
        let obj = Object.assign({}, userObj);
        obj[name] = event.target.value;
        this.setState({ userObj: obj });
    };

    handleChangeDropdown = name => selected => {
        const { userObj } = this.state;
        let obj = Object.assign({}, userObj);
        obj[name] = selected && selected.value || null;
        this.setState({ userObj: obj });
    }


    render() {
        const { add, handleClose, edit, theme } = this.props;
        const { userObj } = this.state;
        let korisnikGrupe = [{ label: 'Admin', value: 10 }, { label: 'Operater', value: 11 }]
        return (
            <div style={{ padding: 20 }}>
                <Dialog
                    open={add}
                    onClose={handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} style={{ background: theme.palette.secondary.dark }} className="responsive-dialog-title">{edit ? i18n.t('Edit user') : i18n.t('Add user')}
                        <IconButton onClick={handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container justify="space-between" alignContent="space-between" spacing={16}>
                            <Grid item>
                                <TextField
                                    required
                                    label={i18n.t('First name')}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    value={userObj.ime}
                                    onChange={this.handleChangeValue('ime')}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    required
                                    label={i18n.t('Last name')}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    value={userObj.prezime}
                                    onChange={this.handleChangeValue('prezime')}
                                />
                            </Grid>
                            <Grid item>
                                <ReactSelect
                                    closeMenuOnSelect={true}
                                    isClearable={true}
                                    isMulti={false}
                                    menuPlacement="auto"
                                    value={korisnikGrupe.find((element) => {
                                        return element.value === userObj.id_korisnik_grupa
                                    })}
                                    label={i18n.t('User role') + '*'}
                                    maxMenuHeight={100}
                                    onChange={this.handleChangeDropdown('id_korisnik_grupa')}
                                    options={korisnikGrupe}
                                    className="react-select-dropdown"
                                    classNamePrefix="select"
                                    placeholder={i18n.t('Select')}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    required
                                    label={i18n.t('Username')}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    value={userObj.username}
                                    onChange={this.handleChangeValue('username')}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    label={i18n.t('Email')}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    value={userObj.mejl}
                                    onChange={this.handleChangeValue('mejl')}
                                />
                            </Grid>
                            {!userObj.id &&
                                <Grid item>
                                    <TextField
                                        required
                                        type="password"
                                        label={i18n.t('Password')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={userObj.password}
                                        onChange={this.handleChangeValue('password')}
                                    />
                                </Grid>
                            }
                            {!userObj.id &&
                                <Grid item>
                                    <TextField
                                        required
                                        type="password"
                                        label={i18n.t('Repeat password')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={userObj.repeat_password}
                                        onChange={this.handleChangeValue('repeat_password')}
                                    />
                                </Grid>
                            }
                            <Grid item>
                                <TextField
                                    label={i18n.t('Mobile phone')}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    value={userObj.mobilni}
                                    onChange={this.handleChangeValue('mobilni')}
                                />
                            </Grid>

                            {(userObj.id_korisnik_grupa === 11) && <Grid item>
                                <TextField
                                    required
                                    select
                                    label={i18n.t('Sector')}
                                    value={userObj.id_sektor}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('id_sektor')}
                                >

                                    <option value='' />
                                    {this.state.sectorDropdown.map((sektor, index) => {
                                        return <option key={index} value={sektor.id}>{sektor.naziv}</option>
                                    })}
                                </TextField>
                            </Grid>}


                        </Grid>
                        <Button
                            style={{ color: 'white', display: 'block', marginLeft: 'auto' }}
                            variant="contained"
                            color="primary"
                            onClick={!userObj.id && this.addUser || this.editUser}
                        >
                            {edit ? i18n.t('Save') : i18n.t('Add')}
                        </Button>
                    </DialogContent>
                </Dialog>
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        sectorPosting: state.userReducer.sectorPosting,
        userPostingFailed: state.userReducer.userPostingFailed,
        userObj: state.userReducer.userObj,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setInitialState: (component) => dispatch(setInitialState(component)),
        addUser: (userObj) => dispatch(addUser(userObj)),
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(AddUser))