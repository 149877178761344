import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Card } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './ImageryStyles';
import ndmi from './../satelliteinfo/ndmi.png';
import ndmi2 from './../satelliteinfo/ndmi2.png';
import ndmi3 from './../satelliteinfo/ndmi3.png';

export class NDMI extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.props.language === 1 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                Indeks normalizovane razlike vlage (voda) (NDMI) je pokazatelj vlažnosti prisutne u listovima biljaka.
                                Koristi se za detekciju suše i procenu posledica koji je nedostatak padavina ostavio na useve. Refleksija
                                SVIR reflektuje promene kako sadržaja vode vegetacije, tako i strukture mezofilnog sloja, dok na
                                refleksiju NIR utiču unutrašnja struktura lista i sadržaj suvih materija listova, ali ne sadržaj vode.
                            </p>
                            <div className={classes.image}>
                                <img src={ndmi} />
                            </div>
                            <p className={classes.paragraph}>
                                Kombinacija NIR sa SVIR uklanja varijacije indukovane unutrašnjom strukturom lista i sadržajem suvih
                                materija listova, poboljšavajući tačnost pri utvrđivanju sadržaja vode vegetacije.
                            </p>
                            <div className={classes.image}>
                                <img src={ndmi2} />
                            </div>
                            <div className={classes.image}>
                                <img src={ndmi3} />
                            </div>
                        </div>
                    </Card>
                }
                {this.props.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                Normalized Difference Moisture (Water) Index (NDMI or NDWI) is a satellite-derived index from the
                                Near-Infrared (NIR) and Short Wave Infrared (SWIR) channels. The SWIR reflectance reflects changes in
                                both the vegetation water content and the spongy mesophyll structure in vegetation canopies, while the
                                NIR reflectance is affected by leaf internal structure and leaf dry matter content but not by water
                                content. The combination of the NIR with the SWIR removes variations induced by leaf internal structure
                                and leaf dry matter content, improving the accuracy in retrieving the vegetation water content. The
                                amount of water available in the internal leaf structure largely controls the spectral reflectance in the
                                SWIR interval of the electromagnetic spectrum. SWIR reflectance is therefore negatively related to leaf
                                water content.
                            </p>
                            <div className={classes.image}>
                                <img src={ndmi} />
                            </div>
                            <p className={classes.paragraph}>
                                It is used for the detection of drought and an assessment of the consequences that the lack of
                                precipitation left on crops.
                            </p>
                            <div className={classes.image}>
                                <img src={ndmi2} />
                            </div>
                            <div className={classes.image}>
                                <img src={ndmi3} />
                            </div>
                        </div>
                    </Card>
                }
                {this.props.language === 4 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                Indeks normalizovane razlike vlage (voda) (NDMI) je pokazatelj vlažnosti prisutne u listovima biljaka.
                                Koristi se za detekciju suše i procenu posledica koji je nedostatak padavina ostavio na useve. Refleksija
                                SVIR reflektuje promene kako sadržaja vode vegetacije, tako i strukture mezofilnog sloja, dok na
                                refleksiju NIR utiču unutrašnja struktura lista i sadržaj suvih materija listova, ali ne sadržaj vode.
                            </p>
                            <div className={classes.image}>
                                <img src={ndmi} />
                            </div>
                            <p className={classes.paragraph}>
                                Kombinacija NIR sa SVIR uklanja varijacije indukovane unutrašnjom strukturom lista i sadržajem suvih
                                materija listova, poboljšavajući tačnost pri utvrđivanju sadržaja vode vegetacije.
                            </p>
                            <div className={classes.image}>
                                <img src={ndmi2} />
                            </div>
                            <div className={classes.image}>
                                <img src={ndmi3} />
                            </div>
                        </div>
                    </Card>
                }
                {this.props.language === 5 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Indeks normalizirane razlike vlage (vode) (NDMI) je indikator vlage, prisotne v listih rastlin.
                                Uporablja se za odkrivanje suše in oceno učinkov pomanjkanja padavin na pridelke. Odsev
                                SVIR odraža spremembe tako v vsebnosti vode v vegetaciji kot v strukturi mezofilne plasti, medtem ko je na
                                Na odboj NIR vplivata notranja struktura lista in vsebnost suhe snovi, ne pa tudi vsebnost vode.

                            </p>
                            <div className={classes.image}>
                                <img src={ndmi} />
                            </div>
                            <p className={classes.paragraph}>
                            Kombinacija NIR s SVIR odstrani variacije, ki jih povzroči notranja struktura listov in vsebnost suhe snovi
                                listna snov, izboljšanje natančnosti pri določanju vsebnosti vode v vegetaciji.
                            </p>
                            <div className={classes.image}>
                                <img src={ndmi2} />
                            </div>
                            <div className={classes.image}>
                                <img src={ndmi3} />
                            </div>
                        </div>
                    </Card>
                }
            </div>
        )
    }
};

export default withStyles(styles)(NDMI);