import axios from '../../utils/AxiosWrapper';

export const Type = {
    //REPORTS

    GET_NOTE_REPORT_CALL: 'GET_NOTE_REPORT_CALL',
    GET_NOTE_REPORT_SUCCESS: 'GET_NOTE_REPORT_SUCCESS',
    GET_NOTE_REPORT_FAILED: 'GET_NOTE_REPORT_FAILED',
    //DASHBOARDS

    GET_NOTE_DASHBOARD_CALL: 'GET_NOTE_DASHBOARD_CALL',
    GET_NOTE_DASHBOARD_SUCCESS: 'GET_NOTE_DASHBOARD_SUCCESS',
    GET_NOTE_DASHBOARD_FAILED: 'GET_NOTE_DASHBOARD_FAILED',

    GET_NOTE_GROUP_DROPDOWN_CALL: 'GET_NOTE_GROUP_DROPDOWN_CALL',
    GET_NOTE_GROUP_DROPDOWN_SUCCESS: 'GET_NOTE_GROUP_DROPDOWN_SUCCESS',
    GET_NOTE_GROUP_DROPDOWN_FAILED: 'GET_NOTE_GROUP_DROPDOWN_FAILED',

    NOTE_SENDING_CALL: 'NOTE_SENDING_CALL',
    NOTE_SENDING_SUCCESS: 'NOTE_SENDING_SUCCESS',
    NOTE_SENDING_FAILED: 'NOTE_SENDING_FAILED',

    SEND_NOTE_DOCUMENT_SUCCESS: 'SEND_NOTE_DOCUMENT_SUCCESS',
    SEND_NOTE_DOCUMENT_FAILED: 'SEND_NOTE_DOCUMENT_FAILED',

    NOTE_DOCUMENTS_CALL: 'NOTE_DOCUMENTS_CALL',
    NOTE_DOCUMENTS_SUCCESS: 'NOTE_DOCUMENTS_SUCCESS',
    NOTE_DOCUMENTS_FAILED: 'NOTE_DOCUMENTS_FAILED',

    NOTE_FETCHING_CALL: 'NOTE_FETCHING_CALL',
    NOTE_FETCHING_SUCCESS: 'NOTE_FETCHING_SUCCESS',
    NOTE_FETCHING_FAILED: 'NOTE_FETCHING_FAILED',


    SET_INITIAL_STATE: 'SET_INITIAL_STATE',

    DELETE_NOTE_CALL: 'DELETE_NOTE_CALL',
    DELETE_NOTE_SUCCESS: 'DELETE_NOTE_SUCCESS',
    DELETE_NOTE_FAILED: 'DELETE_NOTE_FAILED',


};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function getNoteReport(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_NOTE_REPORT_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/report/field_note', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_NOTE_REPORT_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_NOTE_REPORT_FAILED
                });
            });
    }
}


export function getNoteDashboard(filters) {
    return (dispatch) => {
        dispatch({
            type: Type.GET_NOTE_DASHBOARD_CALL
        });

        return axios.get('api/report/dashboard_note', { params: { filters } })
            .then(function (response) {
                dispatch({
                    type: Type.GET_NOTE_DASHBOARD_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_NOTE_DASHBOARD_FAILED
                });
            });
    }
}

export function getNoteGroupDropDown() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_NOTE_GROUP_DROPDOWN_CALL
        });

        axios.get('api/note/group_drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_NOTE_GROUP_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_NOTE_GROUP_DROPDOWN_FAILED
                });
            });
    }
}

export function addFieldNote(notesObj, acceptedDocuments) {
    return (dispatch) => {

        dispatch({
            type: Type.NOTE_SENDING_CALL
        });

        axios.post('api/note/create', notesObj)
            .then(function (response) {
                if (!!acceptedDocuments && acceptedDocuments.length != 0) {
                    var data = new FormData();

                    acceptedDocuments.map(doc => {
                        data.append('id_terenska_beleska', response.data.data.id);
                        data.append('document', doc);
                    })

                    axios({
                        url: `api/note/upload_document`,
                        method: 'POST',
                        onUploadProgress: function (progressEvent) {
                            console.log('wwww', Math.round((progressEvent.loaded * 100) / progressEvent.total));
                        },
                        data: data,
                        headers: { 'Content-Type': 'multipart/form-data' }
                    }).then((resp) => {
                        dispatch({
                            type: Type.SEND_NOTE_DOCUMENT_SUCCESS
                        });
                        dispatch({
                            type: Type.NOTE_SENDING_SUCCESS,
                            data: response.data
                        });
                    }).catch(function (error) {
                        dispatch({
                            type: Type.SEND_NOTE_DOCUMENT_FAILED
                        })
                    });
                } else {
                    dispatch({
                        type: Type.SEND_NOTE_DOCUMENT_SUCCESS
                    });
                    dispatch({
                        type: Type.NOTE_SENDING_SUCCESS,
                        data: response.data
                    });
                }
            })
            .catch(function (error) {
                dispatch({
                    type: Type.NOTE_SENDING_FAILED
                });
            });
    }
}

export function getNoteDocument(id_terenska_beleska) {
    return (dispatch) => {

        dispatch({
            type: Type.NOTE_DOCUMENTS_CALL,
        });

        axios.get(`api/note/document?id_terenska_beleska=${id_terenska_beleska}`)
            .then(function (response) {
                dispatch({
                    type: Type.NOTE_DOCUMENTS_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.NOTE_DOCUMENTS_FAILED
                });
            });
    }
}

export function getFieldNoteById(id) {
    return (dispatch) => {

        dispatch({
            type: Type.NOTE_FETCHING_CALL
        });

        axios.get('api/note/getById?id=' + id)
            .then(function (response) {
                dispatch({
                    type: Type.NOTE_FETCHING_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.NOTE_FETCHING_FAILED
                });
            });
    }
}

export function deleteNote(selection) {
    return (dispatch) => {

        dispatch({
            type: Type.DELETE_NOTE_CALL,
        });

        axios.post(`api/note/delete`, { selection: selection })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_NOTE_SUCCESS,
                    data: response.data,
                    forDelete: selection
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_NOTE_FAILED
                });
            });
    }
}

