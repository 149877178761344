import React from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';

export class NumberRangeForFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
        this.props.onChange({
            ...this.state,
            [name]: event.target.value,
        });
    };

    render() {

        return (
            <form className="numberFilterForm" >
                <TextField
                    id="number"
                    // label="<"
                    placeholder="<"
                    value={this.state.lt}
                    onChange={this.handleChange('lt')}
                    type="number"
                />
                <br/>
                <TextField
                    id="number"
                    // label=">"
                    placeholder=">"
                    value={this.state.gt}
                    onChange={this.handleChange('gt')}
                    type="number"
                />
                <br />
                <TextField
                    id="number"
                    // label="="
                    placeholder="="
                    value={this.state.eq}
                    onChange={this.handleChange('eq')}
                    type="number"
                />
            </form>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NumberRangeForFilter);