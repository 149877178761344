import axios from '../../utils/AxiosWrapper';
export const Type = {
    //VIEW
    GET_USERS_CALL: 'GET_USERS_CALL',
    GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
    GET_USERS_FAILED: 'GET_USERS_FAILED',
    //ADD
    ADD_USER_CALL: 'ADD_USER_CALL',
    ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
    ADD_USER_FAILED: 'ADD_USER_FAILED',
    //DELETE
    DELETE_USER_CALL: 'DELETE_USER_CALL',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_FAILED: 'DELETE_USER_FAILED',

    //UTILS
    SET_INITIAL_STATE: 'SET_INITIAL_STATE',
    SET_USER_IN_REDUCER: 'SET_USER_IN_REDUCER',
};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function setRowInReducer(rowInfo) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_USER_IN_REDUCER,
            rowInfo: rowInfo,
        })
    }
}

export function addUser(userObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_USER_CALL
        });

        axios.post('api/korisnik/create',
            userObj
        )
            .then(function (response) {
                dispatch({
                    type: Type.ADD_USER_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_USER_FAILED
                });
            });
    }
}

export function getUsers(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_USERS_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/korisnik/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_USERS_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_USERS_FAILED
                });
            });
    }
}

export function deleteUser(selection) {
    return (dispatch) => {

        dispatch({
            type: Type.DELETE_USER_CALL,
        });

        axios.post(`api/korisnik/delete`, { selection: selection })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_USER_SUCCESS,
                    data: response.data,
                    forDelete: selection
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_USER_FAILED
                });
            });
    }
}
