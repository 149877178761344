import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Card } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './ImageryStyles';
import ndvi from './../satelliteinfo/ndvi.png';

export class NDVI extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.props.language === 1 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                NDVI (Indeks normalizovane razlike vegetacije) je jednostavan grafički indikator koji pokazuje da li na
                                posmatranoj površini postoji vegetacija i u kom je stanju. NDVI je pokazatelj intenziteta fotosinteze. Kada
                                je zemljište golo ili su usevi tek nikli, NDVI je blizak nuli. Međutim, kako biljke formiraju više listova i
                                spajaju redove, povećava se intenzitet fotosinteze i NDVI raste. Veći NDVI na početku sezone može
                                označavati brži rast useva, ali i pojavu korova, dok manji NDVI u kasnijim fazama razvoja biljaka može
                                označavati manjak vode, nutritijenata ili pojavu bolesti.
                            </p>
                            <p className={classes.paragraph}>
                                Vrednosti NDVI su u opsegu od -1 do +1. Gusta vegetacija obično ima vrednosti od 0,3 do 0,8. Oblaci i
                                snežni pokrivač imaju negativne vrednosti NDVI. Vodene površine (okeani, mora, jezera i reke) imaju
                                niske refleksije u oba spektralna opsega tako da NDVI za vodene površine ima veoma nisku pozitivnu
                                vrednost ili čak i negativnu. Golo zemljište ima malo veću refleksiju u blisko-infracrvenom delu tako da je
                                vrednost NDVI pozitivna od 0,1 do 0,2.
                            </p>
                            <div className={classes.image}>
                                <img src={ndvi} />
                            </div>
                            <p className={classes.paragraph}>
                                Naziva se i „indeks zelenila“, koristan za utvrđivanje produktivnosti vegetacije, jer hlorofil snažno
                                apsorbuje svetlost u crvenom delu spektra, dok se struktura lisnih ćelija snažno reflektuje u
                                blisko-infracrvenom delu spektra. Intenzitet fotosinteze je u pozitivnoj korealciji sa količinom
                                fotosintetski aktivnog zračenja koje biljka apsorbuje. Ispitivanjem NDVI i drugih sličnih indeksa
                                poljoprivrednici su u stanju da identifikuju varijabilnost useva i da prilagode primene dohrane, kao što su
                                đubrivo i pesticidi, koristeći varijabilne načine aplikacija. Međutim, uvek se savetuje kombinovanje NDVI
                                indeksa sa drugim parametrima za postizanje boljih rezultata.
                            </p>
                        </div>
                    </Card>
                }
                {this.props.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            NDVI (Normalized Difference Vegetation Index) is a simple graphic indicator that shows whether there is vegetation on the 
                            observed surface and in what condition it is. NDVI is an indicator of the intensity of photosynthesis. When the soil is 
                            bare or crops have just emerged, the NDVI is close to zero. However, as plants form more leaves and join rows, photosynthetic 
                            intensity increases and NDVI increases. A higher NDVI at the beginning of the season can indicate faster crop growth, but also the 
                            appearance of weeds, while a lower NDVI in the later stages of plant development can indicate a lack of water, nutrients or the appearance of diseases.
                            NDVI values range from -1 to +1. Dense vegetation usually has values from 0.3 to 0.8. Clouds and snow cover have negative NDVI values. Water surfaces 
                            (oceans, seas, lakes and rivers) have low reflectance in both spectral bands so that NDVI for water surfaces has a very low positive value or even a negative one. 
                            Bare soil has a slightly higher reflectance in the near-infrared part so that the NDVI value is positive from 0.1 to 0.2.
                            It is also called the "greenness index", useful for determining the productivity of vegetation, because chlorophyll strongly absorbs 
                            light in the red part of the spectrum, while the structure of leaf cells strongly reflects in the near-infrared part of the spectrum. 
                            The intensity of photosynthesis is positively correlated with the amount of photosynthetically active radiation that the plant absorbs. 
                            By examining NDVI and other similar indices, farmers are able to identify crop variability and adjust supplemental applications, 
                            such as fertilizer and pesticides, using variable application rates. 
                            However, it is always advisable to combine the NDVI index with other parameters to achieve better results.

                            </p>
                    
                            <div className={classes.image}>
                                <img src={ndvi} />
                            </div>
                        </div>
                    </Card>
                }
                {this.props.language === 4 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                NDVI (Normalized Difference Vegetation Index) je jednostavan grafički indikator koji pokazuje 
                                ima li vegetacije na promatranoj površini i u kakvom je stanju. NDVI je pokazatelj intenziteta 
                                fotosinteze. Kada je tlo golo ili su usjevi tek iznikli, NDVI je blizu nule. Međutim, 
                                kako biljke formiraju više lišća i spajaju redove, povećava se intenzitet fotosinteze i 
                                povećava se NDVI. Viši NDVI na početku sezone može ukazivati na brži rast usjeva, ali i pojavu 
                                korova, dok niži NDVI u kasnijim fazama razvoja biljke može ukazivati na nedostatak vode, 
                                hranjiva ili pojavu bolesti.
                            </p>
                            <p className={classes.paragraph}>
                                NDVI vrijednosti se kreću od -1 do +1. Gusta vegetacija obično ima vrijednosti od 0,3 do 0,8. 
                                Oblaci i snježni pokrivač imaju negativne NDVI vrijednosti. Vodene površine (oceani, mora, jezera i rijeke)
                                imaju nisku refleksiju u oba spektralna pojasa tako da NDVI za vodene površine ima vrlo nisku 
                                pozitivnu ili čak negativnu vrijednost. Golo tlo ima nešto veću refleksiju u bliskom infracrvenom 
                                dijelu tako da je NDVI vrijednost pozitivna od 0,1 do 0,2.
                            </p>
                            <div className={classes.image}>
                                <img src={ndvi} />
                            </div>
                            <p className={classes.paragraph}>
                                Također se naziva i "indeks zelenila", koristan za određivanje produktivnosti vegetacije, 
                                jer klorofil snažno apsorbira svjetlost u crvenom dijelu spektra, dok se struktura stanica 
                                lista snažno reflektira u bliskom infracrvenom dijelu spektra. Intenzitet fotosinteze u 
                                pozitivnoj je korelaciji s količinom fotosintetski aktivnog zračenja koje biljka apsorbira. 
                                Ispitivanjem NDVI i drugih sličnih indeksa farmeri mogu identificirati stanje usjeva i primenit 
                                varijabilne količine gnojiva za ishranu bilja. Međutim, uvijek je preporučljivo kombinirati 
                                NDVI indeks s drugim parametrima kako bi se postigli bolji rezultati.
                            </p>
                        </div>
                    </Card>
                }
                {this.props.language === 5 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            NDVI (Normalized Difference Vegetation Index) je preprost grafični indikator, ki prikazuje, ali
                                je na opazovani površini vegetacija in v kakšnem stanju je. NDVI je indikator intenzivnosti fotosinteze. Kdaj
                                če so tla gola ali so posevki pravkar vzklili, je NDVI blizu ničle. Ker pa rastline tvorijo več listov in
                                združijo vrstice, se poveča intenzivnost fotosinteze in poveča NDVI. Višji NDVI na začetku sezone lahko
                                kažejo na hitrejšo rast pridelka, pa tudi na pojav plevela, medtem ko lahko nižji NDVI v poznejših fazah razvoja rastlin
                                kažejo na pomanjkanje vode, hranil ali bolezni.

                            </p>
                            <p className={classes.paragraph}>
                            Vrednosti NDVI se gibljejo od -1 do +1. Gosta vegetacija ima običajno vrednosti od 0,3 do 0,8. Oblaki in
                                snežna odeja ima negativne vrednosti NDVI. Vodna telesa (oceani, morja, jezera in reke) imajo
                                nizka odbojnost v obeh spektralnih pasovih, tako da ima NDVI za vodna telesa zelo nizek pozitiven
                                vrednosti ali celo negativno. Gola prst ima nekoliko večjo odbojnost v bližnjem infrardečem delu, zato je
                                Vrednost NDVI pozitivna od 0,1 do 0,2.  
                            </p>
                            <div className={classes.image}>
                                <img src={ndvi} />
                            </div>
                            <p className={classes.paragraph}>
                            Imenuje se tudi "indeks zelenosti", uporaben za določanje produktivnosti vegetacije, ker je klorofil močan
                                absorbira svetlobo v rdečem delu spektra, medtem ko se struktura listnih celic močno odbija v
                                bližnjem infrardečem delu spektra. Intenzivnost fotosinteze je v pozitivni korelaciji s količino
                                fotosintetsko aktivno sevanje, ki ga absorbira rastlina. S pregledom NDVI in drugih podobnih indeksov
                                kmetje lahko prepoznajo variabilnost pridelka in prilagodijo dodatne aplikacije, kot je npr
                                gnojil in pesticidov z uporabo različnih načinov uporabe. Vendar je vedno priporočljiva kombinacija NDVI
                                indeksa z drugimi parametri za doseganje boljših rezultatov.  
                            </p>
                        </div>
                    </Card>
                }
            </div>
        )
    }
};

export default withStyles(styles)(NDVI);