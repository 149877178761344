/**
 * Created by pajicv on 7/1/18.
 */

import React, { Component } from 'react';

import i18n from '../../../i18n/i18n';
import { TextField, withStyles } from '@material-ui/core';

import { ALL_LOCAL_GOVS_ID } from '../../../utils/constants';

import './ZoomTo.css'

class ZoomToLocalGovernment extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.onLocalGovSelected(event.target.value);
    }

    render() {

        const {classes} = this.props;

        let localGovs = [];
        if (this.props.localGovernments && !this.props.localGovernmentFetching) {
            localGovs = this.props.localGovernments.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }

        return (
            <form id="zoomToLocalGovForm">
                <TextField
                    className={classes.TheInput}
                    InputProps={{
                        className: classes.resize
                    }}
                    select
                    label={i18n.t('Local government')}
                    value={this.props.selectedLocalGovId}
                    onChange={this.handleChange}
                    SelectProps={{
                        native: true,
                    }}
                    margin="normal"

                >
                    <option value={ALL_LOCAL_GOVS_ID}>All</option>
                    {localGovs}
                </TextField>
            </form>
        );
    }
}

const styles = theme => ({
    TheInput: {
        width: 256,
        fontWeight: 'bold',
        lineHeight: 2.4,
        marginTop: 2,
        marginBotom: 2
    },
    resize:{
        fontSize:14,
    }
});

ZoomToLocalGovernment = withStyles(styles)(ZoomToLocalGovernment);

export default ZoomToLocalGovernment;