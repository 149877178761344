export const styles = {
    card: { display: 'flex' },
    container: { margin: '2%' },
    top_container: { display: 'flex', marginTop: '1%' },
    bottom_container: { display: 'flex', marginBottom: '1%' },
    row_container: { display: 'flex', width: '100%', placeContent: 'center' },
    meteogram_container: { marginTop: '1%', marginBottom: '1%', marginLeft: '2%', marginRight: '2%', width: '16%' },
    meteogram_card: { backgroundColor: '#04764e' },
    meteogram_size: {
        //  height: '26vh', width: '14vw'
    },
    meteogram_title: { height: '28px', textAlign: 'center', backgroundColor: '#04764e' },
    title: { fontSize: '18px', color: 'white', margin: '-8px', marginTop: '10px' },
    meteogram_thumbnail: { height: '65%', backgroundColor: 'white' },
    thumbnail: { maxWidth: '-webkit-fill-available' },
    links_container: { height: '20%', display: 'flex', alignItems: 'center', backgroundColor: '#04764e', position: 'relative' },
    link_container: { width: '50%', textAlign: 'center' },
    link: { fontSize: '30px', color: 'white', marginTop: '4px', cursor: 'pointer' },
};