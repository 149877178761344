import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import i18n from '../../../i18n/i18n';
import Select from 'react-select';
import { Fab } from '@material-ui/core';
import { wentWrong } from "../../../utils/ToastConfig";
import axios from '../../../utils/AxiosWrapper';
import { Delete } from '../../../utils/Delete';
import WentWrong from './../../../utils/ToastConfig';
import AddWorkplaces from './AddWorkplaces';
import css from './viewWorkplaces.module.css';
import AddIcon from '@material-ui/icons/Add';
import SearchBar from 'material-ui-search-bar';
import { Tooltip as Tooltip2 } from '@material-ui/core';
import { Button, IconButton } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { PulseLoader } from 'react-spinners';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({ 
    primaryMain: { backgroundColor: theme.palette.primary.main },
    paginationButton: {
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#5EB3E4',
        color: 'white',
        border: 'none',
        cursor: 'pointer'
    },
    headerStyle: { textAlign: 'center',
        textTransform: 'uppercase',
        paddingTop: 10,
        paddingBottom: 10
        },
 });

class ViewWorkplaces extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilters: false, load: false,
            page: 0, pageSize: 10, sorted: null, filtered: null,
            selection: [], selectAll: false, selectedRow: [],
            totalPages: 0, totalWorkplaces: 0,
            add: false, edit: false, delete: false,
            data: [],
            //---------
            editObject:  null,
            searchBarValue: "",
            deleteItemId: -1
        };
    }

    componentDidUpdate(prevProps, prevState){
        if (prevState.searchBarValue !== this.state.searchBarValue) {
                this.setState({
                    load: true
                })
                this.reactTable.fireFetchData();
        }
    }

    getData(state) {
        this.setState({ load: true, data: [] });

        let filtered = [...state.filtered];

        if (this.state.searchBarValue) {
            filtered.push({ id: 'naziv', value: this.state.searchBarValue });
        }
        axios.get(`api/workplace/read`, {
            params: { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: filtered }
        })
            .then(res => {
                this.setState({
                    data: res.data.data,
                    totalPages: res.data.total_pages,
                    totalWorkplaces: res.data.total,
                    load: false
                });
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })
    }

    handleSearchBar = (searchVal) => {
        this.setState({
            searchBarValue: searchVal,
        });
    }

    handleOpenNewWorkplace = () => {
        this.setState({add: true});
        if(this.state.edit == false){
            this.setState({
                editObject: null
            })
        }
    };

    handleCloseNewWorkplace = () => {
        this.setState({add: false});
    } 

    onCreate = () => {
        if (this.reactTable) {
            if(!this.state.edit){
                const { page, pageSize } = this.reactTable.state;
                if (page !== 0 || pageSize !== this.state.pageSize) {
                    this.reactTable.state.page = 0;
                }
            }
            
            this.setState({
                add: false,
                load: true,
                edit: false
            })
            this.reactTable.fireFetchData();
        };
    }

    deleteItem = () => {
        axios.get('api/worker/check_worker', { params: { selection: [this.state.deleteItemId] } })
            .then(res => {
                console.log(res.data.total)
                if (res.data.total > 0) wentWrong(i18n.t('There are workers tied to this workplace'));
                else if (res.data.total === 0) {
                    axios.post(`api/workplace/delete`, { selection: [this.state.deleteItemId] })
                        .then(res => {
                            this.reactTable.fireFetchData();
                            this.setState({ delete: false });
                        })
                        .catch(err => {
                            this.setState({ delete: false });
                            console.log('Error: ', err);
                        })
                }
            })
            .catch(err => { console.log('Error: ', err); })
    }

    isSelected = key => { return this.state.selection.includes(key); };
    onSearchClick = () => this.setState({ showFilters: !this.state.showFilters });
    onEditClick() { this.setState({ add: !this.state.add, edit: true }); }
    onTableClick = () => this.props.history.push('/workplaces');
    onDeleteClick = () => { this.setState({ delete: !this.state.delete }); }

    render() {
        const { classes } = this.props;

        const columns = [
            { Header: i18n.t('Workplace name'), accessor: 'naziv',
                headerStyle: { 
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    paddingTop: 10,
                    paddingBottom: 10,
                    backgroundColor: "#04764e",
                    color: '#FFFFFF'
                }, 
             },
            {
                width: 150,
                Header: i18n.t('Edit'),
                accessor: 'izmena',
                getHeaderProps: () => ({
                    onClick: e => e.stopPropagation(),
                    style: {
                        cursor: 'default'
                    }
                }),
                headerStyle: { textAlign: 'center',
                    textTransform: 'uppercase',
                    paddingTop: 10,
                    paddingBottom: 10,
                    backgroundColor: "#04764e",
                    color: '#FFFFFF'
                    },
                Cell: cell => (
                    <div>
                        <Tooltip2 title={i18n.t("Edit")}>
                                    <IconButton aria-label="copy"
                                        onClick={() => {
                                            console.log("red", cell.row._original)
                                            this.setState({
                                                edit: true,
                                                editObject: cell.row._original
                                            }, () => {
                                                this.handleOpenNewWorkplace();
                                            });
                                        }}
                                    >
                                        <Icons.Edit fontSize="default" />
                                    </IconButton>
                                </Tooltip2>
                    </div>
                )
            },
            {
                width: 150,
                Header: i18n.t('Delete'),
                accessor: 'detalji',
                getHeaderProps: () => ({
                    onClick: e => e.stopPropagation(),
                    style: {
                        cursor: 'default'
                    }
                }),
                headerStyle: { textAlign: 'center',
                    textTransform: 'uppercase',
                    paddingTop: 10,
                    paddingBottom: 10,
                    backgroundColor: "#04764e",
                    color: '#FFFFFF'
                    },
                Cell: cell => (
                    <div>
                        <Tooltip2 title={i18n.t("Delete")}>
                                    <IconButton aria-label="Delete"
                                        onClick={() => {
                                            console.log("id reda", cell.row._original.id);
                                            this.setState({
                                                deleteItemId: cell.row._original.id
                                            })
                                            this.onDeleteClick()}
                                        }
                                    >
                                        <Icons.DeleteOutline fontSize="default" />
                                    </IconButton>
                                </Tooltip2>
                    </div>
                )
            },
        ];

        return (
            <>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

                <div className={css.searchWrap}>
                    <SearchBar
                        className={css.searchBar}
                        placeholder={i18n.t('Search by name')}
                        cancelOnEscape={true}
                        value={this.state.searchBarValue}
                        onChange={(searchValue) => this.handleSearchBar(searchValue)}
                        onCancelSearch={() => this.handleSearchBar('')}
                    />
                    
                    <Button
                        variant="fab"
                        color="primary"
                        aria-label="Add"
                        className={css.addNewBtn}
                        onClick={() => { this.handleOpenNewWorkplace() }}
                    >
                        <AddIcon />
                        {i18n.t('Add Workplace')}
                    </Button>
                </div>
                <div style={{ border: '1px solid #cecece', backgroundColor: '#fff', margin: '30px 30px 30px 30px', borderRadius: '3px', boxSizing: 'border-box', overflow: 'hidden', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)', display: 'flex', flexDirection: 'column' }}>
                        
                    <ReactTable ref={r => (this.reactTable = r)}
                        manual
                        useSortBy
                        paging={true}
                        data={this.state.data}
                        columns={columns}
                        keyField={'id'}
                        pageRangeDisplayed={false}
                        defaultPageSize={this.state.pageSize}
                        pages={this.state.totalPages}
                        showPaginationBottom={true}
                        onFetchData={(state) => this.getData(state)}
                        firtelable={true}
                        previousText={'<'}
                        nextText={'>'}
                        pageText={''}
                        ofText={i18n.t('of')}
                        showPageSizeOptions={false}
                        showPagination={true}
                        rowsText={''}
                        PreviousComponent={(props) => (
                            <button {...props} 
                            className={
                                classes.paginationButton}
                            >{props.children}</button>
                        )}
                        NextComponent={(props) => (
                            <button {...props} 
                            className={
                                classes.paginationButton}
                            >{props.children}</button>
                        )}
                        getTrProps={() => {
                                return {
                                    style: {
                                        textAlign: 'center',
                                        paddingLeft: '10px',
                                        height: '50px',
                                        alignItems: 'center',
                                        overflowY: 'hidden',
                                        overflowX: 'hidden',
                                    }
                                };
                        }}

                        LoadingComponent={() => (
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <PulseLoader loading={this.state.load}/>
                            </div>                         
                        )}

                        onPageChange={() => {
                            this.state.load= true ;
                        }}

                        NoDataComponent={() => (
                            <div style={{ textAlign: 'center', padding: '20px', display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: -300 }}>
                                {!this.state.load && <span>{i18n.t('No rows found')}</span>}
                            </div>
                        )}
                        />
                    </div>
            </div>
            
                {this.state.add && <AddWorkplaces add={this.state.add} edit={this.state.edit} handleCreate={this.onCreate} handleClose={this.handleCloseNewWorkplace} data={this.state.editObject} />}
                {this.state.delete && <Delete delete={this.state.delete} handleClose={this.onDeleteClick} deleteItem={this.deleteItem} />}
            
            </>
        );
    }
}

export default withStyles(styles)(ViewWorkplaces);