import React from 'react';
import i18n from './../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography, Checkbox, DialogContentText } from '@material-ui/core';
import { wentRight, wentWrong } from "../../../utils/ToastConfig";
import axios from '../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import styles from './fields.module.css';
import ReactSelect from './../../../utils/ReactSelect';
import * as Icons from '@material-ui/icons';
import { Tooltip as Tooltip } from '@material-ui/core';
export default class Fields extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: null,
            user_season: null,
            user_layer: null,
            fieldType: [],
            fieldList: [],

            showFilters: false,
            allFieldsList: [],
            allCultures: [],
            allHybrids: [],
            selectedCulture: "all",
            selectedHybrid: "all",
            filteredFieldType: [],

            allCulturesFiltered: [],
            allHybridsFiltered: [],

        };
    };

    componentDidMount() {
        axios.get('api/season/activity_season')
            .then(res => {
                this.setState({ user_season: res.data.data[0].id_default_sezona }, () => {
                    axios.get('api/layer/layer_by_season', { params: { season: this.state.user_season } })
                        .then(res => {
                            this.setState({ user_layer: res.data.data[0].id }, () => {
                                axios.get('api/field/field_list_season', { params: { layer: this.state.user_layer } })
                                    .then(res => {
                                        this.setState({ allFieldsList: res.data.data }, () => {
                                            this.setState({
                                                allCultures: [...new Set(this.state.allFieldsList.map(field => field.kultura_naziv))],
                                                allHybrids: [...new Set(this.state.allFieldsList.flatMap(field => field.sorte.map(sorta => sorta.naziv)))],

                                            })
                                        })
                                        let listObj = [];
                                        let list = res.data.data.map(item => {
                                            let label = item.naziv + ' (' + (Number(item.povrsina)).toFixed(2) + 'ha)';
                                            let value = item.id;
                                            let id_tabla = item.id;
                                            let naziv = item.naziv;
                                            let povrsina = (Number(item.povrsina)).toFixed(2);
                                            let deo_table = item.deo_table;
                                            let kultura_naziv = item.kultura_naziv;
                                            let sorte = item.sorte;
                                            let raspored_redova = item.raspored_redova
                                            listObj.push({ label, value, naziv, id_tabla, povrsina, deo_table, kultura_naziv, sorte, raspored_redova });
                                            return { label, value, povrsina, kultura_naziv, sorte };
                                        });
                                        this.setState({ fieldType: list, fieldList: listObj, filteredFieldType: list }, () => {
                                            this.updateFilteredCulturesAndHybrids();
                                        });
                                    })
                                    .catch(() => wentWrong('Something went wrong'))
                            })
                        })
                        .catch(() => wentWrong('Something went wrong'))
                })
            })
            .catch(() => wentWrong('Something went wrong'))

        if (this.props.activityObj.table) {
            let temp = this.props.activityObj.table.map(item => {
                let obj = {};
                obj.label = item.label;
                obj.value = item.value;
                obj.povrsina = item.povrsina;
                obj.kultura_naziv = item.kultura_naziv;
                obj.sorte = item.sorte;
                return obj;
            });
            this.setState({ fields: temp });
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedCulture !== this.state.selectedCulture ||
            prevState.selectedHybrid !== this.state.selectedHybrid) {
            this.updateFilteredFieldsFilst();
            this.updateFilteredCulturesAndHybrids();
        }

        if (prevState.fields !== this.state.fields ||
            prevState.fieldType !== this.state.fieldType) {
            this.updateFilteredFieldsFilst();
        }
    }

    getByURL(url, obj) {
        axios.get(`api/${url}`)
            .then(res => this.setState({ [obj]: res.data.data }))
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 2 (getByURL): ', error, ', URL: ', url);
            })
    };

    handleAdd = () => {
        if (this.state.fields) {
            if (this.state.fields.length > 0) {
                let fieldObj = [];
                let selectedIds = [];

                this.state.fields.map(item => selectedIds.push(item.value));
                this.state.fieldList.map((item, i) => { if (selectedIds.includes(item.value)) fieldObj.push(item) });

                this.props.handleFieldsObj(fieldObj);
            }
            else wentWrong('You have to select at least one field');
        }
        else wentWrong('You have to select at least one field');
    };


    getSumByKey = (arr, key) => {
        return arr.reduce((accumulator, current) => accumulator + Number(current[key]), 0)
    };

    handleFiltersClick = () => {
        this.setState({ showFilters: !this.state.showFilters, selectedCulture: 'all', selectedHybrid: 'all' });
    }

    updateFilteredFieldsFilst = () => {
        this.setState({
            filteredFieldType: this.state.fieldType
                .filter(field => this.state.selectedCulture === "all" ? field : field.kultura_naziv === this.state.selectedCulture)
                .filter(field => this.state.selectedHybrid === "all" ? field : field.sorte.some(sorta => sorta.naziv === this.state.selectedHybrid))
                .filter(field => {
                    let isChosen = false;
                    if (this.state.fields !== null) {
                        for (let i = 0; i < this.state.fields.length; i++) {
                            if (field.value === this.state.fields[i].value) { isChosen = true }
                        }
                    }

                    if (!isChosen) return field;
                })
        })
    }


    updateFilteredCulturesAndHybrids() {
        this.setState({
            allCulturesFiltered: [...new Set(this.state.fieldType.filter(field => {
                if (this.state.selectedHybrid === "all") return field;
                if (field.sorte.some(sorta => sorta.naziv === this.state.selectedHybrid)) return field;

            }).map(field => field.kultura_naziv))],

            allHybridsFiltered: [... new Set(this.state.fieldType.filter(field => {
                if (this.state.selectedCulture === "all") return field;
                if (this.state.selectedCulture === field.kultura_naziv) return field;

            }).flatMap(item => item.sorte).map(sorta => sorta.naziv))]
        });
    }

    render() {
        let area = 0;
        if (this.state.fields) area = (this.getSumByKey(this.state.fields, 'povrsina')).toFixed(2);

        return (
            <div style={{ padding: 20 }}>
                <Dialog open={this.props.open}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} style={{ background: '#04764e' }} className="responsive-dialog-title">
                        {i18n.t('Add fields')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ boxSizing: 'border-box', paddingLeft: '20px' }}
                            className={this.state.showFilters ? styles.filtersOpen : styles.filtersClosed}
                        >

                            <div style={{ marginBottom: '15px', marginTop: '30px' }}>
                                <label style={{ fontSize: '16px', marginRight: '15px' }} htmlFor="culture">Kultura: </label>
                                <select
                                    className={styles.cultureDropdown}
                                    value={this.state.selectedCulture}
                                    onChange={e => {
                                        let chosenValue = e.target.value
                                        this.setState({ selectedCulture: chosenValue })

                                    }} name="culture" id="culture">
                                    <option value="all">Sve</option>
                                    {this.state.allCulturesFiltered.map((culture, index) => {
                                        return <option key={index} value={culture}>{culture}</option>
                                    })}
                                </select>

                                <label style={{ fontSize: '16px', marginRight: '15px' }} htmlFor="hybrids">Sorta/Hibrid: </label>
                                <select
                                    className={styles.hybridDropdown}
                                    value={this.state.selectedHybrid}
                                    onChange={e => {
                                        let chosenValue = e.target.value
                                        this.setState({ selectedHybrid: chosenValue })

                                    }} name="hybrids" id="hybrids">
                                    <option value="all">Sve</option>
                                    {this.state.allHybridsFiltered.map((hybrid, index) => {
                                        return <option key={index} value={hybrid}>{hybrid}</option>
                                    })}

                                </select>
                            </div>
                        </div>
                        <div className={styles.content}>
                            <ReactSelect
                                closeMenuOnSelect={true}
                                isClearable={true}
                                isMulti={true}
                                // value={this.state.fields}
                                value={[]}
                                label={i18n.t('Field')}
                                maxMenuHeight={320}
                                onChange={(e) => {
                                    if (e.length > 0) {
                                        this.setState(prevState => ({
                                            filteredFieldType: prevState.filteredFieldType.filter(field => field.value !== e[0].value),
                                            fields: prevState.fields !== null ? [...prevState.fields, e[0]] : [e[0]]
                                        }));
                                    }
                                }}
                                options={this.state.filteredFieldType}
                                className={styles.multiSelectFields}
                                classNamePrefix="select"
                                placeholder={i18n.t('Select')}
                                limitedHeight={true}
                            />
                            <Tooltip
                                placement="top"
                                title={"Filteri"}
                            >
                                <Icons.FilterList
                                    className={styles.filterIcon}
                                    onClick={this.handleFiltersClick}
                                    style={this.state.showFilters ? { backgroundColor: '#58b4e5', color: 'white' } : {}}
                                />
                            </Tooltip>

                        </div>

                        <div className={styles.allFieldsContainer}>
                            {this.state.fields !== null && this.state.fields.length > 0 && this.state.fields.map((field, index) => {
                                return <div className={styles.fieldLabel} key={index}>{field.label} <div className={styles.fieldDelete} onClick={() => {
                                    this.setState(prevState => ({
                                        fields: prevState.fields.filter(item => item.value !== field.value),
                                        filteredFieldType: [...prevState.filteredFieldType, field],
                                    }))
                                }}>

                                    <Icons.DeleteForeverRounded
                                        className={styles.deleteIcon}
                                    />
                                </div>
                                </div>
                            })}
                        </div>

                        <div className={styles.flex}>
                            {this.state.fields && area > 0 && <div className={styles.font}>{i18n.t('Total land area')}: {area} ha</div>}
                            <div></div>
                            <div className={styles.center}>
                                <Button onClick={() => { this.setState(prevState => ({ fields: prevState.fields !== null ? [...prevState.fields, ...this.state.filteredFieldType] : [...this.state.filteredFieldType] })) }} variant='contained' color='primary' style={{ margin: '0px 20px 10px 0px' }}>{i18n.t('Select all')}</Button>
                                <Button onClick={() => this.handleAdd()} variant='contained' color='primary' style={{ margin: '0px 20px 10px 0px' }}>{i18n.t('Add')}</Button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </div >
        );
    };
};