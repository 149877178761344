import React from 'react';
import i18n from '../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography, Menu } from '@material-ui/core';
import { wentWrong } from "../../../utils/ToastConfig";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from '../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import MySelect from '../../../utils/MySelect';
import styles from "./addWorkerActivities.module.css"
import * as Icons from '@material-ui/icons';
import { Tooltip as Tooltip } from '@material-ui/core';

import CalendarMonthIcon from '@material-ui/icons/DateRangeSharp';

import { DatePicker } from 'material-ui-pickers';
import moment from 'moment';

export default class AddWorkerActivities extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            // workplace: null, workplaces: [],
            worker: null, workers: [],
            field: [], fields: [],
            worker_unit: null, worker_units: [],
            working_operation: null, working_operations: [],

            cena_jmr: null,
            valuta: '', valuta_code: '',

            showFilters: false,
            allFieldsList: [],
            allCultures: [],
            allHybrids: [],
            selectedCulture: 'all',
            selectedHybrid: 'all',

            filteredFieldType: [],
            allCulturesFiltered: [],
            allHybridsFiltered: [],
            selectedFields: null,
            fieldType: [],

            editableHybridsList: [],

            selectedDate: new Date().toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).split('/').join('.'),

            ukupnoUcinak: 0,
            ukupnoTroskovi: 0,
            dropdownOpen: false,

            sectorDropdown: [],
            selectedSector: "",
            sectorChanged: false,

            ucinakEditMode: false,

            hasSector: null,
            disabled: true

        }
    }

    async componentDidMount() {
        let hasSector;
        await axios.get('api/sektor/drop_down')
            .then(res => {
            res.data.data.length > 0 ?  hasSector = true : hasSector=false
            this.setState({ sectorDropdown: res.data.data, hasSector: hasSector  })}
        )
            .catch(err => console.log('sektor dropdown ERROR', err));




        let user = this.getUser();
        if (user.id_korisnik_grupa === 11) {
            this.fetchAllFields();
        }
        if (user.id_korisnik_grupa === 10 && !this.state.hasSector) {
            this.fetchAllFields(null);
        }
        if (this.props.edit === true) {
            this.fetchAllFields(this.props.data.id_sektor_u_momentu_kreiranja);


        }



        this.getValuta();
        this.getByURL('worker/worker_list', 'workers');
        this.getByURL('field/drop_down', 'fields');
        this.getByURL('worker_unit/drop_down', 'worker_units');
        this.getByURL('working_operation/klijent_drop_down', 'working_operations');

        if (this.props.edit === true) {
            this.setState({
                selectedDate: moment(this.props.data.datum).format("DD.MM.YYYY"),
                cena_jmr: Number(this.props.data.cena_jmr),
                selectedSector: (this.props.data.id_sektor_u_momentu_kreiranja === null) ? "" : this.props.data.id_sektor_u_momentu_kreiranja,

            }, () => {
                this.calculateCosts();
            });
        }
    }

    fetchAllFields = (id_sektor) => {
        this.toggleDisabledSelect()

        axios.get('api/field/drop_down_by_sector', { params: { id_sektor: id_sektor } })
            .then(res => {
                this.setState({ allFieldsList: res.data.data }, () => {
                    this.setState({
                        allCultures: [...new Set(this.state.allFieldsList.map(field => field.kultura_naziv))],
                        allHybrids: [...new Set(this.state.allFieldsList.flatMap(field => field.sorte.map(sorta => sorta.naziv)))],
                    })
                })
                let listObj = [];
                let list = res.data.data.map(item => {
                    let label = item.naziv + ' (' + (Number(item.povrsina)).toFixed(2) + 'ha)';
                    let value = item.id;
                    let id_tabla = item.id;
                    let naziv = item.naziv;
                    let povrsina = (Number(item.povrsina)).toFixed(2);
                    let deo_table = item.deo_table;
                    let kultura_naziv = item.kultura_naziv;
                    let sorte = item.sorte;
                    listObj.push({ label, value, naziv, id_tabla, povrsina, deo_table, kultura_naziv, sorte });
                    return { label, value, naziv, povrsina, kultura_naziv, sorte };
                });



                this.setState({ fieldType: list, fieldList: listObj, filteredFieldType: list }, () => {

                    this.updateFilteredCulturesAndHybrids();

                    if (this.props.edit === true && this.state.sectorChanged === false) {
                        axios.post('api/worker_activities/get_tabla_and_sorta_povrsina', { params: { data: this.props.data } })
                            .then(res => {
                                this.setState({
                                    editableHybridsList: res.data.data.raspodela.map(hybrid => {
                                        return {
                                            id_kultura_sorta: hybrid.id_kultura_sorta,
                                            id_tabla: hybrid.id_tabla,
                                            kultura: hybrid.kultura_naziv,
                                            povrsina: hybrid.id_kultura_sorta === null ? hybrid.tabla_povrsina : hybrid.sorta_povrsina,
                                            naziv: hybrid.sorta_naziv,
                                            tabla_naziv: hybrid.tabla_naziv,
                                            ucinak: Number(hybrid.kolicina),
                                            troskovi: 0,
                                        }
                                    })

                                }, () => {
                                    this.calculateCosts();
                                    this.removeSelectedFields();
                                })

                            })
                            .catch(err => console.log('sektor dropdown ERROR', err));
                    }
                    this.setState({ sectorChanged: false })
                });
            })
            .catch(() => wentWrong('Something went wrong'))
    }
    
    toggleDisabledSelect() {
        let user = this.getUser()
        let disabled = true;
        if(this.state.hasSector){
            if (user.id_korisnik_grupa === 10 && this.state.selectedSector === "") disabled = true
            else if (user.id_korisnik_grupa === 10 && this.state.selectedSector !== "") disabled = false
            else if (user.id_korisnik_grupa === 11 ) disabled = false
        }
        else if(this.state.hasSector === false) disabled = false
        this.setState({ disabled : disabled})
        }

    removeSelectedFields() {
        let selectedFields = [];
        let filteredFieldType = this.state.filteredFieldType;
        let editableHybridsList = this.state.editableHybridsList;

        for (let i = 0; i < filteredFieldType.length; i++) {
            const field = filteredFieldType[i];

            if (field.sorte.length === 0) {
                const index = editableHybridsList.findIndex(item => item.id_tabla === field.value);
                if (index !== -1) {
                    selectedFields.push(filteredFieldType.splice(i--, 1)[0]);
                }
            } else {
                const allSortePresent = field.sorte.every(sorteItem => {
                    return editableHybridsList.some(item => item.id_kultura_sorta === sorteItem.id_kultura_sorta && item.id_tabla === field.value);
                });

                if (allSortePresent) {
                    selectedFields.push(filteredFieldType.splice(i--, 1)[0]);
                }
            }
        }

        this.setState({ selectedFields: selectedFields, filteredFieldType: filteredFieldType })
    }

    getByURL(url, obj, value, name) {
        value = value || '';
        name = name || '';
        if (value != null) {
            axios.get(`api/${url}`, { params: { [name]: value } })
                .then(res => this.setState({ [obj]: res.data.data }, () => {
                    if (this.props.edit === true) {
                        if (this.state.workers.length > 0) {
                            this.setState({ worker: this.props.data.id_radnik })
                        }

                        if (this.state.working_operations.length > 0) {
                            this.setState({ working_operation: this.props.data.id_radna_operacija })
                        }

                        if (this.state.worker_units.length > 0) {
                            this.setState({ worker_unit: this.props.data.id_jmr })
                        }
                    }
                }))
                .catch(error => wentWrong('Something went wrong, please try again!'));
        }
    }

    getValuta() {
        axios.get(`api/valuta/get_valuta_id_naziv`)
            .then(res => {
                let data = res.data.data
                this.setState({ valuta_code: data.valuta_code })
                this.setState({ valuta: data.valuta })
            })
            .catch(error => { console.log('error', error); })
    }

    addWorkerActivity() {
        let user = this.getUser();
        if (
            this.state.worker
                && this.state.working_operation
                && this.state.worker_unit
                && this.state.cena_jmr
                && this.state.editableHybridsList.length > 0
                && this.state.editableHybridsList.every(hybrid => Number(hybrid.ucinak) > 0)
                && Number(this.state.ukupnoTroskovi) > 0
                && ((user.id_korisnik_grupa === 10 && ((this.state.selectedSector !== "" && this.state.hasSector) || (this.state.selectedSector === "" && !this.state.hasSector))) || (user.id_korisnik_grupa === 11))
        ) {

            let data;

            if (this.props.edit === true) {

                data = {
                    id: this.props.data.id,
                    id_radnik: this.state.worker,
                    datum:  moment(this.state.selectedDate, "DD.MM.YYYY").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                    id_jmr: this.state.worker_unit,
                    id_radna_operacija: this.state.working_operation,
                    cena_jmr: this.state.cena_jmr,
                    ukupna_cena: this.state.ukupnoTroskovi,
                    raspodela: this.state.editableHybridsList
                        .filter(hybrid => hybrid.ucinak !== 0 && hybrid.ucinak !== null && hybrid.ucinak !== "")
                        .map(hybrid => {
                            return {
                                id_tabla: hybrid.id_tabla,
                                id_kultura_sorta: hybrid.id_kultura_sorta,
                                kolicina: hybrid.ucinak,
                            }
                        }),
                    id_sektor: (user.id_korisnik_grupa === 10 && this.state.hasSector) ? this.state.selectedSector : null,
                }

            } else {
                data = {
                    id_radnik: this.state.worker,
                    datum: moment(this.state.selectedDate, "DD.MM.YYYY").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                    id_jmr: this.state.worker_unit,
                    id_radna_operacija: this.state.working_operation,
                    cena_jmr: this.state.cena_jmr,
                    ukupna_cena: this.state.ukupnoTroskovi,
                    raspodela: this.state.editableHybridsList
                        .filter(hybrid => hybrid.ucinak !== 0 && hybrid.ucinak !== null && hybrid.ucinak !== "")
                        .map(hybrid => {
                            return {
                                id_tabla: hybrid.id_tabla,
                                id_kultura_sorta: hybrid.id_kultura_sorta,
                                kolicina: hybrid.ucinak,
                            }
                        }),
                    id_sektor: (user.id_korisnik_grupa === 10 && this.state.hasSector) ? this.state.selectedSector : null,
                }
            }



            axios.post('api/worker_activities/create', { data: data })
                .then(res => {

                    this.props.handleAdd();
                    this.props.clearSelection();

                })
                .catch(error => {
                    wentWrong('Something went wrong, please try again!');
                    console.log(`Error: ${error}`);
                })

        }
        else wentWrong('Please enter all required fields!');
    }

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedCulture !== this.state.selectedCulture ||
            prevState.selectedHybrid !== this.state.selectedHybrid) {
            this.updateFilteredFieldsFilst();
            this.updateFilteredCulturesAndHybrids();
        }

        if (prevState.selectedFields !== this.state.selectedFields ||
            prevState.fieldType !== this.state.fieldType) {
            this.updateFilteredFieldsFilst();
        }
    }

    handleFiltersClick = () => {
        this.setState({ showFilters: !this.state.showFilters, selectedCulture: 'all', selectedHybrid: 'all' });
    }

    updateFilteredFieldsFilst = () => {
        this.setState({
            filteredFieldType: (this.state.fieldType.length > 0 ? this.state.fieldType : [])
                .filter(field => this.state.selectedCulture === "all" ? field : field.kultura_naziv === this.state.selectedCulture)
                .filter(field => this.state.selectedHybrid === "all" ? field : field.sorte.some(sorta => sorta.naziv === this.state.selectedHybrid))
                .filter(field => {
                    let isChosen = false;
                    if (this.state.selectedFields !== null) {
                        for (let i = 0; i < this.state.selectedFields.length; i++) {
                            if (field.value === this.state.selectedFields[i].value) { isChosen = true }
                        }
                    }

                    if (!isChosen) return field;
                })
        })
    }


    updateFilteredCulturesAndHybrids() {
        this.setState({
            allCulturesFiltered: [...new Set(this.state.fieldType.filter(field => {
                if (this.state.selectedHybrid === "all") return field;
                if (field.sorte.some(sorta => sorta.naziv === this.state.selectedHybrid)) return field;

            }).map(field => field.kultura_naziv))],

            allHybridsFiltered: [... new Set(this.state.fieldType.filter(field => {
                if (this.state.selectedCulture === "all") return field;
                if (this.state.selectedCulture === field.kultura_naziv) return field;

            }).flatMap(item => item.sorte).map(sorta => sorta.naziv))]
        });
    }

    handleDateSelect(date) {
        this.setState({ selectedDate: date });
    }

    calculateCosts() {
        let ucinak = 0;
        let troskovi = 0;


        let hybridsCalculated = this.state.editableHybridsList.map(hybrid => {
            ucinak += Number(hybrid.ucinak);
            troskovi += Number(hybrid.ucinak) * Number(this.state.cena_jmr ? this.state.cena_jmr : 0);

            return {
                ...hybrid,
                troskovi: Number((Number(hybrid.ucinak) * Number(this.state.cena_jmr ? this.state.cena_jmr : 0)).toFixed(4)),
            }
        })

        this.setState({ editableHybridsList: hybridsCalculated, ukupnoUcinak: ucinak, ukupnoTroskovi: Number(troskovi.toFixed(4)) });

    }

    calculatePerformance = () => {
        let totalArea = 0;

        for (let index = 0; index < this.state.editableHybridsList.length; index++) {
            totalArea += Number(this.state.editableHybridsList[index].povrsina);
        }


        this.setState(prevState => ({
            editableHybridsList: prevState.editableHybridsList.map(hybrid => {
                return {
                    ...hybrid,
                    ucinak: Number((Number(this.state.ukupnoUcinak) / (totalArea / Number(hybrid.povrsina))).toFixed(2)),
                    troskovi: Number((Number(this.state.ukupnoUcinak) / (totalArea / Number(hybrid.povrsina)) * Number(this.state.cena_jmr ? this.state.cena_jmr : 0)).toFixed(2)),
                }
            })
        }), () => {
            let ukupnoTroskovi = 0;
            for (let index = 0; index < this.state.editableHybridsList.length; index++) {
                ukupnoTroskovi += Number(Number(this.state.editableHybridsList[index].troskovi).toFixed(2));
            }
            this.setState({ ukupnoTroskovi: ukupnoTroskovi });
        });
    }



    render() {
        let user = this.getUser();
        let currency = '';
        if (user.id_region === 2) currency = 'RSD';
        else if (user.id_region === 20) currency = 'KM';
        else if (user.id_region === 23) currency = '€';
        else if (user.id_region === 13) currency = '₦';
        else if (user.id_region === 25) currency = '₪';
        else if (user.id_region === 27) currency = '₪';
        else if (user.id_region === 26) currency = '$';

        let total_cost = null;
        if (this.state.cena_jmr && this.state.kolicina) {
            total_cost = this.state.cena_jmr * this.state.kolicina;
        }

        let valuta = 'din', valuta_code = 'RSD';
        if (this.state.valuta !== '') { valuta = this.state.valuta; valuta_code = this.state.valuta_code }

        let formatted = '';
        if (total_cost) {
            formatted = new Intl.NumberFormat('de-DE', { style: 'currency', currency: valuta_code }).format(total_cost);
            formatted = formatted.replace(valuta_code, valuta);
        }

        return (
            <div style={{ padding: 20 }}>
                <Dialog open={this.props.add}
                    onClose={this.props.handleClose}
                    // fullWidth={true}
                    disableBackdropClick
                    // maxWidth="md"
                    maxWidth="false"
                    PaperProps={{
                        style: {
                            maxWidth: '1050px',
                            margin: 'auto',
                            height: '825px',
                        }
                    }}
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} style={{ background: '#04764e' }} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit Worker Activity') : i18n.t('Add Worker Activity')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <form id="add-layer" style={{ minHeight: '100%', maxHeight: 'fit-content', display: 'flex', flexDirection: 'column' }}>


                            {/* <Grid item xs={4}>
                                    <TextField required
                                        label={i18n.t('Workplace')}
                                        select
                                        value={this.state.workplace}
                                        SelectProps={{ native: true }}
                                        InputLabelProps={this.state.workplace ? { shrink: true } : null}
                                        style={{ width: '280px', marginBottom: '10px', marginTop: '15px', marginLeft: '10px' }}
                                        onChange={(e) => {
                                            this.setState({ workplace: e.target.value }, () => {
                                                axios.get('api/worker/worker_list', { params: { workplace: this.state.workplace } })
                                                    .then(res => { this.setState({ workers: res.data.data, worker_disabled: false }) })
                                                    .catch(err => { console.log(err) })
                                            });
                                        }} >
                                        <option value='' />
                                        {this.state.workplaces.map((workplaces) => <option key={workplaces.id} value={workplaces.id}>{workplaces.naziv}</option>)}
                                    </TextField>
                                </Grid> */}

                            <div style={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
                                <TextField required
                                    label={i18n.t('Worker')}
                                    select
                                    // disabled={this.state.worker_disabled}
                                    value={this.state.worker}
                                    SelectProps={{ native: true }}
                                    InputLabelProps={this.state.worker ? { shrink: true } : null}
                                    style={{ width: '336px', marginBottom: '10px', marginTop: '15px', marginLeft: '10px' }}
                                    onChange={(e) => { this.setState({ worker: e.target.value }) }} >
                                    <option value='' />
                                    {this.state.workers.map((workers) => <option key={workers.id} value={workers.id}>{workers.naziv}</option>)}
                                </TextField>



                                <TextField required
                                    label={i18n.t('Working operation')}
                                    select
                                    value={this.state.working_operation}
                                    SelectProps={{ native: true }}
                                    InputLabelProps={this.state.working_operation ? { shrink: true } : null}
                                    style={{ width: '336px', marginBottom: '10px', marginTop: '15px', marginLeft: '10px' }}
                                    onChange={(e) => { this.setState({ working_operation: e.target.value }); }} >
                                    <option value='' />
                                    {this.state.working_operations.map((working_operations) => <option key={working_operations.id} value={working_operations.id}>{working_operations.naziv}</option>)}
                                </TextField>


                                <DatePicker
                                    label={i18n.t('Date')}
                                    format="DD.MM.YYYY"
                                    value={moment(this.state.selectedDate, 'DD.MM.YYYY')}

                                    onChange={(e) => {

                                        this.setState({ selectedDate: (moment(e, 'DD.MM.YYYY').format('DD.MM.YYYY')) });

                                    }
                                    }
                                    style={{ margin: '0px 0px 10px 10px', height: '48px', flex: '1' }}
                                // renderInput={(params) => <TextField {...params} />}
                                />

                            </div>


                            <div style={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>

                                <TextField required
                                    label={i18n.t('Worker unit')}
                                    select
                                    value={this.state.worker_unit}
                                    SelectProps={{ native: true }}
                                    InputLabelProps={this.state.worker_unit ? { shrink: true } : null}
                                    style={{ width: '336px', marginBottom: '10px', marginTop: '15px', marginLeft: '10px' }}
                                    onChange={(e) => {
                                        this.setState({ worker_unit: e.target.value });
                                        // this.calculateAmountByArea(this.state.selectedFields);
                                    }} >
                                    <option value='' />
                                    {this.state.worker_units.map((worker_units) => <option key={worker_units.id} value={worker_units.id}>{worker_units.naziv}</option>)}
                                </TextField>

                                <TextField required
                                    type="number"
                                    label={i18n.t('Price per unit') + ` (${user.valuta_naziv})`}
                                    value={this.state.cena_jmr}
                                    InputLabelProps={this.state.cena_jmr ? { shrink: true } : null}
                                    style={{ width: '336px', marginBottom: '10px', marginTop: '15px', marginLeft: '10px' }}
                                    onChange={(e) => { this.setState({ cena_jmr: e.target.value }, () => this.calculateCosts()); }} >
                                </TextField>

                                {user.id_korisnik_grupa === 10 && <TextField 
                                    required= {this.state.hasSector}
                                    label={i18n.t('Sector')}
                                    select
                                    value={this.state.selectedSector}
                                    SelectProps={{ native: true }}
                                    InputLabelProps={this.state.selectedSector ? { shrink: true } : null}
                                    style={{ margin: '0px 0px 10px 10px', height: '48px', flex: '1' }}
                                    onChange={(e) => {

                                        this.setState({ selectedSector: e.target.value, sectorChanged: true }, () => {

                                            this.setState({
                                                editableHybridsList: [], ukupnoUcinak: 0,
                                                ukupnoTroskovi: 0, selectedCulture: 'all', selectedHybrid: 'all'
                                            }, () => {
                                                this.fetchAllFields(this.state.selectedSector)
                                                this.removeSelectedFields();
                                            })
                                        });
                                    }} >
                                    <option value='' />
                                    {this.state.sectorDropdown.map(sector => <option key={sector.id} value={sector.id}>{sector.naziv}</option>)}
                                </TextField>}

                            </div>



                            <div className={this.state.showFilters ? styles.filtersContainerOpen : styles.filtersContainerClosed}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <TextField
                                        label={i18n.t('Crops')}
                                        select
                                        // disabled={this.state.worker_disabled}
                                        value={this.state.selectedCulture}
                                        SelectProps={{ native: true }}
                                        // InputLabelProps={this.state.worker ? { shrink: true } : null}
                                        style={{ visibility: `${this.state.showFilters ? "visible" : "hidden"}`, width: '336px', marginBottom: '10px', marginTop: '15px', marginLeft: '10px' }}
                                        onChange={(e) => { this.setState({ selectedCulture: e.target.value }); }} >
                                        <option value='all'>Sve</option>
                                        {this.state.allCulturesFiltered.map((culture, index) => <option key={index} value={culture}>{culture}</option>)}
                                    </TextField>



                                    <TextField
                                        label={i18n.t('Sort/Hybrid')}
                                        select
                                        value={this.state.selectedHybrid}
                                        SelectProps={{ native: true }}
                                        style={{ visibility: `${this.state.showFilters ? "visible" : "hidden"}`, width: '336px', marginBottom: '10px', marginTop: '15px', marginLeft: '10px' }}
                                        onChange={(e) => { this.setState({ selectedHybrid: e.target.value }); }} >
                                        <option value='all'>Sve</option>
                                        {this.state.allHybridsFiltered.map((hybrid, index) => <option key={index} value={hybrid}>{hybrid}</option>)}
                                    </TextField>


                                    <Tooltip
                                        placement="top"
                                        title={i18n.t('Reset filters')}
                                    >
                                        <Icons.RotateLeftSharp
                                            className={styles.resetFiltersIcon}
                                            onClick={() => this.setState({ selectedCulture: 'all', selectedHybrid: 'all' })}
                                            style={{ visibility: `${this.state.showFilters ? "visible" : "hidden"}`, }}
                                        />
                                    </Tooltip>
                                </div>
                            </div>

                            <div style={{ width: '884px', paddingLeft: '10px', paddingTop: '10px', marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                                <MySelect
                                    isMulti={true}
                                    closeMenuOnSelect={false}
                                    value={[]}
                                    // ovde nije dobra logika
                                    isDisabled={this.state.disabled}

                                onMenuOpen={() => {
                                        this.setState({ dropdownOpen: true });

                                    }}
                                    onMenuClose={() => {
                                        this.setState({ dropdownOpen: false })

                                    }}
                                    maxMenuHeight={200}
                                    onChange={

                                        (e) => {
                                             
                                            if (e.length > 0) {
                                                this.setState(prevState => ({
                                                    filteredFieldType: prevState.filteredFieldType.filter(field => field.value !== e[0].value),
                                                    selectedFields: prevState.selectedFields !== null ? [...prevState.selectedFields, e[0]] : [e[0]]
                                                }), () => {

                                                    let allHybridsForEditing = this.state.selectedFields.map(field => {

                                                        if (field.sorte.length === 0) {
                                                            return {
                                                                tabla_naziv: field.naziv,
                                                                kultura: field.kultura_naziv,
                                                                id_tabla: field.value,
                                                                povrsina: field.povrsina,
                                                                id_kultura_sorta: null,
                                                                naziv: null,
                                                                ucinak: null,
                                                                troskovi: null,
                                                            }
                                                        }

                                                        return field.sorte.map(sorta => {
                                                            return {
                                                                tabla_naziv: field.naziv,
                                                                kultura: field.kultura_naziv,
                                                                id_tabla: sorta.id_tabla,
                                                                povrsina: sorta.povrsina,
                                                                id_kultura_sorta: sorta.id_kultura_sorta,
                                                                naziv: sorta.naziv,
                                                                ucinak: null,
                                                                troskovi: null,
                                                            }
                                                        })
                                                    }).flatMap(item => item);

                                                    let tempArr = [];

                                                    allHybridsForEditing.forEach(item => {
                                                        if (!this.state.editableHybridsList.some(existingItem => {
                                                            if (item.id_kultura_sorta !== null && existingItem.id_kultura_sorta !== null && item.id_tabla === existingItem.id_tabla) {
                                                                return existingItem.id_kultura_sorta === item.id_kultura_sorta;
                                                            } else if (item.id_kultura_sorta !== null && existingItem.id_kultura_sorta !== null && existingItem.id_kultura_sorta === item.id_kultura_sorta) {
                                                                return item.id_tabla === existingItem.id_tabla;
                                                            } else {
                                                                return existingItem.id_tabla === item.id_tabla;
                                                            }
                                                        })) {
                                                            tempArr.push(item);
                                                        }
                                                    });

                                                    this.setState(prevState => ({ editableHybridsList: [...prevState.editableHybridsList, ...tempArr] }), () => {
                                                    });


                                                });
                                            }
                                        }
                                    }

                                    options={this.state.filteredFieldType}
                                    classNamePrefix="select"
                                    className={styles.fieldDropdown}
                                    allowSelectAll={false}
                                    placeholder={`‏‏‎ ‎ ‏‏‎ ‎` + i18n.t('Field')} />

                                <Tooltip
                                    placement="top"
                                    title={i18n.t('Filters')}
                                >
                                    <Icons.FilterList
                                        className={styles.filterIcon}
                                        onClick={this.handleFiltersClick}
                                        style={this.state.showFilters ? { backgroundColor: '#58b4e5', color: 'white' } : {}}
                                    />
                                </Tooltip>
                            </div>

                            <div className={`${this.state.dropdownOpen ? styles.addedspaceOn : styles.addedspaceOff}`}>
                                <div style={{ width: '100%', height: '200px' }}></div>
                            </div>

                            {/* <div className={styles.allFieldsContainer}>
                                    {this.state.selectedFields !== null && this.state.selectedFields.length > 0 && this.state.selectedFields.map((field, index) => {
                                        return <div className={styles.fieldLabel} key={index}>{field.label} <div className={styles.fieldDelete} onClick={() => {
                                            this.setState(prevState => ({
                                                selectedFields: prevState.selectedFields.filter(item => item.value !== field.value),
                                                filteredFieldType: [...prevState.filteredFieldType, field],
                                            }), () => {
                                                console.log("this.state.selectedFields : ", this.state.selectedFields)
                                                console.log("this.state.filteredFieldType : ", this.state.filteredFieldType)
                                            })
                                        }}>

                                            <Icons.DeleteForeverRounded
                                                className={styles.deleteIcon}
                                            />
                                        </div>
                                        </div>
                                    })}
                                </div> */}
                            <div style={{ minHeight: '50px', padding: '25px 0', marginLeft: '10px' }}>
                                {this.state.editableHybridsList && this.state.editableHybridsList.length > 0 &&
                                    <div className={styles.content_container}>
                                        {/* <div className={styles.content_border} /> */}
                                        <div className={styles.table_div}>
                                            <table className={styles.table}>
                                                <tr className={styles.table_row}>
                                                    <th className={styles.table_header}>{i18n.t("Field")}</th>
                                                    <th className={styles.table_header}>{i18n.t("Crops")}</th>
                                                    <th className={styles.table_header}>{i18n.t("Sort/Hibrid")}</th>
                                                    <th className={styles.table_header}>{i18n.t("Area (ha)")}</th>
                                                    <th className={styles.table_header}>{i18n.t("Effect")}</th>
                                                    <th className={styles.table_header}>{i18n.t("Cost")}</th>
                                                    <th className={styles.table_header_end}>{i18n.t('Delete')}</th>
                                                </tr>
                                                {this.state.editableHybridsList.map((item, index) => {
                                                    return <tr key={index} className={index % 2 === 0 ? styles.table_row_odd : styles.table_row}>
                                                        <td style={{ maxWidth: '200px', overflowWrap: 'break-word' }} className={styles.table_row_text}>{item.tabla_naziv}</td>
                                                        <td style={{ maxWidth: '100px', overflowWrap: 'break-word' }} className={styles.table_row_text}>{item.kultura}</td>
                                                        <td className={styles.table_row_text}>{item.naziv ? item.naziv : ""}</td>
                                                        <td style={{ maxWidth: '100px', overflowWrap: 'break-word' }} className={styles.table_row_text}>{Number(item.povrsina).toFixed(2)}</td>
                                                        <td className={styles.table_row_text}>
                                                            <TextField required
                                                                type="number"
                                                                pattern="[0-9]*"
                                                                value={item.ucinak}
                                                                disabled={this.state.ucinakEditMode}

                                                                style={{ width: '130px' }}
                                                                onChange={(e) => {

                                                                    const index = this.state.editableHybridsList.findIndex(hybrid => {
                                                                        if (item.id_kultura_sorta !== null) {
                                                                            return hybrid.id_kultura_sorta === item.id_kultura_sorta && hybrid.id_tabla === item.id_tabla;
                                                                        } else {
                                                                            return hybrid.id_tabla === item.id_tabla;
                                                                        }
                                                                    });

                                                                    const inputValue = e.target.value;

                                                                    const parsedValue = inputValue === "" ? null : parseFloat(parseFloat(inputValue).toFixed(2));



                                                                    const updatedEditableHybridsList = [...this.state.editableHybridsList];
                                                                    updatedEditableHybridsList[index] = {
                                                                        ...updatedEditableHybridsList[index],
                                                                        ucinak: parsedValue < 0 ? 0 : parsedValue,
                                                                    };

                                                                    this.setState({ editableHybridsList: updatedEditableHybridsList }, () => this.calculateCosts());

                                                                }} >
                                                            </TextField>
                                                        </td>
                                                        <td className={styles.table_row_text}>{Number(item.troskovi).toFixed(2)}   {user.valuta_naziv}</td>
                                                        <td className={styles.table_row_end}>

                                                            <img src="./delete.svg" height="24px" className={styles.delete_button_table} onClick={() => {


                                                                this.setState(prevState => ({
                                                                    editableHybridsList: prevState.editableHybridsList.filter(one => {

                                                                        if (item.id_kultura_sorta !== null) {
                                                                            return !(one.id_kultura_sorta === item.id_kultura_sorta && one.id_tabla === item.id_tabla);
                                                                        } else {

                                                                            return one.id_tabla !== item.id_tabla;
                                                                        }
                                                                    }),
                                                                    selectedFields: prevState.selectedFields.filter(field => field.value !== item.id_tabla)
                                                                }), () => {
                                                                    this.calculateCosts();

                                                                });
                                                            }}
                                                            />
                                                        </td>
                                                    </tr>
                                                })}

                                                <tr className={styles.table_row}>
                                                    <td style={{ visibility: 'none' }} className={styles.table_row_text}></td>
                                                    <td style={{ visibility: 'none' }} className={styles.table_row_text}></td>
                                                    <td style={{ visibility: 'none' }} className={styles.table_row_text}></td>
                                                    <td style={{ fontWeight: 'bold' }} className={styles.table_row_text}>{i18n.t('Total spent')}</td>
                                                    <td style={{ fontWeight: 'bold' }} className={styles.table_row_text}>
                                                        <TextField
                                                            required
                                                            type="number"
                                                            pattern="[0-9]*"
                                                            value={this.state.ukupnoUcinak}
                                                            disabled={!this.state.ucinakEditMode}

                                                            style={{ width: '130px' }}
                                                            onChange={(e) => {

                                                                const inputValue = e.target.value;
                                                                const parsedValue = inputValue === "" ? null : parseFloat(parseFloat(inputValue).toFixed(2));

                                                                this.setState({ ukupnoUcinak: parsedValue < 0 ? 0 : parsedValue }, () => {
                                                                    this.calculatePerformance();
                                                                })



                                                            }} >
                                                        </TextField>
                                                    </td>
                                                    <td style={{ fontWeight: 'bold' }} className={styles.table_row_text}>{Number(this.state.ukupnoTroskovi).toFixed(2)}   {user.valuta_naziv}</td>

                                                    <td className={styles.table_row_end}>
                                                        <Tooltip
                                                            placement="top"
                                                            title={i18n.t('Calculator')}
                                                        >
                                                            <div style={{ width: 'fit-content', marginRight: '3px', cursor: 'pointer', marginLeft: 'auto' }} onClick={() => this.setState({ ucinakEditMode: !this.state.ucinakEditMode })}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 24 24" fill="none">
                                                                    <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM11.03 17.66C11.32 17.95 11.32 18.43 11.03 18.72C10.88 18.87 10.69 18.94 10.5 18.94C10.31 18.94 10.12 18.87 9.97 18.72L8.25 17L6.53 18.72C6.38 18.86 6.19 18.94 6 18.94C5.81 18.94 5.62 18.87 5.47 18.72C5.18 18.43 5.18 17.95 5.47 17.66L7.19 15.94L5.47 14.22C5.18 13.93 5.18 13.45 5.47 13.16C5.76 12.87 6.24 12.87 6.53 13.16L8.25 14.88L9.97 13.16C10.26 12.87 10.74 12.87 11.03 13.16C11.32 13.45 11.32 13.93 11.03 14.22L9.31 15.94L11.03 17.66ZM10.5 8.81H6C5.59 8.81 5.25 8.47 5.25 8.06C5.25 7.65 5.59 7.31 6 7.31H10.5C10.91 7.31 11.25 7.65 11.25 8.06C11.25 8.47 10.91 8.81 10.5 8.81ZM18 18.56H13.5C13.09 18.56 12.75 18.22 12.75 17.81C12.75 17.4 13.09 17.06 13.5 17.06H18C18.41 17.06 18.75 17.4 18.75 17.81C18.75 18.22 18.41 18.56 18 18.56ZM18 14.81H13.5C13.09 14.81 12.75 14.47 12.75 14.06C12.75 13.65 13.09 13.31 13.5 13.31H18C18.41 13.31 18.75 13.65 18.75 14.06C18.75 14.47 18.41 14.81 18 14.81ZM18 8.81H16.52V10.31C16.52 10.72 16.18 11.06 15.77 11.06C15.36 11.06 15.02 10.72 15.02 10.31V8.81H13.5C13.09 8.81 12.75 8.47 12.75 8.06C12.75 7.65 13.09 7.31 13.5 7.31H15.02V5.81C15.02 5.4 15.36 5.06 15.77 5.06C16.18 5.06 16.52 5.4 16.52 5.81V7.31H18C18.41 7.31 18.75 7.65 18.75 8.06C18.75 8.47 18.41 8.81 18 8.81Z" fill={this.state.ucinakEditMode ? "" : "#58B4E5"} stroke={`${this.state.ucinakEditMode ? "#58B4E5" : ""}`} stroke-width="1" />
                                                                </svg>
                                                            </div>
                                                        </Tooltip>
                                                    </td>

                                                </tr>
                                            </table>
                                        </div>
                                        {/* {this.state.activityObj.table.length > 0 &&
                                            <div style={{ margin: '6px 0px 0px 8px', fontWeight: '500', fontSize: '16px' }}>
                                                {i18n.t('Total land area')}: {(this.state.activityObj.table.reduce((prev, item) => prev + Number(item.povrsina), 0)).toFixed(2)} ha
                                            </div>
                                        } */}
                                    </div>
                                }
                            </div>
                            {/* <Grid item xs={6}>
                                    <TextField required
                                        label={i18n.t('Field')}
                                        select
                                        value={this.state.field}
                                        SelectProps={{ native: true }}
                                        InputLabelProps={this.state.field ? { shrink: true } : null}
                                        style={{ width: '256px', marginBottom: '10px', marginLeft: '10px' }}
                                        onChange={(e) => { this.setState({ field: e.target.value }); }} >
                                        <option value='' />
                                        {this.state.fields.map((fields) => <option key={fields.id} value={fields.id}>{fields.naziv}</option>)}
                                    </TextField>
                                </Grid> */}

                            {/* <Grid xs={6}>
                                    <TextField required
                                        type="number"
                                        label={i18n.t('Amount')}
                                        value={this.state.kolicina}
                                        InputLabelProps={this.state.kolicina ? { shrink: true } : null}
                                        style={{ width: '256px', marginBottom: '10px', marginLeft: '10px' }}
                                        onChange={(e) => { this.setState({ kolicina: e.target.value }); }} >
                                    </TextField>
                                </Grid> */}

                            {/* <Grid item xs={6}>
                                    <TextField required
                                        label={i18n.t('Worker unit')}
                                        select
                                        value={this.state.worker_unit}
                                        SelectProps={{ native: true }}
                                        InputLabelProps={this.state.worker_unit ? { shrink: true } : null}
                                        style={{ width: '256px', marginBottom: '10px', marginLeft: '10px' }}
                                        onChange={(e) => { this.setState({ worker_unit: e.target.value }); }} >
                                        <option value='' />
                                        {this.state.worker_units.map((worker_units) => <option key={worker_units.id} value={worker_units.id}>{worker_units.naziv}</option>)}
                                    </TextField>
                                </Grid>

                                <Grid xs={12}>
                                    <TextField required
                                        type="number"
                                        label={i18n.t('Price per unit')}
                                        value={this.state.cena_jmr}
                                        InputLabelProps={this.state.cena_jmr ? { shrink: true } : null}
                                        style={{ width: '256px', marginBottom: '10px', marginLeft: '10px' }}
                                        onChange={(e) => { this.setState({ cena_jmr: e.target.value }); }} >
                                    </TextField>
                                </Grid> */}

                            {/* <div style={{ height: '100px' }}></div> */}

                            {this.state.cena_jmr && this.state.kolicina &&
                                <Grid item xs={12}>
                                    <Typography style={{ fontSize: '18px', marginLeft: '10px', marginTop: '10px' }}>
                                        {i18n.t('Total cost')}: {formatted}
                                    </Typography>
                                </Grid>
                            }


                            <Button style={{ alignSelf: 'start', backgroundColor: '#04764e', marginLeft: '10px', color: 'white', marginTop: 'auto', marginBottom: '10px' }}
                                onClick={() => this.addWorkerActivity()}
                                disabled={this.props.layerPosting} >
                                {this.props.edit ? i18n.t('Save') : i18n.t('Add')}
                            </Button>



                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
};