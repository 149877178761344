import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n/i18n';
import { Button, IconButton, Typography, Switch, InputAdornment } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setInitialState, addParcel, getOwnershipDropDown, addGroupedParcel } from '../../../actions/catastral_data/ParcelActions';
import { getLocalGov, getKatKultura } from '../../../actions/DropDownActions';
import { getUser } from '../../../utils/CommonFunctions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import axios from '../../../utils/AxiosWrapper';
import styles from "./AddNewExpense.module.css"

export class AddNewExpenseType extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            typeName: "",
            typdId: null,

        }
    }

    componentDidMount() {
        if (this.props.edit === true) {
            console.log('this.props.obj : ', this.props.obj);
            this.setState({
                typeName: this.props.obj.naziv,
                typeId: this.props.obj.id,
            })
        }
    }

    addExpense = () => {
        if (this.props.edit === false) {
            axios.post('api/extra_expenses/create_edit_expense_type', {
                naziv: this.state.typeName,
            }).then(res => {
                this.props.handleClose();
                this.props.reload();
            }).catch(err => console.log('sektor dropdown ERROR', err));
        } else {
            axios.post('api/extra_expenses/create_edit_expense_type', {
                id: Number(this.state.typeId),
                naziv: this.state.typeName,
            }).then(res => {
                this.props.handleClose();
                this.props.reload();
            }).catch(err => console.log('sektor dropdown ERROR', err));

        }

    }




    render() {


        const { theme } = this.props;
        return (
            <div style={{ padding: 20 }}>

                {/* <Paper className="roundedBoxMaterial" zdepth={4}> */}
                <Dialog
                    open={this.props.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} style={{ background: theme.palette.secondary.dark }} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit expense type') : i18n.t('Create new expense type')}
                        {!this.props.edit && this.state.featureGroupedParcelsOn && < div style={{ display: 'flex' }}>

                        </div>}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>

                        <TextField
                            disabled={this.props.preview}
                            label={i18n.t('Expense type')}
                            value={this.state.typeName}
                            SelectProps={{ native: true }}
                            // InputLabelProps={{ shrink: this.state.vrsta_proizvodnje ? true : false }}
                            InputProps={{
                                style: {
                                    color: this.props.preview && 'black',
                                }
                            }}
                            style={{ color: 'black', width: '100%', marginBottom: '10px', marginTop: '30px' }}
                            onChange={(e) => {
                                console.log("e.target.value : ", e.target.value);
                                this.setState({ typeName: e.target.value })
                            }} >

                        </TextField>








                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => this.addExpense()}
                            style={{ alignItems: 'right,', marginLeft: '10px', marginTop: '20px' }}
                        >
                            {this.props.edit ? i18n.t('Save') : i18n.t('Add')}
                        </Button>

                    </DialogContent>
                </Dialog>

            </div>


        )
    }
};

export default withStyles(styles, { withTheme: true })(connect(
    // mapStateToProps,
    // mapDispatchToProps
)(AddNewExpenseType))