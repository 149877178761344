import immutable from 'seamless-immutable';
import { Type as ReportsType } from '../../actions/reports/ReportsActions';



export const INITIAL_STATE = immutable({
    cropRotationReport: [],
    cropRotationReportFetching: false,
    cropRotationReportPages: 1,
    cropRotationReportFetchingFailed: false,

    seedingReport: [],
    seedingReportFetching: false,
    seedingReportPages: 1,
    seedingReportFetchingFailed: false,

    statisticReport: [],
    statisticReportFetching: false,
    statisticReportPages: 1,
    statisticReportFetchingFailed: false,

    noteReport: [],
    noteReportFetching: false,
    noteReportFetchingFailed: false,
    noteReportPages: 1,

    //dashboards
    statisticsItems: {},
    statisticsItemsFetching: false,
    statisticsItemsFetchingFailed: false,

    seedingItems: {},
    seedingItemsFetching: false,
    seedingItemsFetchingFailed: false,


    noteItems: {},
    noteItemsFetching: false,
    noteItemsFetchingFailed: false,

    cropRotationItems: {},
    cropRotationItemsFetching: false,
    cropRotationItemsFetchingFailed: false,

    enterpriseDashboard: {},
    enterpriseDashboardFetching: false,
    enterpriseDashboardFetchingFailed: false,

    //ACTIVITY
    activityObj: {},
    activityFetching: false,
    activityFetchingFailed: false,
    agroProduction: [],
    agroProductionFetching: false,
    agroProductionPages: 1,
    agroProductionFetchingFailed: false,


    agroProductionItems: {},
    agroProductionItemsFetching: false,
    agroProductionItemsFetchingFailed: false,

    activityPosting: false,
    activityPostingSuccess: false,
    activityPostingFailed: false,

    activityRejecting: false,
    activityRejectingSuccess: false,
    activityRejectingFailed: false,

    //ENTERPRISE
    enterpriseReport: [],
    enterpriseReportFetching: false,
    enterpriseReportPages: 1,
    enterpriseReportFetchingFailed: false,

    yieldReport: [],
    yieldReportFetching: false,
    yieldReportPages: 1,
    yieldReportFetchingFailed: false,

     //dashboards
     yieldDashboard: {},
     yieldItemsFetching: false,
     yieldItemsFetchingFailed: false,
});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case ReportsType.GET_CROP_ROTATION_REPORT_CALL: {
            const cropRotationReportFetching = true;
            return state.merge({ cropRotationReportFetching });
            break;
        }

        case ReportsType.GET_CROP_ROTATION_REPORT_SUCCESS: {
            const cropRotationReport = action.data.data;
            const cropRotationReportPages = action.data.total_pages
            const cropRotationReportFetching = false;
            return state.merge({ cropRotationReport, cropRotationReportPages, cropRotationReportFetching });
            break;
        }

        case ReportsType.GET_CROP_ROTATION_REPORT_FAILED: {
            const cropRotationReportFetchingFailed = true;
            return state.merge({ cropRotationReportFetchingFailed });
            break;
        }

        case ReportsType.GET_SEEDING_REPORT_CALL: {
            const seedingReportFetching = true;
            return state.merge({ seedingReportFetching });
            break;
        }

        case ReportsType.GET_SEEDING_REPORT_SUCCESS: {
            const seedingReport = action.data.data;
            const seedingReportPages = action.data.total_pages
            const seedingReportFetching = false;
            return state.merge({ seedingReport, seedingReportPages, seedingReportFetching });
            break;
        }

        case ReportsType.GET_SEEDING_REPORT_FAILED: {
            const cropRotationReportFetchingFailed = true;
            return state.merge({ cropRotationReportFetchingFailed });
            break;
        }

        case ReportsType.GET_STATISTICS_DASHBOARD_CALL: {
            const statisticsItemsFetching = true;
            return state.merge({ statisticsItemsFetching });
            break;
        }

        case ReportsType.GET_STATISTICS_DASHBOARD_SUCCESS: {
            const statisticsItems = action.data;
            const statisticsItemsFetching = false;
            return state.merge({ statisticsItems, statisticsItemsFetching });
            break;
        }

        case ReportsType.GET_STATISTICS_DASHBOARD_FAILED: {
            const statisticsItemsFetchingFailed = true;
            const statisticsItemsFetching = false;
            return state.merge({ statisticsItemsFetching, statisticsItemsFetchingFailed });
            break;
        }


        case ReportsType.GET_NOTE_DASHBOARD_CALL: {
            const noteItemsFetching = true;
            return state.merge({ noteItemsFetching });
            break;
        }

        case ReportsType.GET_NOTE_DASHBOARD_SUCCESS: {
            const noteItems = action.data;
            const noteItemsFetching = false;
            return state.merge({ noteItems, noteItemsFetching });
            break;
        }

        case ReportsType.GET_NOTE_DASHBOARD_FAILED: {
            const noteItemsFetchingFailed = true;
            const noteItemsFetching = false;
            return state.merge({ noteItemsFetching, noteItemsFetchingFailed });
            break;
        }

        case ReportsType.GET_CROPROTATION_DASHBOARD_CALL: {
            const cropRotationItemsFetching = true;
            return state.merge({ cropRotationItemsFetching });
            break;
        }

        case ReportsType.GET_CROPROTATION_DASHBOARD_SUCCESS: {
            const cropRotationItems = action.data;
            const cropRotationItemsFetching = false;
            return state.merge({ cropRotationItems, cropRotationItemsFetching });
            break;
        }

        case ReportsType.GET_CROPROTATION_DASHBOARD_FAILED: {
            const cropRotationItemsFetchingFailed = true;
            const cropRotationItemsFetching = false;
            return state.merge({ cropRotationItemsFetching, cropRotationItemsFetchingFailed });
            break;
        }

        case ReportsType.GET_SEEDING_DASHBOARD_CALL: {
            const seedingItemsFetching = true;
            return state.merge({ seedingItemsFetching });
            break;
        }

        case ReportsType.GET_SEEDING_DASHBOARD_SUCCESS: {
            const seedingItems = action.data;
            const seedingItemsFetching = false;
            return state.merge({ seedingItems, seedingItemsFetching });
            break;
        }

        case ReportsType.GET_SEEDING_DASHBOARD_FAILED: {
            const seedingItemsFetchingFailed = true;
            const seedingItemsFetching = false;
            return state.merge({ seedingItemsFetching, seedingItemsFetchingFailed });
            break;
        }

        case ReportsType.GET_ENTERPRISE_DASHBOARD_CALL: {
            const enterpriseDashboardFetching = true;
            return state.merge({ enterpriseDashboardFetching });
            break;
        }

        case ReportsType.GET_ENTERPRISE_DASHBOARD_SUCCESS: {
            const enterpriseDashboard = action.data;
            const enterpriseDashboardFetching = false;
            return state.merge({ enterpriseDashboard, enterpriseDashboardFetching });
            break;
        }

        case ReportsType.GET_ENTERPRISE_DASHBOARD_FAILED: {
            const enterpriseDashboardFetchingFailed = true;
            const enterpriseDashboardFetching = false;
            return state.merge({ enterpriseDashboardFetching, enterpriseDashboardFetchingFailed });
            break;
        }


        case ReportsType.GET_STATISTIC_REPORT_CALL: {
            const statisticReportFetching = true;
            return state.merge({ statisticReportFetching });
            break;
        }

        case ReportsType.GET_STATISTIC_REPORT_SUCCESS: {
            const statisticReport = action.data.data;
            const statisticReportPages = action.data.total_pages
            const statisticReportFetching = false;
            return state.merge({ statisticReport, statisticReportPages, statisticReportFetching });
            break;
        }

        case ReportsType.GET_STATISTIC_REPORT_FAILED: {
            const statisticReportFetchingFailed = true;
            return state.merge({ statisticReportFetchingFailed });
            break;
        }

        case ReportsType.GET_AGRO_PRODUCTION_CALL: {
            const agroProductionFetching = true;
            return state.merge({ agroProductionFetching });
            break;
        }

        case ReportsType.GET_AGRO_PRODUCTION_SUCCESS: {
            const agroProduction = action.data.data;
            const agroProductionPages = action.data.total_pages
            const agroProductionFetching = false;
            return state.merge({ agroProduction, agroProductionPages, agroProductionFetching });
            break;
        }

        case ReportsType.GET_AGRO_PRODUCTION_FAILED: {
            const agroProductionFetchingFailed = true;
            return state.merge({ agroProductionFetchingFailed });
            break;
        }


        case ReportsType.GET_AGRO_PRODUCTION_DASHBOARD_CALL: {
            const agroProductionItemsFetching = true;
            return state.merge({ agroProductionItemsFetching });
            break;
        }

        case ReportsType.GET_AGRO_PRODUCTION_DASHBOARD_SUCCESS: {
            const agroProductionItems = action.data;
            const agroProductionItemsFetching = false;
            return state.merge({ agroProductionItems, agroProductionItemsFetching });
            break;
        }

        case ReportsType.GET_AGRO_PRODUCTION_DASHBOARD_FAILED: {
            const agroProductionItemsFetchingFailed = true;
            const agroProductionItemsFetching = false;
            return state.merge({ agroProductionItemsFetching, agroProductionItemsFetchingFailed });
            break;
        }

        case ReportsType.SET_INITIAL_STATE:
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;


        case ReportsType.GET_ACTIVITY_TEMPLATE_CALL: {
            const activityFetching = true;
            return state.merge({ activityFetching });
            break;
        }

        case ReportsType.GET_ACTIVITY_TEMPLATE_SUCCESS: {
            const activityFetching = false;
            const activityObj = action.data.data[0];
            return state.merge({ activityFetching, activityObj });
            break;
        }

        case ReportsType.GET_ACTIVITY_TEMPLATE_FAILED: {
            const activityFetching = false;
            const activityFetchingFailed = true;
            return state.merge({ activityFetching, activityFetchingFailed });
            break;
        }

        case ReportsType.SIGN_ACTIVITY_CALL: {
            const activityPosting = true;
            return state.merge({ activityPosting });
            break;
        }

        case ReportsType.SIGN_ACTIVITY_SUCCESS: {
            const activityPosting = false;
            const activityPostingSuccess = true;
            return state.merge({ activityPosting, activityPostingSuccess });
            break;
        }

        case ReportsType.SIGN_ACTIVITY_FAILED: {
            const activityPostingFailed = true;
            const activityPosting = false;
            return state.merge({ activityPosting, activityPostingFailed });
            break;
        }

        case ReportsType.DENIE_ACTIVITY_CALL: {
            const activityPosting = true;
            return state.merge({ activityPosting });
            break;
        }

        case ReportsType.DENIE_ACTIVITY_SUCCESS: {
            const activityRejecting = false;
            const activityRejectingSuccess = true;
            return state.merge({ activityRejecting, activityRejectingSuccess });
            break;
        }

        case ReportsType.DENIE_ACTIVITY_FAILED: {
            const activityRejectingFailed = true;
            const activityRejecting = false;
            return state.merge({ activityRejecting, activityRejectingFailed });
            break;
        }


        case ReportsType.GET_NOTE_REPORT_CALL: {
            const noteReportFetching = true;
            return state.merge({ noteReportFetching });
            break;
        }

        case ReportsType.GET_NOTE_REPORT_SUCCESS: {
            const noteReport = action.data.data;
            const noteReportPages = action.data.total_pages
            const noteReportFetching = false;
            return state.merge({ noteReport, noteReportPages, noteReportFetching });
            break;
        }

        case ReportsType.GET_NOTE_REPORT_FAILED: {
            const noteReportFetchingFailed = true;
            return state.merge({ noteReportFetchingFailed });
            break;
        }

        case ReportsType.GET_ENTERPRISE_REPORT_CALL: {
            const enterpriseReportFetching = true;
            return state.merge({ enterpriseReportFetching });
            break;
        }

        case ReportsType.GET_ENTERPRISE_REPORT_SUCCESS: {
            const enterpriseReport = action.data.data;
            const enterpriseReportPages = action.data.total_pages
            const enterpriseReportFetching = false;
            return state.merge({ enterpriseReport, enterpriseReportPages, enterpriseReportFetching });
            break;
        }

        case ReportsType.GET_ENTERPRISE_REPORT_FAILED: {
            const enterpriseReportFetchingFailed = true;
            return state.merge({ enterpriseReportFetchingFailed });
            break;
        }

        case ReportsType.GET_YIELD_REPORT_CALL: {
            const yieldReportFetching = true;
            return state.merge({ yieldReportFetching });
            break;
        }

        case ReportsType.GET_YIELD_REPORT_SUCCESS: {
            const yieldReport = action.data.data;
            const yieldReportPages = action.data.total_pages
            const yieldReportFetching = false;
            return state.merge({ yieldReport, yieldReportPages, yieldReportFetching });
            break;
        }

        case ReportsType.GET_YIELD_REPORT_FAILED: {
            const yieldReportFetchingFailed = true;
            return state.merge({ yieldReportFetchingFailed });
            break;
        }

        case ReportsType.GET_YIELD_DASHBOARD_CALL: {
            const yieldItemsFetching = true;
            return state.merge({ yieldItemsFetching });
            break;
        }

        case ReportsType.GET_YIELD_DASHBOARD_SUCCESS: {
            const yieldDashboard = action.data;
            const yieldItemsFetching = false;
            return state.merge({ yieldDashboard, yieldItemsFetching });
            break;
        }

        case ReportsType.GET_YIELD_DASHBOARD_FAILED: {
            const yieldItemsFetchingFailed = true;
            const yieldItemsFetching = false;
            return state.merge({ yieldItemsFetching, yieldItemsFetchingFailed });
            break;
        }

        default:
            return state;
    }
}
