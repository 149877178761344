import axios from '../utils/AxiosWrapper';
export const Type = {

    GET_LOCAL_GOV_CALL: 'GET_LOCAL_GOV_CALL',
    GET_LOCAL_GOV_SUCCESS: 'GET_LOCAL_GOV_SUCCESS',
    GET_LOCAL_GOV_FAILED: 'GET_LOCAL_GOV_FAILED',

    GET_STATES_CALL: 'GET_STATES_CALL',
    GET_STATES_SUCCESS: 'GET_STATES_SUCCESS',
    GET_STATES_FAILED: 'GET_STATES_FAILED',

    GET_KAT_KULTURA_CALL: 'GET_KAT_KULTURA_CALL',
    GET_KAT_KULTURA_SUCCESS: 'GET_KAT_KULTURA_SUCCESS',
    GET_KAT_KULTURA_FAILED: 'GET_KAT_KULTURA_FAILED',

    GET_CROP_DROPDOWN_CALL: 'GET_CROP_DROPDOWN_CALL',
    GET_CROP_DROPDOWN_SUCCESS: 'GET_CROP_DROPDOWN_SUCCESS',
    GET_CROP_DROPDOWN_FAILED: 'GET_CROP_DROPDOWN_FAILED',

    GET_CROP_GROUP_DROPDOWN_CALL: 'GET_CROP_GROUP_DROPDOWN_CALL',
    GET_CROP_GROUP_DROPDOWN_SUCCESS: 'GET_CROP_GROUP_DROPDOWN_SUCCESS',
    GET_CROP_GROUP_DROPDOWN_FAILED: 'GET_CROP_GROUP_DROPDOWN_FAILED',

    GET_CROP_SUBGROUP_DROPDOWN_CALL: 'GET_CROP_SUBGROUP_DROPDOWN_CALL',
    GET_CROP_SUBGROUP_DROPDOWN_SUCCESS: 'GET_CROP_SUBGROUP_DROPDOWN_SUCCESS',
    GET_CROP_SUBGROUP_DROPDOWN_FAILED: 'GET_CROP_SUBGROUP_DROPDOWN_FAILED',

    GET_MATERIAL_GROUP_DROPDOWN_CALL: 'GET_MATERIAL_GROUP_DROPDOWN_CALL',
    GET_MATERIAL_GROUP_DROPDOWN_SUCCESS: 'GET_MATERIAL_GROUP_DROPDOWN_SUCCESS',
    GET_MATERIAL_GROUP_DROPDOWN_FAILED: 'GET_MATERIAL_GROUP_DROPDOWN_FAILED',

    GET_MATERIAL_SUBGROUP_DROPDOWN_CALL: 'GET_MATERIAL_SUBGROUP_DROPDOWN_CALL',
    GET_MATERIAL_SUBGROUP_DROPDOWN_SUCCESS: 'GET_MATERIAL_SUBGROUP_DROPDOWN_SUCCESS',
    GET_MATERIAL_SUBGROUP_DROPDOWN_FAILED: 'GET_MATERIAL_SUBGROUP_DROPDOWN_FAILED',

    GET_MATERIAL_DROPDOWN_CALL: 'GET_MATERIAL_DROPDOWN_CALL',
    GET_MATERIAL_DROPDOWN_SUCCESS: 'GET_MATERIAL_DROPDOWN_SUCCESS',
    GET_MATERIAL_DROPDOWN_FAILED: 'GET_MATERIAL_DROPDOWN_FAILED',

    GET_WORKING_OPERATION_DROPDOWN_CALL: 'GET_WORKING_OPERATION_DROPDOWN_CALL',
    GET_WORKING_OPERATION_DROPDOWN_SUCCESS: 'GET_WORKING_OPERATION_DROPDOWN_SUCCESS',
    GET_WORKING_OPERATION_DROPDOWN_FAILED: 'GET_WORKING_OPERATION_DROPDOWN_FAILED',

    GET_SEASON_CALL: 'GET_SEASON_CALL',
    GET_SEASON_SUCCESS: 'GET_SEASON_SUCCESS',
    GET_SEASON_FAILED: 'GET_SEASON_FAILED',

    GET_LAYERS_CALL_DROP_DOWN: 'GET_LAYERS_CALL_DROP_DOWN',
    GET_LAYERS_SUCCESS_DROP_DOWN: 'GET_LAYERS_SUCCESS_DROP_DOWN',
    GET_LAYERS_FAILED_DROP_DOWN: 'GET_LAYERS_FAILED_DROP_DOWN',

    SET_INITIAL_STATE: 'SET_INITIAL_STATE',
    SET_FARMER_ROW_IN_REDUCER: 'SET_FARMER_ROW_IN_REDUCER',
};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function setRowInReducer(rowInfo, name) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_FARMER_ROW_IN_REDUCER,
            rowInfo: rowInfo,
            name: name
        })
    }
}

export function getStates() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_STATES_CALL
        });

        axios.get('api/opstina/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_STATES_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_STATES_FAILED
                });
            });
    }
}

export function getLocalGov(id_state) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_LOCAL_GOV_CALL
        });
        axios.get(`api/katastarska_opstina/drop_down${id_state ? '?id_opstina=' + id_state : ''}`)
            // axios.get('api/katastarska_opstina/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_LOCAL_GOV_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_LOCAL_GOV_FAILED
                });
            });
    }
}

export function getLocalGovByState(stateId) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_LOCAL_GOV_CALL
        });

        axios.get('api/katastarska_opstina/drop_down?id_opstina=' + stateId)
            .then(function (response) {
                dispatch({
                    type: Type.GET_LOCAL_GOV_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_LOCAL_GOV_FAILED
                });
            });
    }
}

export function getKatKultura() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_KAT_KULTURA_CALL
        });

        axios.get('api/katastarska_kultura/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_KAT_KULTURA_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_KAT_KULTURA_FAILED
                });
            });
    }
}

export function getSeasonDropDown() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_SEASON_CALL
        });

        axios.get('api/season/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_SEASON_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_SEASON_FAILED
                });
            });
    }
}

export function getLayerDropDown() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_LAYERS_CALL_DROP_DOWN
        });

        axios.get('api/layer/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_LAYERS_SUCCESS_DROP_DOWN,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_LAYERS_FAILED_DROP_DOWN
                });
            });
    }
}

export function getCropForPreceding(id_subgroup) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_CROP_DROPDOWN_CALL
        });

        // axios.get(`api/crop/drop_down${id_subgroup ? '?id_podgrupa=' + id_subgroup : ''}`)
        axios.get('api/crop/drop_down_vlasnik')
            .then(function (response) {
                dispatch({
                    type: Type.GET_CROP_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_CROP_DROPDOWN_FAILED
                });
            });
    }
}


export function getSubgroup(id_group) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_CROP_SUBGROUP_DROPDOWN_CALL
        });

        axios.get(`api/crop_subgroup/drop_down${id_group ? '?id_grupa=' + id_group : ''}`)
            .then(function (response) {
                dispatch({
                    type: Type.GET_CROP_SUBGROUP_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_CROP_SUBGROUP_DROPDOWN_FAILED
                });
            });
    }
}

export function getGroup() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_CROP_GROUP_DROPDOWN_CALL
        });

        axios.get('api/crop_group/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_CROP_GROUP_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_CROP_GROUP_DROPDOWN_FAILED
                });
            });
    }
}


export function getMaterialDropdown() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_MATERIAL_DROPDOWN_CALL
        });

        axios.get('api/material/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_MATERIAL_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_MATERIAL_DROPDOWN_FAILED
                });
            });
    }
}

export function getMaterialSubgroup(id_grupa) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_MATERIAL_SUBGROUP_DROPDOWN_CALL
        });

        axios.get(`api/material_subgroup/drop_down${id_grupa ? '?id_grupa=' + id_grupa : ''}`)
            .then(function (response) {
                dispatch({
                    type: Type.GET_MATERIAL_SUBGROUP_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_MATERIAL_SUBGROUP_DROPDOWN_FAILED
                });
            });
    }
}

export function getMaterialGroup() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_MATERIAL_GROUP_DROPDOWN_CALL
        });

        axios.get('api/material_group/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_MATERIAL_GROUP_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_MATERIAL_GROUP_DROPDOWN_FAILED
                });
            });
    }
}

export function getWODropdown() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_WORKING_OPERATION_DROPDOWN_CALL
        });

        axios.get('api/working_operation/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_WORKING_OPERATION_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_WORKING_OPERATION_DROPDOWN_FAILED
                });
            });
    }
}


