import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n/i18n';
import { Button, IconButton, Typography, Switch, InputAdornment } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import styles from './newactivity.module.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setInitialState, addParcel, getOwnershipDropDown, addGroupedParcel } from '../../../actions/catastral_data/ParcelActions';
import { getLocalGov, getKatKultura } from '../../../actions/DropDownActions';
import { getUser } from '../../../utils/CommonFunctions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import ManageParcels from './ManageParcels';
import axios from '../../../utils/AxiosWrapper';
import { toNumber } from 'lodash';

export class PreviewParcel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            parcelObj: this.props.parcelObj,
            new: false,
            parcelsOpen: false,
            // this.state.new postavlja uslove kada da se otvori desna a kada leva strana switch-a
            new: this.props.parcelObj.grupna === true ? true : false
        }
    }

    componentDidMount() {
        axios.post('api/parcela/get_parts', { params: { id: this.props.row.id } })
            .then(res => {
                let result = res.data.data
                let parcele = []
                let copyObj = Object.assign({}, this.state.parcelObj);

                result.forEach(element => {
                    parcele.push(element)
                });
                copyObj.parcele = parcele
                this.setState({
                    parcelObj: copyObj
                })
            })
            .catch(err => { console.log(err) })
    }
    isOdd = (num) => { return num % 2; };


    componentWillReceiveProps(nextProps) {
        if (nextProps.parcelPostingFailed === true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('parcelPostingFailed');
        }
    }

    render() {
        const { theme } = this.props;
        return (
            <div style={{ padding: 20 }}>

                <Dialog
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} style={{ background: theme.palette.secondary.dark }} className="responsive-dialog-title">
                        {i18n.t('Grouped parcel details')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ display: 'flex', justifyContent: 'left' }}>
                            <Grid>
                                <div style={{ width: '540px', marginBottom: '10px', marginTop: '20px', marginLeft: '10px', marginRight: '10px' }} />
                                <div className={styles.section_title1} style={{ marginBottom: '20px' }} >{i18n.t('Grouped parcel title')}: {this.props.row.naziv}</div>
                                <div />
                                <div className={styles.title_container}>
                                    <div>
                                        <div className={styles.section_title1}>{i18n.t('Belonging parcels')}:</div>
                                    </div>
                                </div>
                                {(this.state.parcelObj.parcele === undefined || this.state.parcelObj.parcele.length === 0) &&
                                    <div className={styles.content_container}>
                                        <div className={styles.content_empty}>{i18n.t('You have no selected parcels')}</div>
                                    </div>
                                }
                                {this.state.parcelObj.parcele && this.state.parcelObj.parcele.length > 0 &&
                                    <div className={styles.content_container}>
                                        <div className={styles.content_border} />
                                        <div className={styles.table_div}>
                                            <table className={styles.table}>
                                                <tr className={styles.table_row}>
                                                    <th className={styles.table_header}>{i18n.t('Title')}</th>
                                                    <th className={styles.table_header}>{i18n.t('Land area')}</th>
                                                    <th className={styles.table_header}>{i18n.t('Local government')}</th>
                                                </tr>
                                                {this.state.parcelObj.parcele.map((item, i) => {
                                                    return <tr className={this.isOdd(i) === 0 ? styles.table_row_odd : styles.table_row}>
                                                        <td className={styles.table_row_text}>{item.broj}</td>
                                                        <td className={styles.table_row_text}>{(Number(item.povrsina)).toFixed(2)} ha</td>
                                                        <td className={styles.table_row_text}>{item.katastarska_opstina_naziv}</td>
                                                    </tr>
                                                })}
                                            </table>
                                        </div>
                                        {this.state.parcelObj.parcele.length > 0 &&
                                            <div style={{ margin: '6px 0px 0px 8px', fontWeight: '500', fontSize: '16px', display: 'flex', justifyContent: 'space-between' }}>
                                                <div>{i18n.t('Total land area')}: {(this.state.parcelObj.parcele.reduce((prev, item) => prev + Number(item.povrsina), 0)).toFixed(2)} ha</div>
                                                <div>{this.state.parcelObj.parcele[0].sektor_naziv ? `Ogranak: ${this.state.parcelObj.parcele[0].sektor_naziv}` : ""}</div>
                                            </div>
                                        }
                                    </div>
                                }
                            </Grid>
                        </div>
                    </DialogContent>
                </Dialog>

            </div>


        )
    }
};

function mapStateToProps(state) {
    return {
        parcelPosting: state.parcelReducer.parcelPosting,
        parcelPostingSuccess: state.parcelReducer.parcelPostingSuccess,
        parcelPostingFailed: state.parcelReducer.parcelPostingFailed,

        localGoverments: state.appReducer.localGoverments,
        localGovermentsFetching: state.appReducer.localGovermentsFetching,
        localGovermentsFetchingFailed: state.appReducer.localGovermentsFetchingFailed,

        kat_kulture: state.appReducer.kat_kulture,
        kat_kultureFetching: state.appReducer.kat_kultureFetching,
        kat_kultureFetchingFailed: state.appReducer.kat_kultureFetchingFailed,

        parcelObj: state.parcelReducer.parcelObj,
        ownershipDropDown: state.parcelReducer.ownershipDropDown,
        ownershipFetchingDropDown: state.parcelReducer.ownershipFetchingDropDown,
        ownershipFetchingFailedDropDown: state.parcelReducer.ownershipFetchingFailedDropDown,

    }
}

function mapDispatchToProps(dispatch) {
    return {
    }

}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(PreviewParcel))