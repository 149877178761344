import React from 'react';
import i18n from '../../../i18n/i18n';
import axios from '../../../utils/AxiosWrapper';
import { Bar } from 'react-chartjs-2';
import 'react-dates/initialize';
import { formatData, formatForecast, formatDisease, formatWorkPlanningData } from '../DataFormatters';
import { withStyles } from '@material-ui/core/styles';
import { MoonLoader } from 'react-spinners';
import MeteoTable from '../MeteoTable';

const styles = {
    value: { width: '8px', height: '8px', margin: '3px', borderRadius: '3px', padding: '8px', textAlign: 'center', fontWeight: 'bold', font: '12px', color: 'white' }
};

export class HarvestWindowFruitGrape extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            station_id: this.props.row.uid,
            data: {},
            work: {},
            load: true,
            barColors: []
        };
    }

    componentDidMount() {
        this.getPessl();
    }

    getWork = (path, work) => {
        let obj = {};
        axios.post('/api/testMeteo/meteo', path)
            .then(res => {
                obj = formatWorkPlanningData(res, obj);
                this.setState({ [work]: obj });
                this.generateValuesAndColors();
            })
            .catch(err => console.log('Error: ', err))
    }

    getPessl = () => {
        let station_id = this.state.station_id;

        let work_path = '/forecast/' + station_id;

        this.getWork({ path: work_path, name: "harvest-window", crop: "fruit-grape", url: "https://api.fieldclimate.com/v2", method: "POST" }, 'work');
    }

    generateValuesAndColors() {
        let newValues = [];
        if (this.state.work['Harvest window']) newValues = this.state.work['Harvest window'].map(item => (item + 6));

        this.setState(prevState => {
            let work = { ...prevState.work };
            work['Harvest window'] = newValues;
            return { work }
        });

        let colors = [];
        for (let i = 0; i < this.state.work['Harvest window'].length; i++) {
            const element = this.state.work['Harvest window'][i];
            if (element === 1) {
                colors.push('#660000');
            } else if (element === 2) {
                colors.push('#AB2424');
            } else if (element === 3) {
                colors.push('#B84949');
            } else if (element === 4) {
                colors.push('#C67465');
            } else if (element === 5) {
                colors.push('#D6AF7B');
            } else if (element === 6) {
                colors.push('#D1CC82');
            } else if (element === 7) {
                colors.push('#A1C16E');
            } else if (element === 8) {
                colors.push('#7AAC5F');
            } else if (element === 9) {
                colors.push('#458248');
            } else {
                colors.push('#336035');
            }
        }
        this.setState({ barColors: colors, load: false });
    }

    render() {
        const { classes } = this.props;
        let graph1 = {};
        let graph2 = {};

        const options1 = {
            maintainAspectRatio: false,
            legend: {
                display: false,
            },
            tooltips: {
                displayColors: false,
                callbacks: {
                    label: (tooltipItem, chart) => graph1.datasets.map(ds => ds.label + ': ' + ds.data[tooltipItem.index]),
                },
            },
            scales: {
                xAxes: [
                    {
                        barPercentage: 1,
                        categoryPercentage: 1,
                        gridLines: {
                            drawOnChartArea: false
                        },
                    },
                ],
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                        gridLines: {
                            drawOnChartArea: true
                        },
                        ticks: {
                            min: 0,
                            max: 10,
                            maxTicksLimit: 10
                        },
                    },
                ],
            },
        };
        const options2 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-2',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[°C]'
                        },
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y-axis-3',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[mm]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 4
                        }
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y-axis-4',
                        gridLines: {
                            drawOnChartArea: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[%]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 100,
                        }
                    }
                ],
            },
        };
        if (
            this.state.work.dates &&
            this.state.work
        ) {
            graph1 = {
                labels: this.state.work.dates,
                datasets: [
                    {
                        label: i18n.t('Precipitation [mm]'),
                        data: this.state.work['Precipitation'],
                        hidden: true,
                    },
                    {
                        label: i18n.t('Relative humidity [%]'),
                        data: this.state.work['Relative humidity'],
                        hidden: true,
                    },
                    {
                        label: i18n.t('Temperature [°C]'),
                        hidden: true,
                        data: this.state.work['Temperature']
                    },
                    {
                        label: i18n.t('Leaf Wetness'),
                        hidden: true,
                        data: this.state.work['Leaf Wetness']
                    },
                    {
                        data: this.state.work['Harvest window'],
                        label: i18n.t('Value'),
                        backgroundColor: this.state.barColors,
                        type: 'bar',
                        borderWidth: 2,
                        yAxisID: 'y-axis-1',

                    },
                ],
            };

            graph2 = {
                labels: this.state.work.dates,
                datasets: [
                    {
                        data: this.state.work['Temperature'],
                        label: i18n.t('Temperature [°C]'),
                        backgroundColor: 'transparent',
                        borderColor: 'red',
                        type: 'line',
                        borderWidth: 2,
                        pointRadius: false,
                        yAxisID: 'y-axis-2',
                    },
                    {
                        data: this.state.work['Precipitation'],
                        label: i18n.t('Precipitation [mm]'),
                        backgroundColor: 'rgba(134,184,217,0.3)',
                        borderColor: '#86b8d9',
                        type: 'line',
                        borderWidth: 2,
                        pointRadius: false,
                        yAxisID: 'y-axis-3',
                    },
                    {
                        data: this.state.work['Probability of Prec.'],
                        label: i18n.t('Precipitation probability [%]'),
                        type: 'line',
                        backgroundColor: 'transparent',
                        borderColor: '#0d86d6',
                        borderWidth: 2,
                        pointRadius: false,
                        yAxisID: 'y-axis-4',
                        borderDash: [10, 5]
                    },

                ],
            };
        };

        return (
            <div style={{ paddingLeft: '35px' }}>
                <div style= {{display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'center'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                        <p style={{fontSize: 20, marginRight: 4 }}>{i18n.t('Conditions for the Harvest - fruit growing and viticulture')}</p>
                        </div>
                </div>
                {this.state.load === true && <div style={{ marginLeft: '39%', marginTop: '14%' }}> <MoonLoader loading={this.state.load} size={100} /></div>}
                {this.state.load === false &&
                    <div>
                        {/* <div>
                            <h1 align="center">{i18n.t('7 day forecast for harvest - ')}{i18n.t('fruit, grape')}</h1>
                        </div> */}
                        <div style={{ marginTop: '30px', textAlign: "center" }}>
                            <span>{i18n.t('Very bad conditions - ')}</span>
                            <span className={classes.value} style={{ background: '#660000' }}>1</span>
                            <span className={classes.value} style={{ background: '#AB2424' }}>2</span>
                            <span className={classes.value} style={{ background: '#B84949' }}>3</span>
                            <span className={classes.value} style={{ background: '#C67465' }}>4</span>
                            <span className={classes.value} style={{ background: '#D6AF7B' }}>5</span>
                            <span className={classes.value} style={{ background: '#D1CC82' }}>6</span>
                            <span className={classes.value} style={{ background: '#A1C16E' }}>7</span>
                            <span className={classes.value} style={{ background: '#7AAC5F' }}>8</span>
                            <span className={classes.value} style={{ background: '#458248' }}>9</span>
                            <span className={classes.value} style={{ background: '#336035' }}>10</span>
                            <span>{i18n.t(' - very good conditions')}</span>
                        </div>
                        <div style={{ width: '95%', marginTop: '30px' }}>
                            <Bar data={graph1} height={300} width={600} options={options1} />
                        </div>
                        {/* <div style={{ width: '95%', marginTop: '20px'}}>
                            <Bar data={graph2} height={200} width={400} options={options2} />
                        </div> */}
                        <MeteoTable data={{
                            headers: [i18n.t('Date and time'), i18n.t('Temperature [°C]'), i18n.t('Precipitation [mm]'), i18n.t('Relative humidity [%]'), i18n.t('Leaf Wetness')],
                            dates: this.state.work.dates,
                            temperature: this.state.work['Temperature'],
                            precipitation: this.state.work['Precipitation'],
                            data: this.state.work['Relative humidity'],
                            data: this.state.work['Leaf Wetness']
                        }} />
                        <div style={{ marginTop: '20px' }}></div>
                    </div>
                }
            </div>
        );
    }
};

export default withStyles(styles)(HarvestWindowFruitGrape);
