import React, { Component } from 'react';
import Select from 'react-select';
import {
  TextField, Checkbox, Typography, withStyles
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, TimePicker, DatePicker } from 'material-ui-pickers';
import axios from '../../../utils/AxiosWrapper';
import i18n from '../../../i18n/i18n';

MomentUtils.prototype.getStartOfMonth=MomentUtils.prototype.startOfMonth;

const styles = theme => ({
  textField: {
    width: 256,
    lineHeight: 2.4,
    marginTop: 2,
    marginBotom: 2
  },
  resize: {
    fontSize: 14,
  },
  form: {
    height: 600,
    display: 'flex',
    flexDirection: 'column'
  },
  checkboxGroup: {
    display: 'flex',
    alignItems: 'center'
  }
});

class ZoomToVehicle extends Component {
  state={
    vehicles: []
  };

  componentDidMount() {
    const self = this;
    axios.get('api/machine/klijent_drop_down')
      .then((response) => {
        const vehicles = response.data.data.map(item => ({ label: item.naziv, value: item.id_pogonska_masina_klijent }));
        self.setState({ vehicles });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const {
      classes, startTime, endTime, selectedVehicles, isLatestPositionChecked, onVehicleChange, onStartTimeChange, onEndTimeChange, onLastCheckboxChange
    } = this.props;
    const { vehicles } = this.state;
    return (
      <form className={classes.form} id="zoomToVehicle">
        <div className={classes.checkboxGroup}>
          <Checkbox
            checked={isLatestPositionChecked}
            onChange={onLastCheckboxChange}
            value="isLatestPositionChecked"
            color="primary"
          />
          <Typography>Prikaz poslednje registrovanih pozicija</Typography>
        </div>
        <Select
          name="vehicle"
          onChange={onVehicleChange}
          value={selectedVehicles}
          closeMenuOnSelect={true}
          options={vehicles}
          className="multi-select-in-filter-dashboards"
          classNamePrefix="select"
          placeholder={i18n.t('Select')}
        />
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Grid container justify="space-around">
            <DatePicker
              margin="normal"
              label={i18n.t('Date - start')}
              value={startTime}
              onChange={onStartTimeChange}
            />
            <TimePicker
              margin="normal"
              label={i18n.t('Time - start')}
              value={startTime}
              onChange={onStartTimeChange}
            />
          </Grid>
          <Grid container justify="space-around">
            <DatePicker
              margin="normal"
              label={i18n.t('Date - end')}
              value={endTime}
              onChange={onEndTimeChange}
            />
            <TimePicker
              margin="normal"
              label={i18n.t('Time - end')}
              value={endTime}
              onChange={onEndTimeChange}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </form>
    );
  }
}

export default withStyles(styles)(ZoomToVehicle);
