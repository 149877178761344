import React from 'react';
import { connect } from 'react-redux';
import i18n from '../i18n/i18n';
import {
    Dialog, DialogContent, DialogTitle, DialogActions,
    Button, TextField, IconButton
} from '@material-ui/core';
import {
    createTicket, setInitialState
} from '../actions/LoginActions';
import { toast } from 'react-toastify';
import { withStyles } from '@material-ui/core/styles';
const styles = theme => ({
    primaryMain: {
        backgroundColor: theme.palette.primary.main,
    },
});
export class Support extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tiket: {},
        };
    }
    toastConf = {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        draggablePercent: 80
    }


    componentDidUpdate(prevProps) {
        const { ticketSentSuccess, ticketSentFailed } = this.props;
        if (ticketSentSuccess === true && prevProps.ticketSentSuccess === false) {
            toast.success(i18n.t('Ticket successfully sent! You will be contacted as soon as possible.'), this.toastConf);
            this.props.setInitialState('ticketSentSuccess');
            this.props.handleClose();
        }
        if (ticketSentFailed === true && prevProps.ticketSentFailed === false) {
            toast.error(i18n.t('Error occured while sending ticket! Please try again'), this.toastConf);
            this.props.setInitialState('ticketSentFailed');
        }
    }

    createTicket = () => {
        if (this.state.tiket &&
            this.state.tiket.naziv &&
            this.state.tiket.tiket_pitanje) {
            this.props.createTicket(this.state.tiket);
        } else {
            toast.error(i18n.t('Please enter all fields!'), this.toastConf);
        }
    }

    handleChangeSupportValue = (name) => event => {
        let obj = Object.assign({}, this.state.tiket);
        obj[name] = event.target.value;
        this.setState({ tiket: obj });
    };

    render() {
        const { theme } = this.props;
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                disableBackdropClick
                maxWidth="md"
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle disableTypography={true} style={{ background: theme.palette.secondary.dark }} className="responsive-dialog-title">{i18n.t('Support')}
                    <IconButton onClick={this.props.handleClose}>
                        <span className="icon-cancel-circle closeIcon" />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        required
                        onChange={this.handleChangeSupportValue('naziv')}
                        label={i18n.t('Title')}
                        style={{ width: '300px', marginTop: '10px', marginBottom: '10px' }}
                    />
                    <br />
                    <TextField
                        required
                        multiline
                        fullWidth
                        onChange={this.handleChangeSupportValue('tiket_pitanje')}
                        label={i18n.t('Message')}
                        style={{ width: '300px', marginTop: '10px', marginBottom: '10px' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={this.createTicket}
                        style={{ marginBottom: '10px', marginRight: '20px' }}
                        variant="contained"
                        color="primary"
                    >
                        {i18n.t('Send message')}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
};

function mapStateToProps(state) {
    return {
        ticketSentSuccess: state.appReducer.ticketSentSuccess,
        ticketSentFailed: state.appReducer.ticketSentFailed
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createTicket: (tiket) => dispatch(createTicket(tiket)),
        setInitialState: (component) => dispatch(setInitialState(component))
    }
}
export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(Support));