import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import i18n from '../../../i18n/i18n';
import axios from '../../../utils/AxiosWrapper';
import styles from "./AgrodronReport.module.css";
import { wentWrong } from '../../../utils/ToastConfig';
import SearchBar from 'material-ui-search-bar';
import * as Icons from '@material-ui/icons';
import { Button, Tooltip, IconButton, Menu } from '@material-ui/core';
import ReactPaginate from "react-paginate";


export default class AgrodronReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allReports: [],
            filteredReports: [],

            searchBarValue: "",

            currentPage: 0, // index based
            pageCount: 1,
            itemsPerPage: 7,
        }
    }

    componentDidMount() {
        this.fetchAllDroneReports();

        const storedNumber = sessionStorage.getItem('pageNumberDroneReport');
        if (storedNumber) {
            this.setState({ currentPage: Number(storedNumber) }, () => this.setupPageCount());
        } else {
            this.setState({ currentPage: 0 }, () => this.setupPageCount());
        }


    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.allReports !== this.state.allReports) {

            this.populateDropdowns();

        }

        if (prevState.filteredReports !== this.state.filteredReports) {
            this.setupPageCount();
        }

        if (prevState.searchBarValue !== this.state.searchBarValue) {
            this.updateFilteredReports();
        }
    }

    fetchAllDroneReports = () => {
        axios.get(`api/agrodron/read_all_by_client`)
            .then((res) => {
                this.setState({ allReports: res.data.data }, () => console.log("this.state.allReports : ", this.state.allReports));
            })
            .catch(() => wentWrong(i18n.t('Something went wrong')))
    }

    populateDropdowns = () => {

        this.setState({

            filteredReports: this.state.allReports,
        }, () => {
            // this.updateFiltersFromSessionStorage();
        })
    }

    updateFilteredReports = () => {
        this.setState({
            filteredReports: this.state.allReports
                .filter(item => this.state.searchBarValue === "" ? item : item.naziv.toLowerCase().includes(this.state.searchBarValue.toLowerCase()))
        });
    }

    setupPageCount = () => {
        const pageCount = Math.ceil(this.state.filteredReports.length / this.state.itemsPerPage);
        this.setState({ pageCount: pageCount }, () => {
            if (this.state.currentPage >= this.state.pageCount && this.state.pageCount > 0) {
                this.setState({ currentPage: this.state.pageCount - 1 });
            }
        });
    }

    handleSearchBar = (searchVal) => {

        this.setState({
            searchBarValue: searchVal,
        });
    }

    handlePageChange = (pageNum) => {
        this.setState({
            currentPage: pageNum.selected,
        })

        sessionStorage.setItem('pageNumberDroneReport', pageNum.selected);
    }

    render() {


        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>


                <div className={styles.searchWrap}>
                    <SearchBar
                        className={styles.searchBar}
                        placeholder={i18n.t('Search by name')}
                        cancelOnEscape={true}
                        value={this.state.searchBarValue}
                        // onChange={(searchValue) => !searchValue && this.handleSearchBar('')}
                        // onRequestSearch={(searchValue) => this.handleSearchBar(searchValue)}
                        // onCancelSearch={() => this.handleSearchBar('')}
                        onChange={(searchValue) => this.handleSearchBar(searchValue)}
                        onCancelSearch={() => this.handleSearchBar('')}
                    />


                </div>

                <div className={styles.filtersSection}></div>


                <div style={{ border: '1px solid #cecece', backgroundColor: '#fff', margin: '0 30px 30px 30px', borderRadius: '3px', boxSizing: 'border-box', overflow: 'hidden', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)', minHeight: 'calc(100vh - 245px)', display: 'flex', flexDirection: 'column' }}>
                    <div className={styles.tableHeader}>

                        <div className={styles.tableHeaderTitle} >{i18n.t("Drone recording")}</div>

                    </div>

                    <div style={{ display: 'flex', alignItems: "center", justifyContent: 'space-between', margin: '0 16px', padding: '15px 0 8px 0', borderBottom: '1px solid #9b9658', fontSize: '14px', color: '#7d7a7a' }}>
                        <div style={{ width: '250px', marginRight: "20px" }}>{i18n.t("Title")}</div>
                        <div style={{ width: '80px', marginRight: "20px" }}>{i18n.t("Date")}</div>
                        <div style={{ width: '250px', marginRight: "20px" }}>{i18n.t("Purpose of recording")}</div>
                        <div style={{ width: '250px', marginRight: "20px" }}>{i18n.t("Table name")}</div>

                        <div style={{ width: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ width: '60px', marginRight: "20px" }}>{i18n.t("Preview")}</div>

                        </div>
                    </div>


                    {this.state.filteredReports.length > 0 && this.state.filteredReports.slice(this.state.currentPage * this.state.itemsPerPage, (this.state.currentPage + 1) * this.state.itemsPerPage).map((item, i) => {
                        return <div key={i} style={{ display: 'flex', alignItems: "center", justifyContent: 'space-between', margin: '0 16px', borderBottom: '1px solid #d2d2d2', minHeight: '60px' }}>
                            <div style={{ width: '250px', marginRight: "20px", fontSize: '14px', color: '#333333' }}>{item.naziv}</div>
                            <div style={{ width: '80px', marginRight: "20px", fontSize: '14px', color: '#333333' }}>{item.datum_snimanja}</div>
                            <div style={{ width: '250px', marginRight: "20px", fontSize: '14px', color: '#333333' }}>{item.svrha_naziv}</div>
                            <div style={{ width: '250px', marginRight: "20px", fontSize: '14px', color: '#333333' }}>{item.tabla_naziv}</div>

                            <div style={{ width: '60px' }}>
                                {item.url !== null && <Tooltip title={i18n.t("Preview")}>
                                    <IconButton aria-label="copy"
                                        // color="secondary"
                                        style={{ transform: 'translateX(-7px)' }}
                                        onClick={() => { window.open(item.url, "_blank") }}>
                                        <Icons.Visibility fontSize="large" />
                                    </IconButton>
                                </Tooltip>}
                            </div>
                        </div>

                    })}


                    <ReactPaginate
                        pageCount={this.state.pageCount}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        onPageChange={this.handlePageChange}
                        containerClassName={styles.paginationContainer}
                        activeClassName={styles.active}
                        previousLabel={"<"}
                        nextLabel={">"}
                        forcePage={this.state.currentPage}
                    />
                </div>

            </div >
        )
    }
}
