export const styles = t => ({
    htmlTooltip: {
        maxWidth: '600px',
        border: '1px solid grey'
    },
    list_item: {
        padding: 0,
        paddingLeft: 24,
        paddingRight: 4
    },
    nested: {
        paddingLeft: 4
    },
    drawer: {
        width: 270,
        flexShrink: 0,
        whiteSpace: "nowrap"
    },
    drawerOpen: {
        width: 270,
        transition: t.transitions.create("width", {
            easing: t.transitions.easing.sharp,
            duration: t.transitions.duration.enteringScreen
        }),
        overflowX: "hidden"
    },
    drawerClose: {
        transition: t.transitions.create("width", {
            easing: t.transitions.easing.sharp,
            duration: t.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
        width: 0
    },
    listItemText: {
        margin: '0px', padding: '0px', lineHeight: 'normal'
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        minHeight: "65px",
    },
    toggleButtonOpen: {
        zIndex: 1e3,
        position: "absolute",
        top: "50%",
        right: 270,
        width: 24,
        height: 60,
        backgroundColor: "rgba(228, 228, 228, 1)",
        transition: t.transitions.create("right", {
            easing: t.transitions.easing.sharp,
            duration: t.transitions.duration.enteringScreen
        }),
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    },
    toggleButtonClose: {
        zIndex: 1e3,
        position: "absolute",
        top: "50%",
        width: 24,
        height: 60,
        right: 1,
        backgroundColor: "rgba(228, 228, 228, 1)",
        transition: t.transitions.create("right", {
            easing: t.transitions.easing.sharp,
            duration: t.transitions.duration.leavingScreen
        }),
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    searchButtonOpen: {
        zIndex: 1e3,
        position: "absolute",
        top: 11,
        right: 280,
        width: 50,
        height: 50,
        backgroundColor: "rgba(228, 228, 228, 1)",
        transition: t.transitions.create("right", {
            easing: t.transitions.easing.sharp,
            duration: t.transitions.duration.enteringScreen
        }),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#fff",
        borderRadius: 5,
        border: "1px solid rgba(0,0,0,1)"
    },
    searchButtonClose: {
        zIndex: 1e3,
        position: "absolute",
        top: 11,
        width: 50,
        height: 50,
        right: 10,
        backgroundColor: "rgba(228, 228, 228, 1)",
        transition: t.transitions.create("right", {
            easing: t.transitions.easing.sharp,
            duration: t.transitions.duration.leavingScreen
        }),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#fff",
        borderRadius: 5,
        border: "1px solid rgba(0,0,0,1)"
    },
    seasonSelectOpen: {
        zIndex: 1e3,
        position: "absolute",
        top: 11,
        right: 340,
        backgroundColor: "rgba(228, 228, 228, 1)",
        transition: t.transitions.create("right", {
            easing: t.transitions.easing.sharp,
            duration: t.transitions.duration.enteringScreen
        }),
        width: 256,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        background: "#fff",
        borderRadius: 5,
        border: "1px solid rgba(0,0,0,1)",
    },
    seasonSelectClose: {
        zIndex: 1e3,
        position: "absolute",
        top: 11,
        right: 80,
        backgroundColor: "rgba(228, 228, 228, 1)",
        transition: t.transitions.create("right", {
            easing: t.transitions.easing.sharp,
            duration: t.transitions.duration.leavingScreen
        }),
        width: 256,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        background: "#fff",
        borderRadius: 5,
        border: "1px solid rgba(0,0,0,1)",
    },
    seasonalForecastButtonOpen: {
        zIndex: 1e3,
        position: "absolute",
        top: 11,
        // right: 696,
        right: 342,
        width: 50,
        height: 50,
        backgroundColor: "rgba(228, 228, 228, 1)",
        transition: t.transitions.create("right", {
            easing: t.transitions.easing.sharp,
            duration: t.transitions.duration.enteringScreen
        }),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#fff",
        borderRadius: 5,
        border: "1px solid rgba(0,0,0,1)"
    },
    seasonalForecastButtonClose: {
        zIndex: 1e3,
        position: "absolute",
        top: 11,
        width: 50,
        height: 50,
        // right: 336,
        right: 82,
        backgroundColor: "rgba(228, 228, 228, 1)",
        transition: t.transitions.create("right", {
            easing: t.transitions.easing.sharp,
            duration: t.transitions.duration.leavingScreen
        }),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#fff",
        borderRadius: 5,
        border: "1px solid rgba(0,0,0,1)"
    },
    menuGroupStyle: {
        borderBottom: '1px lightgrey solid'
        // borderLeft: '3px lightgrey solid',
    },
    mapOpen: {
        height: '100vh', width: '100%', width: 'calc(100%)', marginLeft: '0px',
    },
    mapClosed: {
        height: '100vh', width: '100%', width: 'calc(100% - 270px)', marginLeft: '0px',
        transition: t.transitions.create("width", {
            easing: t.transitions.easing.sharp,
            duration: t.transitions.duration.enteringScreen
        }),
    },
    open_button: { backgroundColor: 'rgb(155, 151, 88, 1)', color: 'white', marginLeft: '10px' },
    sensor_span: {
        alignSelf: 'center',
        width: '25px',
        height: '25px',
        marginLeft: '4%',
        // backgroundColor: 'lightgrey',
        cursor: 'pointer',
        textAlign: 'center',
        lineHeight: '25px',
        borderRadius: '5px'
    },
    topBarItem: {
        fontFamily: 'Roboto',
        fontSize: '16px',
        height: '50px',
        '&:hover': {
            backgroundColor: '#d6d6d4',
        },
        '& > svg': {
            marginRight: '10px',
        },
        display: 'flex',
        alignItems: 'center',
        padding: '0 20px',
        position: 'relative',
        '&:after': {
            content: '""',
            width: '1px',
            height: '40px',
            position: 'absolute',
            right: '0px',
            backgroundColor: '#d6d6d4',
        },
        borderRadius: '8px',
        cursor: 'pointer',
        userSelect: 'none',

    },
    polygonDropdown: {
        position: 'absolute',
        top: '80px',
        left: '0px',
        width: '100%',
        backgroundColor: '#ededed',
        zIndex: '100000',
        borderRadius: '8px',
    },
    polygonDropdownBtn: {
        fontSize: '12px',
        textAlign: 'center',
        borderRadius: '8px',
        padding: '10px 5px',
        '&:hover': {
            backgroundColor: '#d6d6d4',
            border: '1px solid #d6d6d4',
        },
    },
    seasonDropDown: {
        color: '#333333',
        fontFamily: 'Roboto',
        fontSize: '15px',
        border: 'none',
        borderBottom: '1px solid #333333',
        marginRight: '20px',
        cursor: 'pointer',
        '&:focus': {
            outline: 'none',
        },
    },

});