import React from 'react';
import { connect } from 'react-redux';
import { MoonLoader } from 'react-spinners';
import LoginPage from '../components/page/LoginPage';
import ForgotPassword from '../components/page/ForgotPassword';
import ResetPassword from '../components/page/ResetPassword';
import { Route, Redirect } from 'react-router-dom';
import { toggleDrawer, setInitialState } from '../actions/LoginActions';
import styled from 'react-emotion';
import Header from '../utils/Header/Index';
import TopBar from '../utils/Header/TopBar';
import MenuDrawer from '../components/MenuDrawer';
import { ThemeProvider } from 'emotion-theming';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import theme from '../utils/theme';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { withRouter } from 'react-router-dom'
import GlobalDashboard from '../components/GlobalDashboard';
import MaterialMainComponent from '../components/resources/materials/MaterialMainComponent';
import MachinesMainComponent from '../components/resources/machines/MachinesMainComponent';
import WorkingOperationMainComponent from '../components/resources/workingOperations/WorkingOperationMainComponent';
import ViewWO from '../components/resources/workingOperations/ViewWO'
import CropMainComponent from '../components/resources/crop/CropMainComponent';
import ImplementMainComponent from '../components/resources/implements/ImplementMainComponent';
import ProtectedRoute from './ProtectedRoute';
import ActivityContainer from '../components/activities/ActivityContainer';
import ViewParcel from '../components/catastral_data/parcel/ViewParcel';
import ViewField from '../components/catastral_data/field/ViewField';
import ArchiveList from '../components/catastral_data/archive/ArchiveList';
import MapContainer from '../components/Map/MapContainer';
import Note from '../components/note/Note';
import NoteDashboard from '../components/reports/Dashboards/NoteDashboard';
import Statistics from '../components/reports/Statistics';
import StatisticsDashboard from '../components/reports/Dashboards/StatisticsDashboard';
import StatisticsProfit from '../components/reports/Dashboards/StatisticsProfit';
import Enterprise from '../components/reports/Enterprise';
import EnterpriseDashboard from '../components/reports/Dashboards/EnterpriseDashboard';
import ViewSectors from '../components/sectors/ViewSectors';
import ViewUsers from '../components/users/ViewUsers';
import ViewStorage from '../components/traffic/ViewStorage';
import ViewLoad from '../components/traffic/ViewLoad';
import MarketPrices from '../components/market_price/MarketPrices';
//import ViewSensor from '../components/sensor/ViewSensor';
import SensorList from '../components/sensor/SensorList';
import CropRotation from '../components/reports/CropRotation';
import CropRotationDashboard from '../components/reports/Dashboards/CropRotationDashboard';
import Yield from '../components/reports/Yield';
import YieldProfit from '../components/reports/Dashboards/YieldProfit';
import YieldDashboard from '../components/reports/Dashboards/YieldDashboard';
import Analytics from '../components/reports/Analytics';
import ViewWorkers from '../components/workers/workers/ViewWorkers';
import ViewWorkplaces from '../components/workers/workplaces/ViewWorkplaces';
import WorkersDashboard from '../components/reports/WorkersDashboard';
import MaterialCost from '../components/reports/Dashboards/MaterialCost';
import MaterialUsed from '../components/reports/Dashboards/MaterialUsed';
import RevenueCost from '../components/reports/Dashboards/RevenueCost';
import RevenueUsed from '../components/reports/Dashboards/RevenueUsed';
import etfarm from '../components/etfarm/etfarm';
import etfarm2 from '../components/etfarm/etfarm2';
import SensorMap from './../components/sensor/SensorMap';
import Maps from './../components/Maps/Maps';
import MaterialStatistics from './../components/reports/MaterialStatistics';
import YieldStatistics from './../components/reports/YieldStatistics';
import ViewWorkerActivities from '../components/workers/worker_activities/ViewWorkerActivities';
import ViewWorkerUnits from '../components/workers/worker_unit/ViewWorkerUnits';
import WorkerActivityList from '../components/workers/activity_list/WorkerActivityList';
import ViewParts from '../components/catastral_data/parts_of_the_field/ViewParts';
import ViewVarieties from '../components/resources/varieties/ViewVarieties';
import WorkersReport from '../components/reports/Dashboards/WorkersReport';
import NewActivity from './../components/activities/activities_new/NewActivity';
import Preview from './../components/activities/activities_new/Preview';
import AgrodronReport from '../components/reports/AgrodronReport/AgrodronReport';
import SensorList2 from '../components/sensor/SensorList2'
import ExtraExpenses from '../components/resources/ExtraExpenses/ExtraExpenses';
import AdministrationOfExtraExpenses from '../components/resources/ExtraExpenses/AdministrationOfExtraExpenses'


// import MapDashboard from '../components/Map/MapDashboard';
const Container = styled('main')`
  width: 100vw;
`;

const Content = styled('section')`
  padding-top: ${p => p.authenticated ? '65px' : '0px'};
  padding-left: ${p => p.authenticated ? p.theme.size(5) : 0};
  transform-origin: left 2px;
  transition: width  0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: 
    ${p =>
        p.isDrawerOpen && p.authenticated
            ? `translateX(${p.theme.size(15)})`
            : 'none'};
  width:${p =>
        p.isDrawerOpen && p.authenticated
            ? `calc(100% - ${p.theme.size1(19.7)})`
            : `calc(100% - ${p.theme.size1(5)})`};
  display:flex;
  justify-content:${p => p.authenticated ? 'center' : 'start'};
`;

const muiTheme = createMuiTheme({
    palette: theme.palette,
    overrides: {
        MuiCollapse: {
            entered: {
                height: "auto",
                overflow: "visible"
            }
        },
        MuiExpansionPanel: {
            expanded: {
                margin: 0
            }
        }
    }
});

export class Agrolife extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            isDrawerOpen: this.props.isDrawerOpen,
            userProfile: this.props.userProfile,
            authenticated: false
        };
    }

    componentDidMount() {
        let token = localStorage.getItem('token');
        if (token) {
            this.setState({
                authenticated: true
            })
        } else {
            this.setState({
                authenticated: false
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.token && nextProps.user && nextProps.loginSuccess === true) {
            this.setState({
                authenticated: true
            })
            toast.success('Welcome' + ' ' + nextProps.user.username + '', {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
            });
            this.props.setInitialState('loginSuccess');
        }
        this.setState({
            token: nextProps.token,
            isDrawerOpen: nextProps.isDrawerOpen,
            userProfile: nextProps.userProfile
        })
    }

    tokenCheck() {
        let token = localStorage.getItem('token');
        return token;
    }

    render() {
        let content;
        const { authenticated } = this.state;
        content =
            <MuiThemeProvider theme={muiTheme}>
                <ThemeProvider theme={theme}>
                    <div>
                        <Container>
                            <Content authenticated={authenticated} isDrawerOpen={this.state.isDrawerOpen}>
                                {/* <ProtectedRoute exact path="/map" authorized={authenticated} component={MapContainer} />
                                <ProtectedRoute exact path="/map_dashboard" authorized={authenticated} component={MapDashboard} /> 
                                */}
                                <ProtectedRoute exact path="/material" authorized={authenticated} component={MaterialMainComponent} />
                                <ProtectedRoute exact path="/machines" authorized={authenticated} component={MachinesMainComponent} />
                                <ProtectedRoute exact path="/crop" authorized={authenticated} component={CropMainComponent} />
                                <ProtectedRoute exact path="/implement" authorized={authenticated} component={ImplementMainComponent} />
                                {/* <ProtectedRoute exact path="/working_operation" authorized={authenticated} component={WorkingOperationMainComponent} /> */}
                                <ProtectedRoute exact path="/working_operation" authorized={authenticated} component={ViewWO} />
                                <ProtectedRoute exact path="/notes" authorized={authenticated} component={Note} />
                                <ProtectedRoute exact path="/notes_dashboard" authorized={authenticated} component={NoteDashboard} />
                                {/* <ProtectedRoute exact path="/material_table" authorized={authenticated} component={Statistics} /> */}
                                <ProtectedRoute exact path="/material_table" authorized={authenticated} component={MaterialStatistics} />
                                {/* <ProtectedRoute exact path="/statistics_dashboard" authorized={authenticated} component={StatisticsDashboard} /> */}
                                {/* <ProtectedRoute exact path="/dash_profit" authorized={authenticated} component={StatisticsProfit} /> */}
                                <ProtectedRoute exact path="/crop_rotation" authorized={authenticated} component={CropRotation} />
                                <ProtectedRoute exact path="/crop_rotation_cost" authorized={authenticated} component={CropRotationDashboard} />
                                <ProtectedRoute exact path="/enterprise" authorized={authenticated} component={Enterprise} />
                                <ProtectedRoute exact path="/enterprise_cost" authorized={authenticated} component={EnterpriseDashboard} />
                                <ProtectedRoute exact path="/map" authorized={authenticated} component={MapContainer} />
                                <ProtectedRoute exact path="/field" authorized={authenticated} component={ViewField} />
                                <ProtectedRoute exact path="/partsofthefield" authorized={authenticated} component={ViewParts} />
                                <ProtectedRoute exact path="/parcel" authorized={authenticated} component={ViewParcel} />
                                <ProtectedRoute exact path="/archivelist" authorized={authenticated} component={ArchiveList} />
                                <ProtectedRoute exact path="/activities" authorized={authenticated} component={ActivityContainer} />
                                <ProtectedRoute exact path="/dashboard" authorized={authenticated} component={GlobalDashboard} />
                                <ProtectedRoute exact path="/sectors" authorized={authenticated} component={ViewSectors} />
                                <ProtectedRoute exact path="/users" authorized={authenticated} component={ViewUsers} />
                                <ProtectedRoute exact path="/storage" authorized={authenticated} component={ViewStorage} />
                                <ProtectedRoute exact path="/loads" authorized={authenticated} component={ViewLoad} />
                                <ProtectedRoute exact path="/market_prices" authorized={authenticated} component={MarketPrices} />
                                {/* <ProtectedRoute exact path="/sensor" authorized={authenticated} component={ViewSensor} /> */}
                                <ProtectedRoute exact path="/sensors" authorized={authenticated} component={SensorList} />
                                <ProtectedRoute exact path="/sensors_new" authorized={authenticated} component={SensorList2} />
                                <ProtectedRoute exact path="/revenue_table" authorized={authenticated} component={YieldStatistics} />
                                <ProtectedRoute exact path="/work_activities" authorized={authenticated} component={ViewWorkerActivities} />
                                <ProtectedRoute exact path="/work_units" authorized={authenticated} component={ViewWorkerUnits} />
                                <ProtectedRoute exact path="/work_activity_list" authorized={authenticated} component={WorkerActivityList} />
                                <ProtectedRoute exact path="/extra_expenses" authorized={authenticated} component={ExtraExpenses} />
                                <ProtectedRoute exact path="/administration_of_extra_expenses" authorized={authenticated} component={AdministrationOfExtraExpenses} />

                                {/* <ProtectedRoute exact path="/yield_profit" authorized={authenticated} component={YieldProfit} /> */}
                                {/* <ProtectedRoute exact path="/yield_dashboard" authorized={authenticated} component={YieldDashboard} /> */}
                                <ProtectedRoute exact path="/analytics" authorized={authenticated} component={Analytics} />
                                <ProtectedRoute exact path="/workers" authorized={authenticated} component={ViewWorkers} />
                                <ProtectedRoute exact path="/workplaces" authorized={authenticated} component={ViewWorkplaces} />
                                <ProtectedRoute exact path="/workers_dashboard" authorized={authenticated} component={WorkersDashboard} />
                                <ProtectedRoute exact path="/material_cost" authorized={authenticated} component={MaterialCost} />
                                <ProtectedRoute exact path="/material_used" authorized={authenticated} component={MaterialUsed} />
                                <ProtectedRoute exact path="/revenue_cost" authorized={authenticated} component={RevenueCost} />
                                <ProtectedRoute exact path="/revenue_used" authorized={authenticated} component={RevenueUsed} />
                                <ProtectedRoute exact path="/agrodron_report" authorized={authenticated} component={AgrodronReport} />
                                <ProtectedRoute exact path="/etfarm" authorized={authenticated} component={etfarm} />
                                <ProtectedRoute exact path="/etfarm2" authorized={authenticated} component={etfarm2} />
                                <ProtectedRoute exact path="/sensor_map" authorized={authenticated} component={SensorMap} />
                                <ProtectedRoute exact path="/maps" authorized={authenticated} component={Maps} />
                                <ProtectedRoute exact path="/varieties" authorized={authenticated} component={ViewVarieties} />
                                <ProtectedRoute exact path="/workers_report" authorized={authenticated} component={WorkersReport} />
                                <ProtectedRoute exact path="/new_activity" authorized={authenticated} component={NewActivity} />
                                <ProtectedRoute exact path="/preview" authorized={authenticated} component={Preview} />

                                <Route exact path="/" component={LoginPage} />
                                <Route exact path="/forgotpass" component={ForgotPassword} />
                                <Route exact path="/resetpass" component={ResetPassword} />
                                {
                                    authenticated && window.location.pathname === '/' ?
                                        <Redirect from='/' to="activities" />
                                        : null
                                }
                            </Content>
                        </Container>
                        {authenticated &&
                            <div>
                                <TopBar />
                                <MenuDrawer />
                                <Header />
                            </div>
                        }
                    </div>
                </ThemeProvider>
            </MuiThemeProvider>
        return (
            <div className="container">
                {content}
                <ToastContainer draggable={false} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        token: state.appReducer.token,
        user: state.appReducer.user,
        isDrawerOpen: state.appReducer.isDrawerOpen,
        loginSuccess: state.appReducer.loginSuccess,
        userProfile: state.appReducer.userProfile,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        toggleDrawer: open => dispatch(toggleDrawer(open)),
        setInitialState: (component) => dispatch(setInitialState(component)),
    };
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Agrolife))