import axios from '../utils/AxiosWrapper';
import i18n from '../i18n/i18n';
// import { config } from '../config/config';
// const serverUrl = config.serverUrl;
// var OneSignal = window.OneSignal || [];
export const Type = {
    //INITIAL_STATE
    SET_INITIAL_STATE: 'SET_INITIAL_STATE',

    //LOGIN
    LOGIN_CALL: 'LOGIN_CALL',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILED: 'LOGIN_FAILED',
    LOGOUT: 'LOGOUT',

    //FORGOT PASSWORD
    FORGOT_PASSWORD_CALL: 'FORGOT_PASSWORD_CALL',
    FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILED: 'FORGOT_PASSWORD_FAILED',

    //Reset password
    CREATE_NEW_PASSWORD_CALL: 'CREATE_NEW_PASSWORD_CALL',
    CREATE_NEW_PASSWORD_SUCCESS: 'CREATE_NEW_PASSWORD_SUCCESS',
    CREATE_NEW_PASSWORD_FAILED: 'CREATE_NEW_PASSWORD_FAILED',

    //ALERT
    // SET_STATE_FOR_ALERT: 'SET_STATE_FOR_ALERT',
    // RESET_ALERT: 'RESET_ALERT',

    CLIENT_PROFILE_CALL: 'CLIENT_PROFILE_CALL',
    CLIENT_PROFILE_SUCCESS: 'CLIENT_PROFILE_SUCCESS',
    CLIENT_PROFILE_FAILED: 'CLIENT_PROFILE_FAILED',

    USER_PROFILE_CALL: 'USER_PROFILE_CALL',
    USER_PROFILE_SUCCESS: 'USER_PROFILE_SUCCESS',
    USER_PROFILE_FAILED: 'USER_PROFILE_FAILED',

    CHANGE_PASSWORD_CALL: 'CHANGE_PASSWORD_CALL',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILED: 'CHANGE_PASSWORD_FAILED',

    EDIT_PROFILE_CALL: 'EDIT_PROFILE_CALL',
    EDIT_PROFILE_SUCCESS: 'EDIT_PROFILE_SUCCESS',
    EDIT_PROFILE_FAILED: 'EDIT_PROFILE_FAILED',

    EDIT_PROFILE_PICTURE_CALL: 'EDIT_PROFILE_PICTURE_CALL',
    EDIT_PROFILE_PICTURE_SUCCESS: 'EDIT_PROFILE_PICTURE_SUCCESS',
    EDIT_PROFILE_PICTURE_FAILED: 'EDIT_PROFILE_PICTURE_FAILED',

    EDIT_CLIENT_PROFILE_CALL: 'EDIT_CLIENT_PROFILE_CALL',
    EDIT_CLIENT_PROFILE_SUCCESS: 'EDIT_CLIENT_PROFILE_SUCCESS',
    EDIT_CLIENT_PROFILE_FAILED: 'EDIT_CLIENT_PROFILE_FAILED',

    EDIT_CLIENT_PROFILE_PICTURE_CALL: 'EDIT_CLIENT_PROFILE_PICTURE_CALL',
    EDIT_CLIENT_PROFILE_PICTURE_SUCCESS: 'EDIT_CLIENT_PROFILE_PICTURE_SUCCESS',
    EDIT_CLIENT_PROFILE_PICTURE_FAILED: 'EDIT_CLIENT_PROFILE_PICTURE_FAILED',

    GET_LANGUAGE_CALL: 'GET_LANGUAGE_CALL',
    GET_LANGUAGE_SUCCESS: 'GET_LANGUAGE_SUCCESS',
    GET_LANGUAGE_FAILED: 'GET_LANGUAGE_FAILED',

    //MENU
    SHOW_MENU_DRAWER: 'SHOW_MENU_DRAWER',
    SHOW_MENU: 'SHOW_MENU',

    TICKET_REST_CALL: 'TICKET_REST_CALL',
    TICKET_REST_SUCCESS: 'TICKET_REST_SUCCESS',
    TICKET_REST_FAILED: 'TICKET_REST_FAILED',
};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function login(username, password) {
    return (dispatch) => {

        dispatch({
            type: Type.LOGIN_CALL
        });
        return axios.post('login', {
            username: username,
            password: password
        })
            .then(function (response) {
                dispatch({
                    type: Type.LOGIN_SUCCESS,
                    data: response.data
                });
                // OneSignal.push(function () {
                //     OneSignal.isPushNotificationsEnabled().then(function (isEnabled) {
                //         if (isEnabled)
                //             OneSignal.push(function () {
                //                 OneSignal.getUserId(function (userId) {
                //                     axios.post("/api/subscription/create", { player_id: userId })
                //                         .then(() => {
                //                             console.log('SUBSCRIBEDc')
                //                         })
                //                 });
                //             });
                //         else
                //             console.log("Push notifications are not enabled yet.");
                //     });
                // });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.LOGIN_FAILED,
                    message: error.response.data.message
                });
            });
    }
}

export function resetPassword(username) {
    return (dispatch) => {

        dispatch({
            type: Type.FORGOT_PASSWORD_CALL
        });
        return axios.post('login/resetPassword', {
            username: username
        })
            .then(function (response) {
                dispatch({
                    type: Type.FORGOT_PASSWORD_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.FORGOT_PASSWORD_FAILED,
                });
            });
    }
}

export function createNewPassword(password, token) {
    return (dispatch) => {
        dispatch({
            type: Type.CREATE_NEW_PASSWORD_CALL
        });
        return axios.post('login/reset',
            {
                password: password,
                token
            })
            .then(function (response) {
                dispatch({
                    type: Type.CREATE_NEW_PASSWORD_SUCCESS
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.CREATE_NEW_PASSWORD_FAILED
                });
            });
    }
}

export function logout() {
    return (dispatch) => {
        // console.log('11111111');
        // OneSignal.push(function () {
        // console.log('22222222222');
        // OneSignal.isPushNotificationsEnabled().then(function (isEnabled) {
        //     if (isEnabled) {
        //         // console.log('ifffffffffffffffff');
        //         OneSignal.push(function () {
        //             OneSignal.getUserId(function (userId) {
        //                 axios.post("/api/subscription/delete", { player_id: userId })
        //                     .then(() => {
        //                         console.log('SUBSCRIBEfD');
        //                         dispatch({
        //                             type: Type.LOGOUT
        //                         });
        //                     })
        //             });
        //         });
        //     }
        //     else {
        // console.log('elseeeeeeeee');
        dispatch({
            type: Type.LOGOUT
        });
        // }
        // });
        // });
        // console.log('kkkkkkkkkkkkk', Notification, Notification.permission);
        // if (Notification.permission === 'granted') {
        //     OneSignal.push(function () {
        //         OneSignal.getUserId(function (userId) {
        //             axios.post("/api/subscription/delete", { player_id: userId })
        //                 .then(() => {
        //                     console.log('SUBSCRIBED');
        //                     dispatch({
        //                         type: Type.LOGOUT
        //                     });
        //                 })
        //         });
        //     });
        // }

    }
}

// export function resetAlert() {
//     return (dispatch) => {
//         dispatch({
//             type: Type.RESET_ALERT,
//         })
//     }
// }

// export function setStateForAlert(openAlert, alertText, alertTitle) {
//     return (dispatch) => {
//         dispatch({
//             type: Type.SET_STATE_FOR_ALERT,
//             data: { openAlert, alertText, alertTitle }
//         });
//     }
// }

export function toggleDrawer(arg) {
    return (dispatch) => {
        dispatch({
            type: Type.SHOW_MENU_DRAWER,
            data: arg
        })
    }
}
export function toggleMenu(arg) {
    return (dispatch) => {
        dispatch({
            type: Type.SHOW_MENU,
            data: arg
        })
    }
}

export function getClientProfile(id) {
    return (dispatch) => {
        dispatch({
            type: Type.CLIENT_PROFILE_CALL
        });
        return axios.get(`api/profile/client_profile?id=${id}`)
            .then(function (response) {
                dispatch({
                    type: Type.CLIENT_PROFILE_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.CLIENT_PROFILE_FAILED
                });
            });
    }
}

export function getUserProfile() {
    return (dispatch) => {
        dispatch({
            type: Type.USER_PROFILE_CALL
        });
        return axios.get(`api/profile/user_profile`)
            .then(function (response) {
                dispatch({
                    type: Type.USER_PROFILE_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.USER_PROFILE_FAILED
                });
            });
    }
}

export function changePassword(old_password, new_password) {
    return (dispatch) => {
        dispatch({
            type: Type.CHANGE_PASSWORD_CALL
        });
        return axios.post('api/profile/change_password',
            {
                old_password: old_password,
                new_password: new_password,
            })
            .then(function (response) {
                dispatch({
                    type: Type.CHANGE_PASSWORD_SUCCESS
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.CHANGE_PASSWORD_FAILED
                });
            });
    }
}

export function editProfile(obj) {
    return (dispatch) => {

        dispatch({
            type: Type.EDIT_PROFILE_CALL
        });
        axios.post('api/profile/edit',
            obj
        )
            .then(function (response) {
                dispatch({
                    type: Type.EDIT_PROFILE_SUCCESS
                });
                dispatch(getUserProfile());
            })
            .catch(function (error) {
                dispatch({
                    type: Type.EDIT_PROFILE_FAILED
                });
            });
    }
}

export function editProfilePicture(accepted) {
    return (dispatch) => {

        dispatch({
            type: Type.EDIT_PROFILE_PICTURE_CALL
        });
        var data = new FormData();

        accepted.map(doc => {
            data.append('document', doc);
        })

        axios({
            url: `api/profile/edit_picture`,
            method: 'POST',
            onUploadProgress: function (progressEvent) {
                console.log('wwww', Math.round((progressEvent.loaded * 100) / progressEvent.total));
            },
            data: data,
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then(function (response) {
            dispatch({
                type: Type.EDIT_PROFILE_PICTURE_SUCCESS
            });
            dispatch(getUserProfile());
        })
            .catch(function (error) {
                dispatch({
                    type: Type.EDIT_PROFILE_PICTURE_FAILED
                });
            });
    }
}

export function editClientProfile(obj) {
    return (dispatch) => {

        dispatch({
            type: Type.EDIT_CLIENT_PROFILE_CALL
        });
        axios.post('api/profile/edit_client',
            obj
        )
            .then(function (response) {
                dispatch({
                    type: Type.EDIT_CLIENT_PROFILE_SUCCESS
                });
                dispatch(getClientProfile(response.data.data.id));
            })
            .catch(function (error) {
                dispatch({
                    type: Type.EDIT_CLIENT_PROFILE_FAILED
                });
            });
    }
}

export function editClientProfilePicture(accepted) {
    return (dispatch) => {

        dispatch({
            type: Type.EDIT_CLIENT_PROFILE_PICTURE_CALL
        });
        var data = new FormData();

        accepted.map(doc => {
            data.append('document', doc);
        })

        axios({
            url: `api/profile/edit_client_picture`,
            method: 'POST',
            onUploadProgress: function (progressEvent) {
                console.log('wwww', Math.round((progressEvent.loaded * 100) / progressEvent.total));
            },
            data: data,
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then(function (response) {
            dispatch({
                type: Type.EDIT_CLIENT_PROFILE_PICTURE_SUCCESS
            });
            dispatch(getClientProfile(response.data.data.id));
        })
            .catch(function (error) {
                dispatch({
                    type: Type.EDIT_CLIENT_PROFILE_PICTURE_FAILED
                });
            });
    }
}

export function getLanguages() {
    return (dispatch) => {
        dispatch({
            type: Type.GET_LANGUAGE_CALL
        });
        return axios.get('api/profile/getLanguages')
            .then(function (response) {
                dispatch({
                    type: Type.GET_LANGUAGE_SUCCESS,
                    data: response.data.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_LANGUAGE_FAILED
                });
            });
    }

}

export function createTicket(tiket) {
    return (dispatch) => {
        dispatch({
            type: Type.TICKET_REST_CALL
        });
        return axios.post('api/tiket/create', tiket)
            .then(function (response) {
                dispatch({
                    type: Type.TICKET_REST_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.TICKET_REST_FAILED
                });
            });
    }
}