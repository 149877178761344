import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n/i18n';
import { Button, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//  import InputLabel from '@material-ui/core/InputLabel';
import { getGroup, getSubgroup, setInitialState, addImplement } from '../../../actions/resources/ImplementsActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
const styles = theme => ({
});
export class AddImplements extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            implementObj: this.props.implementObj
        }
    }

    componentDidMount() {
        this.props.getGroup();
        this.props.getSubgroup();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.implementsPostingFailed === true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('implementsPostingFailed');
        }
    }

    handleChangeValue = name => event => {
        let obj = Object.assign({}, this.state.implementObj);
        obj[name] = event.target.value;
        this.setState({ implementObj: obj });
        if ([name] == 'id_prikljucna_masina_grupa') {
            this.props.getSubgroup(event.target.value);
        }
    };

    addImplements() {
        if (this.state.implementObj.naziv
            && this.state.implementObj.id_prikljucna_masina_grupa
            && this.state.implementObj.id_prikljucna_masina_podgrupa) {
            this.props.addImplement(
                this.state.implementObj
            )

        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80

            });
        }
    }


    render() {
        const { theme } = this.props;
        let group = [];
        if (this.props.implementGroupDropdown && !this.props.groupFetching) {
            group = this.props.implementGroupDropdown.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let subgroup = [];
        if (this.props.implementSubgroupDropdown && !this.props.subgroupFetching) {
            subgroup = this.props.implementSubgroupDropdown.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        return (
            <div style={{ padding: 20 }}>

                {/* <Paper className="roundedBoxMaterial" zdepth={4}> */}
                <Dialog
                    open={this.props.add}
                    onClose={this.props.handleClose}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} style={{ background: theme.palette.secondary.dark }} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit Implement') : i18n.t('Add Implement')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <form id="add-implement">
                            <Grid container justify="space-between" alignContent="space-between" spacing={16}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label={i18n.t('Code')}
                                        value={this.state.implementObj.sifra}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={this.handleChangeValue('sifra')}
                                        type="number"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label={i18n.t('Title')}
                                        required
                                        value={this.state.implementObj.naziv}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={this.handleChangeValue('naziv')}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} >
                                    <TextField
                                        select
                                        label={i18n.t("Implements group")}
                                        value={this.state.implementObj.id_prikljucna_masina_grupa}
                                        onChange={this.handleChangeValue('id_prikljucna_masina_grupa')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        required
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                        <option value='' />
                                        {group}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <TextField
                                        select
                                        label={i18n.t("Implements subgroup")}
                                        disabled={!this.state.implementObj.id_prikljucna_masina_grupa}
                                        value={this.state.implementObj.id_prikljucna_masina_podgrupa}
                                        onChange={this.handleChangeValue('id_prikljucna_masina_podgrupa')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        required
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                        <option value='' />
                                        {subgroup}
                                    </TextField>
                                </Grid>
                                <Grid item xs>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => this.addImplements()}
                                    >
                                        {this.props.edit ? i18n.t('Save') : i18n.t('Add')}
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
                {/* </Paper> */}
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        implementGroupDropdown: state.implementReducer.implementGroupDropdown,
        implementObj: state.implementReducer.implementObj,
        groupFetching: state.implementReducer.groupFetching,
        groupFetchingFailed: state.implementReducer.groupFetchingFailed,
        implementSubgroupDropdown: state.implementReducer.implementSubgroupDropdown,
        subgroupFetching: state.implementReducer.subgroupFetching,
        subgroupFetchingFailed: state.implementReducer.subgroupFetchingFailed,
        implementsPosting: state.implementReducer.implementsPosting,
        implementsPostingSuccess: state.implementReducer.implementsPostingSuccess,
        implementsPostingFailed: state.implementReducer.implementsPostingFailed
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getGroup: () => dispatch(getGroup()),
        getSubgroup: (id) => dispatch(getSubgroup(id)),
        setInitialState: (component) => dispatch(setInitialState(component)),
        addImplement: (implementObj) => dispatch(addImplement(implementObj)),
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(AddImplements));