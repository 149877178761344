import React from 'react';
import i18n from '../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography, Card } from '@material-ui/core';
import { wentWrong } from "../../../utils/ToastConfig";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from '../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    disabledInput: {
        "& .MuiInputBase-root.Mui-disabled": {
            color: "black"
        }
    }
};

export class WorkerActivityInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            // workplace: null, workplaces: [],
            worker: null, workers: [],
            field: null, fields: [],
            worker_unit: null, worker_units: [],
            working_operation: null, working_operations: [],
            cena_jmr: null, kolicina: null,
        }
    }

    componentDidMount() {
        this.getByURL('worker/worker_list', 'workers');
        this.getByURL('field/drop_down', 'fields');
        this.getByURL('worker_unit/drop_down', 'worker_units');
        this.getByURL('working_operation/klijent_drop_down', 'working_operations');

        if (this.props.edit === true) {

            this.setState({
                // workplace: this.props.data.id_radno_mesto,
                worker: this.props.data.id_radnik,
                field: this.props.data.id_tabla,
                worker_unit: this.props.data.id_jmr,
                cena_jmr: this.props.data.cena_jmr,
                kolicina: this.props.data.kolicina,
                working_operation: this.props.data.id_radna_operacija,
            })
        }
    }

    getByURL(url, obj, value, name) {
        value = value || '';
        name = name || '';
        if (value != null) {
            axios.get(`api/${url}`, { params: { [name]: value } })
                .then(res => this.setState({ [obj]: res.data.data }))
                .catch(error => wentWrong('Something went wrong, please try again!'));
        }
    }

    addWorkerActivity() {
        if (
            // this.state.workplace
            this.state.worker
            && this.state.field
            && this.state.worker_unit
            && this.state.working_operation
            && this.state.cena_jmr
            && this.state.kolicina
        ) {
            let data = {
                id: this.props.data.id, // da prepozna za edit na backendu
                // id_radno_mesto: this.state.workplace,
                id_radnik: this.state.worker,
                id_tabla: this.state.field,
                id_jmr: this.state.worker_unit,
                id_radna_operacija: this.state.working_operation,
                cena_jmr: this.state.cena_jmr,
                kolicina: this.state.kolicina,
                cena: this.state.cena_jmr * this.state.kolicina,
                aktivan: true
            }
            axios.post('api/worker_activities/create', { data: data })
                .then(res => {
                    this.props.handleClose();
                })
                .catch(error => {
                    wentWrong('Something went wrong, please try again!');
                    console.log(`Error: ${error}`);
                })
        }
        else wentWrong('Please enter all required fields!');
    }


    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }

    render() {
        let user = this.getUser();
        let currency = '';
        if (user.id_region === 2) currency = 'RSD';
        else if (user.id_region === 20) currency = 'KM';
        else if (user.id_region === 23) currency = '€';
        else if (user.id_region === 13) currency = '₦';
        else if (user.id_region === 25) currency = '₪';
        else if (user.id_region === 26) currency = '$';

        return (
            <div style={{ padding: 20 }}>
                <Dialog open={this.props.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="md"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} style={{ background: '#04764e' }} className="responsive-dialog-title">{this.props.edit ? i18n.t('Activity info') : i18n.t('Activity info')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Card style={{ marginTop: '24px' }}>
                            <table style={{ width: '96%', margin: '2%' }}>
                                <tr>
                                    <th style={{ textAlign: 'center', fontSize: '16px', margin: '1px' }}>{i18n.t('Worker')}</th>
                                    <th style={{ textAlign: 'center', fontSize: '16px', margin: '1px' }}>{i18n.t('Working operation')}</th>
                                    <th style={{ textAlign: 'center', fontSize: '16px', margin: '1px' }}>{i18n.t('Field')}</th>
                                    <th style={{ textAlign: 'center', fontSize: '16px', margin: '1px' }}>{i18n.t('Amount')}</th>
                                    <th style={{ textAlign: 'center', fontSize: '16px', margin: '1px' }}>{i18n.t('Worker unit')}</th>
                                    <th style={{ textAlign: 'center', fontSize: '16px', margin: '1px' }}>{i18n.t('Price per unit')}</th>
                                    <th style={{ textAlign: 'center', fontSize: '16px', margin: '1px' }}>{i18n.t('Total cost')}</th>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'center', fontSize: '16px', margin: '1px', paddingTop: '8px' }}>{this.props.data.ime + ' ' + this.props.data.prezime}</td>
                                    <td style={{ textAlign: 'center', fontSize: '16px', margin: '1px', paddingTop: '8px' }}>{this.props.data.naziv_radna_operacija}</td>
                                    <td style={{ textAlign: 'center', fontSize: '16px', margin: '1px', paddingTop: '8px' }}>{this.props.data.naziv_tabla}</td>
                                    <td style={{ textAlign: 'center', fontSize: '16px', margin: '1px', paddingTop: '8px' }}>{this.state.kolicina}</td>
                                    <td style={{ textAlign: 'center', fontSize: '16px', margin: '1px', paddingTop: '8px' }}>{this.props.data.naziv_jmr}</td>
                                    <td style={{ textAlign: 'center', fontSize: '16px', margin: '1px', paddingTop: '8px' }}>{this.state.cena_jmr}</td>
                                    <td style={{ textAlign: 'center', fontSize: '16px', margin: '1px', paddingTop: '8px' }}>{this.state.cena_jmr * this.state.kolicina}</td>
                                </tr>
                            </table>
                        </Card>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
};

export default withStyles(styles)(WorkerActivityInfo);
