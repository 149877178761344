import axios from '../../utils/AxiosWrapper';
export const Type = {
    //GROUP DROPDOWN
    GET_MACHINES_GROUP_CALL: 'GET_MACHINES_GROUP_CALL',
    GET_MACHINES_GROUP_SUCCESS: 'GET_MACHINES_GROUP_SUCCESS',
    GET_MACHINES_GROUP_FAILED: 'GET_MACHINES_GROUP_FAILED',
    //SUBGROUP DROPDOWN
    GET_MACHINES_SUBGROUP_CALL: 'GET_MACHINES_SUBGROUP_CALL',
    GET_MACHINES_SUBGROUP_SUCCESS: 'GET_MACHINES_SUBGROUP_SUCCESS',
    GET_MACHINES_SUBGROUP_FAILED: 'GET_MACHINES_SUBGROUP_FAILED',
    //MACHINE DROPDOWN
    GET_MACHINES_DROPDOWN_CALL: 'GET_MACHINES_DROPDOWN_CALL',
    GET_MACHINES_DROPDOWN_SUCCESS: 'GET_MACHINES_DROPDOWN_SUCCESS',
    GET_MACHINES_DROPDOWN_FAILED: 'GET_MACHINES_DROPDOWN_FAILED',

    //VIEW
    GET_MACHINE_CALL: 'GET_MACHINE_CALL',
    GET_MACHINE_SUCCESS: 'GET_MACHINE_SUCCESS',
    GET_MACHINE_FAILED: 'GET_MACHINE_FAILED',

    GET_MACHINES_VIEW_CALL: 'GET_MACHINES_VIEW_CALL',
    GET_MACHINES_VIEW_SUCCESS: 'GET_MACHINES_VIEW_SUCCESS',
    GET_MACHINES_VIEW_FAILED: 'GET_MACHINES_VIEW_FAILED',

    GET_MACHINE_SUBGROUP_CALL: 'GET_MACHINE_SUBGROUP_CALL',
    GET_MACHINE_SUBGROUP_SUCCESS: 'GET_MACHINE_SUBGROUP_SUCCESS',
    GET_MACHINE_SUBGROUP_FAILED: 'GET_MACHINE_SUBGROUP_FAILED',

    //ADD
    ADD_MACHINE_CALL: 'ADD_MACHINE_CALL',
    ADD_MACHINE_SUCCESS: 'ADD_MACHINE_SUCCESS',
    ADD_MACHINE_FAILED: 'ADD_MACHINE_FAILED',

    ADD_MACHINE_GROUP_CALL: 'ADD_MACHINE_GROUP_CALL',
    ADD_MACHINE_GROUP_SUCCESS: 'ADD_MACHINE_GROUP_SUCCESS',
    ADD_MACHINE_GROUP_FAILED: 'ADD_MACHINE_GROUP_FAILED',

    ADD_MACHINE_SUBGROUP_CALL: 'ADD_MACHINE_SUBGROUP_CALL',
    ADD_MACHINE_SUBGROUP_SUCCESS: 'ADD_MACHINE_SUBGROUP_SUCCESS',
    ADD_MACHINE_SUBGROUP_FAILED: 'ADD_MACHINE_SUBGROUP_FAILED',

    //FAVORITE
    ADD_MACHINE_FAVORITE_CALL: 'ADD_MACHINE_FAVORITE_CALL',
    ADD_MACHINE_FAVORITE_SUCCESS: 'ADD_MACHINE_FAVORITE_SUCCESS',
    ADD_MACHINE_FAVORITE_FAILED: 'ADD_MACHINE_FAVORITE_FAILED',
    DELETE_MACHINE_FAVORITE_CALL: 'DELETE_MACHINE_FAVORITE_CALL',
    DELETE_MACHINE_FAVORITE_SUCCESS: 'DELETE_MACHINE_FAVORITE_SUCCESS',
    DELETE_MACHINE_FAVORITE_FAILED: 'DELETE_MACHINE_FAVORITE_FAILED',

    SET_MACHINE_ROW_IN_REDUCER: 'SET_MACHINE_ROW_IN_REDUCER',

    DELETE_MACHINE_CALL: 'DELETE_MACHINE_CALL',
    DELETE_MACHINE_SUCCESS: 'DELETE_MACHINE_SUCCESS',
    DELETE_MACHINE_FAILED: 'DELETE_MACHINE_FAILED',
    SET_INITIAL_STATE: 'SET_INITIAL_STATE',

};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function setRowInReducer(rowInfo, name) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_MACHINE_ROW_IN_REDUCER,
            rowInfo: rowInfo,
            name: name
        })
    }
}

export function getMachinesDropdown() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_MACHINES_DROPDOWN_CALL
        });

        axios.get('api/machine/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_MACHINES_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_MACHINES_DROPDOWN_FAILED
                });
            });
    }
}

export function getGroup() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_MACHINES_GROUP_CALL
        });

        axios.get('api/machine_group/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_MACHINES_GROUP_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_MACHINES_GROUP_FAILED
                });
            });
    }
}

export function getMachinesGroup(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_MACHINES_VIEW_CALL
        });
        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/machine_group/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_MACHINES_VIEW_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_MACHINES_VIEW_FAILED
                });
            });
    }
}

export function addMachineGroup(machineGroupObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_MACHINE_GROUP_CALL
        });

        axios.post('api/machine_group/create', machineGroupObj)
            .then(function (response) {
                dispatch({
                    type: Type.ADD_MACHINE_GROUP_SUCCESS,
                    data: response.data
                });
                dispatch(getGroup());
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_MACHINE_GROUP_FAILED
                });
            });
    }
}

export function getSubgroup(id_grupa) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_MACHINES_SUBGROUP_CALL
        });

        axios.get(`api/machine_subgroup/drop_down${id_grupa ? '?id_grupa=' + id_grupa : ''}`)
            .then(function (response) {
                dispatch({
                    type: Type.GET_MACHINES_SUBGROUP_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_MACHINES_SUBGROUP_FAILED
                });
            });
    }
}

export function getMachinesSubgroup(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_MACHINE_SUBGROUP_CALL
        });
        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/machine_subgroup/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_MACHINE_SUBGROUP_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_MACHINE_SUBGROUP_FAILED
                });
            });
    }
}

export function addMachinesSubgroup(machineSubgroupObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_MACHINE_SUBGROUP_CALL
        });

        axios.post('api/machine_subgroup/create', machineSubgroupObj)
            .then(function (response) {
                dispatch({
                    type: Type.ADD_MACHINE_SUBGROUP_SUCCESS,
                    data: response.data
                });
                dispatch(getSubgroup());
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_MACHINE_SUBGROUP_FAILED
                });
            });
    }
}

export function addMachine(machineObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_MACHINE_CALL
        });

        axios.post('api/machine/create', machineObj)
            .then(function (response) {
                dispatch({
                    type: Type.ADD_MACHINE_SUCCESS,
                    data: response.data
                });
                dispatch(getGroup());
                dispatch(getSubgroup());
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_MACHINE_FAILED
                });
            });
    }
}

export function getMachines(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_MACHINE_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/machine/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_MACHINE_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_MACHINE_FAILED
                });
            });
    }
}

export function deleteMachine(selection, name) {
    return (dispatch) => {

        dispatch({
            type: Type.DELETE_MACHINE_CALL,
        });

        axios.post(`api/${name}/delete`, { selection: selection })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_MACHINE_SUCCESS,
                    data: response.data,
                    name: name,
                    forDelete: selection
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_MACHINE_FAILED
                });
            });
    }
}

export function addPogonskaFavorit(id_pogonska_masina, naziv, registraciona_oznaka, gps_provider, id_pogonska_masina_gps_provajder) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_MACHINE_FAVORITE_CALL
        });

        const data = {
            id_pogonska_masina: id_pogonska_masina,
            naziv: naziv,
            registarska_oznaka: registraciona_oznaka,
            gps_provajder: gps_provider,
            id_pogonska_masina_gps_provajder: id_pogonska_masina_gps_provajder
        }

        axios.post('api/machine/addFavorite', data)
            .then(function (response) {
                dispatch({
                    type: Type.ADD_MACHINE_FAVORITE_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_MACHINE_FAVORITE_FAILED
                });
            });
    }
}

export function deletePogonskaFavorit(id_pogonska_masina_klijent) {
    return (dispatch) => {
        dispatch({
            type: Type.DELETE_MACHINE_FAVORITE_CALL,
        });
        axios.post(`api/machine/deleteFavorite`, { id_pogonska_masina_klijent })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_MACHINE_FAVORITE_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_MACHINE_FAVORITE_FAILED
                });
            });
    }
}

