/**
 * Created by pajicv on 6/4/18.
 */

import React, { Component } from 'react';

import './ParcelAttributes.css'

export default class FeatureAttributes extends Component {

    //u verziji react 16.0 stalno poziva konstruktor, u 16.3 nije pozivao
    constructor(props) {
        super(props);

        const { broj, povrsina } = props.attributes;

        this.state = { broj, povrsina: povrsina.toFixed(4) };

        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleAreaChange = this.handleAreaChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);

    }

    handleNameChange(event) {
        this.setState({broj: event.target.value});
    }

    handleAreaChange(event) {
        this.setState({povrsina: event.target.value});
    }

    handleSubmit(event) {

        let attributes = this.state;
        attributes.povrsina = Number(attributes.povrsina);

        this.props.setParcelAttributes(attributes);
        event.preventDefault();
    }

    handleCancel(event) {
        this.props.cancelParcelCreate();
        event.preventDefault();
    }

    //ovako treba u verziji react 16.3.2
    /*static getDerivedStateFromProps(props, state) {
        const {broj, povrsina} = props.attributes;
        return { broj, povrsina: povrsina.toFixed(4) };
    }*/

    render() {
        return (
        <div className="parcel-attributes-panel">
            <div className="form-style-2-heading">New Parcel</div>
            <form onSubmit={this.handleSubmit}>
                <label><span>Name <span className="required">*</span></span><input type="text" className="input-field" value={this.state.broj} onChange={this.handleNameChange} /></label>
                <label><span>Area <span className="required">*</span></span><input type="number" className="input-field" value={this.state.povrsina} onChange={this.handleAreaChange} /></label>


                <label><span>&nbsp;</span><input style={{float: 'left', marginRight:5}} type="submit" value="OK" /> <input style={{float: 'left', marginLeft:5}} type="button" value="Cancel" onClick={this.handleCancel}/></label>
            </form>
        </div>

/*
 <input style={{float: 'left', marginLeft:10}} type="button" value="Cancel" onClick={this.handleCancel}/>
 <div className="parcel-attributes-panel">
                <div class="form-style-2-heading">New Parcel</div>
                <form className="form-style-2" onSubmit={this.handleSubmit}>
                    <ul className="parcel-attributes-form">
                        <li>
                            <label><span>Name <span class="required">*</span></span><input type="text" class="input-field" value={this.state.broj} onChange={this.handleNameChange}/></label>

                            <label>
                                Name:</label>
                                <input type="text" value={this.state.broj} onChange={this.handleNameChange} />

                        </li>
                        <li>
                            <label>
                            Area:</label>
                            <input type="number" value={this.state.povrsina} onChange={this.handleAreaChange} />

                        </li>
                        <li>
                            <input type="submit" value="Submit" />
                            <input type="button" value="Cancel" onClick={this.handleCancel}/>
                        </li>
                    </ul>
                </form>
            </div>
            */
        );
    }
}