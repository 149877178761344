/**
 * Created by pajicv on 5/25/18.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import L from 'leaflet';

import { LayersControl, FeatureGroup, Popup, LayerGroup } from 'react-leaflet';

import * as LayerSelectors from '../selectors/LayerSelectors';

import * as LayerActions from '../actions/map/LayerActions';

import fields from './fields.json';

import './ParcelLayer.css';
import { getNoteDocument } from '../actions/note/NotesActions';

import { FIELD_NOTES_DESEASE, FIELD_NOTES_FIRE, FIELD_NOTES_MARK, FIELD_NOTES_OTHER, FIELD_NOTES_PEST, FIELD_NOTES_STEAL } from '../utils/constants';

const { Overlay } = LayersControl;

const markIcon = L.icon({
    iconUrl: 'mark-icon.png',
    iconSize: [32, 32]
});

const deseaseIcon = L.icon({
    iconUrl: 'desease-icon.png',
    iconSize: [32, 32]
});

const fireIcon = L.icon({
    iconUrl: 'fire-icon.png',
    iconSize: [32, 32]
});

const otherIcon = L.icon({
    iconUrl: 'other-icon.png',
    iconSize: [32, 32]
});

const pestIcon = L.icon({
    iconUrl: 'pest-icon.png',
    iconSize: [32, 32]
});

const stealIcon = L.icon({
    iconUrl: 'steal-icon.png',
    iconSize: [32, 32]
});

class NoteLayer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            clickedFeature: {
                attributes: {
                }
            }
        };

        this.onNoteClicked = this.onNoteClicked.bind(this);
    }

    onNoteClicked(e) {
        const clickedFeatureAttributes = e.layer.feature.properties;
        this.setState({
            clickedFeature: {
                attributes: { ...clickedFeatureAttributes }
            }
        });
        this.props.getNoteDocument(e.layer.feature.id)
    }

    componentDidUpdate(prevProps) {

        const { notesToAdd, noteToAdd, drawn } = this.props;

        if (notesToAdd) {

            let leafletGeoJSON = new L.GeoJSON(notesToAdd, {
                //style: getNoteStyle
            });
            let leafletFG = this._editableFG.leafletElement;

            leafletFG.clearLayers();

            leafletGeoJSON.eachLayer((layer) => {

                switch (layer.feature.properties.vrsta_beleske_id) {
                    case FIELD_NOTES_PEST: {
                        layer.setIcon(pestIcon);
                        break;
                    }
                    case FIELD_NOTES_STEAL: {
                        layer.setIcon(stealIcon);
                        break;
                    }
                    case FIELD_NOTES_DESEASE: {
                        layer.setIcon(deseaseIcon);
                        break;
                    }
                    case FIELD_NOTES_FIRE: {
                        layer.setIcon(fireIcon);
                        break;
                    }
                    case FIELD_NOTES_MARK: {
                        layer.setIcon(markIcon);
                        break;
                    }
                    case FIELD_NOTES_OTHER: {
                        layer.setIcon(otherIcon);
                        break;
                    }
                    default:
                        layer.setIcon(otherIcon);
                }

                leafletFG.addLayer(layer);

            });

            this.props.sendLoadedNotesDrawSuccess();
        }

        if (noteToAdd) {

            const feature = L.polygon(noteToAdd.geometry).toGeoJSON();
            feature.properties = { ...noteToAdd.attributes };
            const json = L.geoJSON(feature, {
                //style: getNoteStyle
            });
            this._editableFG.leafletElement.addLayer(json);

            this.props.sendNoteAddToMapSuccess();

        }

    }

    render() {

        const { addOverlay, removeLayer, removeLayerControl, noteLayer } = this.props;
        return (
            <Overlay checked={noteLayer.visible}
                name="Notes"
                addOverlay={addOverlay}
                removeLayer={removeLayer}
                removeLayerControl={removeLayerControl}>
                <LayerGroup>
                    <FeatureGroup onClick={this.onNoteClicked} ref={(reactFGref) => { this._onFeatureGroupReady(reactFGref); }}>
                        <Popup className="popup-parcel">
                            <div>
                                <div className="popup-parcel-name">
                                    {this.state.clickedFeature.attributes.naslov}
                                </div>
                                <div className="popup-parcel-subtitle">
                                    {`${this.state.clickedFeature.attributes.vrsta_beleske_naziv}`}
                                </div>
                                <div className="popup-parcel-subtitle">
                                    {`${this.state.clickedFeature.attributes.datum_dogadjaja}`}
                                </div>
                                <div className="popup-parcel-attributes">
                                    {`Reported by ${this.state.clickedFeature.attributes.korisnik_ime} ${this.state.clickedFeature.attributes.korisnik_prezime}`}
                                </div>
                                <div className="popup-parcel-subtitle">
                                    {`${this.state.clickedFeature.attributes.opis}`}
                                </div>
                                {this.props.notesDocuments && this.props.notesDocuments.lenth !== 0 &&
                                    this.props.notesDocuments.map((item) =>
                                        <div className="popup-parcel-subtitle">
                                            <img height="150" width="150" src={item.signedURL} alt={'picture'} />
                                        </div>
                                    )
                                }
                            </div>
                        </Popup>
                    </FeatureGroup>
                </LayerGroup>
            </Overlay>
        )
    }

    _editableFG = null;

    _onFeatureGroupReady = (reactFGref) => {

        if (this._editableFG) return;

        // populate the leaflet FeatureGroup with the geoJson layers

        let leafletGeoJSON = new L.GeoJSON(fields, {
            //style: getNoteStyle
        });
        let leafletFG = reactFGref.leafletElement;

        leafletGeoJSON.eachLayer((layer) => {
            leafletFG.addLayer(layer);
        });

        // store the ref for future access to content

        this._editableFG = reactFGref;

    };

    onCreated(e) {
        //console.log(e);
    }

}

function mapStateToProps(state) {
    return {
        noteLayer: LayerSelectors.getNoteLayer(state),
        notesToAdd: LayerSelectors.getLoadedNotes(state),
        notesDocuments: state.noteReducer.notesDocuments,
        notesDocumentsFetching: state.noteReducer.notesDocumentsFetching
    }
}

function mapDispatchToProps(dispatch) {
    return {
        //sendNoteAddToMapSuccess: () => dispatch(LayerActions.sendNewNoteAddToMapSuccess()),
        sendLoadedNotesDrawSuccess: () => dispatch(LayerActions.sendLoadedNotesDrawSuccess()),
        getNoteDocument: (id) => dispatch(getNoteDocument(id))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NoteLayer);
