import React from 'react';
import { connect } from 'react-redux';
import { withStyles, TextField, Card, Button, Table, Tooltip, TableBody, TableCell, TableHead, TableRow, Dialog, DialogTitle, IconButton, Typography } from '@material-ui/core';
import 'react-toastify/dist/ReactToastify.css';
import ReactSelect from '../../utils/ReactSelect';
import i18n from '../../i18n/i18n';
import { getMaterialDropdown, getUnit, getMaterialGroup } from '../../actions/activity/ActivityActions';
import immutable from 'seamless-immutable'
import AddIcon from '@material-ui/icons/Add';
import * as Icons from '@material-ui/icons';
import axios from './../../utils/AxiosWrapper';
import _ from 'lodash';

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.secondary,
        height: '35px',
        fontWeight: '500',
        fontSize: 16
    },
    body: {
        fontSize: 13,
    },
    root: {
        padding: '5px',
        textAlign: 'left',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        verticalAlign: 'inherit',
        paddingRight: 120,
        paddingLeft: 20
    }
}))(TableCell);

const styles = theme => ({
    innerContainer: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
    },
    innerContainerTable: {
        width: '100%',
        textAlign: 'flex-start',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
    },
    textInputs: {
        display: 'flex',
        flexDirection: 'column',
    },
    card: {
        height: 500,
        width: 600,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    cssRoot: {
        color: '#e50000',
        '&:hover': {
            color: '#ff0000',
        },
    },
    textInput: {
        margin: 10,
        width: 510,
        // paddingLeft: 20,
        paddingRight: 20,
        marginLeft: 30
    },
    button: {
        height: '100%',
        borderRadius: 0,
        boxShadow: 'none',
        width: '235px'
    },
    buttonsRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    panel: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderWidth: '0 0 2px',
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
    },
    typography: {
        paddingLeft: '20px',
        color: theme.palette.text.secondary,
        fontWeight: '600',
    }
});

export class ActivityMaterials extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            materijali: [],
            materialDropdown: [],
            unitDropdown: [],
            material: {},
            add: false,
            index: null,
        }
    }

    componentDidMount() {
        this.props.getMaterialDropdown();
        this.props.getUnit();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.materialDropdown !== this.props.materialDropdown) {
            this.setState({
                materialDropdown: immutable.asMutable(this.props.materialDropdown),
            })
        }
        // if (prevProps.unitDropdown !== this.props.unitDropdown) {
        //     this.setState({
        //         unitDropdown: immutable.asMutable(this.props.unitDropdown),
        //     })
        // }
        if (this.props.activityObj.materijali && prevState.materijali !== this.props.activityObj.materijali) {
            this.setState({
                materijali: this.props.activityObj.materijali
            })
        }
    }

    handleChangeValue = name => selected => {
        this.setState((state) => {
            if (name === "material") {
                axios.get('api/unit/material_unit', { params: { material: selected.value } })
                    .then(res => {
                        axios.get('api/unit/unit', { params: { id_unit: res.data.data[0].id_unit } })
                            .then(res => {
                                let array = [];
                                let unit = { label: res.data.data[0].naziv, value: res.data.data[0].id };
                                array.push(unit);
                                this.setState({ unitDropdown: array }, () => {
                                    let material = this.state.material;
                                    material.id_unit = null;
                                    this.setState({ material });
                                })
                            })
                            .catch(err => { console.log(err) })
                    })
                    .catch(err => { console.log(err) })
            }

            let material = state[name];
            material = selected && selected || {};
            let addedNaziv;
            if (Object.keys(material).length) {
                addedNaziv = Object.assign({}, material, { naziv: material.label, id_materijal: material.value })
                addedNaziv['jedinica_mere'] = this.getUnitName(addedNaziv.id_unit);
                addedNaziv['utroseno'] = null;
                addedNaziv['prinos'] = null;
            } else {
                addedNaziv = Object.assign({}, material)
            }
            if (state.material.id) {
                addedNaziv['id'] = state.material.id;
            }
            return { ...state, material: addedNaziv };
        })
    }

    handleChangeProperty = name => selected => {
        this.setState((state) => {
            let material = Object.assign({}, state['material']);
            material[name] = selected && selected.value;
            material['jedinica_mere'] = this.getUnitName(material.id_unit);
            return { ...state, material };
        })
    }

    handleTextFieldChange = name => event => {
        event.persist();
        this.setState((state) => {
            let material = Object.assign({}, state['material']);
            material[name] = event.target.value;
            return { ...state, material };
        })
    }

    handleAdd = (material) => {
        const { handleAddMaterial, handleEditChild, activityObj } = this.props;
        const { index } = this.state;
        if (index !== null && material.id && activityObj.id) {
            handleEditChild('materijali', material, index);
            this.setState({
                material: {},
                material_group: null,
                index: null,
                add: false,
            })
        } else {
            handleAddMaterial('materijali', material, index);
            this.setState({
                material: {},
                material_group: null,
                index: null,
                add: false,
            })
        }
    }

    isDisabled(material) {
        if (material.utroseno || material.prinos) {
            return false;
        } else {
            return true;
        }
    }

    handlePopup = () => {
        this.setState({
            add: !this.state.add,
            material: {}
        })
    }

    getUnitName = (id_unit) => {
        const { unitDropdown } = this.state;
        let result = unitDropdown.filter((unit) => {
            if (unit.value === id_unit) {
                return unit.label;
            }
        })[0]
        return result && (result.label || '');
    }

    onDeleteClick = (material, index) => {
        const { handleDeleteChild } = this.props
        handleDeleteChild('materijali', index, material);
        this.setState(prevState => {
            let newMaterials = prevState.materijali;
            return { materijali: newMaterials.filter((_, i) => i !== index) };
        })
    }

    onEditClick = (material, i) => {
        let mat = Object.assign({}, material);
        mat['label'] = mat.naziv;
        mat['value'] = mat.id_materijal;
        this.setState({
            material: mat,
            index: i,
            add: true,
        })
    }


    render() {
        const { classes, theme, viewOnly } = this.props;
        const { materijali, materialDropdown } = this.state;
        const materialRender = materijali.map((material, i) => {
            if (!material) return null;
            return <TableRow key={i}>
                <CustomTableCell>{material.naziv}</CustomTableCell>
                <CustomTableCell>{material.utroseno}</CustomTableCell>
                <CustomTableCell>{material.prinos}</CustomTableCell>
                <CustomTableCell>{material.jedinica_mere}</CustomTableCell>
                <CustomTableCell>{material.cena_po_jm}</CustomTableCell>
                <CustomTableCell>
                    <Tooltip title={i18n.t("Edit")}>
                        <IconButton aria-label="edit"
                            color="secondary"
                            disabled={viewOnly}
                            onClick={() => this.onEditClick(material, i)}
                        >
                            <Icons.Edit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={i18n.t("Delete")}>
                        <IconButton aria-label="Delete"
                            className={classes.cssRoot}
                            disabled={viewOnly}
                            onClick={() => this.onDeleteClick(material, i)}
                        >
                            <Icons.Delete />
                        </IconButton>
                    </Tooltip>
                </CustomTableCell>
            </TableRow>
        })
        return (
            <div className={classes.innerContainer} >
                {this.state.add &&
                    <Dialog
                        open={this.state.add}
                        onClose={this.handlePopup}
                        maxWidth="sm"
                        disableBackdropClick
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle style={{ background: theme.palette.secondary.dark }} disableTypography={true} className="responsive-dialog-title">{i18n.t('Add material')}
                            <IconButton onClick={this.handlePopup}>
                                <span className="icon-cancel-circle closeIcon" />
                            </IconButton>
                        </DialogTitle>
                        <Card raised className={classes.card}>
                            <div className={classes.textInputs}>
                                <ReactSelect
                                    closeMenuOnSelect={true}
                                    isClearable={true}
                                    value={this.state.material}
                                    onChange={this.handleChangeValue('material')}
                                    options={_.differenceBy(materialDropdown, materijali, 'id_materijal')}
                                    className="multi-select-in-filter-activity"
                                    classNamePrefix="select"
                                    maxMenuHeight={250}
                                    label={i18n.t('Material')}
                                    placeholder={i18n.t('Select')}
                                />
                                {this.state.material && this.state.material.id_materijal &&
                                    < ReactSelect
                                        closeMenuOnSelect={true}
                                        isClearable={false}
                                        maxMenuHeight={150}
                                        value={this.state.unitDropdown.find((element) => {
                                            return this.state.material && element.value === this.state.material.id_unit
                                            // puca kad se doda materijal || (element.label && element.label.toLowerCase() === this.state.material.jedinica_mere.toLowerCase())
                                        })}
                                        onChange={this.handleChangeProperty('id_unit')}
                                        options={this.state.unitDropdown}
                                        isSearchable={false}
                                        className="multi-select-in-filter-activity"
                                        classNamePrefix="select"
                                        label={i18n.t('Unit')}
                                        placeholder={i18n.t('Select')}
                                        defaultVale={''}
                                    />
                                }
                                {this.state.material &&
                                    <TextField
                                        type="number"
                                        label={i18n.t('Price')}
                                        InputLabelProps={{ shrink: true }}
                                        className={classes.textInput}
                                        value={this.state.material.cena_po_jm}
                                        onChange={this.handleTextFieldChange('cena_po_jm')}
                                    />
                                }
                                {(this.state.material.indikator_prinosa === 1 || this.state.material.prinos) &&
                                    <TextField
                                        required
                                        type="number"
                                        label={i18n.t('Yield')}
                                        className={classes.textInput}
                                        value={this.state.material.prinos}
                                        onChange={this.handleTextFieldChange('prinos')}
                                    />
                                }
                                {this.state.material && (this.state.material.indikator_prinosa === null || this.state.material.utroseno) &&
                                    <TextField
                                        required
                                        type="number"
                                        label={i18n.t('Amount spent')}
                                        className={classes.textInput}
                                        value={this.state.material.utroseno}
                                        onChange={this.handleTextFieldChange('utroseno')}
                                    />
                                }
                            </div>
                            <div className={classes.buttonsRow}>
                                <Button disabled={this.isDisabled(this.state.material)} onClick={() => this.handleAdd(this.state.material)}>{this.state.material.id && i18n.t('Edit') || i18n.t('Add')}</Button>
                                <Button onClick={this.handlePopup}>{i18n.t('Cancel')}</Button>
                            </div>
                        </Card>
                    </Dialog>
                }
                <div className={classes.panel}>
                    <Typography className={classes.typography} variant="subtitle1">{i18n.t('Materials')}</Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        aria-label="Add"
                        disabled={viewOnly}
                        className={classes.button}
                        onClick={this.handlePopup}
                    >
                        {'+ ' + i18n.t('Add material')}
                    </Button>
                </div>
                <div className={classes.innerContainerTable}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <CustomTableCell >{i18n.t('Material')}</CustomTableCell>
                                <CustomTableCell >{i18n.t('Spent')}</CustomTableCell>
                                <CustomTableCell >{i18n.t('Yield')}</CustomTableCell>
                                <CustomTableCell >{i18n.t('Unit')}</CustomTableCell>
                                <CustomTableCell >{i18n.t('Price per unit')}</CustomTableCell>
                                <CustomTableCell >{i18n.t('Edit/Delete')}</CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {materialRender}
                        </TableBody>
                    </Table>
                </div>
            </div>
        )

    }

}


function mapStateToProps(state) {
    return {
        materialDropdown: state.activityReducer.materialDropdown,
        unitDropdown: state.activityReducer.unitDropdown,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getMaterialDropdown: () => dispatch(getMaterialDropdown()),
        getUnit: () => dispatch(getUnit()),
    }
}
export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivityMaterials))