/**
 * Created by pajicv on 5/10/18.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { WMSTileLayer, LayersControl } from 'react-leaflet';

import * as LayerSelectors from '../selectors/LayerSelectors';

const { Overlay } = LayersControl;

class SatelliteImageryLayers extends Component {

    render() {

        const { layers, removeLayer, removeLayerControl, addOverlay } = this.props;

        return layers.map(layer => {

            return (
                <Overlay checked={ layer.visible }
                         name={layer.name}
                         addOverlay={addOverlay}
                         removeLayer={removeLayer}
                         removeLayerControl={removeLayerControl}>
                    <WMSTileLayer
                        url={layer.url}
                        tileSize={layer.tileSize}
                        attribution={layer.attribution}
                        maxcc={layer.maxcc}
                        minZoom={layer.minZoom}
                        maxZoom={layer.maxZoom}
                        layers={layer.layers}
                        time= {layer.date}
                        showLogo={this.props.showLogo}
                    />
                </Overlay>
            )
        });

    }
}

function mapStateToProps(state) {
    return {
        layers: LayerSelectors.getSatelliteImageryLayers(state)
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SatelliteImageryLayers);
