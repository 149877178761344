import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { getFieldDropDown } from '../../actions/catastral_data/FieldActions';
import { getCrop, getGroup, getSubgroup } from '../../actions/resources/CropActions';
import { getCropRotationReport } from '../../actions/reports/ReportsActions';
import { getSeasonDropDown, getLocalGov } from '../../actions/DropDownActions';
import { getUser } from '../../utils/CommonFunctions';
import { exportDocument } from '../../actions/ExportActions';
import { getStates } from '../../actions/DropDownActions';
import i18n from '../../i18n/i18n';
import Select from 'react-select';
import immutable from 'seamless-immutable';
import { Button } from '@material-ui/core';
import Export from '../../utils/Export';
import DashboardDrawer from '../../utils/DashboardDrawer';
import ForBooleanFilter from '../../utils/ForBooleanFilter';
import { withStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const styles = theme => ({
    primaryMain: {
        backgroundColor: theme.palette.primary.main,
    },
});
export class CropRotation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
            add: false,
            selection: [],
            selectAll: false,
            edit: false,
            user: {},
            export: false
        };
        this.tableScrollTop = 0;
    }

    componentDidMount() {
        if (this.props.fieldDropDown.length == 0) {
            this.props.getFieldDropDown();
        }
        if (this.props.crops.length === 0) {
            this.props.getCrop();
        }
        if (this.props.cropGroupDropdown.length === 0) {
            this.props.getGroup();
        }
        if (this.props.cropSubgroupDropdown.length === 0) {
            this.props.getSubgroup();
        }
        if (this.props.states.length === 0) {
            this.props.getStates();
        }
        if (this.props.localGoverments.length === 0) {
            this.props.getLocalGov();
        }
        if (this.props.seasonDropDown.length === 0) {
            this.props.getSeasonDropDown();
        }
        this.setState({
            user: getUser()
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            seasonDropDown: immutable.asMutable(nextProps.seasonDropDown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            states: immutable.asMutable(nextProps.states.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            fieldDropDown: immutable.asMutable(nextProps.fieldDropDown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            localGoverments: immutable.asMutable(nextProps.localGoverments.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            cropGroupDropdown: immutable.asMutable(nextProps.cropGroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            cropSubgroupDropdown: immutable.asMutable(nextProps.cropSubgroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            crops: immutable.asMutable(nextProps.crops.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
        })

        // if (nextProps.exportFetchingSuccess === true) {
        //     toast.success(i18n.t('Test passed successfully!'), {
        //         position: "top-center",
        //         autoClose: 5000,
        //         hideProgressBar: true,
        //         closeOnClick: true,
        //         pauseOnHover: false,
        //         draggable: false,
        //         draggablePercent: 80
        //     });
        //     this.props.setInitialStateExport('exportFetchingSuccess');
        // }

    }

    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    onExportClick = () => {
        this.setState({ export: !this.state.export });
    }

    exportFunction = () => {
        const table = this.reactTable;
        this.props.exportDocument(this.state.docTitle, table.state, 'api/report/crop_rotation');
        this.setState({ export: !this.state.export });
    }

    onPieClick = () => {
        this.props.history.push('/crop_rotation_cost');
    }

    onProfitClick = () => {
        this.props.history.push('/crop_rotation_cost');
    }

    onTableClick = () => {
        this.props.history.push('/crop_rotation');
    }

    isSelected = key => { return this.state.selection.includes(key); };

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true, selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => { selection.push(item._original.id); });
        }
        this.setState({ selectAll, selection });
    };

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection], selectedRow = null;
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) selection = [...selection.slice(0, keyIndex), ...selection.slice(keyIndex + 1)];
        else selection.push(key);
        if (selection.length == 1) selectedRow = this.state.data.filter((el) => el.id == selection)[0];
        this.setState({ selection, selectedRow });
    };

    render() {
        const { clientProfile, theme } = this.props;
        const { user } = this.state;
        const columns = [
            {
                Header: i18n.t('Season'),
                accessor: 'sezona',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        defaultValue={this.state.seasonDropDown && this.state.seasonDropDown.find(function (element) {
                            if (clientProfile) {
                                return element.value === clientProfile.id_default_sezona;
                            }
                        })}
                        closeMenuOnSelect={false}
                        options={this.state.seasonDropDown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Crop group'),
                accessor: 'kultura_grupa',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.cropGroupDropdown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />

            }, {
                Header: i18n.t('Crop subgroup'),
                accessor: 'kultura_podgrupa',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.cropSubgroupDropdown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            }, {
                Header: i18n.t('Crop'),
                accessor: 'kultura',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.crops}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Field title'),
                accessor: 'tabla',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.fieldDropDown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            }, {
                id: 'deo_table',
                Header: i18n.t('Part of the field'),
                accessor: d => d.deo_table,// Custom value accessors!  
                Cell: ({ value }) => value == true ? i18n.t('Yes') : i18n.t('No'),
                Filter: ({ filter, onChange }) =>
                    <ForBooleanFilter
                        onChange={(s) => {
                            onChange(s);
                        }}
                    />
            }, {
                Header: i18n.t('Main field name'),
                accessor: 'glavna_tabla_naziv',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.fieldDropDown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            }, {
                Header: i18n.t('Land area'),
                accessor: 'povrsina',
            },
            /*        {
                       Header: i18n.t('BVN'),
                       accessor: 'bpg',
                       show: user.id_klijent_grupa == 14 ? false : true,
                   }, */
            // {
            //     Header: i18n.t('Name surname'),
            //     accessor: 'kontakt_osoba',
            // }, 
            {
                Header: i18n.t('State'),
                accessor: 'opstina_naziv',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.states}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            }, {
                Header: i18n.t('Local government'),
                accessor: 'kat_opstina',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.localGoverments}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
        ];

        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;
        const checkboxProps = {
            selectAll, isSelected, toggleSelection, toggleAll, selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return { style: { backgroundColor: selected ? "#cfc980" : "inherit" } };
                }
                return {};
            },
            getTheadFilterProps: () => { return { style: { backgroundColor: '#4da7df' } }; },
            getTheadTrProps: () => { return { style: { backgroundColor: '#04764e', color: '#FFFFFF' } }; },
        };

        const filterOptions = {
            defaultFiltered:
                [
                    {
                        id: 'sezona',
                        value: clientProfile && this.state.seasonDropDown ? [this.state.seasonDropDown.find(function (element) {
                            return element.value === clientProfile.id_default_sezona;
                        })] : []
                    }
                ],
            getTheadFilterProps: () => {
                return {
                    style: {
                        backgroundColor: theme.palette.primary.main
                    }
                };
            },
            getTheadTrProps: () => {
                return {
                    style: {
                        backgroundColor: theme.palette.secondary.main,
                        color: '#FFFFFF'
                    }
                };
            },
        };
        return (
            <div className="page">
                <div className="table-header">
                    <Button
                        onClick={() => this.onSearchClick()}
                        variant="fab"
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-search headerIcon" />
                    </Button>
                    <Button
                        onClick={() => this.onExportClick()}
                        variant="fab"
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-download headerIcon" />
                    </Button>
                </div>
                <ReactTable
                    data={this.props.cropRotationReport}
                    ref={r => (this.reactTable = r)}
                    getTheadFilterThProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
                    columns={columns}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.props.cropRotationReportPages}
                    loading={this.props.cropRotationReportFetching}
                    manual
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => this.props.getCropRotationReport(state, instance)}
                    className="-striped -highlight table-drawer"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...filterOptions}
                    {...checkboxProps}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: theme.palette.primary.main,
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: theme.palette.primary.main,
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />
                <DashboardDrawer onPieClick={this.onPieClick} onTableClick={this.onTableClick} onProfitClick={this.onProfitClick} isVisible={true} />
                {this.state.export && <Export
                    export={this.state.export}
                    onTextChange={({ title }) => {
                        this.setState({ docTitle: title });
                    }}
                    handleClose={this.onExportClick}
                    exportDoc={this.exportFunction} />
                }
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        cropRotationReport: state.reportsReducer.cropRotationReport,
        cropRotationReportFetching: state.reportsReducer.cropRotationReportFetching,
        cropRotationReportPages: state.reportsReducer.cropRotationReportPages,
        fieldDropDown: state.fieldReducer.fieldDropDown,
        seasonDropDown: state.appReducer.seasonDropDown,
        fieldFetchingDropDown: state.fieldReducer.fieldFetchingDropDown,
        fieldFetchingFailedDropDown: state.fieldReducer.fieldFetchingFailedDropDown,
        crops: state.cropReducer.crops,
        cropFetching: state.cropReducer.cropFetching,
        cropFetchingFailed: state.cropReducer.cropFetchingFailed,
        cropGroupDropdown: state.cropReducer.cropGroupDropdown,
        groupFetching: state.cropReducer.groupFetching,
        groupFetchingFailed: state.cropReducer.groupFetchingFailed,
        cropSubgroupDropdown: state.cropReducer.cropSubgroupDropdown,
        subgroupFetching: state.cropReducer.subgroupFetching,
        subgroupFetchingFailed: state.cropReducer.subgroupFetchingFailed,
        states: state.appReducer.states,
        localGoverments: state.appReducer.localGoverments,
        clientProfile: state.appReducer.clientProfile,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getFieldDropDown: () => dispatch(getFieldDropDown()),
        exportDocument: (title, state, url) => dispatch(exportDocument(title, state, url)),
        getStates: () => dispatch(getStates()),
        getSeasonDropDown: () => dispatch(getSeasonDropDown()),
        getLocalGov: () => dispatch(getLocalGov()),
        getCropRotationReport: (state, instance) => dispatch(getCropRotationReport(state, instance)),
        getCrop: () => dispatch(getCrop()),
        getGroup: () => dispatch(getGroup()),
        getSubgroup: () => dispatch(getSubgroup()),
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(CropRotation));