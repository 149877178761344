export const zoomFormat = (fields) => {
    let x = [], y = [];

    for (let i = 0; i < fields.features.length; i++) {
        if (fields.features[i].geometry.coordinates !== undefined) {
            for (let j = 0; j < fields.features[i].geometry.coordinates[0].length; j++) {
                x.push(fields.features[i].geometry.coordinates[0][j][0])
                y.push(fields.features[i].geometry.coordinates[0][j][1])
            }
        }
    }

    let xmin = Math.min(...x);
    let xmax = Math.max(...x);
    let ymin = Math.min(...y);
    let ymax = Math.max(...y);

    let cent_x = (ymax + ymin) / 2;
    let cent_y = (xmax + xmin) / 2;

    let sizex = ymax - ymin;
    let sizey = xmax - xmin;

    let size = null;
    if (sizex > sizey) size = sizex;
    else size = sizey;

    let cent = [cent_x, cent_y];
    let zoom_level = null;

    if (size < 0.003) zoom_level = 18;
    else if (size >= 0.003 && size < 0.005) zoom_level = 17;
    else if (size >= 0.005 && size < 0.011) zoom_level = 16;
    else if (size >= 0.011 && size < 0.022) zoom_level = 15;
    else if (size >= 0.022 && size < 0.044) zoom_level = 14;
    else if (size >= 0.044 && size < 0.088) zoom_level = 13;
    else if (size >= 0.088 && size < 0.176) zoom_level = 12;
    else if (size >= 0.176 && size < 0.352) zoom_level = 11;
    else if (size >= 0.352 && size < 0.703) zoom_level = 10;
    else if (size >= 0.703 && size < 1.406) zoom_level = 9;
    else if (size >= 1.406 && size < 2.813) zoom_level = 8;
    else if (size >= 2.813 && size < 5.625) zoom_level = 7;

    let zoom_data = {
        zoom: zoom_level,
        center: cent
    };

    return zoom_data;
}