import immutable from 'seamless-immutable';
import { Type as MaterialType } from '../../actions/resources/MaterialActions';


export const INITIAL_STATE = immutable({
    //dropdowns
    materialGroupDropdown: [],
    groupFetching: false,
    groupFetchingFailed: false,
    materialSubgroupDropdown: [],
    subgroupFetching: false,
    subgroupFetchingFailed: false,
    materialDropdown: [],
    materialDropdownFetching: false,
    materialDropdownFetchingFailed: false,

    //materijali
    materialPosting: false,
    materialPostingSuccess: false,
    materialPostingFailed: false,
    material: [],
    materialObj: {},
    materialsFetching: false,
    materialsFetchingFailed: false,
    materialsPages: 1,

    //materijali grupe
    material_group: [],
    materialGroupObj: {},
    materialGroupsFetching: false,
    materialGroupsFetchingFailed: false,
    materialGroupPages: 1,
    materialGroupPosting: false,
    materialGroupPostingSuccess: false,
    materialGroupPostingFailed: false,

    //materijali podgrupe
    material_subgroup: [],
    materialSubgroupObj: {},
    materialSubgroupsFetching: false,
    materialSubgroupsFetchingFailed: false,
    materialSubgroupsPages: 1,
    materialSubgroupsPosting: false,
    materialSubgroupsPostingSuccess: false,
    materialSubgroupsPostingFailed: false,

    addFavoriteSuccess: false,


    deleted: false,
    deleteMaterialFailed: false,
});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case MaterialType.SET_INITIAL_STATE:
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;


        case MaterialType.GET_MATERIAL_GROUP_DROPDOWN_CALL: {
            const groupFetching = true;
            return state.merge({ groupFetching });
            break;
        }

        case MaterialType.GET_MATERIAL_GROUP_DROPDOWN_SUCCESS: {
            const materialGroupDropdown = action.data.data;
            const groupFetching = false;
            return state.merge({ materialGroupDropdown, groupFetching });
            break;
        }

        case MaterialType.GET_MATERIAL_GROUP_DROPDOWN_FAILED: {
            const groupFetchingFailed = true;
            const groupFetching = false;
            return state.merge({ groupFetchingFailed, groupFetching });
            break;
        }

        case MaterialType.GET_MATERIAL_SUBGROUP_DROPDOWN_CALL: {
            const subgroupFetching = true;
            return state.merge({ subgroupFetching });
            break;
        }

        case MaterialType.GET_MATERIAL_SUBGROUP_DROPDOWN_SUCCESS: {
            const materialSubgroupDropdown = action.data.data;
            const subgroupFetching = false;
            return state.merge({ materialSubgroupDropdown, subgroupFetching });
            break;
        }

        case MaterialType.GET_MATERIAL_SUBGROUP_DROPDOWN_FAILED: {
            const subgroupFetchingFailed = true;
            const subgroupFetching = false;
            return state.merge({ subgroupFetchingFailed, subgroupFetching });
            break;
        }

        case MaterialType.GET_MATERIAL_DROPDOWN_CALL: {
            const materialDropdownFetching = true;
            return state.merge({ materialDropdownFetching });
            break;
        }

        case MaterialType.GET_MATERIAL_DROPDOWN_SUCCESS: {
            const materialDropdown = action.data.data;
            const materialDropdownFetching = false;
            return state.merge({ materialDropdown, materialDropdownFetching });
            break;
        }

        case MaterialType.GET_MATERIAL_DROPDOWN_FAILED: {
            const materialDropdownFailed = true;
            const materialDropdown = false;
            return state.merge({ materialDropdownFailed, materialDropdown });
            break;
        }

        case MaterialType.ADD_MATERIAL_CALL: {
            const materialPosting = true;
            return state.merge({ materialPosting });
            break;
        }

        case MaterialType.ADD_MATERIAL_SUCCESS: {
            const materialPosting = false;
            const materialPostingSuccess = true;
            return state.merge({ materialPostingSuccess, materialPosting, materialObj: INITIAL_STATE.materialObj })
            break;
        }

        case MaterialType.ADD_MATERIAL_FAILED: {
            const materialPosting = false;
            const materialPostingFailed = true;
            return state.merge({ materialPosting, materialPostingFailed });
            break;
        }

        case MaterialType.GET_MATERIAL_CALL: {
            const materialsFetching = true;
            return state.merge({ materialsFetching });
            break;
        }

        case MaterialType.GET_MATERIAL_SUCCESS: {
            const material = action.data.data;
            const materialsFetching = false;
            const materialsPages = action.data.total_pages
            return state.merge({ material, materialsFetching, materialsPages });
            break;
        }

        case MaterialType.GET_MATERIAL_FAILED: {
            const materialsFetchingFailed = true;
            const materialsFetching = false;
            return state.merge({ materialsFetching, materialsFetchingFailed });
            break;
        }

        case MaterialType.GET_MATERIAL_GROUP_CALL: {
            const materialGroupsFetching = true;
            return state.merge({ materialGroupsFetching });
            break;
        }

        case MaterialType.GET_MATERIAL_GROUP_SUCCESS: {
            const material_group = action.data.data;
            const materialGroupsFetching = false;
            const materialGroupPages = action.data.total_pages
            return state.merge({ material_group, materialGroupsFetching, materialGroupPages });
            break;
        }

        case MaterialType.GET_MATERIAL_GROUP_FAILED: {
            const materialGroupsFetchingFailed = true;
            const materialGroupsFetching = false;
            return state.merge({ materialGroupsFetching, materialGroupsFetchingFailed });
            break;
        }

        case MaterialType.ADD_MATERIAL_GROUP_CALL: {
            const materialGroupPosting = true;
            return state.merge({ materialGroupPosting });
            break;
        }

        case MaterialType.ADD_MATERIAL_GROUP_SUCCESS: {
            const materialGroupPosting = false;
            const materialGroupPostingSuccess = true;
            return state.merge({ materialGroupPostingSuccess, materialGroupPosting, materialGroupObj: INITIAL_STATE.materialGroupObj })
            break;
        }

        case MaterialType.ADD_MATERIAL_GROUP_FAILED: {
            const materialGroupPosting = false;
            const materialGroupPostingFailed = true;
            return state.merge({ materialGroupPosting, materialGroupPostingFailed });
            break;
        }

        case MaterialType.GET_MATERIAL_SUBGROUP_CALL: {
            const materialSubgroupsFetching = true;
            return state.merge({ materialSubgroupsFetching });
            break;
        }

        case MaterialType.GET_MATERIAL_SUBGROUP_SUCCESS: {
            const material_subgroup = action.data.data;
            const materialSubgroupsFetching = false;
            const materialSubgroupsPages = action.data.total_pages
            return state.merge({ material_subgroup, materialSubgroupsFetching, materialSubgroupsPages });
            break;
        }

        case MaterialType.GET_MATERIAL_SUBGROUP_FAILED: {
            const materialSubgroupsFetchingFailed = true;
            const materialSubgroupsFetching = false;
            return state.merge({ materialSubgroupsFetching, materialSubgroupsFetchingFailed });
            break;
        }

        case MaterialType.ADD_MATERIAL_SUBGROUP_CALL: {
            const materialSubgroupsPosting = true;
            return state.merge({ materialSubgroupsPosting });
            break;
        }

        case MaterialType.ADD_MATERIAL_SUBGROUP_SUCCESS: {
            const materialSubgroupsPosting = false;
            const materialSubgroupsPostingSuccess = true;
            return state.merge({ materialSubgroupsPostingSuccess, materialSubgroupsPosting, materialSubgroupObj: INITIAL_STATE.materialSubgroupObj })
            break;
        }

        case MaterialType.ADD_MATERIAL_SUBGROUP_FAILED: {
            const materialSubgroupsPosting = false;
            const materialSubgroupsPostingFailed = true;
            return state.merge({ materialSubgroupsPosting, materialSubgroupsPostingFailed });
            break;
        }

        case MaterialType.SET_MATERIAL_ROW_IN_REDUCER: {
            const data = action.rowInfo.asMutable();
            return state.merge({ [action.name]: data })
            break;
        }

        case MaterialType.DELETE_MATERIAL_SUCCESS: {
            const array = state[action.name].asMutable();
            const forDelete = action.forDelete;
            for (let i = 0; i < array.length; i++) {
                for (let j = 0; j <= forDelete.length; j++) {
                    if (array[i] && array[i].id === forDelete[j]) {
                        array.splice(i, 1);
                    }
                }
            }
            const deleted = true;
            return state.merge({ [action.name]: array, deleted: deleted })
            break;
        }

        case MaterialType.DELETE_MATERIAL_FAILED: {
            const deleteMaterialFailed = true;
            return state.merge({ deleteMaterialFailed });
            break;
        }

        case MaterialType.ADD_MATERIAL_FAVORITE_SUCCESS: {
            const addFavoriteSuccess = true;
            return state.merge({ addFavoriteSuccess })
        }

        default:
            return state;
    }
}