import React from 'react';
import i18n from '../../../i18n/i18n';
import { Bar } from 'react-chartjs-2';
import { Link, Typography } from '@material-ui/core';
import { formatForecast } from '../DataFormatters';
import { withStyles } from '@material-ui/core/styles';
import { MoonLoader } from 'react-spinners';
import { Loader } from './../Loader';

const styles = {};

export class ForecastDewPoint extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            week: false,
            load: true
        };
    };

    componentDidMount = () => {
        Loader(this.props, ['basic-1h', 'agro-1h'], ['forecastCalled', 'agroCalled'], ['forecast', 'agro'])
    };

    render() {
        let graph = {}, data = [], obj = {}, ready = true;

        this.props.data.forEach((item, i) => {
            if (Object.keys(item).length > 0) { obj = formatForecast(item); data.push(obj); }
            else data.push({});
        });

        data.forEach((item, i) => { if (Object.keys(item).length === 0) ready = false; });

        if (ready === true) {
            graph = {
                labels: this.state.week ? data[0].dates : data[0].dates.slice(0, 72),
                datasets: [
                    {
                        data: this.state.week ? data[1].dew_point_temperature : data[1].dew_point_temperature.slice(0, 72),
                        label: i18n.t('Dew point [°C]'),
                        type: 'line',
                        backgroundColor: 'transparent',
                        borderColor: 'rgb(90, 232, 223)',
                        borderWidth: 4,
                        pointRadius: this.state.week ? 0 : 2,
                    },
                    {
                        data: this.state.week ? data[0].temperature : data[0].temperature.slice(0, 72),
                        label: i18n.t('Temperature [°C]'),
                        backgroundColor: 'transparent',
                        borderColor: 'red',
                        type: 'line',
                        borderWidth: 2,
                        pointRadius: this.state.week ? 0 : 2,
                    }
                ],
            };
        }

        const options1 = {
            scales: {
                yAxes: [{
                    type: 'linear',
                    display: true,
                    position: 'left',
                    gridLines: { drawOnChartArea: false },
                }]
            }
        };

        return (
            <div style={{ paddingLeft: '35px', height: '100%' }}>
                <div style={{ display: 'flex', marginLeft: '40%', paddingTop: '22px' }}>
                    <Link style={{ marginRight: '3px', marginLeft: '3px', fontSize: '18px', color: 'black', cursor: 'pointer' }}
                        onClick={() => { this.setState({ week: false }); }}
                        variant="contained"
                        color="secondary">
                        <Typography>{i18n.t('3 days')}</Typography>
                    </Link> /
                <Link style={{ marginRight: '3px', marginLeft: '3px', fontSize: '18px', color: 'black', cursor: 'pointer' }}
                        onClick={() => { this.setState({ week: true }); }}
                        variant="contained"
                        color="secondary" >
                        <Typography>{i18n.t('7 days')}</Typography>
                    </Link>
                </div>
                {Object.keys(graph).length === 0 && <div style={{ marginLeft: '39%', marginTop: '14%' }}> <MoonLoader loading={this.state.load} size={100} /></div>}
                {Object.keys(graph).length > 0 &&
                    <div style={{ width: '95%', marginTop: '10px' }}>
                        <Bar data={graph} height={300} width={600} options={options1} />
                    </div>
                }
            </div>
        );
    }
};

export default withStyles(styles)(ForecastDewPoint);