import React from 'react';
import i18n from '../../../i18n/i18n';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './MeteogramStyles';
import { Card } from '@material-ui/core';
import Meteogram from './Meteogram';

export class Meteograms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                <Card>
                    <div>
                        <div className={classes.top_container}>
                            <div className={classes.row_container}>
                                <Meteogram zoom_data={this.props.zoom_data} meteogram={'14day'} name={i18n.t('14 Days')} />
                                <Meteogram zoom_data={this.props.zoom_data} meteogram={'agroSowing'} name={i18n.t('Sowing')} sowing={true} />
                                <Meteogram zoom_data={this.props.zoom_data} meteogram={'agroSpraying'} name={i18n.t('Spraying')} />
                                <Meteogram zoom_data={this.props.zoom_data} meteogram={'picto'} name={i18n.t('7 Days')} />
                            </div>
                        </div>
                        <div className={classes.bottom_container}>
                            <div className={classes.row_container}>
                                <Meteogram zoom_data={this.props.zoom_data} meteogram={'multiSimple'} name={i18n.t('Multimodel')} />
                                <Meteogram zoom_data={this.props.zoom_data} meteogram={'soiltraffic'} name={i18n.t('Soil Trafficability')} />
                                <Meteogram zoom_data={this.props.zoom_data} meteogram={'seasonal'} name={i18n.t('Seasonal')} />
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
};

export default withStyles(styles)(Meteograms);