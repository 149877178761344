import React from 'react';
import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import 'react-toastify/dist/ReactToastify.css';
import i18n from '../../i18n/i18n';
import randomColor from 'randomcolor';
import axios from '../../utils/AxiosWrapper';
import DashboardFilters from './DashboardFilters';
import Chart from './Dashboards/Chart';

const styles = theme => ({
    card: { minWidth: 275, padding: '10px', width: '90%', },
    title: { marginBottom: 16, fontSize: 14, },
});

export default class WorkersDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            total: null,
            season: [], sector: [], crop: [], field: [], plan: [],
            workerLabels: [], workerValues: [],
            operationLabels: [], operationValues: [],
            workerOperationLabels: [], workerOperationValues: [],
            colorsW: [], colorsO: [], colorsWO: [],
        }
    }

    componentDidMount() {
        this.getChartData([], [], [], [], [], 'IV_radnik_enterprise');
    }

    getChartData(season, sector, crop, field, plan, tabela) {
        axios.get('api/report/read_dashboard', { params: { season: season, sector: sector, crop: crop, field: field, plan: plan, tabela: tabela, } })
            .then(response => {
                let objO = formatData(response.data.data, '', '', '', '', 'naziv_radna_operacija', 'iznos');
                let objW = formatData(response.data.data, 'ime_radnika', ' ', 'prezime_radnika', '', '', 'iznos');
                let objWO = formatData(response.data.data, 'ime_radnika', ' ', 'prezime_radnika', ' / ', 'naziv_radna_operacija', 'iznos');
                this.setState({
                    workerLabels: objW.labels, workerValues: objW.values, colorsW: objW.colors,
                    operationLabels: objO.labels, operationValues: objO.values, colorsO: objO.colors,
                    workerOperationLabels: objWO.labels, workerOperationValues: objWO.values, colorsWO: objWO.colors,
                });
            })
            .catch(error => console.log('Error: ', error));
    }

    getValues(items, states) {
        for (let i = 0; i < states.length; i++) items.push(states[i].value);
        return items;
    }

    handleChange = name => selectedOption => { this.setState({ [name]: selectedOption }); }

    render() {
        let totalCost = null;
        totalCost = this.state.workerValues.reduce((a, b) => a + b, 0);
        totalCost = Math.round(totalCost * 100) / 100;

        let stringTotal = 0;
        if (this.state.workerValues !== null) stringTotal = (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(totalCost));

        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div style={{ 'padding': '20px', width: '100%' }}>
                    <Card style={styles.card}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <h1 style={{ marginLeft: '10px', marginBottom: '10px' }}>{i18n.t('Total Cost')}: {stringTotal} $</h1>
                        </div>
                    </Card>
                </div>
                <div style={{ display: 'flex', alignSelf: 'center', marginLeft: '-10px' }}>
                    <div style={{ marginTop: '0px', marginRight: '1px' }}>
                        <DashboardFilters
                            handleChange={this.handleChange}
                            season={this.state.season} sector={this.state.sector} crop={this.state.crop} field={this.state.field} plan={this.state.plan}
                        />
                    </div>
                    <div style={{ display: 'flex', alignSelf: 'center', marginRight: '10px' }}>
                        <Button
                            onClick={(e) => {
                                let seasonValues = [], sectorValues = [], cropValues = [], fieldValues = [], planValues = [];

                                seasonValues = this.getValues(seasonValues, this.state.season);
                                sectorValues = this.getValues(sectorValues, this.state.sector);
                                cropValues = this.getValues(cropValues, this.state.crop);
                                fieldValues = this.getValues(fieldValues, this.state.field);
                                planValues = this.getValues(planValues, this.state.plan);

                                this.getChartData(seasonValues, sectorValues, cropValues, fieldValues, planValues, 'IV_radnik_enterprise');
                            }}
                            style={{ backgroundColor: '#04764e', color: 'white', height: '3em', width: '80px', marginTop: '20px', marginLeft: '20px' }}>
                            {i18n.t('Submit')}
                        </Button>
                        <Button
                            onClick={(e) => {
                                this.setState({ season: [], sector: [], crop: [], field: [], plan: [] });
                            }}
                            style={{ backgroundColor: '#04764e', color: 'white', height: '3em', width: '80px', marginTop: '20px', marginLeft: '10px', marginRight: '25px' }}
                        >
                            {i18n.t('Reset')}
                        </Button>
                    </div>
                </div> <br></br>
                <div style={{ width: '94%', marginLeft: '10px' }}>
                    <Chart width={100} title={i18n.t('Cost by Working Operation')} color={this.state.colorsO} labels={this.state.operationLabels} values={this.state.operationValues} currency={true} />
                </div> <br></br>
                <div style={{ width: '94%', marginLeft: '10px' }}>
                    <Chart width={100} title={i18n.t('Cost by Workers')} color={this.state.colorsW} labels={this.state.workerLabels} values={this.state.workerValues} currency={true} />
                </div> <br></br>
                <div style={{ width: '94%', marginLeft: '10px' }}>
                    <Chart width={100} title={i18n.t('Cost by Work')} color={this.state.colorsWO} labels={this.state.workerOperationLabels} values={this.state.workerOperationValues} currency={true} />
                </div>
            </div >
        )
    }
}

function getLabels(item, labels) {
    labels.push(item);
    let unique = new Set(labels);
    labels = [...unique];
    return labels;
};

function getData(values, filter, cena) {
    let quant = 0;
    filter.map((mat) => {
        if (mat[cena] !== undefined && mat[cena] > 0) {
            quant += parseFloat(mat[cena]);
        }
    });
    values.push(quant.toFixed(2));
    return values;
};

function formatData(data, name1, sep1, name2, sep2, name3, cena) {
    let temp_labels = [], labels = [], values = [];

    if (name1 !== '' && name2 !== '' && name3 !== '') {
        data.forEach((item, i) => temp_labels = getLabels(item[name1] + sep1 + item[name2] + sep2 + item[name3], temp_labels).sort());
        temp_labels.forEach(item => { let filter = data.filter(i => i[name1] + sep1 + i[name2] + sep2 + i[name3] === item); values = getData(values, filter, cena) });
    }
    else if (name1 === '' && name2 === '' && name3 !== '') {
        data.forEach((item, i) => temp_labels = getLabels(item[name3], temp_labels).sort());
        temp_labels.forEach(item => { let filter = data.filter(i => i[name3] === item); values = getData(values, filter, cena) });
    }
    else if (name1 !== '' && name2 !== '' && name3 === '') {
        data.forEach((item, i) => temp_labels = getLabels(item[name1] + sep1 + item[name2], temp_labels).sort());
        temp_labels.forEach(item => { let filter = data.filter(i => i[name1] + sep1 + i[name2] === item); values = getData(values, filter, cena) });
    }
    values = values.map(item => Number(item));

    let deleted = values.reduce(function (acc, curr, index) {
        if (curr === 0) acc.push(index);
        return acc;
    }, []);

    let val = [], lab = [];

    for (let i = 0; i < values.length; i++) {
        if (!deleted.includes(i)) {
            val.push(values[i]);
            lab.push(temp_labels[i]);
        }
    }

    temp_labels = lab;
    values = val;

    labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' $');

    let object = { labels: labels, values: values, colors: randomColor({ count: values.length }) };
    return object;
}
