import React from 'react';
import i18n from '../../../i18n/i18n';
import 'react-toastify/dist/ReactToastify.css';
import { IconButton, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { NDVI, SAVI, SR, RGB, NDWI, NDMI, LAI, EVI, NBR } from './imagery';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './SatelliteStyles';
import axios from '../../../utils/AxiosWrapper';

export class SatelliteInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 1
        };
    };

    componentDidMount() {
        axios.get('api/map/language')
            .then(res => {
                let response = res.data.data[0].id_jezik;
                if (response) this.setState({ language: response });
            })
            .catch(err => { console.log(err) })
    };

    render() {
        let title = '';
        switch (this.props.selectedSatellite) {
            case 'NDVI': title = i18n.t('NDVI (Normalised Difference Vegetation Index)'); break;
            case 'SAVI': title = i18n.t('SAVI (Soil-Adjusted Vegetation Index)'); break;
            case 'SR': title = i18n.t('SR (Simple Ratio Index)'); break;
            case 'BATHYMETRIC': title = i18n.t('RGB (Red-Green-Blue Index)'); break;
            case 'NDWI': title = i18n.t('NDWI (Normalised Difference Water Index)'); break;
            case 'NDMI': title = i18n.t('NDMI (Normalised Difference Moisture Index)'); break;
            case 'LAI': title = i18n.t('LAI (Leaf Area Index)'); break;
            case 'EVI': title = i18n.t('EVI (Enhanced Vegetation Index)'); break;
            case 'NBR': title = i18n.t('NBR (Normalised Burn Ratio)'); break;
            default: title = i18n.t('Satellite Imagery')
        }
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                <Dialog open={this.props.open} onClose={this.props.handleClick} PaperProps={{ height: '100%' }}
                    fullWidth={true} disableBackdropClick maxWidth="md" aria-labelledby="responsive-dialog-title2">
                    <DialogTitle disableTypography={true} style={{ background: '#04764e', maxHeight: '100%' }} className="responsive-dialog-title2">
                        <p className={classes.title}>{title}</p>
                        <IconButton onClick={this.props.handleClick} className={classes.close}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className={classes.content}>
                        <div>{this.props.selectedSatellite === 'NDVI' && <NDVI language={this.state.language} />}</div>
                        <div>{this.props.selectedSatellite === 'SAVI' && <SAVI language={this.state.language} />}</div>
                        <div>{this.props.selectedSatellite === 'SR' && <SR language={this.state.language} />}</div>
                        <div>{this.props.selectedSatellite === 'BATHYMETRIC' && <RGB language={this.state.language} />}</div>
                        <div>{this.props.selectedSatellite === 'NDWI' && <NDWI language={this.state.language} />}</div>
                        <div>{this.props.selectedSatellite === 'NDMI' && <NDMI language={this.state.language} />}</div>
                        <div>{this.props.selectedSatellite === 'LAI' && <LAI language={this.state.language} />}</div>
                        <div>{this.props.selectedSatellite === 'EVI' && <EVI language={this.state.language} />}</div>
                        <div>{this.props.selectedSatellite === 'NBR' && <NBR language={this.state.language} />}</div>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
};

export default withStyles(styles)(SatelliteInfo);