import immutable from 'seamless-immutable';
import { Type as SensorType } from '../../actions/sensor/SensorActions';


export const INITIAL_STATE = immutable({
    sensors: [],
    sensorsFetching: false,
    sensorsFetchingFailed: false,
    sensorsPages: 1,
    //sensor 
    sensorObj: {},
    sensorPosting: false,
    sensorPostingSuccess: false,
    sensorPostingFailed: false,
    delete: false,
    deleteFailed: false,
    sensorData: [],
    sensorDataFetching: false
});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case SensorType.VIEW_SENSOR_CALL: {
            const sensorsFetching = true;
            return state.merge({ sensorsFetching });
            break;
        }
        case SensorType.VIEW_SENSOR_SUCCESS: {
            const sensors = action.data.data;
            const sensorsPages = action.data.total_pages
            const sensorsFetching = false;
            return state.merge({ sensors, sensorsPages, sensorsFetching });
            break;
        }
        case SensorType.VIEW_SENSOR_FAILED: {
            const sensorsFetchingFailed = true;
            return state.merge({ sensorsFetchingFailed });
            break;
        }
        case SensorType.ADD_SENSOR_CALL: {
            const sensorPosting = true;
            return state.merge({ sensorPosting })
            break;
        }
        case SensorType.ADD_SENSOR_SUCCESS: {
            const sensorPosting = false;
            const sensorPostingSuccess = true;
            return state.merge({ sensorPosting, sensorPostingSuccess });
            break;
        }
        case SensorType.ADD_SENSOR_FAILED: {
            const sensorPosting = false;
            const sensorPostingFailed = true;
            return state.merge({ sensorPosting, sensorPostingFailed });
            break;
        }
        case SensorType.DELETE_SENSOR_SUCCESS: {
            const array = state.sensors.asMutable();
            const forDelete = action.forDelete;
            for (let i = 0; i < array.length; i++) {
                for (let j = 0; j <= forDelete.length; j++) {
                    if (array[i] && array[i].id === forDelete[j]) {
                        array.splice(i, 1);
                    }
                }
            }
            const deleted = true;
            return state.merge({ sensors: array, deleted: deleted })
            break;
        }
        case SensorType.DELETE_SENSOR_FAILED: {
            const deleteFailed = true;
            return state.merge({ deleteFailed });
            break;
        }
        case SensorType.SET_SENSOR_IN_REDUCER: {
            const data = action.rowInfo.asMutable();
            return state.merge({ sensorObj: data })
            break;
        }
        case SensorType.SET_INITIAL_STATE:
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;
        case SensorType.VIEW_SENSOR_DATA_CALL: {
            const sensorDataFetching = true;
            return state.merge({ sensorDataFetching });
            break;
        }
        case SensorType.VIEW_SENSOR_DATA_SUCCESS: {
            const sensorData = action.data;
            const sensorDataFetching = false;
            return state.merge({ sensorData, sensorDataFetching });
            break;
        }
        case SensorType.VIEW_SENSOR_DATA_FAILED: {
            const sensorDataFetchingFailed = true;
            return state.merge({ sensorDataFetchingFailed });
            break;
        }
        default:
            return state;
    }
}