import React from 'react';
import { Card, withStyles, } from '@material-ui/core';
import axios from '../../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { styles } from '../InfoStyles';

export class InfoSoilTemperature extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 1
        };
    };

    componentDidMount() {
        axios.get('api/map/language')
            .then(res => {
                let response = res.data.data[0].id_jezik;
                if (response) this.setState({ language: response });
            })
            .catch(err => { console.log(err) })
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.language === 1 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Na osnovu temperature setvenog sloja određuje se vreme početka setve. Proizvodni optimum je kada je temperatura setvenog sloja u rasponu od 8-10°C na dubini do 10cm ustaljena nekoliko dana. Prerana setva u hladno zemljište produžava period klijanja i nicanja biljaka.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                Values description: The value range of an NDVI is -1 to 1. Negative values of NDVI (values approaching -1)
                                correspond to water. Values close to zero (-0.1 to 0.1) generally correspond to barren areas of rock, sand,
                                or snow. Low, positive values represent shrub and grassland (approximately 0.2 to 0.4), while high values
                                indicate temperate and tropical rainforests (values approaching 1).
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 4 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Na osnovu temperature sjetvenog sloja određuje se vreme početka sjetve. 
                            Proizvodni optimum je kada je temperatura sjetvenog sloja u rasponu od 8-10°C na dubini 
                            do 10cm ustaljena nekoliko dana. Prerana sjetva u hladno tlo produžava period klijanja i nicanja biljaka.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 5 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Opis vrednosti: Razpon vrednosti NDVI je od -1 do 1. 
                            Negativne vrednosti NDVI (vrednosti, ki se približujejo -1) ustrezajo vodi. 
                            Vrednosti blizu nič (-0,1 do 0,1) na splošno ustrezajo pustim območjem kamna, peska ali snega. 
                            Nizke, pozitivne vrednosti predstavljajo grmičevje in travnike (približno 0,2 do 0,4), 
                            visoke vrednosti pa označujejo zmerne in tropske deževne gozdove (vrednosti, ki se približujejo 1).
                            </p>
                        </div>
                    </Card>
                }
            </div>
        )
    };
};

export default withStyles(styles)(InfoSoilTemperature);
