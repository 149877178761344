import React, {Component} from 'react';
import i18n from "../../../../i18n/i18n";
import Select from 'react-select';

class SeasonSelect extends Component {

        handleChange = (selectedOptions) => {
                const { onSeasonSelect } = this.props;
                const values = selectedOptions.map(option => option.value);
                onSeasonSelect(values);
        };

        render() {
          const { selectedSeasons, seasons, onSeasonSelect } = this.props;
          const options = seasons.asMutable().map(season => ({value: season.id, label: season.naziv }));
          const value = options.reduce((acc, option) => {
                  if(selectedSeasons.findIndex(season => season === option.value) > -1) {
                          acc.push(option)
                  }
                  return acc;
          }, []);

          return (
            <Select
              isMulti
              name="season"
              onChange={this.handleChange}
              value={value}
              closeMenuOnSelect={false}
              options={options}
              classNamePrefix="select"
              placeholder={i18n.t('Select Season')}
            />
          )
  }

}

export default SeasonSelect;