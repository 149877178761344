import React from 'react';
import {
    CartesianGrid,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import moment from 'moment';

const xAxisProps = {
    dataKey: 'datum',
    stroke: '#E6E8EB',
    tick: { fill: '#737B8D', fontSize: '12px', lineHeight: '20px' },
    tickFormatter: d => moment(d, 'DD.MM.YYYY').format('DD MMM').toUpperCase(),
    tickMargin: 10,
};

const yAxisProps = {
    axisLine: false,
    domain: ['dataMin', 'dataMax'],
    orientation: 'right',
    scale: 'linear',
    tick: { fontSize: '12px' },
    tickFormatter: d => d + ' rsd',
    tickLine: false,
    type: 'number',
};

const styles = {
    tooltipContainer: {
        background: 'rgba(255,255,255,0.95)',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.07)',
        border: `1px solid grey`,
        borderRadius: '5px',
        padding: '10px',
    },
    tooltipTitle: {
        fontSize: '12px',
        margin: `0px`,
        fontWeight: 500
    },
    tooltipValue: {
        fontSize: '14px',
        fontWeight: 600
    }
}

const TooltipContent = props => {
    const payload = props.payload !== null && props.payload.length > 0 ? props.payload[0].payload : {};
    const { datum, cena, naziv } = payload;
    return !datum ? null : (
        <div style={styles.tooltipContainer}>
            <h5 style={styles.tooltipTitle}>{naziv}</h5>
            <hr />
            <h5 style={styles.tooltipValue}>{parseFloat(cena).toFixed(2)} rsd</h5>
            <hr />
            <h5 style={styles.tooltipTitle}>{moment(datum, 'DD.MM.YYYY').format('DD. MMMM YYYY')}</h5>
        </div>
    );
};

const MaterialPricesLineChart = ({ data, minHeight = 250 }) => (
    <ResponsiveContainer minHeight={minHeight}>
        <LineChart data={data} margin={{ top: 5, bottom: 5 }}>
            <CartesianGrid isAnimationActive={true} stroke={'#E6E8EB'} strokeDasharray={'3 3'} vertical={false} />
            <Tooltip
                content={<TooltipContent />}
                formatter={value => value}
            />
            <Line dataKey="cena" dot={false} stroke={'#04764e'} strokeWidth={2} />
            <XAxis {...xAxisProps} />
            <YAxis {...yAxisProps} />
        </LineChart>
    </ResponsiveContainer>
);

export default MaterialPricesLineChart;