import React from 'react';
import 'react-table/react-table.css';
import { Map, TileLayer, WMSTileLayer } from 'react-leaflet';
import { withStyles } from '@material-ui/core/styles';
import { GoogleLayer } from "react-leaflet-google"
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';

const styles = {
    back: { position: 'absolute', top: '2%', left: '81%', backgroundColor: 'white', border: '1px solid rgb(156 156 156)', borderRadius: 5, alignItems: 'center', justifyContent: 'center', zIndex: 1001 },
    root: { width: '100%', maxWidth: 360 },
};

export class etfarm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            osm: true, google: false,
            ndvi: true, kc: false, ndmi: false, psri: false, savi: false, lai: false, cw: false, cab: false, fapar: false, fcover: false
        };
    }

    componentDidMount() {
    }

    render() {
        const { classes } = this.props;
        return (
            <div className="page" style={{ height: '100%' }}>
                <Map
                    center={[49.406, -100.48]}
                    zoom={14}
                    style={{ height: '100vh', width: '100%' }}
                    minZoom={6}
                    maxZoom={25}
                    ref="map"
                >
                    {this.state.osm && <TileLayer attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" />}
                    {this.state.google && <GoogleLayer googlekey={'AIzaSyBuZJYdqpIR_vERdvSmk0zNRrCQMBiMaz0'} maptype={'SATELLITE'} />}

                    {this.state.ndvi && <WMSTileLayer format="image/png" transparent={true} layers={'slojevi:NDVIxxxxxx_20210511_31043_218340'} url="https://api.etfarm.ba//geoserver/wms" />}
                    {this.state.kc && <WMSTileLayer format="image/png" transparent={true} layers={'slojevi:KCxxxxxxxx_20210511_31043_218340'} url="https://api.etfarm.ba//geoserver/wms" />}
                    {this.state.ndmi && <WMSTileLayer format="image/png" transparent={true} layers={'slojevi:NDMIxxxxxx_20210511_31043_218340'} url="https://api.etfarm.ba//geoserver/wms" />}
                    {this.state.psri && <WMSTileLayer format="image/png" transparent={true} layers={'slojevi:PSRIxxxxxx_20210511_31043_218340'} url="https://api.etfarm.ba//geoserver/wms" />}
                    {this.state.savi && <WMSTileLayer format="image/png" transparent={true} layers={'slojevi:SAVIxxxxxx_20210511_31043_218340'} url="https://api.etfarm.ba//geoserver/wms" />}
                    {this.state.lai && <WMSTileLayer format="image/png" transparent={true} layers={'slojevi:LAIxxxxxxx_20210511_31043_218340'} url="https://api.etfarm.ba//geoserver/wms" />}
                    {this.state.cw && <WMSTileLayer format="image/png" transparent={true} layers={'slojevi:CWxxxxxxxx_20210511_31043_218340'} url="https://api.etfarm.ba//geoserver/wms" />}
                    {this.state.cab && <WMSTileLayer format="image/png" transparent={true} layers={'slojevi:CABxxxxxxx_20210511_31043_218340'} url="https://api.etfarm.ba//geoserver/wms" />}
                    {this.state.fapar && <WMSTileLayer format="image/png" transparent={true} layers={'slojevi:FAPARxxxxx_20210511_31043_218340'} url="https://api.etfarm.ba//geoserver/wms" />}
                    {this.state.fcover && <WMSTileLayer format="image/png" transparent={true} layers={'slojevi:FCOVERxxxx_20210511_31043_218340'} url="https://api.etfarm.ba//geoserver/wms" />}

                    {/* ["NDVIxxxxxx","KCxxxxxxxx","NDMIxxxxxx","PSRIxxxxxx","SAVIxxxxxx","LAIxxxxxxx","CWxxxxxxxx","CABxxxxxxx","FAPARxxxxx","FCOVERxxxx"] */}

                    <div className={classes.back}>
                        <List style={{ paddingBottom: '0px', paddingTop: '0px' }} subheader={<ListSubheader>Base Layers</ListSubheader>}>
                            <ListItem style={{ paddingBottom: '0px', paddingTop: '0px' }}>
                                <ListItemText id="osm" primary="Open Street Map" />
                                <Switch
                                    edge="end"
                                    onChange={() => this.setState({ google: !this.state.google, osm: !this.state.osm }, () => {
                                        this.setState({
                                            ndvi: !this.state.ndvi,
                                            kc: !this.state.kc,
                                            ndmi: !this.state.ndmi,
                                            psri: !this.state.psri,
                                            savi: !this.state.savi,
                                            lai: !this.state.lai,
                                            cw: !this.state.cw,
                                            cab: !this.state.cab,
                                            fapar: !this.state.fapar,
                                            fcover: !this.state.fcover
                                        },
                                            () => {
                                                this.setState({
                                                    ndvi: !this.state.ndvi,
                                                    kc: !this.state.kc,
                                                    ndmi: !this.state.ndmi,
                                                    psri: !this.state.psri,
                                                    savi: !this.state.savi,
                                                    lai: !this.state.lai,
                                                    cw: !this.state.cw,
                                                    cab: !this.state.cab,
                                                    fapar: !this.state.fapar,
                                                    fcover: !this.state.fcover
                                                })
                                            })
                                    })}
                                    checked={this.state.osm}
                                    inputProps={{ 'aria-labelledby': 'osm' }}
                                />
                            </ListItem>
                            <ListItem style={{ paddingBottom: '0px', paddingTop: '0px' }}>
                                <ListItemText id="google" primary="Google Map" />
                                <Switch
                                    edge="end"
                                    onChange={() => this.setState({ google: !this.state.google, osm: !this.state.osm }, () => {
                                        this.setState({
                                            ndvi: !this.state.ndvi,
                                            kc: !this.state.kc,
                                            ndmi: !this.state.ndmi,
                                            psri: !this.state.psri,
                                            savi: !this.state.savi,
                                            lai: !this.state.lai,
                                            cw: !this.state.cw,
                                            cab: !this.state.cab,
                                            fapar: !this.state.fapar,
                                            fcover: !this.state.fcover
                                        },
                                            () => {
                                                this.setState({
                                                    ndvi: !this.state.ndvi,
                                                    kc: !this.state.kc,
                                                    ndmi: !this.state.ndmi,
                                                    psri: !this.state.psri,
                                                    savi: !this.state.savi,
                                                    lai: !this.state.lai,
                                                    cw: !this.state.cw,
                                                    cab: !this.state.cab,
                                                    fapar: !this.state.fapar,
                                                    fcover: !this.state.fcover
                                                })
                                            })
                                    })}
                                    checked={this.state.google}
                                    inputProps={{ 'aria-labelledby': 'google' }}
                                />
                            </ListItem>
                        </List>
                        <List style={{ paddingBottom: '0px', paddingTop: '0px' }} subheader={<ListSubheader>Vegetation indices</ListSubheader>}>
                            <ListItem style={{ paddingBottom: '0px', paddingTop: '0px' }}>
                                <ListItemText id="ndvi" primary="NDVI" />
                                <Switch
                                    edge="end"
                                    onChange={() => this.setState({ ndvi: true, kc: false, ndmi: false, psri: false, savi: false, lai: false, cw: false, cab: false, fapar: false, fcover: false })}
                                    checked={this.state.ndvi}
                                    inputProps={{ 'aria-labelledby': 'ndvi' }}
                                />
                            </ListItem>
                            <ListItem style={{ paddingBottom: '0px', paddingTop: '0px' }}>
                                <ListItemText id="kc" primary="KC" />
                                <Switch
                                    edge="end"
                                    onChange={() => this.setState({ ndvi: false, kc: true, ndmi: false, psri: false, savi: false, lai: false, cw: false, cab: false, fapar: false, fcover: false })}
                                    checked={this.state.kc}
                                    inputProps={{ 'aria-labelledby': 'kc' }}
                                />
                            </ListItem>
                            <ListItem style={{ paddingBottom: '0px', paddingTop: '0px' }}>
                                <ListItemText id="ndmi" primary="NDMI" />
                                <Switch
                                    edge="end"
                                    onChange={() => this.setState({ ndvi: false, kc: false, ndmi: true, psri: false, savi: false, lai: false, cw: false, cab: false, fapar: false, fcover: false })}
                                    checked={this.state.ndmi}
                                    inputProps={{ 'aria-labelledby': 'ndmi' }}
                                />
                            </ListItem>
                            <ListItem style={{ paddingBottom: '0px', paddingTop: '0px' }}>
                                <ListItemText id="psri" primary="PSRI" />
                                <Switch
                                    edge="end"
                                    onChange={() => this.setState({ ndvi: false, kc: false, ndmi: false, psri: true, savi: false, lai: false, cw: false, cab: false, fapar: false, fcover: false })}
                                    checked={this.state.psri}
                                    inputProps={{ 'aria-labelledby': 'psri' }}
                                />
                            </ListItem>
                            <ListItem style={{ paddingBottom: '0px', paddingTop: '0px' }}>
                                <ListItemText id="savi" primary="SAVI" />
                                <Switch
                                    edge="end"
                                    onChange={() => this.setState({ ndvi: false, kc: false, ndmi: false, psri: false, savi: true, lai: false, cw: false, cab: false, fapar: false, fcover: false })}
                                    checked={this.state.savi}
                                    inputProps={{ 'aria-labelledby': 'savi' }}
                                />
                            </ListItem>
                            <ListItem style={{ paddingBottom: '0px', paddingTop: '0px' }}>
                                <ListItemText id="lai" primary="LAI" />
                                <Switch
                                    edge="end"
                                    onChange={() => this.setState({ ndvi: false, kc: false, ndmi: false, psri: false, savi: false, lai: true, cw: false, cab: false, fapar: false, fcover: false })}
                                    checked={this.state.lai}
                                    inputProps={{ 'aria-labelledby': 'lai' }}
                                />
                            </ListItem>
                            <ListItem style={{ paddingBottom: '0px', paddingTop: '0px' }}>
                                <ListItemText id="cw" primary="CW" />
                                <Switch
                                    edge="end"
                                    onChange={() => this.setState({ ndvi: false, kc: false, ndmi: false, psri: false, savi: false, lai: false, cw: true, cab: false, fapar: false, fcover: false })}

                                    checked={this.state.cw}
                                    inputProps={{ 'aria-labelledby': 'cw' }}
                                />
                            </ListItem>
                            <ListItem style={{ paddingBottom: '0px', paddingTop: '0px' }}>
                                <ListItemText id="cab" primary="CAB" />
                                <Switch
                                    edge="end"
                                    onChange={() => this.setState({ ndvi: false, kc: false, ndmi: false, psri: false, savi: false, lai: false, cw: false, cab: true, fapar: false, fcover: false })}
                                    checked={this.state.cab}
                                    inputProps={{ 'aria-labelledby': 'cab' }}
                                />
                            </ListItem>
                            <ListItem style={{ paddingBottom: '0px', paddingTop: '0px' }}>
                                <ListItemText id="fapar" primary="FAPAR" />
                                <Switch
                                    edge="end"
                                    onChange={() => this.setState({ ndvi: false, kc: false, ndmi: false, psri: false, savi: false, lai: false, cw: false, cab: false, fapar: true, fcover: false })}
                                    checked={this.state.fapar}
                                    inputProps={{ 'aria-labelledby': 'fapar' }}
                                />
                            </ListItem>
                            <ListItem style={{ paddingBottom: '0px', paddingTop: '0px' }}>
                                <ListItemText id="fcover" primary="FCOVER" />
                                <Switch
                                    edge="end"
                                    onChange={() => this.setState({ ndvi: false, kc: false, ndmi: false, psri: false, savi: false, lai: false, cw: false, cab: false, fapar: false, fcover: true })}
                                    checked={this.state.fcover}
                                    inputProps={{ 'aria-labelledby': 'fcover' }}
                                />
                            </ListItem>
                        </List>

                    </div>
                </Map>
            </div>
        )
    }
};

export default withStyles(styles)(etfarm);
