import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Card } from '@material-ui/core';
import { OpenInNew, GetApp } from '@material-ui/icons';
import axios from './../../../utils/AxiosWrapper';
import { styles } from './MeteogramStyles';

export class Meteogram extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    };

    openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    };

    downloadImage = (meteogram, name) => {
        let user = this.getUser();

        let language = '';
        if (user.id_jezik === 1) language = 'sr';
        else language = 'en';

        let sowing = '';
        if (this.props.sowing === true) sowing = '&look=all';

        axios.get(`https://my.meteoblue.com/visimage/meteogram_${meteogram}_hd?apikey=OUP4p7JqGlARwkMF&lat=${this.props.zoom_data.center[0]}&lon=${this.props.zoom_data.center[1]}&lang=${language}${sowing}`,
            { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json', 'Accept': 'application/png' } })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${name}.png`); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => console.log(error));
    };

    render() {
        let user = this.getUser();
        let language = '';
        if (user.id_jezik === 1) language = 'sr';
        else language = 'en';
        const { classes } = this.props;
        let sowing = '';
        if (this.props.sowing === true) sowing = '&look=all';
        return (
            <div className={classes.meteogram_container}>
                <Card className={classes.meteogram_card}>
                    <div className={classes.meteogram_size}>
                        <div className={classes.meteogram_title}>
                            <p className={classes.title}>{this.props.name}</p>
                        </div>
                        <div className={classes.meteogram_thumbnail}>
                            <img className={classes.thumbnail} src={`./meteograms/${this.props.meteogram}.png`} />
                        </div>
                        <div className={classes.links_container}>
                            <div className={classes.link_container}>
                                <OpenInNew className={classes.link} onClick={() => this.openInNewTab(`https://my.meteoblue.com/visimage/meteogram_${this.props.meteogram}_hd?apikey=OUP4p7JqGlARwkMF&lat=${this.props.zoom_data.center[0]}&lon=${this.props.zoom_data.center[1]}&lang=${language}${sowing}`)} />
                            </div>
                            <div className={classes.link_container}>
                                <GetApp className={classes.link} onClick={() => this.downloadImage(this.props.meteogram, this.props.name)} />
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
};

export default withStyles(styles)(Meteogram);