/**
 * Created by pajicv on 5/10/18.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TileLayer, LayersControl } from 'react-leaflet';

import { GoogleLayer } from 'react-leaflet-google';

import * as LayerSelectors from '../selectors/LayerSelectors';

const { BaseLayer } = LayersControl;

class BaseLayers extends Component {

    render() {

        const {layers, removeLayer, removeLayerControl, addBaseLayer} = this.props;

        return [
            <BaseLayer checked={ layers.osm.visible }
                       name={layers.osm.name}
                       addBaseLayer={addBaseLayer}
                       removeLayer={removeLayer}
                       removeLayerControl={removeLayerControl}>
                <TileLayer
                    attribution={layers.osm.attribution}
                    url={layers.osm.url}
                />
            </BaseLayer>,
                <BaseLayer checked={ layers.google.visible }
                           name="Google"
                           addBaseLayer={addBaseLayer}
                           removeLayer={removeLayer}
                           removeLayerControl={removeLayerControl}>
                    <GoogleLayer googlekey={layers.google.key} maptype={layers.google.maptype}/>
                </BaseLayer>

        ];

    }
}

function mapStateToProps(state) {
    return {
        layers: LayerSelectors.getBaseLayers(state)
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BaseLayers);
