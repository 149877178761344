import moment from 'moment';

export const formatForecast = (res) => {
    let data = res.data.data_1h;
    let dates = res.data.data_1h.time;
    let obj = {};
    let short_dates = [];

    if (dates) short_dates = dates.map(date => moment(date).utc().local().format('DD/MM, HH:mm'));

    if (data && short_dates) {
        obj = {
            dates: short_dates,
            convective_precipitation: data.convective_precipitation ? data.convective_precipitation : [],
            felttemperature: data.felttemperature ? data.felttemperature : [],
            isdaylight: data.isdaylight ? data.isdaylight : [],
            pictocode: data.pictocode ? data.pictocode : [],
            precipitation: data.precipitation ? data.precipitation : [],
            precipitation_probability: data.precipitation_probability ? data.precipitation_probability : [],
            rainspot: data.rainspot ? data.rainspot : [],
            relativehumidity: data.relativehumidity ? data.relativehumidity : [],
            sealevelpressure: data.sealevelpressure ? data.sealevelpressure : [],
            snowfraction: data.snowfraction ? data.snowfraction : [],
            temperature: data.temperature ? data.temperature : [],
            uvindex: data.uvindex ? data.uvindex : [],
            winddirection: data.winddirection ? data.winddirection : [],
            windspeed: data.windspeed ? data.windspeed : [],
            dew_point_temperature: data.dewpointtemperature ? data.dewpointtemperature : [],
            evapotranspiration: data.evapotranspiration ? data.evapotranspiration : [],
            leaf_wetness: data.leafwetnessindex ? data.leafwetnessindex : [],
            potential_evapotranspiration: data.potentialevapotranspiration ? data.potentialevapotranspiration : [],
            reference_evapotranspiration_fao: data.referenceevapotranspiration_fao ? data.referenceevapotranspiration_fao : [],
            sensible_heat_flux: data.sensibleheatflux ? data.sensibleheatflux : [],
            skin_temperature: data.skintemperature ? data.skintemperature : [],
            soil_moisture: data.soilmoisture_0to10cm ? data.soilmoisture_0to10cm : [],
            soil_temperature: data.soiltemperature_0to10cm ? data.soiltemperature_0to10cm : [],
            wet_bulb_temperature: data.wetbulbtemperature ? data.wetbulbtemperature : [],
        };
    };

    return obj;
};

export const formatWorkPlanningData = (res) => {
    let obj = {};

    if (res[0].data !== undefined) {
        if (res[0].data.data_1h !== undefined) {

            let data = res[0].data.data_1h;
            let dates = res[0].data.data_1h.time;
            let short_dates = [];

            if (dates) short_dates = dates.map(date => moment(date).utc().local().format('DD/MM, HH:mm'));

            if (data && short_dates) {
                obj = {
                    dates: short_dates,
                    sowing_barley: data.sowing_barley ? data.sowing_barley : [],
                    sowing_cotton: data.sowing_cotton ? data.sowing_cotton : [],
                    sowing_maize: data.sowing_maize ? data.sowing_maize : [],
                    sowing_potato: data.sowing_potato ? data.sowing_potato : [],
                    sowing_rapseed: data.sowing_rapseed ? data.sowing_rapseed : [],
                    sowing_riceindica: data.sowing_riceindica ? data.sowing_riceindica : [],
                    sowing_ricejaponi: data.sowing_ricejaponi ? data.sowing_ricejaponi : [],
                    sowing_sorghum: data.sowing_sorghum ? data.sowing_sorghum : [],
                    sowing_soybean: data.sowing_soybean ? data.sowing_soybean : [],
                    sowing_sugarbeets: data.sowing_sugarbeets ? data.sowing_sugarbeets : [],
                    sowing_wheat: data.sowing_wheat ? data.sowing_wheat : [],
                };
            };
        }
    }

    return obj;
}

export const formatSpray = (res) => {
    let obj = {};

    if (res[0].data !== undefined) {
        if (res[0].data.data_1h !== undefined) {

            let data = res[0].data.data_1h;
            let dates = res[0].data.data_1h.time;
            let short_dates = [];

            if (dates) short_dates = dates.map(date => moment(date).utc().local().format('DD/MM, HH:mm'));

            if (data && short_dates) {
                obj = {
                    dates: short_dates,
                    spraywindow: data.spraywindow ? data.spraywindow : [],
                };
            };
        }
    }

    return obj;
}