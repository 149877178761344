import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import i18n from '../../i18n/i18n';
import Select from 'react-select';
import { Fab, Button, Typography } from '@material-ui/core';
import { wentWrong } from "../../utils/ToastConfig";
import axios from '../../utils/AxiosWrapper';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import SensorDialog from './SensorDialog';
import * as Icons from '@material-ui/icons';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

const styles = {
    back: { position: 'absolute', top: 80, left: 76, width: 30, height: 30, backgroundColor: 'white', border: '1px solid rgb(156 156 156)', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1001, "&:hover": { backgroundColor: '#ccc', border: '1px solid #333', cursor: 'pointer' }, },
    back2: { position: 'absolute', top: 11, left: 116, width: 150, height: 50, borderRadius: 3, backgroundColor: 'white', border: '1px solid rgb(156 156 156)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1001, "&:hover": { cursor: 'pointer' }, },
    back3: { position: 'absolute', top: 11, left: 277, borderRadius: 3, backgroundColor: 'white', border: '1px solid rgb(156 156 156)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1001, "&:hover": { cursor: 'pointer' }, },
    open_button: { backgroundColor: '#58B4E5', color: 'white', marginLeft: '10px' },
};

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    iconSize: [20, 32.8], // [width, height]
    shadowSize: [32.8, 32.8],
    iconAnchor: [10, 32.8], // [point width, point height (inverse)]
    shadowAnchor: [9, 32.8],
});

export class SensorMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            center: [45.64, 19.7],
            zoom: 9, row: {},
            sensors: [], open: false,
            sensor_temp: null,
            stateType: [],
            states: [39, 19, 82, 166, 22, 873, 879, 890, 911, 1023],
            filter: null
        };
    }

    componentDidMount() {

        let user = this.getUser();
        if (
            user.id_opstina === 39 ||
            user.id_opstina === 19 ||
            user.id_opstina === 82 ||
            user.id_opstina === 166 ||
            user.id_opstina === 22 ||
            user.id_opstina === 873 ||
            user.id_opstina === 879 ||
            user.id_opstina === 890 ||
            user.id_opstina === 911 ||
            user.id_opstina === 1023
            
        ) {
            this.setState({ filter: user.id_opstina }, () => { this.getData(user.id_opstina); })
        }
        else this.getData();

        this.getByURL('opstina/drop_down', 'stateType');
        axios.get('api/events/add', { params: { id_vrsta_dogadjaja: 4 } })
    }

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }

    getData = (filter) => {
        let user = this.getUser();

        axios.get('map/read_map', { params: { filter: filter, id_klijent: user.id_klijent } })
            .then(res => {
                this.setState({ sensors: res.data.data });
                if (filter !== null) {
                    let x = res.data.data.map(item => Number(item.x))
                    let y = res.data.data.map(item => Number(item.y))
                    let minx = Math.min(...x);
                    let miny = Math.min(...y);
                    let maxx = Math.max(...x);
                    let maxy = Math.max(...y);
                    let centerx = (minx + maxx) / 2;
                    let centery = (miny + maxy) / 2;

                    this.map.leafletElement.flyTo([centery, centerx], 10, { animate: true, duration: 0.5 })
                }
                else this.map.leafletElement.flyTo([45.64, 19.7], 9, { animate: true, duration: 0.5 });
            })
            .catch(err => { console.log(err) })
    }

    onTableClick = () => this.props.history.push('/sensors');
    handleClick = (item) => { this.setState({ open: !this.state.open, row: item }) };
    handleChange = name => selectedOption => { this.setState({ [name]: selectedOption }); }

    getByURL(url, obj) {
        axios.get(`api/${url}`)
            .then(res => this.setState({ [obj]: res.data.data }))
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 2 (getByURL): ', error, ', URL: ', url);
            })
    }

    render() {

        let userMy = this.getUser()

        const marker = this.state.sensors;
        const { classes } = this.props;
        let stateType = this.state.stateType.filter(item => item.id === 39 || item.id === 19 || item.id === 82 || item.id === 166 || item.id === 22 || item.id === 873 || item.id === 879 || item.id === 890 || item.id === 911 || item.id === 1023 )
        return (
            <div className="page" style={{ height: '100%' }}>
                {this.state.sensors.length > 0 &&
                    <Map
                        center={this.state.center}
                        zoom={this.state.zoom}
                        style={{ height: '100vh', width: '100%' }}
                        minZoom={9}
                        maxZoom={20}
                        //bounds={[[44.75, 17.44], [46.5, 21.83]]}
                        //maxBounds={[[44.75, 17.44], [46.5, 21.83]]}
                        ref={(c) => { this.map = c }}
                    >
                        <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                        />
                        {marker !== undefined && marker.map(((item, i) => {
                            return <Marker key={i} position={[Number(item.y), Number(item.x)]} onClick={() => {
                                this.setState({ sensor_temp: null });
                                let data_path = '/data/optimized/' + item.uid + '/hourly/last/1h';
                                axios.post('/api/testMeteo/meteo', { path: data_path, method: "GET", userIdRegion: userMy.id_region })
                                    .then(res => { this.setState({ sensor_temp: res.data.data.data["18_X_X_506"].aggr.avg[0] }); })
                                    .catch(err => { console.log(err) })
                            }}>
                                <Popup className="popup-sensor">
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography style={{ fontSize: '18px' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {item.naziv.substring(0, 15)}
                                                {item.naziv.length > 15 && '...'}
                                                {this.state.sensor_temp !== null &&
                                                    <Typography style={{ fontSize: '18px', marginTop: '0px', marginBottom: '0px' }}> {this.state.sensor_temp}°C</Typography>
                                                }
                                            </div>
                                        </Typography>
                                        <Button className={classes.open_button} onClick={() => { this.handleClick(item); }}>{i18n.t('More')}</Button>
                                    </div>
                                </Popup>
                            </Marker>
                        }))}
                    </Map>
                }

                <div className={classes.back} onClick={() => this.onTableClick()} >
                    <Typography style={{ fontSize: '22px' }}>←</Typography>
                </div>
                <div className={classes.back2}  >
                    <TextField
                        label={i18n.t('State')}
                        select
                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                        onChange={(e) => { this.setState({ filter: e.target.value }, () => { this.getData(e.target.value); }); }}
                        InputLabelProps={{ shrink: true }}
                        value={this.state.filter}
                    >
                        <option value='' />
                        {stateType.map((state) => <option key={state.id} value={state.id}>{state.naziv}</option>)}
                    </TextField>
                </div>
                <div>
                    <Button className={classes.back3} onClick={() => { this.setState({ filter: null }, () => { this.getData(this.state.filter) }) }}>{i18n.t('Reset')}</Button>
                </div>
                {this.state.open && <SensorDialog open={this.state.open} handleClick={this.handleClick} row={this.state.row} />}
            </div>
        )
    }
};

export default withStyles(styles)(SensorMap);
