import immutable from 'seamless-immutable';
import { Type as ApplicationType } from '../../actions/financial/ApplicationActions';


export const INITIAL_STATE = immutable({
    applications: [],
    applicationsFetching: false,
    applicationsFetchingFailed: false,
    applicationsPages: 1,
    applicationsObj: {},

    appPosting: false,
    appPostingSuccess: false,
    appPostingFailed: false,

    appStatuses: [],
    appStatusesFetching: false,
    appStatusesFetchingFailed: false,

    dashboardItems: {},
    dashboardFetching: false,
    dashboardFetchingFailed: false,

    applicationDocuments: [],
    applicationDocumentsFetching: false,
    applicationDocumentsFetchingFailed: false,
});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case ApplicationType.SET_INITIAL_STATE:
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;

        case ApplicationType.SET_APPLICATION_ROW_IN_REDUCER: {
            const data = action.rowInfo.asMutable();

            return state.merge({ [action.name]: data })
            break;
        }

        case ApplicationType.GET_APP_STATUS_CALL: {
            const appStatusesFetching = true;
            return state.merge({ appStatusesFetching });
            break;
        }

        case ApplicationType.GET_APP_STATUS_SUCCESS: {
            const appStatuses = action.data.data;
            const appStatusesFetching = false;
            return state.merge({ appStatuses, appStatusesFetching });
            break;
        }

        case ApplicationType.GET_APP_STATUS_FAILED: {
            const appStatusesFetchingFailed = true;
            const appStatusesFetching = false;
            return state.merge({ appStatusesFetching, appStatusesFetchingFailed });
            break;
        }

        case ApplicationType.GET_DASHBOARD_CALL: {
            const dashboardFetching = true;
            return state.merge({ dashboardFetching });
            break;
        }

        case ApplicationType.GET_DASHBOARD_SUCCESS: {
            const dashboardItems = action.data;
            const dashboardFetching = false;
            return state.merge({ dashboardItems, dashboardFetching });
            break;
        }

        case ApplicationType.GET_DASHBOARD_FAILED: {
            const dashboardFetchingFailed = true;
            const dashboardFetching = false;
            return state.merge({ dashboardFetching, dashboardFetchingFailed });
            break;
        }

        case ApplicationType.UPDATE_SUBSIDIE_CALL: {
            const appPosting = true;
            return state.merge({ appPosting });
            break;
        }

        case ApplicationType.UPDATE_SUBSIDIE_SUCCESS: {
            const appPostingSuccess = true;
            const appPosting = false;
            return state.merge({ appPostingSuccess, appPosting });
            break;
        }

        case ApplicationType.UPDATE_SUBSIDIE_FAILED: {
            const appPostingFailed = true;
            const appPosting = false;
            return state.merge({ appPosting, appPostingFailed });
            break;
        }


        case ApplicationType.GET_APPLICATIONS_CALL: {
            const applicationsFetching = true;
            return state.merge({ applicationsFetching });
            break;
        }

        case ApplicationType.GET_APPLICATIONS_SUCCESS: {
            const applications = action.data.data;
            const applicationsFetching = false;
            const applicationsPages = action.data.total_pages
            return state.merge({ applications, applicationsFetching, applicationsPages });
            break;
        }

        case ApplicationType.GET_APPLICATIONS_FAILED: {
            const applicationsFetchingFailed = true;
            const applicationsFetching = false;
            return state.merge({ applicationsFetching, applicationsFetchingFailed });
            break;
        }

        case ApplicationType.APPLICATION_DOCUMENTS_CALL: {
            const applicationDocumentsFetching = true;
            return state.merge({ applicationDocumentsFetching });
            break;
        }

        case ApplicationType.APPLICATION_DOCUMENTS_SUCCESS: {
            const applicationDocuments = action.data.data;
            const applicationDocumentsFetching = false;
            return state.merge({ applicationDocuments, applicationDocumentsFetching });
            break;
        }

        case ApplicationType.APPLICATION_DOCUMENTS_FAILED: {
            const applicationDocumentsFetchingFailed = true;
            const applicationDocumentsFetching = false;
            return state.merge({ applicationDocumentsFetching, applicationDocumentsFetchingFailed });
            break;
        }


        default:
            return state;
    }
}