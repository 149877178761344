import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Card } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './ImageryStyles';
import savi from './../satelliteinfo/savi.png';
import savi2 from './../satelliteinfo/savi2.png';
import savi3 from './../satelliteinfo/savi3.png';

export class SAVI extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.props.language === 1 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Prilagođeni indeks vegetacije za zemljište (SAVI) pruža informacije o površinama sa niskom gustoćom vegetacije izuzimajući površinu zemljišta.
                            </p>
                            <p className={classes.paragraph}>
                            Istraživanja su pokazala da empirijski podaci NDVI-ja mogu biti nestabilni jer variraju u zavisnosti od boje zemljišta i prisutnosti vlage, 
                            funkcije dvosmerne refleksije, atmosferskih uslova i prisutnosti mrtve materije u samim biljkama. 
                            Stoga SAVI predstavlja modifikaciju NDVI-ja radi otklanjanja uticaja atmosfere i zemljišta. 
                            </p>
                            <div className={classes.image}>
                                <img src={savi} />
                            </div>
                            <div className={classes.image}>
                                <img src={savi2} />
                            </div>
                            <div className={classes.image}>
                                <img src={savi3} />
                            </div>
                        </div>
                    </Card>
                }
                {this.props.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            The Soil-Adjusted Vegetation Index (SAVI) provides information about areas with low vegetation density, excluding the soil surface. 
                            </p>
                            <p className={classes.paragraph}>
                            Research has shown that empirical NDVI data can be unstable due to variations in soil color, moisture content, 
                            bidirectional reflectance function, atmospheric conditions, and the presence of dead matter within the plants. Therefore, 
                            SAVI represents a modification of NDVI to eliminate the influence of atmosphere and soil.
                            </p>
                            <div className={classes.image}>
                                <img src={savi} />
                            </div>
                            <div className={classes.image}>
                                <img src={savi2} />
                            </div>
                            <div className={classes.image}>
                                <img src={savi3} />
                            </div>
                        </div>
                    </Card>
                }
                {this.props.language === 4 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Prilagođeni indeks vegetacije tla (SAVI) pruža informacije o područjima s niskom gustoćom vegetacije isključujući površinu tla.
                            </p>
                            <p className={classes.paragraph}>
                            Istraživanja su pokazala da empirijski NDVI podaci mogu biti nestabilni jer variraju ovisno o boji tla i prisutnosti vlage, 
                            funkciji dvosmjerne refleksije, atmosferskim uvjetima i prisutnosti mrtve tvari u samim biljkama. 
                            Stoga SAVI predstavlja modifikaciju NDVI-ja radi uklanjanja utjecaja atmosfere i tla.
                            </p>
                            <div className={classes.image}>
                                <img src={savi} />
                            </div>
                            <div className={classes.image}>
                                <img src={savi2} />
                            </div>
                            <div className={classes.image}>
                                <img src={savi3} />
                            </div>
                        </div>
                    </Card>
                }
                {this.props.language === 5 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Prilagojeni indeks kopenske vegetacije (SAVI) zagotavlja informacije o območjih z nizko gostoto vegetacije, razen kopenske površine.
                            </p>
                            <p className={classes.paragraph}>
                            Raziskave so pokazale, da so empirični podatki NDVI lahko nestabilni, ker se razlikujejo glede na barvo tal in prisotnost vlage,
                            funkcije dvosmernega odboja, atmosferske razmere in prisotnost mrtve snovi v samih rastlinah.
                            Zato je SAVI modifikacija NDVI za odpravo atmosferskih in talnih vplivov.
                            </p>
                            <div className={classes.image}>
                                <img src={savi} />
                            </div>
                            <div className={classes.image}>
                                <img src={savi2} />
                            </div>
                            <div className={classes.image}>
                                <img src={savi3} />
                            </div>
                        </div>
                    </Card>
                }
            </div>
        )
    }
};

export default withStyles(styles)(SAVI);