import React from 'react';
import { Card, withStyles, } from '@material-ui/core';
import axios from '../../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { styles } from '../InfoStyles';

export class InfoSowingWindowCanola extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 1
        };
    };

    componentDidMount() {
        axios.get('api/map/language')
            .then(res => {
                let response = res.data.data[0].id_jezik;
                if (response) this.setState({ language: response });
            })
            .catch(err => { console.log(err) })
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.language === 1 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Minimalna temperatura zemljišta za setvu uljane repice je 5॰C. Ukoliko je zemljište hladnije smanjuje se nicanje i rast mladih biljaka. Temperatura od 5-10॰C smatra se optimalnom za setvu. Minimalna temperatura za klijanje inosi 5°C a optimalna 25°C. 
                            Pri temperaturi od 14-17°C i povoljnom stanju vlage u zemljištu, repica niče nakon 4-6 dana.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            The minimum soil temperature for sowing oilseed rape is 5°C. If the soil is colder, sprouting and growth of young plants is reduced. A temperature of 5-10°C is considered optimal for sowing. The minimum temperature for germination is 5°C, and the optimal temperature is 25°C. 
                            At a temperature of 14-17°C and favorable soil moisture conditions, oilseed rape germinates within 4-6 days.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 4 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Minimalna temperatura tla za sjetvu uljane repice je 5°C. Ako je tlo hladnije, smanjeno je nicanje i rast mladih biljaka. Temperatura od 5-10°C smatra se optimalnom za sjetvu. Minimalna temperatura za klijanje je 5°C, a optimalna 25°C. 
                            Pri temperaturi od 14-17°C i povoljnim uvjetima vlage u tlu, uljana repica niče nakon 4-6 dana.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 5 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Minimalna temperatura tal za setev oljne ogrščice je 5°C. Če so tla hladnejša, se zmanjša vznik in rast mladih rastlin. Optimalna temperatura za setev je 5-10 ° C. Minimalna temperatura za kalitev je 5°C, optimalna pa 25°C.
                            Pri temperaturi 14-17°C in ugodnih razmerah vlažnosti tal repica požene po 4-6 dneh.

                            </p>
                        </div>
                    </Card>
                }
            </div>
        )
    };
};

export default withStyles(styles)(InfoSowingWindowCanola);