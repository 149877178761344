import React from 'react';
import i18n from '../../i18n/i18n';
import 'react-toastify/dist/ReactToastify.css';
import axios from './../../utils/AxiosWrapper';
import { zoomFormat } from './../Maps/ZoomAndCenter';
import { Button, IconButton, Dialog, DialogContent, DialogTitle, List, ListItem, ListItemText, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './DialogStyles';
import styled from 'react-emotion';

import DailyForecastWrapper from './Daily/DailyForecastWrapper';
import Meteograms from './Meteograms/Meteograms';
import { ForecastTemperature, ForecastSoilTemperature, ForecastDewPoint, ForecastTemperatureHumidity, ForecastWetBulbTemperature, ForecastSkinTemperature, ForecastPrecipitation, ForecastConvectivePrecipitation, ForecastPrecipitationHumidity, ForecastSnow, ForecastHumidityTemperature, ForecastSoilMoisture, ForecastET, ForecastPotentialET, ForecastReferenceET, ForecastWind, ForecastPressure, ForecastHeatFlux, ForecastUVIndex } from './Forecast';
import { SowingBarley, SowingCotton, SowingMaize, SowingPotato, SowingRapeseed, SowingRiceIndica, SowingRiceJaponi, SowingWheat, SowingSugarbeets, SowingSoybean, SowingSorghum, Spraying } from './Work planning';

// const NewDialog = styled('Dialog')`
//  max-height: '100%'
//   }
// `
export class MeteoDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstRender: true,
            currentPage: '', currentPageGroup: '', currentData: '',
            zoom_data: null,

            dailyCalled: false, daily: {}, // forecast basic-day
            forecastCalled: false, forecast: {}, // forecast basic-1h
            agroCalled: false, agro: {}, // forecast agro-1h
            sowingCalled: false, sowing: {}, // agromodelsowing-1h
            sprayCalled: false, spray: {}, // agromodelspray-1h
        };
    };

    componentDidMount() {
        this.setState({ currentPage: 'daily/daily', currentPageGroup: 'daily', currentData: 'daily' });

        axios.get('api/field/meteo_field', { params: { id_field: this.props.row.id } })
            .then(res => {
                let parsed = JSON.parse(res.data.data.geojson);
                let zoom_data = zoomFormat(parsed);
                this.setState({ zoom_data });
            })
            .catch(err => { console.log(err); })
    };

    onTabClick = (data, page, pageGroup) => { this.setState({ currentData: data, currentPage: page, currentPageGroup: pageGroup }) };
    onPageClick = (page) => { this.setState({ currentPage: page }) };

    handleCalled = (response, type, data) => {
        this.setState({ [data]: response }, () => {
            if (this.state[type] === false) this.setState({ [type]: true });
        })
    };

    dataGroupHandler = (group) => {
        if (this.state.currentPageGroup !== group) this.setState({ currentPageGroup: group });
        else if (this.state.currentPageGroup === group) this.setState({ currentPageGroup: '' });
    };

    render() {
        const { classes } = this.props;
        return (
            <div style={{ padding: 20 }}>
                {this.state.zoom_data && <Dialog PaperProps={{ style: { maxHeight: '100%' } }} open={this.props.open} onClose={this.props.handleClick}
                    fullWidth={true} disableBackdropClick maxWidth="lg" aria-labelledby="responsive-dialog-title2">
                    <DialogTitle disableTypography={true} style={{ background: '#04764e', maxHeight: '100%' }} className="responsive-dialog-title2">
                        <p style={{ marginLeft: '20px' }}>{this.props.row.naziv}</p>
                        <div style={{ display: 'flex', alignSelf: 'center', width: '76%' }}>
                            <Button onClick={() => { this.onTabClick('daily', 'daily/daily', 'daily'); }} className={this.state.currentData === 'daily' ? classes.pressedTab : classes.tab}>{i18n.t('Weekly')}</Button>
                            <Button onClick={() => { this.onTabClick('forecast', 'forecast/temperature', 'forecast'); }} className={this.state.currentData === 'forecast' ? classes.pressedTab : classes.tab}>{i18n.t('Forecast')}</Button>
                            <Button onClick={() => { this.onTabClick('work', 'work/sowing_wheat', 'sowing'); }} className={this.state.currentData === 'work' ? classes.pressedTab_work : classes.tab_work}>{i18n.t('Work planning')}</Button>
                            {/* <Button onClick={() => { this.onTabClick('meteogram', 'meteogram/meteogram', 'meteogram'); }} className={this.state.currentData === 'meteogram' ? classes.pressedTab : classes.tab}>{i18n.t('Meteograms')}</Button> */}
                            <IconButton onClick={this.props.handleClick} style={{ marginLeft: '25%' }}>
                                <span className="icon-cancel-circle closeIcon" />
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent style={{ padding: '0px', backgroundColor: '#f7f7f7', overflow: 'hidden' }}>
                        <div>
                            {this.state.currentData === 'daily' &&
                                <DailyForecastWrapper row={this.props.row} called={[this.state.dailyCalled]} data={this.state.daily} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} />
                            }
                            {this.state.currentData === 'forecast' &&
                                <div className={classes.container}>
                                    <div className={classes.drawer}>
                                        <List component="nav" aria-labelledby="nested-list-subheader" className={classes.list}>
                                            <ListItem button onClick={() => { this.dataGroupHandler('forecast'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Forecast')} />
                                                {this.state.currentPageGroup === 'forecast' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'forecast'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='forecast/temperature' title={i18n.t('Temperature')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='forecast/soil_temperature' title={i18n.t('Soil temperature')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='forecast/dew_point' title={i18n.t('Dew point')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    {/* <ListItemSyled page='forecast/temperature_humidity' title={i18n.t('Temperature and humidity')} classes={classes} onPageClick={this.onPageClick} state={this.state} /> */}
                                                    <ListItemSyled page='forecast/wet_bulb_temperature' title={i18n.t('Wet bulb temperature')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='forecast/skin_temperature' title={i18n.t('Skin temperature')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('precipitation'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Precipitation')} />
                                                {this.state.currentPageGroup === 'precipitation' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'precipitation'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='forecast/precipitation' title={i18n.t('Precipitation and leaf wetness')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='forecast/convective_precipitation' title={i18n.t('Convective precipitation')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='forecast/precipitation_humidity' title={i18n.t('Precipitation and humidity')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='forecast/snow' title={i18n.t('Snow')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('humidity'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Humidity')} />
                                                {this.state.currentPageGroup === 'humidity' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'humidity'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='forecast/humidity_temperature' title={i18n.t('Humidity and temperature')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='forecast/soil_moisture' title={i18n.t('Soil moisture')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('evapotranspiration'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Evapotranspiration')} />
                                                {this.state.currentPageGroup === 'evapotranspiration' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'evapotranspiration'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='forecast/evapotranspiration' title={i18n.t('Evapotranspiration')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='forecast/potential_evapotranspiration' title={i18n.t('Potential ET')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='forecast/reference_evapotranspiration' title={i18n.t('Reference ET')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('wind'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Wind')} />
                                                {this.state.currentPageGroup === 'wind' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'wind'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='forecast/wind' title={i18n.t('Wind speed and direction')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('pressure'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Pressure')} />
                                                {this.state.currentPageGroup === 'pressure' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'pressure'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='forecast/pressure' title={i18n.t('Sea level pressure')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('other'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Other')} />
                                                {this.state.currentPageGroup === 'other' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'other'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='forecast/heat_flux' title={i18n.t('Sensible heat flux')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='forecast/uv_index' title={i18n.t('UV Index')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                        </List>
                                    </div>
                                    {this.state.currentPage === 'forecast/temperature' && <div className={classes.page}> <ForecastTemperature row={this.props.row} called={[this.state.forecastCalled]} data={[this.state.forecast]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}
                                    {this.state.currentPage === 'forecast/soil_temperature' && <div className={classes.page}> <ForecastSoilTemperature row={this.props.row} called={[this.state.forecastCalled, this.state.agroCalled]} data={[this.state.forecast, this.state.agro]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}
                                    {this.state.currentPage === 'forecast/dew_point' && <div className={classes.page}> <ForecastDewPoint row={this.props.row} called={[this.state.forecastCalled, this.state.agroCalled]} data={[this.state.forecast, this.state.agro]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}
                                    {this.state.currentPage === 'forecast/temperature_humidity' && <div className={classes.page}> <ForecastTemperatureHumidity row={this.props.row} called={[this.state.forecastCalled]} data={[this.state.forecast]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}
                                    {this.state.currentPage === 'forecast/wet_bulb_temperature' && <div className={classes.page}> <ForecastWetBulbTemperature row={this.props.row} called={[this.state.forecastCalled, this.state.agroCalled]} data={[this.state.forecast, this.state.agro]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}
                                    {this.state.currentPage === 'forecast/skin_temperature' && <div className={classes.page}> <ForecastSkinTemperature row={this.props.row} called={[this.state.forecastCalled, this.state.agroCalled]} data={[this.state.forecast, this.state.agro]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}

                                    {this.state.currentPage === 'forecast/precipitation' && <div className={classes.page}> <ForecastPrecipitation row={this.props.row} called={[this.state.forecastCalled, this.state.agroCalled]} data={[this.state.forecast, this.state.agro]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}
                                    {this.state.currentPage === 'forecast/convective_precipitation' && <div className={classes.page}> <ForecastConvectivePrecipitation row={this.props.row} called={[this.state.forecastCalled]} data={[this.state.forecast]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}
                                    {this.state.currentPage === 'forecast/precipitation_humidity' && <div className={classes.page}> <ForecastPrecipitationHumidity row={this.props.row} called={[this.state.forecastCalled]} data={[this.state.forecast]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}
                                    {this.state.currentPage === 'forecast/snow' && <div className={classes.page}> <ForecastSnow row={this.props.row} called={[this.state.forecastCalled]} data={[this.state.forecast]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}

                                    {this.state.currentPage === 'forecast/humidity_temperature' && <div className={classes.page}> <ForecastHumidityTemperature row={this.props.row} called={[this.state.forecastCalled]} data={[this.state.forecast]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}
                                    {this.state.currentPage === 'forecast/soil_moisture' && <div className={classes.page}> <ForecastSoilMoisture row={this.props.row} called={[this.state.forecastCalled, this.state.agroCalled]} data={[this.state.forecast, this.state.agro]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}

                                    {this.state.currentPage === 'forecast/evapotranspiration' && <div className={classes.page}> <ForecastET row={this.props.row} called={[this.state.agroCalled]} data={[this.state.agro]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}
                                    {this.state.currentPage === 'forecast/potential_evapotranspiration' && <div className={classes.page}> <ForecastPotentialET row={this.props.row} called={[this.state.agroCalled]} data={[this.state.agro]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}
                                    {this.state.currentPage === 'forecast/reference_evapotranspiration' && <div className={classes.page}> <ForecastReferenceET row={this.props.row} called={[this.state.agroCalled]} data={[this.state.agro]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}

                                    {this.state.currentPage === 'forecast/wind' && <div className={classes.page}> <ForecastWind row={this.props.row} called={[this.state.forecastCalled]} data={[this.state.forecast]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}

                                    {this.state.currentPage === 'forecast/pressure' && <div className={classes.page}> <ForecastPressure row={this.props.row} called={[this.state.forecastCalled]} data={[this.state.forecast]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}

                                    {this.state.currentPage === 'forecast/heat_flux' && <div className={classes.page}> <ForecastHeatFlux row={this.props.row} called={[this.state.agroCalled]} data={[this.state.agro]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}
                                    {this.state.currentPage === 'forecast/uv_index' && <div className={classes.page}> <ForecastUVIndex row={this.props.row} called={[this.state.forecastCalled]} data={[this.state.forecast]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}
                                </div>
                            }
                            {this.state.currentData === 'work' &&
                                <div className={classes.container}>
                                    <div className={classes.drawer}>
                                        <List component="nav" aria-labelledby="nested-list-subheader" className={classes.list}>
                                            <ListItem button onClick={() => { this.dataGroupHandler('sowing'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Sowing window')} />
                                                {this.state.currentPageGroup === 'sowing' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'sowing'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='work/sowing_wheat' title={i18n.t('Wheat')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='work/sowing_sugarbeets' title={i18n.t('Sugarbeets')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='work/sowing_soybean' title={i18n.t('Soybean')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='work/sowing_sorghum' title={i18n.t('Sorghum')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='work/sowing_ricejaponi' title={i18n.t('Rice Japoni')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='work/sowing_riceindica' title={i18n.t('Rice Indica')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='work/sowing_rapseed' title={i18n.t('Rapeseed')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='work/sowing_potato' title={i18n.t('Potato')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='work/sowing_maize' title={i18n.t('Maize')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='work/sowing_cotton' title={i18n.t('Cotton')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='work/sowing_barley' title={i18n.t('Barley')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('spraying'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Spraying window')} />
                                                {this.state.currentPageGroup === 'spraying' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'spraying'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='work/spray' title={i18n.t('Spray')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                        </List>
                                    </div>
                                    {this.state.currentPage === 'work/sowing_wheat' && <div className={classes.page}> <SowingWheat row={this.props.row} called={[this.state.sowingCalled]} data={[this.state.sowing]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}
                                    {this.state.currentPage === 'work/sowing_sugarbeets' && <div className={classes.page}> <SowingSugarbeets row={this.props.row} called={[this.state.sowingCalled]} data={[this.state.sowing]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}
                                    {this.state.currentPage === 'work/sowing_soybean' && <div className={classes.page}> <SowingSoybean row={this.props.row} called={[this.state.sowingCalled]} data={[this.state.sowing]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}
                                    {this.state.currentPage === 'work/sowing_sorghum' && <div className={classes.page}> <SowingSorghum row={this.props.row} called={[this.state.sowingCalled]} data={[this.state.sowing]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}
                                    {this.state.currentPage === 'work/sowing_ricejaponi' && <div className={classes.page}> <SowingRiceJaponi row={this.props.row} called={[this.state.sowingCalled]} data={[this.state.sowing]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}
                                    {this.state.currentPage === 'work/sowing_riceindica' && <div className={classes.page}> <SowingRiceIndica row={this.props.row} called={[this.state.sowingCalled]} data={[this.state.sowing]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}
                                    {this.state.currentPage === 'work/sowing_rapseed' && <div className={classes.page}> <SowingRapeseed row={this.props.row} called={[this.state.sowingCalled]} data={[this.state.sowing]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}
                                    {this.state.currentPage === 'work/sowing_potato' && <div className={classes.page}> <SowingPotato row={this.props.row} called={[this.state.sowingCalled]} data={[this.state.sowing]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}
                                    {this.state.currentPage === 'work/sowing_maize' && <div className={classes.page}> <SowingMaize row={this.props.row} called={[this.state.sowingCalled]} data={[this.state.sowing]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}
                                    {this.state.currentPage === 'work/sowing_cotton' && <div className={classes.page}> <SowingCotton row={this.props.row} called={[this.state.sowingCalled]} data={[this.state.sowing]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}
                                    {this.state.currentPage === 'work/sowing_barley' && <div className={classes.page}> <SowingBarley row={this.props.row} called={[this.state.sowingCalled]} data={[this.state.sowing]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}

                                    {this.state.currentPage === 'work/spray' && <div className={classes.page}> <Spraying row={this.props.row} called={[this.state.sprayCalled]} data={[this.state.spray]} handleCalled={this.handleCalled} zoom_data={this.state.zoom_data} /> </div>}
                                </div>
                            }
                            {this.state.currentData === 'meteogram' &&
                                <div>
                                    <Meteograms zoom_data={this.state.zoom_data} />
                                </div>
                            }
                        </div>
                    </DialogContent>
                </Dialog>
                }
            </div>
        )
    }
};

export default withStyles(styles)(MeteoDialog);

function ListItemSyled(props) {
    return (
        <ListItem button onClick={() => { props.onPageClick(props.page) }} className={props.state.currentPage === props.page ? props.classes.list_item : props.classes.pressed}>
            <ListItemText primary={props.title} />
        </ListItem>
    )
};