import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n/i18n';
import { Button, IconButton, withStyles, FormLabel } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setInitialState, addLoad, getStorageDropdown, getMaterialsDropdown } from '../../actions/traffic/TrafficActions';
import { getFieldDropDown } from '../../actions/catastral_data/FieldActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactSelect from '../../utils/ReactSelect';
import immutable from 'seamless-immutable';
import moment from 'moment';
import { DatePicker } from 'material-ui-pickers';
const styles = theme => ({
    formControl: {
        margin: theme.spacing.unit * 3,
    },
})
export class AddLoad extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadsObj: {},
            silosDropdown: [],
            materialDropdown: [],
            fieldDropDown: []
        }
    }

    componentDidMount() {
        const { loadsObj, getStorageDropdown, getMaterialsDropdown, getFieldDropDown } = this.props;
        getStorageDropdown();
        getMaterialsDropdown();
        getFieldDropDown();
        if (loadsObj) {
            this.setState({
                loadsObj
            })
        }
    }


    componentDidUpdate(prevProps) {
        const { loadsPostingFailed, setInitialState, silosDropdown, materialDropdown, fieldDropDown, errorStatus } = this.props;
        if (prevProps.loadsPostingFailed === false && loadsPostingFailed === true && errorStatus !== 406) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
            setInitialState('loadsPostingFailed');
        }
        if (prevProps.loadsPostingFailed === false && loadsPostingFailed === true && errorStatus === 406) {
            toast.error(i18n.t('Not enough space in storage!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
            setInitialState('loadsPostingFailed');
        }
        if (prevProps.silosDropdown !== silosDropdown) {
            this.setState({
                silosDropdown: immutable.asMutable(silosDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            })
        }
        if (prevProps.materialDropdown !== materialDropdown) {
            this.setState({
                materialDropdown: immutable.asMutable(materialDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            })
        }
        if (prevProps.fieldDropDown !== fieldDropDown) {
            this.setState({
                fieldDropDown: immutable.asMutable(fieldDropDown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            })
        }
    }

    addLoad = () => {
        const { addLoad } = this.props;
        const { loadsObj } = this.state;
        if (loadsObj.iznos && loadsObj.id_materijal && loadsObj.id_silos_ulaz && loadsObj.datum) {
            addLoad(
                loadsObj
            )

        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    handleChangeValue = name => event => {
        const { loadsObj } = this.state;
        let obj = Object.assign({}, loadsObj);
        obj[name] = event.target.value;
        this.setState({ loadsObj: obj });
    };

    handleChangeDropdown = name => selected => {
        const { loadsObj } = this.state;
        let obj = Object.assign({}, loadsObj);
        obj[name] = selected && selected.value || null;
        this.setState({ loadsObj: obj });
    }

    handleChangeDateValue = name => date => {
        const { loadsObj } = this.state;
        let obj = Object.assign({}, loadsObj);
        obj[name] = moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY');
        this.setState({ loadsObj: obj });
    }

    renderLabel = (date) => {
        if (date.isValid()) {
            return date.format('DD.MM.YYYY');
        } else {
            return '';
        }
    }

    render() {
        const { add, handleClose, edit, theme } = this.props;
        const { loadsObj, silosDropdown, materialDropdown, fieldDropDown } = this.state;
        return (
            <div style={{ padding: 20 }}>
                <Dialog
                    open={add}
                    onClose={handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="xs"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} style={{ background: theme.palette.secondary.dark }} className="responsive-dialog-title">{edit ? i18n.t('Edit load') : i18n.t('Add load')}
                        <IconButton onClick={handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container justify="space-between" alignContent="space-between" spacing={16}>
                            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <TextField
                                    required
                                    label={i18n.t('Quantity') + ' (t)'}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    value={loadsObj.iznos}
                                    onChange={this.handleChangeValue('iznos')}
                                />
                                <FormLabel component="legend"></FormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <ReactSelect
                                    closeMenuOnSelect={true}
                                    isClearable={true}
                                    isMulti={false}
                                    menuPlacement="auto"
                                    value={materialDropdown.find((element) => {
                                        return element.value === loadsObj.id_materijal
                                    })}
                                    label={i18n.t('Commodity') + ' *'}
                                    maxMenuHeight={100}
                                    onChange={this.handleChangeDropdown('id_materijal')}
                                    options={materialDropdown}
                                    className="react-select-dropdown"
                                    classNamePrefix="select"
                                    placeholder={i18n.t('Choose commodity')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ReactSelect
                                    closeMenuOnSelect={true}
                                    isClearable={true}
                                    isMulti={false}
                                    menuPlacement="auto"
                                    value={silosDropdown.find((element) => {
                                        return element.value === loadsObj.id_silos_ulaz
                                    })}
                                    label={i18n.t('Source') + ' *'}
                                    maxMenuHeight={100}
                                    onChange={this.handleChangeDropdown('id_silos_ulaz')}
                                    options={silosDropdown}
                                    className="react-select-dropdown"
                                    classNamePrefix="select"
                                    placeholder={i18n.t('Select')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ReactSelect
                                    closeMenuOnSelect={true}
                                    isClearable={true}
                                    isMulti={false}
                                    menuPlacement="auto"
                                    value={fieldDropDown.find((element) => {
                                        return element.value === loadsObj.id_tabla
                                    })}
                                    label={i18n.t('Field')}
                                    maxMenuHeight={100}
                                    onChange={this.handleChangeDropdown('id_tabla')}
                                    options={fieldDropDown}
                                    className="react-select-dropdown"
                                    classNamePrefix="select"
                                    placeholder={i18n.t('Select')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ReactSelect
                                    closeMenuOnSelect={true}
                                    isClearable={true}
                                    isMulti={false}
                                    menuPlacement="auto"
                                    value={silosDropdown.find((element) => {
                                        return element.value === loadsObj.id_silos_izlaz
                                    })}
                                    label={i18n.t('Destination')}
                                    maxMenuHeight={100}
                                    onChange={this.handleChangeDropdown('id_silos_izlaz')}
                                    options={silosDropdown}
                                    className="react-select-dropdown"
                                    classNamePrefix="select"
                                    placeholder={i18n.t('Select')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label={i18n.t('Ticket#')}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    value={loadsObj.tiket}
                                    onChange={this.handleChangeValue('tiket')}
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <TextField
                                    type="number"
                                    label={i18n.t('Moisture content')}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    value={loadsObj.iznos_vlage}
                                    onChange={this.handleChangeValue('iznos_vlage')}
                                />
                                <FormLabel component="legend">%</FormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <DatePicker
                                    keyboard
                                    label={i18n.t('Date') + ' *'}
                                    format="DD.MM.YYYY"
                                    value={moment(loadsObj.datum, 'DD.MM.YYYY')}
                                    onChange={this.handleChangeDateValue('datum')}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    disableOpenOnEnter
                                    labelFunc={this.renderLabel}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    multiline
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    id="full-width"
                                    margin="normal"
                                    label={i18n.t('Description')}
                                    value={loadsObj.opis}
                                    onChange={this.handleChangeValue('opis')}
                                />
                            </Grid>
                            <Grid item xs>
                                <Button
                                    style={{ alignSelf: 'center', color: 'white' }}
                                    variant="contained"
                                    color="primary"
                                    onClick={this.addLoad}
                                >
                                    {edit ? i18n.t('Save') : i18n.t('add')}
                                </Button>
                            </Grid>
                            <Grid item xs={3}>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        loadsPostingFailed: state.trafficReducer.loadsPostingFailed,
        loadsObj: state.trafficReducer.loadsObj,
        silosDropdown: state.trafficReducer.silosDropdown,
        materialDropdown: state.trafficReducer.materialDropdown,
        errorStatus: state.trafficReducer.errorStatus,
        fieldDropDown: state.fieldReducer.fieldDropDown,

    }
}

function mapDispatchToProps(dispatch) {
    return {
        setInitialState: (component) => dispatch(setInitialState(component)),
        addLoad: (loadsObj) => dispatch(addLoad(loadsObj)),
        getStorageDropdown: () => dispatch(getStorageDropdown()),
        getMaterialsDropdown: () => dispatch(getMaterialsDropdown()),
        getFieldDropDown: () => dispatch(getFieldDropDown())
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(AddLoad))