import React from 'react';
import { connect } from 'react-redux';
import { MoonLoader } from 'react-spinners';
import i18n from '../../i18n/i18n';
import { resetPassword, setInitialState } from '../../actions/LoginActions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, TextField, Link } from '@material-ui/core';

export class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: ''
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.usernameCheckFetchingFailed === true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('usernameCheckFetchingFailed');
        }
        if (nextProps.usernameCheck === true) {
            this.setState({
                username: ''
            })
            this.props.setInitialState('usernameCheck');
        }
    }

    forgotPassword() {
        if (this.state.username) {
            this.props.resetPassword(this.state.username)
        } else {
            toast.error(i18n.t('Username is required.'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    handleOk() {
      this.props.history.push('/');
    }

    render() {
        return (
            <div className="login">
                {this.props.usernameCheckFetching ? (
                    <MoonLoader />
                ) : (
                    <div className="loginContainer" >
                        <div className="logo">
                            <img src={require('../../assets/images/login_agrolife.svg')} width="200" height="50" />
                        </div>
                        {this.props.usernameCheck ? (
                            <>
                                <div>
                                    <p style={{ fontSize: 16, fontWeight: 500 }}>{i18n.t('Forgot password')}</p>
                                </div>
                                <div>
                                    <p style={{ width: 250, textAlign: 'center', fontSize: 14, fontWeight: 400 }}>{i18n.t("We've sent link")}</p>
                                </div>
                                <br />
                                <Button
                                    className="loginButton"
                                    variant="contained"
                                    style={{ backgroundColor: '#04764e', color: 'white' }}
                                    onClick={() => this.handleOk()}
                                >
                                    {i18n.t('ok')}
                                </Button>
                            </>
                        ) : (
                            <>
                                <div>
                                  <p style={{ fontSize: 16, fontWeight: 500 }}>{i18n.t('Change password')}</p>
                                </div>
                                <TextField
                                    label={i18n.t('Email')}
                                    required
                                    style={{ width: '260px' }}
                                    onChange={(event) => this.setState({ username: event.target.value })}
                                />
                                <br />
                                <Button
                                    className="loginButton"
                                    variant="contained"
                                    style={{ backgroundColor: '#04764e', color: 'white', marginTop: 20, textTransform: 'none' }}
                                    onClick={() => this.forgotPassword()}
                                >
                                    {i18n.t('Send link')}
                                </Button>
                                <br />
                                <Link
                                onClick={() => this.props.history.goBack()}
                                style={{ color: '#000' }}
                                >
                                    {i18n.t('cancel')}
                                </Link>
                            </>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        usernameCheck: state.appReducer.usernameCheck,
        usernameCheckFetching: state.appReducer.usernameCheckFetching,
        usernameCheckFetchingFailed: state.appReducer.usernameCheckFetchingFailed,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        resetPassword: (username) => dispatch(resetPassword(username)),
        setInitialState: (component) => dispatch(setInitialState(component))
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPassword);