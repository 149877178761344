import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n/i18n';
import { Button, IconButton, Typography, Switch, InputAdornment } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setInitialState, addParcel, getOwnershipDropDown, addGroupedParcel } from '../../../actions/catastral_data/ParcelActions';
import { getLocalGov, getKatKultura } from '../../../actions/DropDownActions';
import { getUser } from '../../../utils/CommonFunctions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import axios from '../../../utils/AxiosWrapper';
import styles from "./AddNewExpense.module.css"

export class AddNewExpense extends React.Component {
    constructor(props) {
        super(props);
        this.state = {


            ogranakDropdown: [],
            selectedOgranak: "",


            selectedExpenseType: "",
            selectedMonth: "",
            selectedYear: "",
            amount: null,
            comment: "",
            expenseTypes: [],

            allMonths: [],
            allYears: [],
        }
    }

    componentDidMount() {
        // if (this.props.parcelObj.grupna) {
        //     if (this.props.parcelObj.grupna === true) { this.setState({ new: true }) }
        // } else if (this.props?.editParcelObject?.grupna) { if (this.props.editParcelObject.grupna === true) { this.setState({ new: true }) } };

        axios.get('api/sektor/drop_down')
            .then(res => this.setState({ ogranakDropdown: res.data.data }, () => console.log("this.state.ogranakDropdown : ", this.state.ogranakDropdown)))
            .catch(err => console.log('sektor dropdown ERROR', err));


        axios.get('api/extra_expenses/get_expense_type')
            .then(res => {
                this.setState({ expenseTypes: res.data.data }, () => console.log("expense type : ", this.state.expenseTypes))

            })
            .catch(err => console.log('expense type ERROR', err));

        axios.get('api/extra_expenses/get_months')
            .then(res => {
                this.setState({ allMonths: res.data.data }, () => console.log("months : ", this.state.allMonths))

            })
            .catch(err => console.log('expense type ERROR', err));

        axios.get('api/extra_expenses/get_years')
            .then(res => {
                this.setState({ allYears: res.data.data }, () => console.log("years : ", this.state.allYears))

            })
            .catch(err => console.log('expense type ERROR', err));

        if (this.props.obj && !this.props.edit) {
            console.log("PROPS : ", this.props.obj);

            this.setState({
                selectedExpenseType: Number(this.props.obj.id_tip_troska),
                selectedMonth: Number(this.props.obj.id_mesec),
                selectedYear: Number(this.props.obj.id_godina),
                selectedOgranak: Number(this.props.obj.id_sektor),
                amount: parseFloat(this.props.obj.cena),
                comment: this.props.obj.komentar
            }, () => {
                console.log("STATE IZ PROPS :", this.state)
            })
        }

        if (this.props.obj && this.props.edit) {
            this.setState({
                id: this.props.obj.id,
                selectedExpenseType: Number(this.props.obj.id_tip_troska),
                selectedMonth: Number(this.props.obj.id_mesec),
                selectedYear: Number(this.props.obj.id_godina),
                selectedOgranak: Number(this.props.obj.id_sektor),
                amount: parseFloat(this.props.obj.cena),
                comment: this.props.obj.komentar
            }, () => {
                console.log("STATE IZ PROPS :", this.state)
            })
        }


    }

    addExpense = () => {
        if (this.props.edit) {
            axios.post(`api/extra_expenses/create`, {
                id: this.props.obj.id,
                id_tip_troska: Number(this.state.selectedExpenseType),
                id_mesec: Number(this.state.selectedMonth),
                id_godina: Number(this.state.selectedYear),
                id_sektor: Number(this.state.selectedOgranak),
                cena: parseFloat(this.state.amount),
                komentar: this.state.comment
            }).then((e) => {
                this.props.reload();
                this.props.handleClose();
                console.log("IZ ADD EXPENSE : ", e)
            }).catch((e) => { console.log("IZ ERRORA : ", e) })
        } else {
            axios.post(`api/extra_expenses/create`, {
                id_tip_troska: Number(this.state.selectedExpenseType),
                id_mesec: Number(this.state.selectedMonth),
                id_godina: Number(this.state.selectedYear),
                id_sektor: Number(this.state.selectedOgranak),
                cena: parseFloat(this.state.amount),
                komentar: this.state.comment
            }).then((e) => {
                this.props.reload();
                this.props.handleClose();
                console.log("IZ ADD EXPENSE : ", e)
            }).catch((e) => { console.log("IZ ERRORA : ", e) })
        }
    }




    render() {


        const { theme } = this.props;
        return (
            <div style={{ padding: 20 }}>

                {/* <Paper className="roundedBoxMaterial" zdepth={4}> */}
                <Dialog
                    open={this.props.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} style={{ background: theme.palette.secondary.dark }} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit expense') : i18n.t('Create new expense')}
                        {!this.props.edit && this.state.featureGroupedParcelsOn && < div style={{ display: 'flex' }}>

                        </div>}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>

                        <TextField
                            disabled={this.props.preview}
                            label={i18n.t('Expense type')}
                            select
                            value={this.state.selectedExpenseType}
                            SelectProps={{ native: true }}
                            // InputLabelProps={{ shrink: this.state.vrsta_proizvodnje ? true : false }}
                            InputProps={{
                                style: {
                                    color: this.props.preview && 'black',
                                }
                            }}
                            style={{ color: 'black', width: '100%', marginBottom: '10px', marginTop: '30px' }}
                            onChange={(e) => { this.setState({ selectedExpenseType: e.target.value }) }} >
                            <option value='' />
                            {this.state.expenseTypes.map((type) => <option key={type.id} value={type.id}>{type.naziv}</option>)}
                        </TextField>

                        <div style={{ display: 'flex', margin: '30px 0' }}>

                            <TextField
                                disabled={this.props.preview}
                                label={i18n.t('Month')}
                                select
                                value={this.state.selectedMonth}
                                InputProps={{
                                    style: {
                                        color: this.props.preview && 'black',
                                    }
                                }}
                                SelectProps={{ native: true }}
                                // InputLabelProps={{ shrink: this.state.vrsta_proizvodnje ? true : false }}
                                style={{ width: '100%', marginBottom: '10px' }}
                                onChange={(e) => { this.setState({ selectedMonth: e.target.value }); }} >
                                <option value='' />
                                {this.state.allMonths.map((type) => <option key={type.id} value={type.id}>{i18n.t(`${type.naziv}`)}</option>)}
                            </TextField>

                            <TextField
                                disabled={this.props.preview}
                                label={i18n.t('Year')}
                                select
                                value={this.state.selectedYear}
                                SelectProps={{ native: true }}
                                InputProps={{
                                    style: {
                                        color: this.props.preview && 'black',
                                    }
                                }}
                                // InputLabelProps={{ shrink: this.state.vrsta_proizvodnje ? true : false }}
                                style={{ width: '100%', marginBottom: '10px', marginLeft: '10px' }}
                                onChange={(e) => { this.setState({ selectedYear: e.target.value }); }} >
                                <option value='' />
                                {this.state.allYears.map((type) => <option key={type.id} value={type.id}>{type.naziv}</option>)}
                            </TextField>

                        </div>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                                // required
                                disabled={this.props.preview}
                                type="number"
                                pattern="[0-9]*"
                                label={i18n.t('Amount')}
                                InputProps={{
                                    style: {
                                        color: this.props.preview && 'black',
                                    }
                                }}
                                value={this.state.amount}
                                style={{ width: '100%', margin: '10px 10px 10px 0' }}
                                onChange={(e) => {
                                    this.setState({ amount: e.target.value });
                                }}
                            />

                            <TextField
                                disabled={this.props.preview}
                                label={i18n.t('Sector')}
                                select
                                value={this.state.selectedOgranak}
                                SelectProps={{ native: true }}
                                InputProps={{
                                    style: {
                                        color: this.props.preview && 'black',
                                    }
                                }}
                                // InputLabelProps={{ shrink: this.state.vrsta_proizvodnje ? true : false }}
                                style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
                                onChange={(e) => { this.setState({ selectedOgranak: e.target.value }); }} >
                                <option value='' />
                                {this.state.ogranakDropdown.map((type) => <option key={type.id} value={type.id}>{type.naziv}</option>)}
                            </TextField>
                        </div>

                        <label style={{ visibility: `${this.state.comment && this.state.comment.length > 0 ? "visible" : "hidden"} ` }} className={styles.commentLabel} htmlFor="comment">{i18n.t('Comment')}</label>
                        <textarea
                            disabled={this.props.preview}
                            className={styles.commentBox}
                            placeholder={i18n.t('Comment')}
                            name="comment"
                            // style={{ width: '100%' }}
                            value={this.state.comment}
                            onChange={(e) => this.setState({ comment: e.target.value })}
                            id="comment"
                        ></textarea>


                        {!this.props.preview && <Button
                            variant="contained"
                            color="primary"
                            onClick={() => this.addExpense()}
                            style={{ alignItems: 'right,', marginLeft: '10px', marginTop: '20px' }}
                        >
                            {this.props.edit ? i18n.t('Save') : i18n.t('Save')}
                        </Button>}

                    </DialogContent>
                </Dialog>

            </div>


        )
    }
};

export default withStyles(styles, { withTheme: true })(connect(
    // mapStateToProps,
    // mapDispatchToProps
)(AddNewExpense))