/**
 * Created by pajicv on 6/4/18.
 */

import React, { Component } from 'react';
import {connect} from 'react-redux';

import i18n from '../../../i18n/i18n';
import { TextField, withStyles } from '@material-ui/core';

import { ALL_STATES_ID } from '../../../utils/constants';

import './ZoomTo.css'

class ZoomToState extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.onStateSelected(event.target.value);
    }

    render() {

        const {classes} = this.props;

        let states = [];
        if (this.props.states && !this.props.statesFetching) {
            states = this.props.states.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }

        return (
            <form id="zoomToStateForm">
                <TextField
                    className={classes.TheInput}
                    InputProps={{
                            className: classes.resize
                    }}
                    select
                    label={i18n.t('State')}
                    value={this.props.selectedStateId}
                    onChange={this.handleChange}
                    SelectProps={{
                        native: true,
                    }}
                    margin="normal"

                >
                    <option value={ALL_STATES_ID}>All</option>
                    {states}
                </TextField>
            </form>
        );
    }
}

const styles = theme => ({
    TheInput: {
        width: 256,
        fontWeight: 'bold',
        lineHeight: 2.4,
        marginTop: 2,
        marginBotom: 2
    },
    resize:{
        fontSize:14,
    }
});

ZoomToState = withStyles(styles)(ZoomToState);

export default ZoomToState;