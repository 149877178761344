import React from 'react';
import i18n from './../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography, Checkbox, DialogContentText } from '@material-ui/core';
import { wentRight, wentWrong } from "../../../utils/ToastConfig";
import axios from '../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import styles from './fields.module.css';
import ReactSelect from './../../../utils/ReactSelect';

export default class ManageParcels extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            parcels: null,
            user_season: null,
            user_layer: null,
            fieldType: [],
            parcelList: [],
        };
    };

    async componentDidMount() {
        await axios.post('api/parcela/read_non_grouped', { params: { id: this.props.parcelObj.id } })
            .then(res => {
                console.log("READ NON GROUPED : ", res)
                let listObj = [];
                let list = res.data.data.map(item => {
                    let label = item.broj + ' (' + (Number(item.povrsina)).toFixed(2) + 'ha)' + ` ${item.katastarska_opstina_naziv ? i18n.t('Local government') + ': ' + item.katastarska_opstina_naziv : ""}`+ ` ${item.sektor_naziv ? item.sektor_naziv : ""}`;
                    let value = item.id;
                    let id = item.id;
                    let broj = item.broj;
                    let povrsina = (Number(item.povrsina)).toFixed(2);
                    let id_sektor = item.id_sektor;
                    let sektor_naziv = item.sektor_naziv;
                    let katastarska_opstina = item.katastarska_opstina_naziv;

                    listObj.push({ label, value, broj, povrsina, id, id_sektor, sektor_naziv, katastarska_opstina});
                    return { label, value, broj, povrsina, id, id_sektor, sektor_naziv, katastarska_opstina };
                });
                this.setState({ fieldType: list, parcelList: listObj });

            })
            .catch(() => wentWrong('Something went wrong'))

        if (this.props.parcelObj.parcele) {
            let temp = this.props.parcelObj.parcele.map(item => {
                let obj = {};
                obj.label = item.broj + ' (' + (Number(item.povrsina)).toFixed(2) + 'ha)' + ` ${item.katastarska_opstina_naziv ? i18n.t('Local government') + ': ' + item.katastarska_opstina_naziv : ""}`;
                obj.value = item.id;
                obj.povrsina = (Number(item.povrsina)).toFixed(2);
                obj.id_sektor = item.id_sektor;
                obj.sektor_naziv = item.sektor_naziv;
                obj.id = item.id;
                return obj;
            });
            this.setState({ parcels: temp });
        }
    };

    getByURL(url, obj) {
        axios.get(`api/${url}`)
            .then(res => this.setState({ [obj]: res.data.data }))
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 2 (getByURL): ', error, ', URL: ', url);
            })
    };

    handleAdd = () => {
        if (this.state.parcels) {
            if (this.state.parcels.length > 1) {

                let allTheSame = this.checkSameIdSektor(this.state.parcels);

                if (allTheSame) {
                    let parcelObj = [];
                    let selectedIds = [];

                    this.state.parcels.map(item => selectedIds.push(item.value));
                    this.state.parcelList.map((item, i) => { if (selectedIds.includes(item.value)) parcelObj.push(item) });

                    this.props.handleParcelObj(parcelObj);
                } else {
                    wentWrong('Sve odabrane parcele moraju biti iz istog ogranka!');
                }

            }
            else wentWrong('You have to select at least two field!');
        }
        else wentWrong('You have to select at least two field!');
    };


    getSumByKey = (arr, key) => {
        return arr.reduce((accumulator, current) => accumulator + Number(current[key]), 0)
    };

    checkSameIdSektor = (array) => {
        if (array.length === 0) {
            return true;
        }
        const firstIdSektor = array[0].id_sektor;
        for (let i = 1; i < array.length; i++) {
            if (array[i].id_sektor !== firstIdSektor) {
                return false;
            }
        }
        return true;
    }

    render() {
        let area = 0;
        if (this.state.parcels) area = (this.getSumByKey(this.state.parcels, 'povrsina')).toFixed(2);

        return (
            <div style={{ padding: 20 }}>
                <Dialog open={this.props.open}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} style={{ background: '#04764e' }} className="responsive-dialog-title">
                        {i18n.t('Add parcels')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <div className={styles.content}>
                            <ReactSelect
                                closeMenuOnSelect={false}
                                isClearable={true}
                                isMulti={true}
                                value={this.state.parcels}
                                label={i18n.t('Parcels')}
                                maxMenuHeight={300}
                                onChange={(e) => {
                                    this.setState({ parcels: e });
                                    console.log("EVENT : ", e)
                                }}
                                options={this.state.fieldType}
                                className="multi-select-activity-form-new"
                                classNamePrefix="select"
                                placeholder={i18n.t('Select')}
                            />
                        </div>
                        <div className={styles.flex}>
                            {this.state.parcels && area > 0 && <div className={styles.font}>{i18n.t('Total land area')}: {area} ha</div>}
                            <div></div>
                            <div className={styles.center}>
                                <Button onClick={() => { this.setState({ parcels: this.state.fieldType }) }} variant='contained' color='primary' style={{ margin: '0px 20px 10px 0px' }}>{i18n.t('Select all')}</Button>
                                <Button onClick={() => this.handleAdd()} variant='contained' color='primary' style={{ margin: '0px 20px 10px 0px' }}>{i18n.t('Add')}</Button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </div >
        );
    };
};