/**
 * Created by pajicv on 6/4/18.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from '../../../utils/AxiosWrapper';

import i18n from '../../../i18n/i18n';
import { TextField, withStyles } from '@material-ui/core';

import './ZoomTo.css'

class ZoomToCoordinates extends Component {

    render() {

        const { classes, onLatitudeChange, onLongitudeChange } = this.props;

        return (
            <form className={classes.form} id="zoomToCoordinatesForm">
                <TextField
                    label={i18n.t('Latitude')}
                    className={classes.TheInput}
                    onChange={(event) => onLatitudeChange(event.target.value)}
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        min: -90,
                        max: 90
                    }}
                />
                <TextField
                  label={i18n.t('Longitude')}
                  className={classes.TheInput}
                  onChange={(event) => onLongitudeChange(event.target.value)}
                  type="number"
                  InputLabelProps={{
                      shrink: true,
                  }}
                  inputProps={{
                      min: -180,
                      max: 180
                  }}
                />
            </form>
        );
    }
}

const styles = theme => ({
    TheInput: {
        width: 256,
        fontWeight: 'bold',
        lineHeight: 2.4,
        marginTop: 12,
        marginBotom: 2
    },
    resize:{
        fontSize:14,
    },
    form: {
        display: 'flex',
        flexDirection: 'column'
    }
});

const StyledZoomToCoordinates = withStyles(styles)(ZoomToCoordinates);

export default connect(state => ({
    clientProfile: state.appReducer.clientProfile
}))(StyledZoomToCoordinates);