import React from 'react';
import { Card, withStyles, } from '@material-ui/core';
import axios from '../../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { styles } from '../InfoStyles';

export class InfoSowingWindowSunflower extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 1
        };
    };

    componentDidMount() {
        axios.get('api/map/language')
            .then(res => {
                let response = res.data.data[0].id_jezik;
                if (response) this.setState({ language: response });
            })
            .catch(err => { console.log(err) })
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.language === 1 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Preporučuje se da se setva suncokreta izbegava dok temperatura zemljišta nije barem 10॰C. Niže temperature usporavaju klijanje semena i rast mladih biljaka. Optimalna temperatura za setvu je između 12॰C i 16॰C. 
                            U ovom opsegu seme brže klija i biljka će se bolje razvijati.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            It is recommended to avoid sowing sunflowers until the soil temperature is at least 10°C. Lower temperatures slow down the germination of seeds and the growth of young plants. 
                            The optimum temperature for sowing is between 12°C and 16°C. In this range, the seeds germinate faster and the plant will develop better.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 4 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Preporuča se izbjegavati sjetvu suncokreta dok temperatura tla ne bude najmanje 10°C. Niže temperature usporavaju klijanje sjemena i rast mladih biljaka. Optimalna temperatura za sjetvu je između 12°C i 16°C. 
                            U tom rasponu sjeme brže klija i biljka će se bolje razvijati.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 5 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Priporočljivo je, da se setvi sončnic izogibamo, dokler temperatura tal ni vsaj 10°C. Nižje temperature upočasnijo kalitev semen in rast mladih rastlin. Optimalna temperatura za setev je med 12°C in 16°C.
                            V tem območju seme hitreje kali in rastlina se bo bolje razvijala.
                            </p>
                        </div>
                    </Card>
                }
            </div>
        )
    };
};

export default withStyles(styles)(InfoSowingWindowSunflower);