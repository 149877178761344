import React from 'react';
import TextField from '@material-ui/core/TextField';
import i18n from '../i18n/i18n';
export class ForBooleanFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    handleChange = name => event => {
        let boolean;
        switch (event.target.value.toLowerCase()) {
            case 'da': case 'yes':
                boolean = true;
                break;
            case 'ne': case 'no':
                boolean = false;
                break;
        }
        this.setState({
            boolean: event.target.value
        });
        this.props.onChange(boolean !== undefined ? boolean : []);
    };

    render() {

        return (
            <TextField
                className="booleanFilter"
                id="boolean"
                value={this.state.boolean}
                onChange={this.handleChange()}
                type="text"
            />
        );
    }
}


export default ForBooleanFilter;