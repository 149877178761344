import axios from '../../utils/AxiosWrapper';
export const Type = {
    //CROP
    GET_CROP_CALL: 'GET_CROP_CALL',
    GET_CROP_SUCCESS: 'GET_CROP_SUCCESS',
    GET_CROP_FAILED: 'GET_CROP_FAILED',
    //GROUP
    GET_CROP_GROUP_DROPDOWN_CALL: 'GET_CROP_GROUP_DROPDOWN_CALL',
    GET_CROP_GROUP_DROPDOWN_SUCCESS: 'GET_CROP_GROUP_DROPDOWN_SUCCESS',
    GET_CROP_GROUP_DROPDOWN_FAILED: 'GET_CROP_GROUP_DROPDOWN_FAILED',
    //SUBGROUP
    GET_CROP_SUBGROUP_DROPDOWN_CALL: 'GET_CROP_SUBGROUP_DROPDOWN_CALL',
    GET_CROP_SUBGROUP_DROPDOWN_SUCCESS: 'GET_CROP_SUBGROUP_DROPDOWN_SUCCESS',
    GET_CROP_SUBGROUP_DROPDOWN_FAILED: 'GET_CROP_SUBGROUP_DROPDOWN_FAILED',
    //ADD
    ADD_CROP_CALL: 'ADD_CROP_CALL',
    ADD_CROP_SUCCESS: 'ADD_CROP_SUCCESS',
    ADD_CROP_FAILED: 'ADD_CROP_FAILED',
    //VIEW
    GET_VIEW_CROP_CALL: 'GET_VIEW_CROP_CALL',
    GET_VIEW_CROP_SUCCESS: 'GET_VIEW_CROP_SUCCESS',
    GET_VIEW_CROP_FAILED: 'GET_VIEW_CROP_FAILED',
    //
    ADD_CROP_GROUP_CALL: 'ADD_CROP_GROUP_CALL',
    ADD_CROP_GROUP_SUCCESS: 'ADD_CROP_GROUP_SUCCESS',
    ADD_CROP_GROUP_FAILED: 'ADD_CROP_GROUP_FAILED',
    GET_CROP_GROUP_CALL: 'GET_CROP_GROUP_CALL',
    GET_CROP_GROUP_SUCCESS: 'GET_CROP_GROUP_SUCCESS',
    GET_CROP_GROUP_FAILED: 'GET_CROP_GROUP_FAILED',
    //
    GET_CROP_SUBGROUP_CALL: 'GET_CROP_SUBGROUP_CALL',
    GET_CROP_SUBGROUP_SUCCESS: 'GET_CROP_SUBGROUP_SUCCESS',
    GET_CROP_SUBGROUP_FAILED: 'GET_CROP_SUBGROUP_FAILED',
    ADD_CROP_SUBGROUP_CALL: 'ADD_CROP_SUBGROUP_CALL',
    ADD_CROP_SUBGROUP_SUCCESS: 'ADD_CROP_SUBGROUP_SUCCESS',
    ADD_CROP_SUBGROUP_FAILED: 'ADD_CROP_SUBGROUP_FAILED',
    //SET
    SET_CROP_ROW_IN_REDUCER: 'SET_CROP_ROW_IN_REDUCER',
    SET_INITIAL_STATE: 'SET_INITIAL_STATE',

    //DELETE
    DELETE_CROP_CALL: 'DELETE_CROP_CALL',
    DELETE_CROP_SUCCESS: 'DELETE_CROP_SUCCESS',
    DELETE_CROP_FAILED: 'DELETE_CROP_FAILED',
    //KAT_KULTURA
    GET_KAT_KULTURA_CALL: 'GET_KAT_KULTURA_CALL',
    GET_KAT_KULTURA_SUCCESS: 'GET_KAT_KULTURA_SUCCESS',
    GET_KAT_KULTURA_FAILED: 'GET_KAT_KULTURA_FAILED',

    ADD_CROP_FAVORITE_CALL: 'ADD_CROP_FAVORITE_CALL',
    ADD_CROP_FAVORITE_SUCCESS: 'ADD_CROP_FAVORITE_SUCCESS',
    ADD_CROP_FAVORITE_FAILED: 'ADD_CROP_FAVORITE_FAILED',
    DELETE_CROP_FAVORITE_CALL: 'DELETE_CROP_FAVORITE_CALL',
    DELETE_CROP_FAVORITE_SUCCESS: 'DELETE_CROP_FAVORITE_SUCCESS',
    DELETE_CROP_FAVORITE_FAILED: 'DELETE_CROP_FAVORITE_FAILED',


};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function getCrop(id_subgroup) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_CROP_CALL
        });

        axios.get(`api/crop/drop_down${id_subgroup ? '?id_podgrupa=' + id_subgroup : ''}`)
            .then(function (response) {
                dispatch({
                    type: Type.GET_CROP_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_CROP_FAILED
                });
            });
    }
}

export function getGroup() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_CROP_GROUP_DROPDOWN_CALL
        });

        axios.get('api/crop_group/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_CROP_GROUP_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_CROP_GROUP_DROPDOWN_FAILED
                });
            });
    }
}

export function getKatKultura() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_KAT_KULTURA_CALL
        });

        axios.get('api/katastarska_kultura/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_KAT_KULTURA_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_KAT_KULTURA_FAILED
                });
            });
    }
}

export function getCropGroups(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_CROP_GROUP_CALL
        });
        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/crop_group/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_CROP_GROUP_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_CROP_GROUP_FAILED
                });
            });
    }
}

export function addCropGroup(cropGroupObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_CROP_GROUP_CALL
        });

        axios.post('api/crop_group/create',
            cropGroupObj
        )
            .then(function (response) {
                dispatch({
                    type: Type.ADD_CROP_GROUP_SUCCESS,
                    data: response.data
                });
                dispatch(getGroup());
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_CROP_GROUP_FAILED
                });
            });
    }
}

export function getSubgroup(id_group) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_CROP_SUBGROUP_DROPDOWN_CALL
        });

        axios.get(`api/crop_subgroup/drop_down${id_group ? '?id_grupa=' + id_group : ''}`)
            .then(function (response) {
                dispatch({
                    type: Type.GET_CROP_SUBGROUP_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_CROP_SUBGROUP_DROPDOWN_FAILED
                });
            });
    }
}

export function getCropSubgroups(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_CROP_SUBGROUP_CALL
        });
        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/crop_subgroup/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_CROP_SUBGROUP_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_CROP_SUBGROUP_FAILED
                });
            });
    }
}

export function addCropSubgroup(cropSubgroupObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_CROP_SUBGROUP_CALL
        });

        axios.post('api/crop_subgroup/create', cropSubgroupObj)
            .then(function (response) {
                dispatch({
                    type: Type.ADD_CROP_SUBGROUP_SUCCESS,
                    data: response.data
                });
                dispatch(getSubgroup());
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_CROP_SUBGROUP_FAILED
                });
            });
    }
}

export function addCrop(cropObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_CROP_CALL
        });

        axios.post('api/crop/create',
            cropObj
        )
            .then(function (response) {
                dispatch({
                    type: Type.ADD_CROP_SUCCESS,
                    data: response.data
                });
                dispatch(getGroup());
                dispatch(getSubgroup());
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_CROP_FAILED
                });
            });
    }
}

export function getViewCrop(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_VIEW_CROP_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/crop/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_VIEW_CROP_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_VIEW_CROP_FAILED
                });
            });
    }
}

export function setRowInReducer(rowInfo, name) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_CROP_ROW_IN_REDUCER,
            rowInfo: rowInfo,
            name: name
        })
    }
}

export function deleteCrop(selection, name) {
    return (dispatch) => {

        dispatch({
            type: Type.DELETE_CROP_CALL,
        });

        axios.post(`api/${name}/delete`, { selection: selection })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_CROP_SUCCESS,
                    data: response.data,
                    name: name,
                    forDelete: selection
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_CROP_FAILED
                });
            });
    }
}

export function addCropToFavorite(id_kultura) {
    return (dispatch) => {
        dispatch({
            type: Type.ADD_CROP_FAVORITE_CALL,
        });
        axios.post(`api/crop/addFavorite`, { id_kultura })
            .then(function (response) {
                dispatch({
                    type: Type.ADD_CROP_FAVORITE_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_CROP_FAVORITE_FAILED
                });
            });
    }
}

export function deleteCropFavorite(id_kultura) {
    return (dispatch) => {
        dispatch({
            type: Type.DELETE_CROP_FAVORITE_CALL,
        });
        axios.post(`api/crop/deleteFavorite`, { id_kultura })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_CROP_FAVORITE_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_CROP_FAVORITE_FAILED
                });
            });
    }
}

