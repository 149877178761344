import React from 'react';
import { Card, withStyles, } from '@material-ui/core';
import axios from '../../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { styles } from '../InfoStyles';

export class InfoUVIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 1
        };
    };

    componentDidMount() {
        axios.get('api/map/language')
            .then(res => {
                let response = res.data.data[0].id_jezik;
                if (response) this.setState({ language: response });
            })
            .catch(err => { console.log(err) })
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.language === 1 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Sunčevo zračenje je glavni izvor energije za sve procese koji se dešavaju na Zemlji. 
                            Njegov intenzitet ima uticaj na hemijski sastav biljaka a samim tim i na kvalitet poljoprivrednih useva. 
                            S jedne strane, UV zračenje povoljno utiče na životni ciklus biljaka, pojačava intenzitet fotosinteze, olakšava proizvodnju hlorofila i apsorpciju hranljivih materija. 
                            S druge strane, prekomerno izlaganje UV zračenju može izazvati degradaciju proteina u ćelijama lista, što dovodi do propadanja biljaka. 
                            Opasnost nastaje kada doza zračenja prouzrokuje štetu koja prevazilazi regenerativne sposobnosti biljke, što dovodi do smanjenja prinosa i kvaliteta proizvoda. 
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Solar radiation is the main source of energy for all processes that occur on Earth.
                            Its intensity has an impact on the chemical composition of plants and therefore on the quality of agricultural crops.
                            On the one hand, UV radiation has a favorable effect on the life cycle of plants, increases the intensity of photosynthesis, facilitates the production of chlorophyll and the absorption of nutrients.
                            On the other hand, excessive exposure to UV radiation can cause protein degradation in leaf cells, leading to plant decay.
                            The danger arises when the dose of radiation causes damage that exceeds the regenerative abilities of the plant, which leads to a decrease in the yield and quality of the product.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 4 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Sunčevo zračenje glavni je izvor energije za sve procese koji se odvijaju na Zemlji. Njegov intenzitet utječe na kemijski sastav biljaka, a time i na kvalitetu poljoprivrednih usjeva.
                            S jedne strane, UV zračenje povoljno djeluje na životni ciklus biljaka, pojačava intenzitet fotosinteze, olakšava stvaranje klorofila i apsorpciju hranjivih tvari.
                            S druge strane, pretjerano izlaganje UV zračenju može uzrokovati razgradnju proteina u stanicama lista, što dovodi do propadanja biljke. Opasnost nastaje kada doza zračenja uzrokuje štetu koja premašuje regenerativne sposobnosti biljke, što dovodi do smanjenja prinosa i kvalitete proizvoda.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 5 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Sončno sevanje je glavni vir energije za vse procese, ki se dogajajo na Zemlji. 
                            Njegova intenzivnost vpliva na kemično sestavo rastlin in s tem na kakovost kmetijskih pridelkov. 
                            Po eni strani UV-sevanje ugodno vpliva na življenjski cikel rastlin, poveča intenzivnost fotosinteze, 
                            olajša nastajanje klorofila in absorpcijo hranil. Po drugi strani pa lahko čezmerna izpostavljenost UV-sevanju povzroči 
                            razgradnjo beljakovin v listnih celicah, kar povzroči propadanje rastlin. Nevarnost nastane, ko odmerek sevanja povzroči škodo,
                             ki presega regenerativne sposobnosti rastline, kar povzroči zmanjšanje pridelka in kakovosti proizvoda.
                            </p>
                        </div>
                    </Card>
                }
            </div>
        )
    };
};

export default withStyles(styles)(InfoUVIndex);