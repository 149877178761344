import React from 'react';
import { Card, withStyles, } from '@material-ui/core';
import axios from '../../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { styles } from '../InfoStyles';

export class InfoPrecipitation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 1
        };
    };

    componentDidMount() {
        axios.get('api/map/language')
            .then(res => {
                let response = res.data.data[0].id_jezik;
                if (response) this.setState({ language: response });
            })
            .catch(err => { console.log(err) })
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.language === 1 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Padavine, posebno kiša, u velikoj meri utiču na poljoprivredu. Svim biljkama je potrebna voda da prežive, stoga je kiša, 
                            kao najefikasnije sredstvo za zalivanje, važna za poljoprivredu. Previše ili premalo vode može biti štetno za biljke, suše povećava eroziju a preterano vlažno vreme dovodi do pojave bolesti.
                            Vlažnost lišća je meteorološki parametar koji opisuje količinu rose i padavina preostalih na biljnim površinama a prvenstveno na listu. 
                            Koristi se za praćenje vlažnosti lišća čija vrednost značajno utiče na pojavu biljnih bolesti i bitan je podatak za kontrolisano navodnjavanje.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Precipitation, especially rain, greatly affects agriculture. All plants need water to survive, so rain, as the most effective means of watering, 
                            is important for agriculture. Too much or too little water can be harmful to plants, drought increase erosion and excessively wet weather lead to diseases.
                            Leaf wetness is a meteorological parameter that describes the amount of dew and precipitation remaining on plant, primarily on the leaf. 
                            Leaf wetness significantly affects the occurrence of plant diseases and it is important information for controlled irrigation.

                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 4 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Oborine, posebice kiša, uvelike utječu na poljoprivredu. Sve biljke trebaju vodu da bi preživjele, 
                            stoga je kiša, kao najučinkovitije sredstvo zalijevanja, važna za poljoprivredu. Previše ili 
                            premalo vode može biti štetno za biljke, suše povećavaju eroziju, a pretjerano vlažno vrijeme 
                            dovodi do bolesti.
                            Vlažnost lista je meteorološki parametar koji opisuje količinu rose i oborina zaostalih na 
                            površini biljaka, prvenstveno na listu. Služi za praćenje vlažnosti lišća čija vrijednost 
                            značajno utječe na pojavu biljnih bolesti i važan je podatak za kontrolirano navodnjavanje.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 5 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Padavine, predvsem dež, močno vplivajo na kmetijstvo. Vse rastline potrebujejo vodo za preživetje, zato dež,  kot najučinkovitejše sredstvo za namakanje, pomembno za kmetijstvo. 
                            Preveč ali premalo vode je lahko škodljivo za rastline, suša poveča erozijo, pretirano vlažno vreme pa povzroči bolezni.
                            Vlažnost listov je meteorološki parameter, ki opisuje količino rose in padavin, ki ostanejo na rastlinskih površinah, predvsem na listih.
                            Uporablja se za spremljanje vlažnosti listov, katere vrednost pomembno vpliva na pojav rastlinskih bolezni in je pomemben podatek za kontrolirano namakanje.

                            </p>
                        </div>
                    </Card>
                }
            </div>
        )
    };
};

export default withStyles(styles)(InfoPrecipitation);