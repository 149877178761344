import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { getGroup, getSubgroup, getViewCrop, setRowInReducer, setInitialState, addCropToFavorite, deleteCropFavorite } from '../../../actions/resources/CropActions';
import i18n from '../../../i18n/i18n';
import Select from 'react-select';
import immutable from 'seamless-immutable';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import { withStyles } from '@material-ui/core/styles';
import { getUser } from '../../../utils/CommonFunctions';
import { Delete } from "../../../utils/Delete";
import * as Icons from '@material-ui/icons';
import { IconButton, Tooltip } from '@material-ui/core';
import ForBooleanFilter from '../../../utils/ForBooleanFilter';
const CheckboxTable = checkboxHOC(ReactTable);
const styles = theme => ({
    primaryMain: {
        backgroundColor: theme.palette.primary.main,
    },
    center: {
        textAlign: 'center'
    },
    starButton: {
        padding: '3px',
    }
});
export class ViewCrop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilters: this.props.showFilters,
            selection: [],
            selectAll: false,
            user: {},
            deleteFavorite: false
        };
    }

    componentDidMount() {
        if (this.props.cropGroupDropdown.length === 0) {
            this.props.getGroup();
        }
        if (this.props.cropSubgroupDropdown.length === 0) {
            this.props.getSubgroup();
        }
        this.setState({
            user: getUser()
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            showFilters: nextProps.showFilters,
            crops: nextProps.crops,
            crop: nextProps.crop,
            cropsFetching: nextProps.cropsFetching,
            cropGroupDropdown: immutable.asMutable(nextProps.cropGroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            groupFetching: nextProps.groupFetching,
            groupFetchingFailed: nextProps.groupFetchingFailed,
            cropSubgroupDropdown: immutable.asMutable(nextProps.cropSubgroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            subgroupFetching: nextProps.subgroupFetching,
            subgroupFetchingFailed: nextProps.subgroupFetchingFailed
        })
    }

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            selection.push(key);
        }
        this.setState({ selection }, function () {
            if (selection.length == 1) {
                let row1 = this.props.crop.filter((crop) => {
                    return crop.id == selection;
                })
                this.props.setRowInReducer(row1[0], 'cropObj');
            }
        });
        this.props.setSelection(selection, 'crop');
    };
    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection });
        this.props.setSelection(selection, 'crop');
    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    onDeleteClick = (cropRow) => {
        this.setState({ deleteFavorite: !this.state.deleteFavorite, cropRow });
    }

    deleteItem = () => {
        const { cropRow } = this.state;
        const { deleteCropFavorite } = this.props;
        deleteCropFavorite(cropRow.original.id);
        const crop = [...this.state.crop];
        let cropObj = Object.assign({}, crop[cropRow.index]);
        cropObj[cropRow.column.id] = !cropRow.value;
        crop[cropRow.index] = cropObj;
        this.setState({ deleteFavorite: false, crop });
    }

    handleEditFavorite = (row) => {
        const { addCropToFavorite } = this.props;
        if (row.value) {
            this.onDeleteClick(row);
        } else {
            addCropToFavorite(row.original.id);
            const crop = [...this.state.crop];
            let cropObj = Object.assign({}, crop[row.index]);
            cropObj[row.column.id] = !row.value;
            crop[row.index] = cropObj;
            this.setState({ crop });
        }
    }


    render() {
        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll, user } = this.state;
        const { theme, classes } = this.props;

        const columns = [
            {
                Header: i18n.t('Active'),
                accessor: "favorit",
                filterable: false,
                width: 60,
                show: user.id_korisnik_grupa === 10 ? true : false,
                Cell: (row) => {
                    return (
                        <div className={classes.center}>
                            <Tooltip title={row.value ? i18n.t('Remove from active') : i18n.t('Add to active')}>
                                <IconButton
                                    className={classes.starButton}
                                    style={{ color: row.value ? '#f2ce3e' : 'gray', marginBottom: '0.4vh', height: '1.2vh' }}
                                    onClick={() => this.handleEditFavorite(row)}>
                                    {
                                        row.value ? <Icons.GradeRounded /> :
                                            <Icons.GradeOutlined />
                                    }
                                </IconButton>
                            </Tooltip>
                        </div >
                    );
                }
            },
            {
                Header: i18n.t('Title'),
                accessor: 'naziv'
            },
            {
                Header: i18n.t('Group'),
                accessor: 'kultura_grupa_naziv',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.cropGroupDropdown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Subgroup'),
                accessor: 'kultura_podgrupa_naziv',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.cropSubgroupDropdown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                id: 'organska_proizvodnja',
                Header: i18n.t('Permitted in organic production'),
                accessor: d => d.organska_proizvodnja,
                width: 240,
                Cell: ({ value }) => value === true ? i18n.t('Yes') : i18n.t('No'),
                Filter: ({ filter, onChange }) =>
                    <ForBooleanFilter
                        onChange={(s) => {
                            onChange(s);
                        }}
                    />

            },
            // {
            //     Header: i18n.t('Average yield'),
            //     accessor: 'prosecan_prinos',
            //     filterable: false
            // },
            // {
            //     Header: i18n.t('Precipitation of'),
            //     accessor: 'padavine_od',
            //     filterable: false
            // },
            // {
            //     Header: i18n.t('Precipitation up to'),
            //     accessor: 'padavine_do',
            //     filterable: false
            // },
            // {
            //     Header: i18n.t('Sum of mean daily temperatures of'),
            //     accessor: 'stepeni_od',
            //     filterable: false
            // },
            // {
            //     Header: i18n.t('Sum of average daily temperatures up to'),
            //     accessor: 'stepeni_do',
            //     filterable: false,
            // }
        ];
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return {
                        style: {
                            backgroundColor: selected ? "#ACDAF1" : "inherit",
                            lineHeight: '2.5vh',
                            alignItems: 'flex-end'
                        }
                    };
                }
                return {};
            },
            getTheadFilterProps: () => {
                return {
                    style: {
                        backgroundColor: theme.palette.primary.main
                    }
                };
            },
            getTheadTrProps: () => {
                return {
                    style: {
                        backgroundColor: theme.palette.secondary.main,
                        color: '#FFFFFF'
                    }
                };
            },
        };

        return (
            <div className="ReactTable">
                <CheckboxTable
                    ref={r => (this.checkboxTable = r)}
                    data={this.state.crop}
                    columns={columns}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    showPaginationTop
                    keyField={'id'}
                    showPaginationBottom={false}
                    pages={this.props.cropsPages}
                    loading={this.props.cropsFetching}
                    manual
                    onFetchData={(state, instance) => this.props.getViewCrop(state, instance)}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: theme.palette.primary.main,
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: theme.palette.primary.main,
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />
                {this.state.deleteFavorite &&
                    <Delete
                        delete={this.state.deleteFavorite}
                        handleClose={this.onDeleteClick}
                        deleteItem={this.deleteItem}
                        favorite={true} />
                }
            </div >
        )
    }
};

function mapStateToProps(state) {
    return {
        crop: state.cropReducer.crop,
        cropsFetching: state.cropReducer.cropsFetching,
        cropsPages: state.cropReducer.cropsPages,
        cropGroupDropdown: state.cropReducer.cropGroupDropdown,
        groupFetching: state.cropReducer.groupFetching,
        groupFetchingFailed: state.cropReducer.groupFetchingFailed,
        cropSubgroupDropdown: state.cropReducer.cropSubgroupDropdown,
        subgroupFetching: state.cropReducer.subgroupFetching,
        subgroupFetchingFailed: state.cropReducer.subgroupFetchingFailed,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getGroup: () => dispatch(getGroup()),
        setInitialState: (component) => dispatch(setInitialState(component)),
        setRowInReducer: (rowInfo, name) => dispatch(setRowInReducer(rowInfo, name)),
        getSubgroup: () => dispatch(getSubgroup()),
        getViewCrop: (state, instance) => dispatch(getViewCrop(state, instance)),
        addCropToFavorite: (id_kultura) => dispatch(addCropToFavorite(id_kultura)),
        deleteCropFavorite: (id_kultura) => dispatch(deleteCropFavorite(id_kultura))
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewCrop));