import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n/i18n';
import { Button, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import { SketchPicker } from 'react-color';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//  import InputLabel from '@material-ui/core/InputLabel';
import { setInitialState, addWorkingOperation, getGroup } from '../../../actions/resources/WorkingOperationsActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
const styles = theme => ({
});
export class AddWorkingOperation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            workingOperationObj: this.props.workingOperationObj
        }
    }

    componentDidMount() {
        if (this.props.woGroupDropdown.length === 0) {
            this.props.getGroup();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.workingOperationPostingFailed === true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('workingOperationPostingFailed');
        }
    }

    addWorkingOperation() {
        if (this.state.workingOperationObj.naziv
            && this.state.workingOperationObj.id_radna_operacija_grupa) {
            this.props.addWorkingOperation(
                this.state.workingOperationObj
            )

        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    handleChangeValue = name => event => {
        let obj = Object.assign({}, this.state.workingOperationObj);
        obj[name] = event.target.value;
        this.setState({ workingOperationObj: obj });
    };


    render() {
        const { theme } = this.props;
        let group = [];
        if (this.props.woGroupDropdown && !this.props.groupFetching) {
            group = this.props.woGroupDropdown.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        return (
            <div style={{ padding: 20 }}>

                {/* <Paper className="roundedBoxMaterial" zdepth={4}> */}
                <Dialog
                    open={this.props.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="xs"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} style={{ background: theme.palette.secondary.dark }} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit Working Operation') : i18n.t('Add Working Operation')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <form id="add-workingoperation">
                            <Grid container justify="space-between" alignContent="space-between" spacing={16}>
                                <Grid item>
                                    <TextField
                                        select
                                        label={i18n.t("Working operation group")}
                                        value={this.state.workingOperationObj.id_radna_operacija_grupa}
                                        onChange={this.handleChangeValue('id_radna_operacija_grupa')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        required
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                        <option value='' />
                                        {group}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        required
                                        label={i18n.t('Title')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={this.state.workingOperationObj.naziv}
                                        onChange={this.handleChangeValue('naziv')} />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('Code')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={this.state.workingOperationObj.sifra}
                                        onChange={this.handleChangeValue('sifra')}
                                        type="number"
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => this.addWorkingOperation()}
                                    >
                                        {this.props.edit ? i18n.t('Save') : i18n.t('Add')}
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
                {/* </Paper> */}
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        woGroupDropdown: state.workingOperationReducer.woGroupDropdown,
        groupFetching: state.workingOperationReducer.groupFetching,
        groupFetchingFailed: state.workingOperationReducer.groupFetchingFailed,
        workingOperationPosting: state.workingOperationReducer.workingOperationPosting,
        workingOperationPostingSuccess: state.workingOperationReducer.workingOperationPostingSuccess,
        workingOperationPostingFailed: state.workingOperationReducer.workingOperationPostingFailed,
        workingOperationObj: state.workingOperationReducer.workingOperationObj
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getGroup: () => dispatch(getGroup()),
        setInitialState: (component) => dispatch(setInitialState(component)),
        addWorkingOperation: (workingOperationObj) => dispatch(addWorkingOperation(workingOperationObj)),
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(AddWorkingOperation));