import React from 'react';
import i18n from '../../../i18n/i18n';
import { Bar } from 'react-chartjs-2';
import { formatForecast } from '../DataFormatters';
import { Degrees } from './../../../utils/Degrees';
import { Link, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import NavigationIcon from '@material-ui/icons/Navigation';
import { MoonLoader } from 'react-spinners';
import { Loader } from './../Loader';
import moment from 'moment';

const styles = {};

export class ForecastWind extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            week: false,
            load: true,
            half: false,
        };
    };

    componentDidMount = () => {
        Loader(this.props, ['basic-1h'], ['forecastCalled'], ['forecast'])
    };

    render() {
        let graph1 = {};
        let data = [];
        let obj = {};
        let wind_dir = [];
        let dates_dir = [];

        this.props.data.forEach((item, i) => {
            if (Object.keys(item).length > 0) {
                obj = formatForecast(item);
                data.push(obj);
            }
        });

        data.forEach((item, i) => {
            if (item) {
                if (item.dates !== undefined) {

                    var now = moment();
                    now = now.format("HH");

                    if (this.state.week === true) {
                        wind_dir = distributedCopy(item.winddirection, 12);
                        dates_dir = distributedCopy(item.dates, 12);
                    }
                    else if (this.state.week === false) {
                        wind_dir = distributedCopy(item.winddirection.slice(0, 72), 12);
                        dates_dir = distributedCopy(item.dates.slice(0, 72), 12);
                    }

                    graph1 = {
                        labels: this.state.week ? item.dates : item.dates.slice(0, 72),
                        datasets: [
                            {
                                data: this.state.week ? item.windspeed : item.windspeed.slice(0, 72),
                                label: i18n.t('Wind speed [m/s]'),
                                backgroundColor: 'rgba(82, 162, 209, 0.4)',
                                borderColor: 'rgb(82, 162, 209)',
                                type: 'line',
                                borderWidth: 3,
                                pointRadius: 0,
                            }
                        ],
                    };

                    if (this.state.half === true) {
                        wind_dir = distributedCopy(item.winddirection.slice(Number(now), Number(now) + 13), 12);
                        dates_dir = distributedCopy(item.dates.slice(Number(now), Number(now) + 13), 12);

                        graph1 = {
                            labels: item.dates.slice(Number(now), Number(now) + 13),
                            datasets: [
                                {
                                    data: item.windspeed.slice(Number(now), Number(now) + 13),
                                    label: i18n.t('Wind speed [m/s]'),
                                    backgroundColor: 'rgba(82, 162, 209, 0.4)',
                                    borderColor: 'rgb(82, 162, 209)',
                                    type: 'line',
                                    borderWidth: 3,
                                    pointRadius: 4,
                                }
                            ],
                        };
                    }

                }
            }
        })

        const options1 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        gridLines: {
                            drawOnChartArea: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[m/s]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                        }
                    },
                ],
            },
        }

        return (
            <div style={{ paddingLeft: '35px', height: '100%' }}>
                <div style={{ display: 'flex', marginLeft: '40%', paddingTop: '22px' }}>
                    <Link style={{ marginRight: '3px', marginLeft: '3px', fontSize: '18px', color: 'black', cursor: 'pointer' }}
                        onClick={() => { this.setState({ week: false, half: true }); }}
                        variant="contained"
                        color="secondary">
                        <Typography>{i18n.t('12 hours')}</Typography>
                    </Link> /
                    <Link style={{ marginRight: '3px', marginLeft: '3px', fontSize: '18px', color: 'black', cursor: 'pointer' }}
                        onClick={() => { this.setState({ week: false, half: false }); }}
                        variant="contained"
                        color="secondary">
                        <Typography>{i18n.t('3 days')}</Typography>
                    </Link> /
                <Link style={{ marginRight: '3px', marginLeft: '3px', fontSize: '18px', color: 'black', cursor: 'pointer' }}
                        onClick={() => { this.setState({ week: true, half: false }); }}
                        variant="contained"
                        color="secondary" >
                        <Typography>{i18n.t('7 days')}</Typography>
                    </Link>
                </div>
                {Object.keys(data).length === 0 && <div style={{ marginLeft: '39%', marginTop: '14%' }}> <MoonLoader loading={this.state.load} size={100} /></div>}
                {Object.keys(data).length > 0 &&
                    <div>
                        <div style={{ width: '95%', marginTop: '10px' }}>
                            <Bar data={graph1} height={300} width={600} options={options1} />
                        </div>
                        <Typography style={{ textAlign: 'center', fontSize: '18px', margin: '10px', marginTop: '3%', marginBottom: '10px' }}>{i18n.t('Wind direction')}:</Typography>
                        <table style={{ marginBottom: '58px', marginLeft: '15px' }}>
                            <tbody>
                                <tr>
                                    {wind_dir !== [] && wind_dir.map((item, i) => {
                                        let first = i;
                                        return <td style={{ width: '75px', height: '50px', textAlign: 'center' }} key={first}>
                                            <NavigationIcon style={{ transform: `rotate(${item + 180}deg)`, height: '40px', width: '40px', color: 'rgb(82, 162, 209)' }} />
                                        </td>
                                    })}
                                </tr>
                                <tr>
                                    {wind_dir !== [] && wind_dir.map((item, i) => {
                                        let second = i;
                                        let string_degrees = Degrees(item);
                                        return <td style={{ width: '70px', height: '30px', textAlign: 'center' }} key={second}>
                                            <Typography>{string_degrees}</Typography>
                                        </td>
                                    })}
                                </tr>
                                <tr>
                                    {dates_dir !== [] && dates_dir.map((item, i) => {
                                        let third = i;
                                        return <td style={{ width: '75px', height: '40px', textAlign: 'center' }} key={third}>
                                            <Typography>{item.substring(0, 5)}</Typography>
                                            <Typography>{item.substring(7, 12)}</Typography>
                                        </td>
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        );
    }
};

export default withStyles(styles)(ForecastWind);

function distributedCopy(items, n) {
    var elements = [items[0]];
    var totalItems = items.length - 2;
    var interval = Math.floor(totalItems / (n - 2));
    for (var i = 1; i < n - 1; i++) {
        elements.push(items[i * interval]);
    }
    elements.push(items[items.length - 1]);
    return elements;
}