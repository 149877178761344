import React from 'react';
import { Card, withStyles, } from '@material-ui/core';
import axios from '../../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { styles } from '../InfoStyles';

export class InfoWind extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 1
        };
    };

    componentDidMount() {
        axios.get('api/map/language')
            .then(res => {
                let response = res.data.data[0].id_jezik;
                if (response) this.setState({ language: response });
            })
            .catch(err => { console.log(err) })
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.language === 1 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Vetar je jedan od ekoloških faktora koji značajno utiču na poljoprivrednu proizvodnju. 
                            Vetar širi polen i seme biljaka i samim tim pomaže biljkama da se oprašuju i razmnožavaju. 
                            Proces transporta ugljen-dioksida i kiseonika u vazduhu ubrzava se ili jača sa povećanjem brzine vetra. 
                            Stoga, umerena brzina vetra igra važnu ulogu u poboljšanju uslova životne sredine na poljoprivrednom zemljištu. 
                            Vetar takođe deluje i negativno na poljoprivredu - širiti patogene i biljne bolesti, izaziva eroziju i otežava 
                            ili čak potpuno sprečava izvođenje određenih tenoloških operacija, kao što je zaštita useva.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            The wind is one of the environmental factors that significantly affect agricultural production.
                            The wind spreads the pollen and seeds of plants and helps them to pollinate and reproduce.
                            The process of transport of carbon dioxide and oxygen in the air is accelerated or strengthened with increasing wind speed. 
                            Therefore, moderate wind speed is important for improving the environmental conditions of agricultural land.
                            Wind also harms agriculture - spreading pathogens and plant diseases, causing erosion and making it difficult to do technological operations such as crop protection.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 4 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Vjetar je jedan od ekoloških čimbenika koji značajno utječe na poljoprivrednu proizvodnju.
                            Vjetar raznosi pelud i sjemenke biljaka i tako pomaže biljkama da se oprašuju i razmnožavaju.
                            Proces prijenosa ugljičnog dioksida i kisika u zraku ubrzava se ili jača s povećanjem brzine vjetra. 
                            Stoga umjerena brzina vjetra igra važnu ulogu u poboljšanju ekoloških uvjeta poljoprivrednog zemljišta.
                            Vjetar utječe i negativno na poljoprivredu - širi uzročnike bolesti i štetnike, uzrokuje eroziju i otežava 
                            ili čak potpuno onemogućuje određene tehnološke radnje, poput zaštite usjeva.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 5 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Veter je eden izmed okoljskih dejavnikov, ki pomembno vpliva na kmetijsko pridelavo. 
                            Veter raznaša cvetni prah in semena rastlin ter jim pomaga pri opraševanju in razmnoževanju. 
                            Proces transporta ogljikovega dioksida in kisika v zraku se pospeši ali okrepi z naraščajočo hitrostjo vetra. 
                            Zato je zmerna hitrost vetra pomembna za izboljšanje okoljskih razmer kmetijskih zemljišč. 
                            Veter škoduje tudi kmetijstvu - širi patogene in rastlinske bolezni, povzroča erozijo in otežuje izvajanje 
                            tehnoloških postopkov, kot je zaščita pridelka.
                            </p>
                        </div>
                    </Card>
                }
            </div>
        )
    };
};

export default withStyles(styles)(InfoWind);