import React from 'react';
import { IconButton, Dialog, DialogContent, DialogTitle, withStyles } from '@material-ui/core';
import dialogStyles from './meteoinfo.module.css';

export default class MeteoInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 1
        };
    };

    render() {
        return (
            <div>
                <Dialog open={this.props.open}
                    onClose={this.props.onClose}
                    maxWidth={this.props.width ? this.props.width : 'md'}
                    PaperProps={{ style: { minWidth: '40%' } }}
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} style={{ background: '#04764e' }} className="responsive-dialog-title">
                        {this.props.title}
                        <IconButton onClick={this.props.onClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <div className={dialogStyles.content}>
                            {this.props.text}
                        </div>
                    </DialogContent>
                </Dialog>
            </div >
        );
    };
};