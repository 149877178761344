import React from 'react';
import { Card, withStyles, } from '@material-ui/core';
import axios from '../../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { styles } from '../InfoStyles';

export class InfoSowingWindowSugarbeet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 1
        };
    };

    componentDidMount() {
        axios.get('api/map/language')
            .then(res => {
                let response = res.data.data[0].id_jezik;
                if (response) this.setState({ language: response });
            })
            .catch(err => { console.log(err) })
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.language === 1 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Seme šećerne repe seje se na dubinu od 3cm, kada je temperatura zemljišta na dubini od 5cm tri uzastopna dana 5-6॰C iako seme počinje da klija već na 4॰C, ali veoma sporo. Za brzo i jednolično klijanje optimalne srednje temperature zemljišta su od 10-12॰C. 
                            Kada je temperatura zemljišta viša od 15॰C, uz dovoljnu količinu vlage, repa niče za 7-8 dana.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Sugar beet seeds are sown at a depth of 3cm, when the soil temperature at a depth of 5cm is 5-6°C for three consecutive days, although the seeds begin to germinate already at 4°C, but very slowly. For rapid and uniform germination, the optimum average soil temperature is 10-12°C. 
                            When the soil temperature is higher than 15°C, with a sufficient amount of moisture, sugar beet germinates in 7-8 days.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 4 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Sjeme šećerne repe sije se na dubinu od 3 cm, kada je temperatura tla na dubini od 5 cm tri dana uzastopno 5-6°C iako sjeme počinje klijati već na 4°C, ali vrlo sporo. Za brzo i ravnomjerno klijanje optimalna prosječna temperatura tla je 10-12°C. 
                            Pri temperaturi tla višoj od 15°C, uz dovoljnu količinu vlage, repa niče za 7-8 dana.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 5 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Semena sladkorne pese sejemo na globino 3cm, ko je temperatura tal na globini 5cm tri dni zapored 5-6°C, čeprav začne seme kaliti že pri 4°C, vendar zelo počasi. Za hitro in enakomerno kalitev je optimalna povprečna temperatura tal 10-12°C.
                            Pri temperaturi tal nad 15°C ob zadostni količini vlage repa požene v 7-8 dneh.
                            </p>
                        </div>
                    </Card>
                }
            </div>
        )
    };
};

export default withStyles(styles)(InfoSowingWindowSugarbeet);