import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import i18n from '../../i18n/i18n';
import Select from 'react-select';
import { Fab, Button } from '@material-ui/core';
import { wentWrong } from "../../utils/ToastConfig";
import axios from '../../utils/AxiosWrapper';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import SensorDialog from './SensorDialog';
import * as Icons from '@material-ui/icons';
import ReactSelect from '../../utils/ReactSelect';

export default class SensorList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // regionType: [], 
            stateType: [],
            showFilters: false, loading: true,
            page: 0, pageSize: 50, sorted: null, filtered: null,
            selection: [], selectAll: false, selectedRow: [],
            totalPages: 0, totalSensors: 0,
            open: false, row: {},
            data: [],
            states: [39, 19, 82, 166, 22, 873, 879, 890, 911]
        };
    }

    componentDidMount() {
        localStorage.removeItem('row');
        // this.getByURL('sensor/filter_region', 'regionType');
        this.getByURL('opstina/drop_down', 'stateType');

        axios.get('api/events/add', { params: { id_vrsta_dogadjaja: 3 } });

        this.setState({ loading: false }, () => {
            this.reactTable.fireFetchData();
        })
    }

    getData(url, state, instance) {
        this.setState({ data: [] });
        axios.get(`api/${url}`, {
            params: { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: state.filtered }
        })
            .then(res => {
                this.setState({
                    data: res.data.data,
                    totalPages: res.data.total_pages,
                    totalSensors: res.data.total
                });
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })
    }

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }

    getByURL(url, obj) {
        axios.get(`api/${url}`)
            .then(res => this.setState({ [obj]: res.data.data }))
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 2 (getByURL): ', error, ', URL: ', url);
            })
    }

    isSelected = key => { return this.state.selection.includes(key); };
    onSearchClick = () => this.setState({ showFilters: !this.state.showFilters });
    onTableClick = () => this.props.history.push('/sensors');
    onMapClick = () => this.props.history.push('/sensor_map');

    handleButtonClick = (cell) => {
        let row = {
            naziv: cell.naziv,
            uid: cell.uid,
            row: cell._index,
        }
        this.setState({ row }, () => { this.handleClick() });
    };

    handleClick = () => { this.setState({ open: !this.state.open }) };

    render() {
        let user = this.getUser();

        // let regionType = this.state.regionType.map((item) => { return { label: item.naziv, value: item.id } });
        let stateType = this.state.stateType.map((item) => { return { label: item.naziv, value: item.id } });

        const columns = [
            // { Header: i18n.t('ID'), accessor: 'id' },
            { Header: i18n.t('Naziv'), accessor: 'naziv' },
            { Header: i18n.t('Station ID'), accessor: 'uid' , show:false},

            // { Header: i18n.t('Region'), accessor: 'naziv_region', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={regionType} /> },
            {
                Header: i18n.t('State'), accessor: 'naziv_opstina'
                ,
                Filter: ({ filter, onChange }) => {
                    return <ReactSelect
                        filter
                        isMulti
                        name="label"
                        onChange={value => { onChange(value) }}
                        defaultValue={stateType.filter((element) => {
                            if (this.state.states.includes(user.id_opstina)) return element.value === user.id_opstina;
                        })}
                        closeMenuOnSelect={false}
                        options={stateType}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
                }
            },
            { Header: i18n.t('Last temperature [°C]'), accessor: 'poslednja_temperatura' },
            { Header: i18n.t('Precipitation 24h [mm]'), accessor: 'padavine_24h' },
            { Header: i18n.t('Last humidity [%]'), accessor: 'poslednja_vlaznost_vazduha' },
            { Header: i18n.t('Wind speed [km/h]'), accessor: 'poslednja_brzina_vetra' },
            {
                Header: i18n.t('Wind direction'), accessor: 'poslednji_smer_vetra', Cell: ({ value }) => {

                    return <div style={{ height: '40px', width: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <svg class="MuiSvgIcon-root-185" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation" style={{ transform: `rotate(${value}deg)`, height: '40px', width: '40px', color: 'rgb(82, 162, 209)' }}><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2L4.5 20.29l.71.71L12 18l6.79 3 .71-.71z"></path></svg>
                    </div>

                }
            },




            {
                Header: i18n.t('Time of update'), accessor: 'vreme_ocitanja', Cell: ({ value }) => {

                    const date = new Date(value);

                    const formattedDate = `${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)} ${('0' + date.getDate()).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`;

                    return formattedDate
                }
            },







            { id: 'aktivan', Header: i18n.t('Active'), accessor: d => d.aktivan, Cell: ({ value }) => value != 0 ? i18n.t('Yes') : i18n.t('No') },
            {
                width: 150,
                Header: i18n.t('Data'),
                accessor: 'naziv',
                Cell: cell => {

                    if (cell.original.id_tip === 2) return;
                    return <Button onClick={() => this.handleButtonClick(cell.row)} style={{ backgroundColor: 'transparent', color: 'black', height: '20px', width: '30px', textTransform: 'none' }}>
                        {i18n.t('More')}
                    </Button>
                }

            },
        ];

        const url = 'sensor/read';

        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;
        const checkboxProps = {
            selectAll, isSelected, toggleSelection, toggleAll,
            // selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return { style: { backgroundColor: selected ? "#ACDAF1" : "inherit", fontSize: '13px', lineHeight: '20px', height: '32.8px' } };
                }
                return { style: { height: '32.8px' } };
            },
            getTheadFilterProps: () => { return { style: { backgroundColor: '#3E9571' } }; },
            getTheadTrProps: () => { return { style: { backgroundColor: '#04764e', color: '#FFFFFF' } }; },
        };

        const filterOptions = {
            defaultFiltered:
                [
                    {
                        id: 'naziv_opstina',
                        value: stateType.filter((element) => {
                            if (this.state.states.includes(user.id_opstina)) return element.value === user.id_opstina;
                        })
                    }
                ]
        };

        return (
            <div className="page newSensorList">
                <div className="table-header">
                    <Fab onClick={() => this.onSearchClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button">
                        <span className="icon-search headerIcon" />
                    </Fab>

                    <Button style={{ backgroundColor: '#04764e', color: 'white', marginLeft: '20px', height: '32px' }} onClick={() => this.onMapClick()}>
                        {i18n.t('Map')}
                        <Icons.Public className="icon-public menuIcon" style={{ marginLeft: '8px', marginRight: '-22px' }} />
                    </Button>

                </div>
                <ReactTable ref={r => (this.reactTable = r)}
                    manual
                    data={this.state.data}
                    paging={true}
                    columns={columns}
                    keyField={'id'}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={50}
                    filterable={this.state.showFilters}
                    pages={this.state.totalPages}
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => {
                        if (this.state.loading === false) { this.getData(url, state, instance); }
                    }}
                    loading={this.state.loading}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    {...filterOptions}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />

                {this.state.open && <SensorDialog open={this.state.open} handleClick={this.handleClick} row={this.state.row} />}
            </div>
        )
    }
};

function FilterSelect(props) {
    return <Select
        isMulti
        name="naziv"
        onChange={value => { props.onChange(value) }}
        closeMenuOnSelect={false}
        options={props.type}
        className="multi-select-in-filter"
        classNamePrefix="select"
        placeholder={i18n.t('Select')}
    />
}