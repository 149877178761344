import React from 'react';
import { Provider } from 'react-redux';
import store from './container/Store';
import Agrolife from './container/Agrolife'
import { BrowserRouter } from 'react-router-dom';
import { Switch } from 'react-router';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';

export default class Root extends React.Component {
    render() {
        return (
            <Provider store={store}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <BrowserRouter>
                        <Switch>
                            <Agrolife />
                        </Switch>
                    </BrowserRouter>
                </MuiPickersUtilsProvider>
            </Provider>
        )
    }
}