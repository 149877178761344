import React from 'react';
import { Card, withStyles, } from '@material-ui/core';
import axios from '../../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { styles } from '../InfoStyles';

export class InfoSowingWindowCorn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 1
        };
    };

    componentDidMount() {
        axios.get('api/map/language')
            .then(res => {
                let response = res.data.data[0].id_jezik;
                if (response) this.setState({ language: response });
            })
            .catch(err => { console.log(err) })
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.language === 1 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Setvu kukuruza treba izvršiti kada se temperatura zemljišta na dubini 5-7cm tokom tri dana ustali na 10-12॰C i ne prognozira se pogoršanje vremena i pad temperature. 
                            Ukoliko nastupi hladno i kišovito vreme setvu je potrebno odložiti.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Corn should be sown when the soil temperature at a depth of 5-7cm remains consistently at 10-12°C for three days and there is no forecast for a worsening of the weather or temperature drop. 
                            If cold and rainy weather occurs, sowing should be postponed.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 4 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Kukuruz treba sijati kada se temperatura tla na dubini od 5-7cm tijekom tri dana ustali na 10-12°C i nema prognoze pogoršanja vremena i pada temperature. 
                            Ako nastupi hladno i kišovito vrijeme, sjetvu treba odgoditi.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 5 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Koruzo sejemo, ko se temperatura tal v globini 5-7 cm za tri dni dvigne na 10-12°C in ni napovedanega poslabšanja vremena in padca temperature.
                            Če nastopi hladno in deževno vreme, je treba setev odložiti.
                            </p>
                        </div>
                    </Card>
                }
            </div>
        )
    };
};

export default withStyles(styles)(InfoSowingWindowCorn);