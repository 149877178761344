import React from 'react';
import i18n from '../../../../i18n/i18n';

import {
    Button, DialogActions,
    Dialog, DialogContent, DialogTitle
} from '@material-ui/core';

export class RemoveSort extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: ''
        }
    }

    render() {
        return (
            <Dialog
                open={this.props.isOpen}
                onClose={this.props.handleClose}
                disableBackdropClick
                maxWidth="sm"
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{i18n.t('Remove sort dialog title')}</DialogTitle>
                <DialogContent>
                    {i18n.t('Remove sort rows warning')}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={this.props.handleConfirm}
                        variant="contained"
                        color="primary">
                        {i18n.t('Ok')}
                    </Button>
                    <Button
                        onClick={this.props.handleClose}
                        variant="contained"
                        color="primary">
                        {i18n.t('Cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}



export default RemoveSort