import React from 'react';
import Card from '@material-ui/core/Card';
import { HorizontalBar } from 'react-chartjs-2';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../../utils/AxiosWrapper';


const styles = theme => ({ card: { minWidth: 275, padding: '10px', width: '90%', }, });

export default class StatisticsProfit extends React.Component {
    constructor(props) {
        super(props);
        this.state = { valuta: '' }
    }
    componentDidMount() {

        this.getValuta();
    }
    getValuta() {
        axios.get(`api/valuta/valuta`)
            .then(res => {
                let valuta = res.data.data[0].id_valuta;
                if (valuta === 11) {
                    this.setState({
                        valuta: ' KM'
                    })
                } else if (valuta === 124) {
                    this.setState({
                        valuta: ' din'
                    })
                } else if (valuta === 152) {
                    this.setState({
                        valuta: ' $'
                    })
                } else if (valuta === 50) {
                    this.setState({
                        valuta: ' €'
                    })
                } else if (valuta === 109) {
                    this.setState({
                        valuta: ' $'
                    })
                } else {
                    this.setState({
                        valuta: ' €'
                    })
                }
            })
            .catch(error => {
                console.log('error', error);
            })
    }
    render() {
        let options = {};

        if (this.props.currency === true) {
            options = {
                animation: { duration: 0 },
                hover: { animationDuration: 0 },
                responsiveAnimationDuration: 0,
                legend: { display: false },
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return `${(new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(tooltipItem.xLabel))} `;
                        }
                    }
                },
                scales: {
                    yAxes: [{ ticks: { beginAtZero: true } }],
                    xAxes: [{ ticks: { beginAtZero: true } }],
                }
            }
        }

        else {
            options = {
                animation: { duration: 0 },
                hover: { animationDuration: 0 },
                responsiveAnimationDuration: 0,
                legend: { display: false },
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return tooltipItem.xLabel.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
                        }
                    }
                },
                scales: {
                    yAxes: [{ ticks: { beginAtZero: true } }],
                    xAxes: [{ ticks: { beginAtZero: true } }],
                }
            };
        }

        let data = {
            labels: [],
            datasets: [{
                data: [], data1: [], borderWidth: 1,
                backgroundColor: this.props.color,
                borderColor: this.props.color,
                hoverBackgroundColor: this.props.color,
                hoverBorderColor: this.props.color,
            }],
            count: 0,
        };

        data.labels = this.props.labels;
        data.datasets[0].data = this.props.values;
        data.count = this.props.values.length * 2;

        return (
            <div >
                <Card style={styles.card}>
                    <h1 style={{ marginLeft: '10px' }}>{this.props.title}: </h1>
                    {data.count !== 0 &&
                        <HorizontalBar
                            height={data.count + 5}
                            width={this.props.width}
                            data={data}
                            redraw={true}
                            options={options} />
                    }
                </Card>
            </div >
        )
    }
}