import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import randomColor from 'randomcolor';
import {
    CarouselProvider,
    Slider,
    Slide,
    ButtonBack,
    ButtonNext,
    DotGroup,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import * as Icons from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    container: {
        width: '100%',
        height: '200px',
        '@media only screen and (max-width: 36.25rem)': {
            height: 'auto',
        },
    },
    tile: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #e6e3e3',
        borderRadius: '4px',
        cursor: 'pointer',
        padding: `24px`,
        margin: '0  4px',
        textAlign: 'center',
        minWidth: '100px',
        height: '150px',
    },
    tile_selected: {
        borderColor: theme.palette.secondary.light,
        borderWidth: '3px',
        padding: `22px`,
    },
    naziv: {
        fontSize: '12px',
        letterSpacing: '0.3px',
        lineHeight: 2,
        textTransform: 'uppercase',
        marginTop: '4px',
        marginBottom: '4px',
        wordBreak: 'break-word',
        wordWrap: 'break-word',
        maxWidth: '100px',
    },
    cena: {
        fontSize: '30px',
        fontWeight: 500,
        lineHeight: 1,
        marginBottom: '4px',
    },
    cenaRSD: {
        fontSize: '20px',
    },
    avatar: {
        width: '40px !important',
        height: '40px !important',
        display: 'flex',
        overflow: 'hidden',
        fontSize: '1.25rem',
        alignItems: 'center',
        flexShrink: 0,
        borderRadius: '4px',
        justifyContent: 'center',
        color: ' #fff',
        margin: '10px',
    }
});

class Carousel extends React.Component {
    constructor(props) {
        super(props);
        this.carouselContainer = React.createRef();
        this.calculateState = this.calculateState.bind(this);
        this.state = {
            showSelect: false,
            slideVisible: null,
        }
    }

    componentDidMount() {
        this.calculateState();
        window.addEventListener("resize", this.calculateState);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.calculateState);
    }

    calculateState = () => {
        const getCount = (width, slides) => {
            if (width <= 350) { return 1 }
            if (width <= 650) { return 2 }
            if (width <= 750) { return 3 }
            if (width <= 860) { return 4 }
            if (width <= 990) { return 5 }
            if (width <= 1070) { return 6 }
            if (width <= 1170) { return 7 }
            return 8;
        };

        const { data } = this.props;
        if (this.carouselContainer.current) {
            const containerWidth = this.carouselContainer.current.getBoundingClientRect().width;
            this.setState(() => ({
                showSelect: containerWidth <= 450,
                slideVisible: getCount(containerWidth, data),
            }));
        }
    };


    render() {
        const {
            data,
            selectedMaterialId,
            handleMaterialChange,
            classes
        } = this.props;
        if (!data) return null;

        const slideCount = data.length;

        return (
            <div className={classes.container} ref={this.carouselContainer}>
                <CarouselProvider
                    naturalSlideWidth={100}
                    naturalSlideHeight={200}
                    totalSlides={data.length}
                    visibleSlides={this.state.slideVisible || 1}
                >
                    {slideCount > 1 && (
                        <ButtonBack >
                            <div className={classes.icon}>
                                <Icons.ChevronLeft />
                            </div>
                        </ButtonBack>
                    )}
                    <Slider>{
                        data.map((item, i) => {
                            const isSelected = selectedMaterialId === item.id;
                            const title = item.naziv;
                            return (
                                <Slide index={i} key={i} >
                                    <div
                                        onClick={() => handleMaterialChange(item)}
                                        title={title}
                                        className={classNames(classes.tile,
                                            isSelected && classes.tile_selected)}
                                    >
                                        <div className={classes.avatar} style={{ backgroundColor: `${randomColor()}` }}>
                                            {item.naziv.substring(0, 2).toUpperCase()}
                                        </div>
                                        <h3 className={classes.naziv}>{item.naziv}</h3>
                                        <p className={classes.cena}>{parseFloat(item.cena).toFixed(2)}
                                            <span className={classes.cenaRSD}> RSD</span>
                                        </p>
                                    </div>
                                </Slide>
                            )
                        })
                    }
                    </Slider>
                    {slideCount > 1 && (
                        <ButtonNext >
                            <div className={classes.icon}>
                                <Icons.ChevronRight />
                            </div>
                        </ButtonNext>
                    )}
                </CarouselProvider>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(Carousel));