import React from 'react';
import { Card, withStyles, } from '@material-ui/core';
import axios from '../../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { styles } from '../InfoStyles';

export class InfoDataDeltaT extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 1
        };
    };

    componentDidMount() {
        axios.get('api/map/language')
            .then(res => {
                let response = res.data.data[0].id_jezik;
                if (response) this.setState({ language: response });
            })
            .catch(err => { console.log(err) })
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.language === 1 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Delta T [°C] predstavlja razliku između najviše dnevne temperature vazduha i najniže noćne temperature vazduha u određenom vremenskom periodu, obično u toku 24 sata. 
                            Na primer, ako je najviša dnevna temperatura 30°C, a najniža noćna temperatura 15°C, Delta T iznosi 15°C.
                            </p>
                        </div>
                    </Card>    
                }
                {this.state.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Delta T [°C] represents the difference between the highest daytime air temperature and the lowest nighttime air temperature within a specific time period, typically within 24 hours. 
                            For example, if the highest daytime temperature is 30°C, and the lowest nighttime temperature is 15°C, the Delta T is 15°C.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 4 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Delta T [°C] predstavlja razliku između najviše dnevne temperature zraka i najniže noćne temperature zraka u određenom vremenskom razdoblju, obično unutar 24 sata. 
                            Na primjer, ako je najviša dnevna temperatura 30°C, a najniža noćna 15°C, Delta T je 15°C.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 5 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>

                            Delta T [°C] predstavlja razliko med najvišjo dnevno temperaturo zraka in najnižjo 
                            nočno temperaturo zraka v določenem časovnem obdobju, običajno v 24 urah. 
                            Na primer, če je najvišja dnevna temperatura 30 °C in najnižja nočna temperatura 15 °C, je delta T 15 °C.
 
                            </p>
                        </div>
                    </Card>
                }
            </div>
        )
    };
};

export default withStyles(styles)(InfoDataDeltaT);
