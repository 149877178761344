import React from 'react';
import { Card, withStyles, } from '@material-ui/core';
import axios from '../../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { styles } from '../InfoStyles';

export class InfoPrecipitationHumidity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 1
        };
    };

    componentDidMount() {
        axios.get('api/map/language')
            .then(res => {
                let response = res.data.data[0].id_jezik;
                if (response) this.setState({ language: response });
            })
            .catch(err => { console.log(err) })
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.language === 1 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Informacije o očekivanim padavinama i vrednosti vlažnosti vazduha veoma su značajne sa aspekta planiranja radova a posebo za zaštitu bilja. 
                            Omogućavaju planiranje i sprovođenje preventivne, pravovremene zaštite useva, kako ne bi došlo do pojave ili širenja bolesti. 
                            Ovo se naročito odnosi na voćarske, vinogradarske i povrtarske kulture, gde se pre ili nakon padavina obavezno vrši tretman zaštite bilja od patogenih gljiva i bakterija. 
                            Vlažnost vazduha moguće je kontrolisati u zaštićenim prostorima, gde ovaj parametar ima veoma važnu ulogu jer utiče na intenzitet transpiracije, fotosintezu, oplodnju i pojavu i širenje bolesti. 
                            Vlažnost vazduha zavisi od temperature vazduha pa je vrednost najveća u ranim jutarnjim časovima a sa odmicanjem dana opada.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Information about expected precipitation and air humidity values are very important from the aspect of work planning, especially for plant protection. 
                            They enable the planning and realising of preventive and timely crop protection so that diseases do not appear or spread. This is important for fruit, 
                            viticulture and vegetable crops where before or after rainfall treatment to protect plants from pathogenic fungi and bacteria must be carried out.
                            Air humidity can be controlled in greenhouses where this parameter is very important because it affects the intensity of transpiration, photosynthesis, 
                            fertilization and the and the occurrence and spread of diseases. Air humidity depends on the air temperature, so the value is highest in the early morning hours and decreases as the day progresses.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 4 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Podaci o očekivanim oborinama i vrijednostima vlažnosti zraka vrlo su važni s aspekta planiranja radova, 
                            posebice za zaštitu bilja. Omogućuju planiranje i provođenje preventivne, pravovremene zaštite usjeva, 
                            kako se bolesti ne bi pojavile ili proširile. To se posebno odnosi na voćarske, vinogradarske i 
                            povrtlarske kulture, gdje je prije ili poslije oborina potrebno provesti tretiranje biljaka od 
                            patogenih gljivica i bakterija.
                            Vlažnost zraka može se kontrolirati u zaštićenim prostorima, gdje ovaj parametar ima vrlo važnu 
                            ulogu jer utječe na intenzitet transpiracije, fotosintezu, oplodnju te pojavu i širenje bolesti. 
                            Vlažnost zraka ovisi o temperaturi zraka, pa je vrijednost najveća u ranim jutarnjim satima, a opada 
                            kako dan odmiče.
                        </p>
                        </div>
                    </Card>
                }
                {this.state.language === 5 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Podatki o pričakovanih padavinah in vrednostih zračne vlage so zelo pomembni z vidika načrtovanja dela, predvsem za varstvo rastlin. 
                            Omogočajo načrtovanje in izvajanje preventivnega in pravočasnega varstva pridelkov, da se bolezni ne pojavijo ali razširijo. 
                            To je pomembno za sadjarstvo, vinogradništvo in zelenjadnice, kjer je treba pred ali po padavinah izvesti obdelavo za zaščito rastlin pred patogenimi glivami in bakterijami. 
                            Zračno vlago lahko nadzorujemo v rastlinjakih, kjer je ta parameter zelo pomemben, saj vpliva na intenzivnost transpiracije, fotosintezo, oploditev ter na pojav in širjenje bolezni. 
                            Vlažnost zraka je odvisna od temperature zraka, zato je vrednost najvišja v zgodnjih jutranjih urah in pada z dnem.
                        </p>
                        </div>
                    </Card>
                }
                
                
            
                
            </div>
        )
    };
};

export default withStyles(styles)(InfoPrecipitationHumidity);