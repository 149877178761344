/**
 * Created by pajicv on 6/29/18.
 */
/* eslint-disable import/first */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import L from 'leaflet';

import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip as ChartTooltip, Legend,
} from 'recharts';

import { LayersControl, FeatureGroup, Popup, Marker, Tooltip } from 'react-leaflet';

import * as LayerSelectors from '../selectors/LayerSelectors';

import * as LayerActions from '../actions/map/LayerActions';

import './InstitutionalLayers.css';

const { Overlay } = LayersControl;

class SiloLayer extends Component {

    render() {

        const { layer, silos, removeLayer, removeLayerControl, addOverlay  } = this.props;


            return (
                <Overlay checked={ layer.visible }
                         name={layer.name}
                         addOverlay={addOverlay}
                         removeLayer={removeLayer}
                         removeLayerControl={removeLayerControl}>
                    <FeatureGroup visible={layer.visible}>
                        {silos.map(marker => {

                            if(!marker.latitude || !marker.longitude) return;

                            const position = [marker.latitude, marker.longitude];

                            const data = [{
                              name: 'Popunjenost',
                              popunjeno: marker.popunjenost || 10000,
                              prazno: marker.kapacitet - (marker.popunjenost || 10000)
                            }];

                            return (
                                <Marker icon={L.icon({iconUrl: layer.icon})} position={position}>
                                        <Popup>
                                            <div>
                                                <BarChart
                                              width={300}
                                              height={200}
                                              data={data}
                                              margin={{
                                                top: 20, right: 30, left: 20, bottom: 5,
                                              }}
                                            >
                                            <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis />
                                                <ChartTooltip />
                                                <Legend />
                                                <Bar dataKey="popunjeno" stackId="a" fill="#8884d8" />
                                                <Bar dataKey="prazno" stackId="a" fill="#82ca9d" />
                                                </BarChart>
                                                <h2>Opis</h2>
                                                <p>{marker.opis.substr(0, 197,) + '...'}</p>
                                            </div>
                                        </Popup>
                                    <Tooltip  className="institutional-layer-label-tooltip" direction="center" permanent>
                                        <p className="institutional-layer-label-tooltip-text">{marker.naziv}</p>
                                    </Tooltip>
                                </Marker>
                            )
                        })}
                    </FeatureGroup>
                </Overlay>
            )

    }


}

function mapStateToProps(state) {
    return {
      layer: LayerSelectors.getSiloLayer(state)
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SiloLayer);
