import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { getGroup, getWorkingOperations, setInitialState, setRowInReducer, addWorkingOperationFavorite, deleteWorkingOperationFavorite } from '../../../actions/resources/WorkingOperationsActions';
import i18n from '../../../i18n/i18n';
import Select from 'react-select';
import immutable from 'seamless-immutable';
import AddWorkingOperation from './AddWorkingOperation';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Tooltip } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Delete } from "../../../utils/Delete";
import { getUser } from '../../../utils/CommonFunctions';
const CheckboxTable = checkboxHOC(ReactTable);
const styles = theme => ({
    primaryMain: {
        backgroundColor: theme.palette.primary.main,
    },
    center: {
        textAlign: 'center'
    },
    starButton: {
        padding: '3px',
    }
});
export class ViewWorkingOperation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            add: this.props.add,
            edit: this.props.edit,
            showFilters: this.props.showFilters,
            selection: [],
            selectAll: false,
            working_operation: [],
            deleteFavorite: false,
            user: {}
        };
    }

    componentDidMount() {
        if (this.props.woGroupDropdown.length == 0) {
            this.props.getGroup();
        }
        this.setState({
            user: getUser()
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.deleted === true) {
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getWorkingOperations(wrapped.state);
            this.setState({
                selection: [],
                selectAll: false,
            })
            this.props.setInitialState('deleted');

        }
        if (nextProps.workingOperationPostingSuccess === true) {
            this.setState({
                selection: [],
                name: '',
                selectAll: false
            })
            this.props.handleClose();
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getWorkingOperations(wrapped.state);
            this.props.setInitialState('workingOperationPostingSuccess');
        }
        this.setState({
            add: nextProps.add,
            showFilters: nextProps.showFilters,
            working_operation: nextProps.working_operation,
            woGroupDropdown: immutable.asMutable(nextProps.woGroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            groupFetching: nextProps.groupFetching,
            groupFetchingFailed: nextProps.groupFetchingFailed,
        })
        if (nextProps.add == true && nextProps.edit == false) {
            this.props.setInitialState('workingOperationObj');
            this.setState({
                selection: [],
                name: '',
                selectAll: false
            })
        }
    }

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            selection.push(key);
            this.props.setRowInReducer(row, 'workingOperationObj');
        }
        this.setState({ selection }, function () {
            if (selection.length == 1) {
                let row1 = this.props.working_operation.filter((wo) => {
                    return wo.id == selection;
                })
                this.props.setRowInReducer(row1[0], 'workingOperationObj');
            }
        });
        this.props.setSelection(selection, 'working_operation');
    };

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection });
        this.props.setSelection(selection, 'working_operation');

    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    onDeleteClick = (workingOperationRow) => {
        this.setState({ deleteFavorite: !this.state.deleteFavorite, workingOperationRow });
    }

    deleteItem = () => {
        const { workingOperationRow } = this.state;
        this.props.deleteWorkingOperationFavorite(workingOperationRow.original.id);
        const working_operation = [...this.state.working_operation];
        let workingOperationObj = Object.assign({}, working_operation[workingOperationRow.index]);
        workingOperationObj[workingOperationRow.column.id] = !workingOperationRow.value;
        working_operation[workingOperationRow.index] = workingOperationObj;
        this.setState({ deleteFavorite: false, working_operation });
    }

    handleEditFavorite = (row) => {
        if (row.value) {
            this.onDeleteClick(row);
        } else {
            this.props.addWorkingOperationFavorite(row.original.id);
            const working_operation = [...this.state.working_operation];
            let workingOperationObj = Object.assign({}, working_operation[row.index]);
            workingOperationObj[row.column.id] = !row.value;
            working_operation[row.index] = workingOperationObj;
            this.setState({ working_operation });
        }
    }


    render() {
        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll, user } = this.state;
        const { theme, classes } = this.props;
        const columns = [
            {
                Header: i18n.t('Active'),
                accessor: "favorit",
                filterable: false,
                width: 60,
                show: user.id_korisnik_grupa === 10 ? true : false,
                Cell: (row) => {
                    return (
                        <div className={classes.center}>
                            <Tooltip title={row.value ? i18n.t('Remove from active') : i18n.t('Add to active')}>
                                <IconButton
                                    className={classes.starButton}
                                    style={{ color: row.value ? '#f2ce3e' : 'gray', marginBottom: '0.4vh', height: '1.2vh' }}
                                    onClick={() => this.handleEditFavorite(row)}>
                                    {
                                        row.value ? <Icons.GradeRounded /> :
                                            <Icons.GradeOutlined />
                                    }
                                </IconButton>
                            </Tooltip>
                        </div >
                    );
                }
            },
            {
                Header: i18n.t('Title'),
                accessor: 'naziv'
            },
            // {
            //     Header: i18n.t('Code'),
            //     accessor: 'sifra',
            //     // Filter: ({filter, onChange }) =>
            //     //     <Select
            //     //         isMulti
            //     //         name="naziv"
            //     //         onChange={value => {
            //     //             onChange(value)
            //     //         }}
            //     //         closeMenuOnSelect={false}
            //     //         options={this.state.zones}
            //     //         className="multi-select-in-filter"
            //     //         classNamePrefix="select"
            //     //     />
            // },
            {
                Header: i18n.t('Working operation group name'),
                accessor: 'radna_operacija_grupa_naziv',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.woGroupDropdown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },

        ];
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return {
                        style: {
                            backgroundColor: selected ? "#ACDAF1" : "inherit",
                            lineHeight: '2.5vh',
                            alignItems: 'flex-end'
                        }
                    };
                }
                return {};
            },
            getTheadFilterProps: () => {
                return {
                    style: {
                        backgroundColor: theme.palette.primary.main
                    }
                };
            },
            getTheadTrProps: () => {
                return {
                    style: {
                        backgroundColor: theme.palette.secondary.main,
                        color: '#FFFFFF'
                    }
                };
            },
        };

        return (
            <div className="ReactTable">
                <CheckboxTable data={this.state.working_operation}
                    columns={columns}
                    ref={r => (this.checkboxTable = r)}
                    keyField={'id'}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    showPaginationTop
                    showPaginationBottom={false}
                    pages={this.props.workingOperationPages}
                    loading={this.props.workingOperationFetching}
                    manual
                    onFetchData={(state, instance) => this.props.getWorkingOperations(state, instance)}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: theme.palette.primary.main,
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: theme.palette.primary.main,
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />
                {this.state.add && <AddWorkingOperation add={this.state.add} edit={this.props.edit}
                    handleClose={this.props.handleClose} />}
                {this.state.deleteFavorite &&
                    <Delete
                        delete={this.state.deleteFavorite}
                        handleClose={this.onDeleteClick}
                        deleteItem={this.deleteItem}
                        favorite={true} />
                }
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        working_operation: state.workingOperationReducer.working_operation,
        deleted: state.workingOperationReducer.deleted,
        workingOperationFetching: state.workingOperationReducer.workingOperationFetching,
        workingOperationPages: state.workingOperationReducer.workingOperationPages,
        workingOperationPostingSuccess: state.workingOperationReducer.workingOperationPostingSuccess,
        woGroupDropdown: state.workingOperationReducer.woGroupDropdown,
        groupFetching: state.workingOperationReducer.groupFetching,
        groupFetchingFailed: state.workingOperationReducer.groupFetchingFailed,

    }
}

function mapDispatchToProps(dispatch) {
    return {
        getGroup: () => dispatch(getGroup()),
        setInitialState: (component) => dispatch(setInitialState(component)),
        setRowInReducer: (rowInfo, name) => dispatch(setRowInReducer(rowInfo, name)),
        getWorkingOperations: (state, instance) => dispatch(getWorkingOperations(state, instance)),
        addWorkingOperationFavorite: (id_radna_operacija) => dispatch(addWorkingOperationFavorite(id_radna_operacija)),
        deleteWorkingOperationFavorite: (id_radna_operacija) => dispatch(deleteWorkingOperationFavorite(id_radna_operacija)),
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewWorkingOperation));