import immutable from 'seamless-immutable';
import { Type as UserType } from '../../actions/users/UserActions';


export const INITIAL_STATE = immutable({
    users: [],
    usersFetching: false,
    usersFetchingFailed: false,
    usersPages: 1,
    //sector 
    userObj: {},
    userPosting: false,
    userPostingSuccess: false,
    userPostingFailed: false,
    delete: false,
    deleteFailed: false,
});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case UserType.GET_USERS_CALL: {
            const usersFetching = true;
            return state.merge({ usersFetching });
            break;
        }

        case UserType.GET_USERS_SUCCESS: {
            const users = action.data.data;
            const usersPages = action.data.total_pages
            const usersFetching = false;
            return state.merge({ users, usersPages, usersFetching });
            break;
        }

        case UserType.GET_USERS_FAILED: {
            const usersFetchingFailed = true;
            return state.merge({ usersFetchingFailed });
            break;
        }


        case UserType.ADD_USER_CALL: {
            const userPosting = true;
            return state.merge({ userPosting })
            break;
        }

        case UserType.ADD_USER_SUCCESS: {
            const userPosting = false;
            const userPostingSuccess = true;
            return state.merge({ userPosting, userPostingSuccess });
            break;
        }

        case UserType.ADD_USER_FAILED: {
            const userPosting = false;
            const userPostingFailed = true;
            return state.merge({ userPosting, userPostingFailed });
            break;
        }

        case UserType.DELETE_USER_SUCCESS: {
            const array = state.users.asMutable();
            const forDelete = action.forDelete;
            for (let i = 0; i < array.length; i++) {
                for (let j = 0; j <= forDelete.length; j++) {
                    if (array[i] && array[i].id === forDelete[j]) {
                        array.splice(i, 1);
                    }
                }
            }
            const deleted = true;
            return state.merge({ users: array, deleted: deleted })
            break;
        }

        case UserType.DELETE_USER_FAILED: {
            const deleteFailed = true;
            return state.merge({ deleteFailed });
            break;
        }

        case UserType.SET_USER_IN_REDUCER: {
            const data = action.rowInfo.asMutable();
            return state.merge({ userObj: data })
            break;
        }

        case UserType.SET_INITIAL_STATE:
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;


        default:
            return state;
    }
}
