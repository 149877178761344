import React from 'react';
import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import 'react-toastify/dist/ReactToastify.css';
import i18n from '../../../i18n/i18n';
import randomColor from 'randomcolor';
import DashboardDrawer from '../../../utils/DashboardDrawer';
import axios from '../../../utils/AxiosWrapper';
import DashboardFilters from '../DashboardFilters';
import Chart from './Chart';

const styles = theme => ({
    card: { minWidth: 275, padding: '10px', width: '90%', },
    title: { marginBottom: 16, fontSize: 14, },
});

export default class RevenueUsed extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            total: null,
            season: [], sector: [], crop: [], field: [], plan: [],
            revenueLabels: [], revenueValues: [],
            revenueGroupLabels: [], revenueGroupValues: [],
            revenueSubgroupLabels: [], revenueSubgroupValues: [],
            colorsR: [], colorsRG: [], colorsRSG: [],
        }
    }

    componentDidMount() {
        this.getChartData([], [], [], [], [], 'IV_prinos_enterprise');
    }

    getChartData(season, sector, crop, field, plan, tabela) {
        axios.get('api/report/read_dashboard', { params: { season: season, sector: sector, crop: crop, field: field, plan: plan, tabela: tabela, } })
            .then(response => {
                let objR = formatData(response.data.data, 'materijal_naziv', 'jedinica_mere', ' / ', 'prinos');
                let objRG = formatData(response.data.data, 'materijal_grupa_naziv', 'jedinica_mere', ' / ', 'prinos');
                let objRSG = formatData(response.data.data, 'materijal_podgrupa_naziv', 'jedinica_mere', ' / ', 'prinos');
                this.setState({
                    total: response.data.total,
                    revenueLabels: objR.labels, revenueValues: objR.values, colorsR: objR.colors,
                    revenueGroupLabels: objRG.labels, revenueGroupValues: objRG.values, colorsRG: objRG.colors,
                    revenueSubgroupLabels: objRSG.labels, revenueSubgroupValues: objRSG.values, colorsRSG: objRSG.colors,
                });
            })
            .catch(error => console.log('Error: ', error));
    }

    getValues(items, states) {
        for (let i = 0; i < states.length; i++) items.push(states[i].value);
        return items;
    }

    onPieClick = () => { this.props.history.push('/revenue_used'); }
    onTableClick = () => { this.props.history.push('/revenue_table'); }
    onProfitClick = () => { this.props.history.push('/revenue_cost'); }
    handleChange = name => selectedOption => { this.setState({ [name]: selectedOption }); }

    render() {
        let stringTotal = 0;
        if (this.state.total !== null) stringTotal = this.state.total;

        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div style={{ 'padding': '20px', width: '100%' }}>
                    <Card style={styles.card}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <h1 style={{ marginLeft: '10px', marginBottom: '10px' }}>{i18n.t('Total Number of Reports')}: {stringTotal}</h1>
                        </div>
                    </Card>
                </div>
                <div style={{ display: 'flex', alignSelf: 'center', marginLeft: '-10px' }}>
                    <div style={{ marginTop: '0px', marginRight: '1px' }}>
                        <DashboardFilters
                            handleChange={this.handleChange}
                            season={this.state.season} sector={this.state.sector} crop={this.state.crop} field={this.state.field} plan={this.state.plan}
                        />
                    </div>
                    <div style={{ display: 'flex', alignSelf: 'center', marginRight: '10px' }}>
                        <Button
                            onClick={(e) => {
                                let seasonValues = [], sectorValues = [], cropValues = [], fieldValues = [], planValues = [];

                                seasonValues = this.getValues(seasonValues, this.state.season);
                                sectorValues = this.getValues(sectorValues, this.state.sector);
                                cropValues = this.getValues(cropValues, this.state.crop);
                                fieldValues = this.getValues(fieldValues, this.state.field);
                                planValues = this.getValues(planValues, this.state.plan);

                                this.getChartData(seasonValues, sectorValues, cropValues, fieldValues, planValues, 'IV_prinos_enterprise');
                            }}
                            style={{ backgroundColor: '#04764e', color: 'white', height: '3em', width: '80px', marginTop: '20px', marginLeft: '20px' }}>
                            {i18n.t('Submit')}
                        </Button>
                        <Button
                            onClick={(e) => {
                                this.setState({ season: [], sector: [], crop: [], field: [], plan: [] });
                            }}
                            style={{ backgroundColor: '#04764e', color: 'white', height: '3em', width: '80px', marginTop: '20px', marginLeft: '10px', marginRight: '25px' }}
                        >
                            {i18n.t('Reset')}
                        </Button>
                    </div>
                </div> <br></br>
                <div style={{ width: '94%', marginLeft: '10px' }}>
                    <Chart width={100} title={i18n.t('Yield by Group')} color={this.state.colorsRG} labels={this.state.revenueGroupLabels} values={this.state.revenueGroupValues} currency={false} />
                </div> <br></br>
                <div style={{ width: '94%', marginLeft: '10px' }}>
                    <Chart width={100} title={i18n.t('Yield by Subgroup')} color={this.state.colorsRSG} labels={this.state.revenueSubgroupLabels} values={this.state.revenueSubgroupValues} currency={false} />
                </div> <br></br>
                <div style={{ width: '94%', marginLeft: '10px' }}>
                    <Chart width={100} title={i18n.t('Yield')} color={this.state.colorsR} labels={this.state.revenueLabels} values={this.state.revenueValues} currency={false} />
                </div>
                <DashboardDrawer onPieClick={this.onPieClick} onTableClick={this.onTableClick} onProfitClick={this.onProfitClick} isVisible={true} />
            </div >
        )
    }
}


function getLabels(item, labels) {
    labels.push(item);
    let unique = new Set(labels);
    labels = [...unique];
    return labels;
};

function getData(values, filter, cena) {
    let quant = 0;
    filter.map((mat) => {
        if (mat[cena] !== undefined && mat[cena] > 0) {
            quant += parseFloat(mat[cena]);
        }
    });
    values.push(quant.toFixed(2));
    return values;
};

function formatData(data, name1, name2, slash, cena) {
    let temp_labels = [], labels = [], values = [];

    data.forEach((item, i) => temp_labels = getLabels(item[name1] + slash + item[name2], temp_labels).sort());
    temp_labels.forEach(item => { let filter = data.filter(i => i[name1] + slash + i[name2] === item); values = getData(values, filter, cena) });
    values = values.map(item => Number(item));
    labels = temp_labels.map((item, i) => item + ': ' + (values[i].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")));

    let object = { labels: labels, values: values, colors: randomColor({ count: values.length }) };
    return object;
}