import React from 'react';
import i18n from '../../../i18n/i18n';
import axios from '../../../utils/AxiosWrapper';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { Button, Card, Link, Typography } from '@material-ui/core';
import DatePickerMeteo from '../../../utils/DatePickerMeteo';
import { formatData, formatForecast, formatDisease } from './../DataFormatters';
import { withStyles } from '@material-ui/core/styles';
import { MoonLoader } from 'react-spinners';
import MeteoTable from '../MeteoTable';
import MeteoInfoButton from '../MeteoInfoButton';
import InfoDataDeltaT from '../Info/Data/InfoDataDeltaT';


const styles = {};

export class DataDeltaT extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            station_id: this.props.row.uid,
            startDate: moment().subtract(7, 'days'),
            endDate: moment(),
            data: {},
            load: true
        };
    }

    setDates = (start, end) => { this.setState({ startDate: start, endDate: end }, () => { this.getPessl(); }); }

    getData = (path) => {
        axios.post('/api/testMeteo/meteo', path)
            .then(res => { let obj = formatData(res); this.setState({ data: obj, load: false }); })
            .catch(err => console.log('Error: ', err))
    }

    getPessl = () => {
        if (this.state.startDate !== null && this.state.endDate !== null) {
            let start_date = moment(this.state.startDate).unix();
            let end_date = moment(this.state.endDate).unix();
            let station_id = this.state.station_id;

            // URL-ovi za podatke sa meteo stanica
            let disease_path = '/data/' + station_id + '/hourly/from/' + start_date + '/to/' + end_date;
            let forecast_path = '/forecast/' + station_id + '/basic-1h';
            let data_path = '/data/' + station_id + '/hourly/from/' + start_date + '/to/' + end_date;

            // Disease mora POST method, za Data i Forecast mora GET method
            this.getData({ path: data_path, method: "GET" });
        };
    }

    render() {
        const { classes } = this.props;

        // console.log('disease1: ', this.state.disease1);
        // console.log('disease2: ', this.state.disease2);
        // console.log('data meteo: ', this.state.data);

        let graph1 = {};

        if (
            this.state.data.dates &&
            this.state.data
        ) {
            graph1 = {
                labels: this.state.data.dates,
                datasets: [
                    {
                        data: this.state.data.dew_point,
                        label: i18n.t('Delta T [°C]'),
                        backgroundColor: 'transparent',
                        borderColor: 'rgb(90, 232, 223)',
                        type: 'line',
                        borderWidth: 2,
                        pointBackgroundColor: 'rgb(90, 232, 223)', 
                        pointBorderColor: 'rgb(90, 232, 223)',
                        pointRadius: this.state.data.dates.length > 504 ? 0 : 2,
                    },
                    // {
                    //     data: this.state.data.temperatures,
                    //     label: i18n.t('Temperature [°C]'),
                    //     backgroundColor: 'transparent',
                    //     borderColor: 'red',
                    //     type: 'line',
                    //     borderWidth: 2,
                    //     pointRadius: this.state.data.dates.length > 72 ? 0 : 2,
                    // },
                ],
            };

        };

        const options = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        gridLines: {
                            drawOnChartArea: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[°C]'
                        },
                    },
                ],
            },
        }

        return (
            <div style={{ paddingLeft: '35px' }}>
                <div style= {{display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'center'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                        <p style={{fontSize: 20, marginRight: 4}}>{i18n.t('Delta T')}</p>
                        </div>
                        <div style={{marginLeft: 20}}>
                        <MeteoInfoButton
                            title={i18n.t('Delta T')}
                            text={<InfoDataDeltaT />}
                            height='400px'
                            width='sm'
                            />
                        </div>
                </div>
                <DatePickerMeteo startDate={this.state.startDate} endDate={this.state.endDate} setDates={this.setDates} getPessl={this.getPessl} />
                {this.state.load === true && <div style={{ marginLeft: '39%', marginTop: '14%' }}> <MoonLoader loading={this.state.load} size={100} /></div>}
                {this.state.load === false &&
                <div>
                <div style={{ width: '95%', marginTop: '10px' }}> 
                    <Bar data={graph1} height={300} width={600} options={options} />
                </div>
                <MeteoTable data={{
                    headers: [ i18n.t('Delta T [°C]'), ],
                    dates: this.state.data.dates,
                    dew_point: this.state.data.dew_point,
                    temperatures: this.state.data.temperatures,
                    
                }} />
                <div style={{ marginTop: '20px' }}></div> 
                </div>
                }
            </div>
        );
    }
};

export default withStyles(styles)(DataDeltaT);
