import axios from '../../utils/AxiosWrapper';
export const Type = {
    //GET
    GET_APP_STATUS_CALL: 'GET_APP_STATUS_CALL',
    GET_APP_STATUS_SUCCESS: 'GET_APP_STATUS_SUCCESS',
    GET_APP_STATUS_FAILED: 'GET_APP_STATUS_FAILED',
    //READ
    GET_APPLICATIONS_CALL: 'GET_APPLICATIONS_CALL',
    GET_APPLICATIONS_SUCCESS: 'GET_APPLICATIONS_SUCCESS',
    GET_APPLICATIONS_FAILED: 'GET_APPLICATIONS_FAILED',
    //UPDATE
    UPDATE_SUBSIDIE_CALL: 'UPDATE_SUBSIDIE_CALL',
    UPDATE_SUBSIDIE_SUCCESS: 'UPDATE_SUBSIDIE_SUCCESS',
    UPDATE_SUBSIDIE_FAILED: 'UPDATE_SUBSIDIE_FAILED',

    APPLICATION_DOCUMENTS_CALL: 'APPLICATION_DOCUMENTS_CALL',
    APPLICATION_DOCUMENTS_SUCCESS: 'APPLICATION_DOCUMENTS_SUCCESS',
    APPLICATION_DOCUMENTS_FAILED: 'APPLICATION_DOCUMENTS_FAILED',

    //DASHBOARD ITEMS
    GET_DASHBOARD_CALL: 'GET_DASHBOARD_CALL',
    GET_DASHBOARD_SUCCESS: 'GET_DASHBOARD_SUCCESS',
    GET_DASHBOARD_FAILED: 'GET_DASHBOARD_FAILED',

    //SET
    SET_APPLICATION_ROW_IN_REDUCER: 'SET_APPLICATION_ROW_IN_REDUCER',
    SET_INITIAL_STATE: 'SET_INITIAL_STATE',

};

export function getApplicationStatuses() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_APP_STATUS_CALL
        });
        axios.get('api/application/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_APP_STATUS_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_APP_STATUS_FAILED
                });
            });
    }
}

export function updateSubsidie(applicationsObj) {
    return (dispatch) => {

        dispatch({
            type: Type.UPDATE_SUBSIDIE_CALL
        });

        axios.post('api/application/update', {
            id: applicationsObj.id,
            id_program: applicationsObj.id_program,
            id_aplikacija_status: applicationsObj.id_aplikacija_status,
            value_asked: applicationsObj.value_asked,
            state: applicationsObj.state,
            federal: applicationsObj.federal,
            zonal: applicationsObj.zonal,
            value_approved: applicationsObj.value_approved,
            value: applicationsObj.value,
            opis: applicationsObj.opis,
            datum_kreiranja: applicationsObj.datum_kreiranja,
            datum_apliciranja: applicationsObj.datum_apliciranja,
            id_korisnik: applicationsObj.id_korisnik,
        })
            .then(function (response) {
                dispatch({
                    type: Type.UPDATE_SUBSIDIE_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.UPDATE_SUBSIDIE_FAILED
                });
            });
    }
}

export function getApplications(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_APPLICATIONS_CALL
        });
        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/application/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_APPLICATIONS_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_APPLICATIONS_FAILED
                });
            });
    }
}

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}
export function setRowInReducer(rowInfo, name) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_APPLICATION_ROW_IN_REDUCER,
            rowInfo: rowInfo,
            name: name
        })
    }
}

export function getApplicationDocuments(id) {
    return (dispatch) => {

        dispatch({
            type: Type.APPLICATION_DOCUMENTS_CALL,
        });

        axios.get(`api/application/document?id_aplikacija=${id}`)
            .then(function (response) {
                dispatch({
                    type: Type.APPLICATION_DOCUMENTS_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.APPLICATION_DOCUMENTS_FAILED
                });
            });
    }
}

export function getDashboardItems(filters) {
    return (dispatch) => {
        dispatch({
            type: Type.GET_DASHBOARD_CALL,
        });

        return axios.get('api/application/dashboard', { params: { filters } })
            .then(function (response) {
                dispatch({
                    type: Type.GET_DASHBOARD_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_DASHBOARD_FAILED
                });
            });
    }
}