import React from 'react';
import i18n from '../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { wentWrong } from "../../../utils/ToastConfig";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from '../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
export default class AddWorkplaces extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            naziv: '',
        }
    }

    componentDidMount() {

        if (this.props.edit === true) {
            this.setState({
                naziv: this.props.data.naziv,
            })
        }
    }

    getByURL(url, obj, event, required) {
        event = event || '';
        required = required || '';
        if (event != null) {
            axios.get(`api/${url}`, { params: { [required]: event } })
                .then(res => this.setState({ [obj]: res.data.data }))
                .catch(error => wentWrong('Something went wrong, please try again!'));
        }
    }

    addWorkplaces() {
        // let data = {
        //     id: this.props.data.id, // da prepozna za edit na backendu
        //     naziv: this.state.naziv,
        // }
        let data = {
            naziv: this.state.naziv,
        };
        
        if (this.props.data) {
            data.id = this.props.data.id;
        }
        axios.post('api/workplace/create', { data: data })
            .then(res => {
                this.props.handleCreate();
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log(`Error: ${error}`);
            })
    }

    render() {
        return (
            <div style={{ padding: 20 }}>
                <Dialog open={this.props.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} style={{ background: '#04764e' }} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit Workplace') : i18n.t('Add Workplace')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <form id="add-layer">
                            <Grid container justify="space-between" alignContent="space-between" >

                                <Grid xs={12}>
                                    <TextField required
                                        label={i18n.t('Workplace name')}
                                        value={this.state.naziv}
                                        style={{ width: '532px', marginBottom: '10px', marginTop: '10px' }}
                                        onChange={(e) => { this.setState({ naziv: e.target.value }); }} >
                                    </TextField>
                                </Grid>

                                <Grid item xs={12}>
                                    <Button style={{ alignSelf: 'center', backgroundColor: '#04764e', color: 'white', marginTop: '20px' }}
                                        onClick={() => this.addWorkplaces()}
                                        disabled={this.props.layerPosting} >
                                        {this.props.edit ? i18n.t('Save') : i18n.t('Add')}
                                    </Button>
                                </Grid>

                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
};