import React from 'react';
import { Card, withStyles, } from '@material-ui/core';
import axios from '../../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { styles } from '../InfoStyles';

export class InfoSolarRadiation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 1
        };
    };

    componentDidMount() {
        axios.get('api/map/language')
            .then(res => {
                let response = res.data.data[0].id_jezik;
                if (response) this.setState({ language: response });
            })
            .catch(err => { console.log(err) })
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.language === 1 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Transformacijom sunčevog zračenja može se proizvesti električna energija. 
                            Solarni agregati su veoma pogodni za primenu u poljoprivredi, posebno na lokacijama gde je mrežno napajanje nedostupno. 
                            Postoje dva osnovna vida korišćenja solarne energije a to su dobijanje toplotne i električne energije. 
                            Električna energija je našla primenu za navodnjavanje i daljinsko upravljanje navodnjavanjem, osvetljenje, napajanje meteoroloških stanica i na farmama životinja. 
                            Takođe, uspešno se može koristiti za zagrevanje zaštićenih prostora, za sušenje voća i povrća kao i za zagrevanje potrošne vode.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 2 &&-
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Electrical energy can be produced by transforming solar radiation. 
                            Solar aggregates are very suitable for agricultural production, especially in locations where mains power is unavailable.
                            There are two basic ways of using solar energy, heat energy and electricity. 
                            Electric energy has found application for irrigation and remote control of irrigation, lighting, powering meteorological stations and on animal farms. 
                            Also, it can be successfully used for heating greenhouses, for drying fruits and vegetables and for heating water.

                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 4 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Električna energija se može proizvesti transformacijom sunčevog zračenja. Solarni agregati vrlo su 
                            prikladni za primjenu u poljoprivredi, posebno na mjestima gdje je nedostupna električna energija.
                            Dva su osnovna načina korištenja sunčeve energije, a to su toplina i električna energija. 
                            Električna energija je našla primjenu za navodnjavanje i daljinsko upravljanje navodnjavanjem, 
                            rasvjetu, napajanje meteoroloških stanica i na farmama životinja. Također, može se uspješno koristiti 
                            za zagrijavanje zaštićenih prostora, za sušenje voća i povrća, kao i za zagrijavanje vode.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 5 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Električno energijo lahko proizvedemo s transformacijo sončnega sevanja. 
                            Sončni agregati so zelo primerni za kmetijsko pridelavo, še posebej na lokacijah, 
                            kjer ni električnega omrežja. Obstajata dva osnovna načina izrabe sončne energije, 
                            toplotna energija in električna energija. Električna energija je našla uporabo pri 
                            namakanju in daljinskem nadzoru namakanja, razsvetljave, napajanja meteoroloških postaj in na živalskih farmah. 
                            Prav tako se lahko uspešno uporablja za ogrevanje rastlinjakov, za sušenje sadja in zelenjave ter za ogrevanje vode.
                            </p>
                        </div>
                    </Card>
                }
            </div>
        )
    };
};

export default withStyles(styles)(InfoSolarRadiation);
