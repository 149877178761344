import axios from '../../utils/AxiosWrapper';
export const Type = {
    //UNIT
    GET_UNIT_CALL: 'GET_UNIT_CALL',
    GET_UNIT_SUCCESS: 'GET_UNIT_SUCCESS',
    GET_UNIT_FAILED: 'GET_UNIT_FAILED',

};

export function getUnit() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_UNIT_CALL
        });

        axios.get('api/unit/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_UNIT_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_UNIT_FAILED
                });
            });
    }
}