/**
 * Created by pajicv on 5/14/18.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { WMSTileLayer, LayersControl } from 'react-leaflet';

import * as LayerSelectors from '../selectors/LayerSelectors';

const { Overlay } = LayersControl;

class ClientLayers extends Component {

    getOverlays() {

        const { layers, removeLayer, removeLayerControl, addOverlay } = this.props;

        //console.log(layers);

        return layers.map(layer => {
            return (
                <Overlay checked={ layer.visible }
                         name={layer.name}
                         addOverlay={addOverlay}
                         removeLayer={removeLayer}
                         removeLayerControl={removeLayerControl}>
                    <WMSTileLayer
                        url={layer.url}
                        tileSize={layer.tileSize}
                        maxcc={layer.maxcc}
                        minZoom={layer.minZoom}
                        maxZoom={layer.maxZoom}
                        layers={layer.layers}
                        tiled={layer.tiled}
                        transparent={layer.transparent}
                        format={layer.format}
                    />
                </Overlay>
            );

        });

    }

    render() {

        const { addOverlay } = this.props;

        const overlays = this.getOverlays();

        return overlays;
    }
}

function mapStateToProps(state) {
    return {
        layers: LayerSelectors.getClientLayers(state)
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientLayers);
