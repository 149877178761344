import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import ViewMachines from './ViewMachines'
import { deleteMachine, setInitialState } from '../../../actions/resources/MachinesActions';

export class MachinesMainComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            add: false,
            edit: false,
            showFilters: false,
            selectedIndex: 0,
            selection: [],
            name: '',
            duplicate: false
        };
    }

    handleClick = () => {
        if (this.state.edit == true && this.state.add == false) {
            this.setState({ edit: false })
        }
        this.setState({ add: !this.state.add }, () => {
            if (this.state.add == true && this.state.edit == false) {
                this.setState({
                    selection: [],
                    name: '',
                })
            };
        });
    };

    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    handleSelection = (selection, action, index) => {
        if (action) {
            this.setState((state) => {
                let selectionState = state.selection;
                selectionState.splice(index, 1);
                return { ...state, selection: selectionState };
            })
        } else {
            this.setState((state) => {
                let selectionState = state.selection;
                selectionState = selection && [...selectionState, selection] || [];
                return { ...state, selection: selectionState };
            })
        }
    }

    onDeleteClick = () => {
        this.setState({ delete: !this.state.delete });
    }

    deleteItem = () => {
        this.props.deleteMachine(this.state.selection, this.state.name);
        this.setState({ delete: false })
    }

    onEditClick() {
        this.setState({ add: !this.state.add, edit: true });
    }

    onDuplicateClick = () => {
        this.setState({ duplicate: !this.state.duplicate })
    }

    render() {
        const { selection, duplicate } = this.state;
        let content;
        content =
            <div className="table-header">
                {/* <Button
                    onClick={() => this.onSearchClick()}
                    variant="fab"
                    color="secondary"
                    aria-label="add"
                    className="action-button"
                >
                    <span className="icon-search headerIcon" />
                </Button> */}
                {selection.length == 1 && selection[0].favorit === true &&
                    <Button
                        onClick={() => this.onDuplicateClick()}
                        variant="fab"
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-stack headerIcon" />
                    </Button>
                }
            </div>
        return (
            <div className="page">
                {content}
                {this.state.selectedIndex === 0 && <ViewMachines duplicate={duplicate} onDuplicateClick={this.onDuplicateClick} setSelection={this.handleSelection} edit={this.state.edit} add={this.state.add} showFilters={this.state.showFilters}
                    handleClose={this.handleClick} />}
            </div>
        )
    }

};

function mapStateToProps(state) {
    return {
        deleted: state.machinesReducer.deleted,
        deleteMachineFailed: state.machinesReducer.deleteMachineFailed,
        machinesSubgroupsPostingSuccess: state.machinesReducer.machinesSubgroupsPostingSuccess,
        machinesPostingSuccess: state.machinesReducer.machinesPostingSuccess,
        machinesGroupsPostingSuccess: state.machinesReducer.machinesGroupsPostingSuccess
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deleteMachine: (selection, name) => dispatch(deleteMachine(selection, name)),
        setInitialState: (component) => dispatch(setInitialState(component)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MachinesMainComponent)