import immutable from 'seamless-immutable';
import { Type as WorkingOperationType } from '../../actions/resources/WorkingOperationsActions';


export const INITIAL_STATE = immutable({
    //dropdowns
    woGroupDropdown: [],
    groupFetching: false,
    groupFetchingFailed: false,
    woDropdown: [],
    woDropdownFetching: false,
    woDropdownFetchingFailed: false,


    //radne operacije
    workingOperationPosting: false,
    workingOperationPostingSuccess: false,
    workingOperationPostingFailed: false,
    working_operation: [],
    workingOperationObj: {},
    workingOperationFetching: false,
    workingOperationFetchingFailed: false,
    workingOperationPages: 1,

    //radne operacije grupe
    working_operation_group: [],
    workingOperationGroupObj: {},
    workingOperationGroupsFetching: false,
    workingOperationGroupsFetchingFailed: false,
    workingOperationGroupsPages: 1,
    workingOperationGroupsPosting: false,
    workingOperationGroupsPostingSuccess: false,
    workingOperationGroupsPostingFailed: false,

    //delete
    deleted: false,
    deleteOperationFailed: false,

});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case WorkingOperationType.SET_INITIAL_STATE:
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;

        case WorkingOperationType.GET_WORKING_OPERATION_GROUP_CALL: {
            const groupFetching = true;
            return state.merge({ groupFetching });
            break;
        }

        case WorkingOperationType.GET_WORKING_OPERATION_GROUP_SUCCESS: {
            const woGroupDropdown = action.data.data;
            const groupFetching = false;
            return state.merge({ woGroupDropdown, groupFetching });
            break;
        }

        case WorkingOperationType.GET_WORKING_OPERATION_GROUP_FAILED: {
            const groupFetchingFailed = true;
            const groupFetching = false;
            return state.merge({ groupFetchingFailed, groupFetching });
            break;
        }
        case WorkingOperationType.GET_WORKING_OPERATION_DROPDOWN_CALL: {
            const woDropdownFetching = true;
            return state.merge({ woDropdownFetching });
            break;
        }

        case WorkingOperationType.GET_WORKING_OPERATION_DROPDOWN_SUCCESS: {
            const woDropdown = action.data.data;
            const woDropdownFetching = false;
            return state.merge({ woDropdown, woDropdownFetching });
            break;
        }

        case WorkingOperationType.GET_WORKING_OPERATION_DROPDOWN_FAILED: {
            const woDropdownFetchingFailed = true;
            const woDropdownFetching = false;
            return state.merge({ woDropdownFetchingFailed, woDropdownFetching });
            break;
        }

        case WorkingOperationType.ADD_WORKING_OPERATION_CALL: {
            const workingOperationPosting = true;
            return state.merge({ workingOperationPosting });
            break;
        }

        case WorkingOperationType.ADD_WORKING_OPERATION_SUCCESS: {
            const workingOperationPosting = false;
            const workingOperationPostingSuccess = true;
            return state.merge({ workingOperationPostingSuccess, workingOperationPosting, workingOperationObj: INITIAL_STATE.workingOperationObj })
            break;
        }

        case WorkingOperationType.ADD_WORKING_OPERATION_FAILED: {
            const workingOperationPosting = false;
            const workingOperationPostingFailed = true;
            return state.merge({ workingOperationPosting, workingOperationPostingFailed });
            break;
        }

        case WorkingOperationType.GET_WORKING_OPERATION_CALL: {
            const workingOperationFetching = true;
            return state.merge({ workingOperationFetching });
            break;
        }

        case WorkingOperationType.GET_WORKING_OPERATION_SUCCESS: {
            const working_operation = action.data.data;
            const workingOperationFetching = false;
            const workingOperationPages = action.data.total_pages
            return state.merge({ working_operation, workingOperationFetching, workingOperationPages });
            break;
        }

        case WorkingOperationType.GET_WORKING_OPERATION_FAILED: {
            const workingOperationFetchingFailed = true;
            const workingOperationFetching = false;
            return state.merge({ workingOperationFetching, workingOperationFetchingFailed });
            break;
        }

        case WorkingOperationType.GET_WORKING_OPERATION_VIEW_CALL: {
            const workingOperationGroupsFetching = true;
            return state.merge({ workingOperationGroupsFetching });
            break;
        }

        case WorkingOperationType.GET_WORKING_OPERATION_VIEW_SUCCESS: {
            const working_operation_group = action.data.data;
            const workingOperationGroupsFetching = false;
            const workingOperationGroupsPages = action.data.total_pages
            return state.merge({ working_operation_group, workingOperationGroupsFetching, workingOperationGroupsPages });
            break;
        }

        case WorkingOperationType.GET_WORKING_OPERATION_VIEW_FAILED: {
            const workingOperationGroupsFetchingFailed = true;
            const workingOperationGroupsFetching = false;
            return state.merge({ workingOperationGroupsFetching, workingOperationGroupsFetchingFailed });
            break;
        }

        case WorkingOperationType.ADD_WORKING_OPERATION_GROUP_CALL: {
            const workingOperationGroupsPosting = true;
            return state.merge({ workingOperationGroupsPosting });
            break;
        }

        case WorkingOperationType.ADD_WORKING_OPERATION_GROUP_SUCCESS: {
            const workingOperationGroupsPosting = false;
            const workingOperationGroupsPostingSuccess = true;
            return state.merge({ workingOperationGroupsPostingSuccess, workingOperationGroupsPosting, workingOperationGroupObj: INITIAL_STATE.workingOperationGroupObj })
            break;
        }

        case WorkingOperationType.ADD_WORKING_OPERATION_GROUP_FAILED: {
            const workingOperationGroupsPosting = false;
            const workingOperationGroupsPostingFailed = true;
            return state.merge({ workingOperationGroupsPosting, workingOperationGroupsPostingFailed });
            break;
        }

        case WorkingOperationType.SET_WORKING_OPERATION_ROW_IN_REDUCER: {
            const data = action.rowInfo.asMutable();
            return state.merge({ [action.name]: data })
            break;
        }

        case WorkingOperationType.DELETE_WORKING_OPERATION_SUCCESS: {
            const array = state[action.name].asMutable();
            const forDelete = action.forDelete;
            for (let i = 0; i < array.length; i++) {
                for (let j = 0; j <= forDelete.length; j++) {
                    if (array[i] && array[i].id === forDelete[j]) {
                        array.splice(i, 1);
                    }
                }
            }
            const deleted = true;
            return state.merge({ [action.name]: array, deleted: deleted })
            break;
        }

        case WorkingOperationType.DELETE_WORKING_OPERATION_FAILED: {
            const deleteOperationFailed = true;
            return state.merge({ deleteOperationFailed });
            break;
        }

        default:
            return state;
    }
}