import React from 'react';
import i18n from '../../../i18n/i18n';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { withStyles } from '@material-ui/core/styles';
import DataInfo from '../Info/InfoPages/DataInfo';
import DataInfoImage from '../Info/InfoPages/imagery/DataInfoImage.png';


const styles = {
    margin: { margin: '1%' },
    paragraph: { fontSize: '20px', lineHeight: 'initial', textAlign: 'justify', margin: '3%' },
    image: { textAlign: 'center' }
};

export class DataLanding extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            load: true
        };
    }

    render() {
        const { classes } = this.props;

        return (
            
            <div style={{ paddingLeft: '15px',paddingRight: '15px',display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center' }}>
                
                <div style={{margin: '-1%'}}>
                    <p style={{fontSize: 20, marginRight: 4}}>{i18n.t('Historical data')}</p>
                </div>
                <div className={classes.margin}>
                    <DataInfo />
                </div>
                <div style={{maxWidth: '100%'}} className={classes.margin}>
                <img src={DataInfoImage} style={{maxWidth: 'inherit', boxShadow: '4px 8px 50px rgb(165, 165, 165)'}}/>
                </div>
            </div>
        );
    }
}


export default withStyles(styles)(DataLanding);
