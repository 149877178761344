import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n/i18n';
import { Button, IconButton, withStyles, RadioGroup, FormControlLabel, Radio, FormControl, FormLabel } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setInitialState, addStorage } from '../../actions/traffic/TrafficActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getSectorDropDown } from '../../actions/catastral_data/FieldActions';
import ReactSelect from '../../utils/ReactSelect';
import immutable from 'seamless-immutable';
const styles = theme => ({
    formControl: {
        margin: theme.spacing.unit * 3,
    },
})
export class AddStorage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            storageObj: {},
            sectorDropDown: []
        }
    }

    componentDidMount() {
        const { storageObj, getSectorDropDown } = this.props;
        getSectorDropDown()
        if (storageObj) {
            this.setState({
                storageObj
            })
        }
    }


    componentDidUpdate(prevProps) {
        const { storagePostingFAiled, setInitialState, sectorDropDown } = this.props;
        if (prevProps.storagePostingFAiled !== storagePostingFAiled) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
            setInitialState('storagePostingFAiled');
        }
        if (prevProps.sectorDropDown !== sectorDropDown) {
            this.setState({
                sectorDropDown: immutable.asMutable(sectorDropDown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            })
        }
    }

    addStorage = () => {
        const { addStorage } = this.props;
        const { storageObj } = this.state;
        if (storageObj.naziv && storageObj.kapacitet && storageObj.vlasnistvo && storageObj.id_sektor) {
            addStorage(
                storageObj
            )

        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    handleChangeValue = name => event => {
        const { storageObj } = this.state;
        let obj = Object.assign({}, storageObj);
        obj[name] = event.target.value;
        this.setState({ storageObj: obj });
    };

    handleChangeDropdown = name => selected => {
        const { storageObj } = this.state;
        let obj = Object.assign({}, storageObj);
        obj[name] = selected && selected.value || null;
        this.setState({ storageObj: obj });
    }


    render() {
        const { add, handleClose, edit, theme, classes } = this.props;
        const { storageObj, sectorDropDown } = this.state;
        return (
            <div style={{ padding: 20 }}>
                <Dialog
                    open={add}
                    onClose={handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="xs"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} style={{ background: theme.palette.secondary.dark }} className="responsive-dialog-title">{edit ? i18n.t('Edit storage') : i18n.t('Add storage')}
                        <IconButton onClick={handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container justify="space-between" alignContent="space-between" spacing={16}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    label={i18n.t('Naziv')}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    value={storageObj.naziv}
                                    onChange={this.handleChangeValue('naziv')}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <TextField
                                    required
                                    type="number"
                                    label={i18n.t('Capacity') + ' (t)'}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    value={storageObj.kapacitet}
                                    onChange={this.handleChangeValue('kapacitet')}
                                />
                                <FormLabel component="legend"></FormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <ReactSelect
                                    closeMenuOnSelect={true}
                                    isClearable={true}
                                    isMulti={false}
                                    menuPlacement="auto"
                                    value={sectorDropDown.find((element) => {
                                        return element.value === storageObj.id_sektor
                                    })}
                                    label={i18n.t('Sector') + ' *'}
                                    maxMenuHeight={100}
                                    onChange={this.handleChangeDropdown('id_sektor')}
                                    options={sectorDropDown}
                                    className="react-select-dropdown"
                                    classNamePrefix="select"
                                    placeholder={i18n.t('Choose sector')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    label={i18n.t('Grupa')}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    value={storageObj.grupa}
                                    onChange={this.handleChangeValue('grupa')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormLabel component="legend">{i18n.t('Rent or own?')}</FormLabel>
                                    <RadioGroup
                                        aria-label="Rent or own?"
                                        value={storageObj.vlasnistvo}
                                        onChange={this.handleChangeValue('vlasnistvo')}
                                    >
                                        <FormControlLabel value={i18n.t('Own')} control={<Radio />} label={i18n.t('Own')} />
                                        <FormControlLabel value={i18n.t('Rent')} control={<Radio />} label={i18n.t('Rent')} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    multiline
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    id="full-width"
                                    margin="normal"
                                    label={i18n.t('Description')}
                                    value={storageObj.opis}
                                    onChange={this.handleChangeValue('opis')}
                                />
                            </Grid>
                            <Grid item xs>
                                <Button
                                    style={{ alignSelf: 'center', color: 'white' }}
                                    variant="contained"
                                    color="primary"
                                    onClick={this.addStorage}
                                >
                                    {edit ? i18n.t('Save') : i18n.t('add')}
                                </Button>
                            </Grid>
                            <Grid item xs={3}>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        storagePostingFAiled: state.trafficReducer.storagePostingFAiled,
        storageObj: state.trafficReducer.storageObj,
        sectorDropDown: state.fieldReducer.sectorDropDown,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setInitialState: (component) => dispatch(setInitialState(component)),
        addStorage: (storageObj) => dispatch(addStorage(storageObj)),
        getSectorDropDown: () => dispatch(getSectorDropDown())
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(AddStorage))